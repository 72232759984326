import useNotificationActions from 'Component/NotiComp/hooks/useNotificationActions'
import { IconDoubleCheck } from 'Component/NotiComp/interfaces/_icon'
import './style.scss'
import {useTranslation} from "react-i18next";

export const HeaderNotification = () => {
    const {t} = useTranslation()
  const {handleAllRead} = useNotificationActions()
  return (
    <div className="header-notification ">
      <div className="header-notification__header">
        <div className="header-notification__title">{t('notification')}</div>
        <div className="header-notification__read" onClick={handleAllRead}>
          <IconDoubleCheck />
          {t('check_read_all')}
        </div>
      </div>
    </div>
  )
}
