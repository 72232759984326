import React from "react";
import styled from "styled-components";
import {GLOBAL_ICON} from "../../../../../../../../../../interfaceGlobal";
import {Text} from "../../../../../../../../../../common/text";
import {useTranslation} from "react-i18next";
export const ChartSeriesRatioProduct = ({...props}) =>{
  const { t } = useTranslation()
    const {data, key} = props
    return(
        <StyleChartSeries>
            { data.title === t('success') ? GLOBAL_ICON.seriesSuccess : GLOBAL_ICON.seriesFailed}
            <div style={{marginLeft: 8}}>
                <Text as={'p'} >{t(data?.title)}</Text>
                <Text fontSize={18} fontWeight={600}>{data?.total} {t('purchases_products')}</Text>
                <Text color={'#00AB56'} fontSize={16} fontWeight={500}>{' '}({Math.round(data?.percent * 100) / 100}%)</Text>
            </div>
        </StyleChartSeries>
    )
}
const StyleChartSeries = styled.div`
  width: 295px;
  height: 81px;
  padding: 8px 16px;
  display: flex;
  align-items: flex-start;
`