import {useContext, useEffect} from "react";
import { ReportProductContext} from "../provider/_context";
import {reportProductAction} from "../provider/_reducer";
import {getDateFromNow, formatDatetime, convertDateTimeToApiFormatV2, formatDatetimeV2} from "../../../utils/formatDate";
import useReportEcommerce from "../../../index";
import {sendRequestAuth} from "../../../../../../../api/api";
import config from "../../../../../../../config";
import {removeAcent} from "../../../../../../../common/fieldText/_functions";
import useAlert from "../../../../../../../hook/useAlert";
import {DASHBOARD_PRODUCT_TAG} from "../interface/_contant";

export const useReportProductFilter = ()=>{
    const {pageState, pageDispatch} = useContext(ReportProductContext)

    const{ecommerce, dateTime, stores, status, chartTag, topProductTag, topProductChart, topProductTable} = pageState.filter

    const ecommerceReportInitState = useReportEcommerce()

    const dateStart= dateTime?.dateStart
    const dateEnd= dateTime?.dateEnd

    const {showAlert} = useAlert()
    const queries={
        ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
        ecom_seller_id: ecommerce?.activeValue?.value === 'all' && stores?.activeValue?.length < 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        start_date:convertDateTimeToApiFormatV2(dateStart),
        end_date:convertDateTimeToApiFormatV2(dateEnd),
        sync_status:  +status?.activeValue?.status === 3 || !!!status?.activeValue  ? '' : status?.activeValue?.status,
        sort_type:'',

    }
    const queriesTopProduct={
        ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
        ecom_seller_id: ecommerce?.activeValue?.value === 'all' ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        start_date:convertDateTimeToApiFormatV2(dateStart),
        end_date:convertDateTimeToApiFormatV2(dateEnd),

    }
    const transformQueries = (qs)=>{
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        return queryString
    }

    const fetchApiFilter = async (qs, qsTop)=>{
        pageDispatch({
            type: reportProductAction.REPORT_PRODUCT_UPDATE,
            payload:{
                topProductTag: DASHBOARD_PRODUCT_TAG?.map(map=>({
                    ...map,
                    value: 0
                })) || [] ,
                topProductChart:topProductChart?.list?.map(map=> ({
                    ...map,
                    type: chartTag?.activeValue?.tab,
                    data: 0
                })),
                topProductTable:topProductTable?.list
            }
        })
        const res = await Promise.all([
            sendRequestAuth('get',`${config.API}/ecommerce/report/product/dashboard${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/top-product${transformQueries(qsTop)}`),
        ])

        if(res[0].data?.success || res[1]?.data?.success){
            const list = res[0].data?.data?.map(map=> ({
                ...map,
                type: chartTag?.activeValue?.tab,
                data: map[chartTag?.activeValue?.tab] || 0
            })).sort((min, max)=> +max?.data - +min?.data)

            pageDispatch({
                type: reportProductAction.REPORT_PRODUCT_UPDATE,
                payload:{
                    topProductTag: DASHBOARD_PRODUCT_TAG?.map(map=>({
                        ...map,
                        value: res[1]?.data?.meta[map?.type]
                    })) || [] ,
                    topProductChart: list,
                    topProductTable: list
                }
            })
        }

    }
    const list = ecommerceReportInitState?.pageState?.stores?.list
    const tagTopProduct = ecommerceReportInitState?.pageState?.topProduct?.meta
    const topProductList = ecommerceReportInitState?.pageState?.reportProduct
    useEffect(()=>{
        if(list?.length > 0 || !!tagTopProduct && topProductList?.length > 0)
            pageDispatch({
                type: reportProductAction.REPORT_PRODUCT_GET_ALL_LIST,
                payload:{
                    list: list,
                    listOrigin: list,
                    topProductTag: DASHBOARD_PRODUCT_TAG?.map(map=>({
                        ...map,
                        value: tagTopProduct[map?.type]
                    })) || [],
                    topProductChart:topProductList.map(map => ({
                        ...map,
                        type:'total_amount',
                        data: map?.total_amount || 0
                    })),
                    topProductTable:topProductList
                }
            })

    },[list, tagTopProduct ,topProductList])


    //======= ECOMMERCE ======
    const activeValue = ecommerce?.activeValue
    const handleActiveValue = data =>{
        let listOrigin = [...list]
        if(data?.value !== 'all'){
            listOrigin = listOrigin?.filter(filter => filter?.ecom_type === data?.type)
        }
        pageDispatch({
            type: reportProductAction?.REPORT_PRODUCT_ECOMMERCE_ACTIVE,
            payload: {
                active: data,
                list: listOrigin,
                listOrigin:listOrigin
            }
        })
        fetchApiFilter({
            ...queries,
            ecom_type: data?.value === 'all' ? '' : data?.value,
            ecom_seller_id:''
        },{...queriesTopProduct,
            ecom_type: data?.value === 'all' ? '' : data?.value,
            ecom_seller_id:''
        })
    }
    //====== END ECOMMERCE ====

    //====== DATE TIME======
    const listDate = dateTime?.list
    const activeDate = dateTime?.activeValue
    const handleActiveDate = data =>{
        let dateStart=''
        let dateEnd=''

        switch (data?.value) {
            case 'seven days':
                dateStart=getDateFromNow(-6,'start')
                dateEnd=getDateFromNow(0,'end')
                break;
            default:
                dateStart=getDateFromNow(-29,'start')
                dateEnd=getDateFromNow(0,'end')
                break
        }

        dateStart = formatDatetime(dateStart)
        dateEnd =  formatDatetime(dateEnd)

        pageDispatch({
            type: reportProductAction.REPORT_PRODUCT_DATE_TIME_ACTIVE,
            payload: {
                active:data,
                dateStart,
                dateEnd
            }
        })

        const query={
            ...queries,
            start_date:convertDateTimeToApiFormatV2(dateStart),
            end_date:convertDateTimeToApiFormatV2(dateEnd),
            sync_status:  status?.activeValue?.status || ''
        }
        const queryTopProduct={
            ...queriesTopProduct,
            start_date:convertDateTimeToApiFormatV2(dateStart),
            end_date:convertDateTimeToApiFormatV2(dateEnd)
        }
        fetchApiFilter(query, queryTopProduct)

    }
    //====== END DATE TIME======

    //====== STORES==========
    const listStore = stores?.list
    const listOriginStore = stores?.listOrigin
    const activeStore = stores?.activeValue
    const keywordStore = stores?.keyword
    const canSubmitFilter =[
        JSON.stringify(activeStore) !== JSON.stringify(stores?.applyFilter) && activeStore?.length > 0,
        JSON.stringify(status?.activeValue) !== JSON.stringify(status?.applyFilter)
    ].includes(true)
    const handleSelected = data =>{
        let newArr = [...activeStore]
        const findData = newArr?.find(find => +find?.ecom_seller_id === +data?.ecom_seller_id)
        pageDispatch({
            type:reportProductAction.REPORT_PRODUCT_STORES_SELECTED,
            payload: !!findData ?
                newArr?.filter(filter =>+filter?.ecom_seller_id !== +data?.ecom_seller_id ) :
                [...newArr, data]
        })
    }

    const handleResetInput = ()=> pageDispatch({
        type:reportProductAction.REPORT_PRODUCT_STORES_SELECTED,
        payload:[]
    })

    const handleTabChange = data =>{
        let list = [...activeStore]
        if(data === 'all') list = [...listOriginStore]

        pageDispatch({
            type:reportProductAction.REPORT_PRODUCT_STORES_TAB,
            payload:{
                tab: data,
                list: list
            }
        })
    }

    const handleApplyFilter = () =>{
        const typeFilter = [
            'stores',
            'status'
        ]
        const query={
            ...queries,
            ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
            ecom_seller_id: stores?.activeValue?.length === 0 && +stores?.activeValue?.length < 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
            sync_status:  +status?.activeValue?.status === 3 || !!!status?.activeValue ? '' : status?.activeValue?.status
        }
        const queryTopProduct ={
            ...queriesTopProduct,
            ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
            ecom_seller_id: stores?.activeValue?.length === 0 && +stores?.activeValue?.length < 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        }
        typeFilter?.forEach(each =>{
            if(each === 'stores')  pageDispatch({
                type:reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER,
                payload:{
                    type: 'stores',
                    active: stores?.activeValue
                }
            })
            if(each === 'status')  pageDispatch({
                type:reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER,
                payload:{
                    type: 'status',
                    active: status?.activeValue
                }
            })
        })
        fetchApiFilter(query, queryTopProduct)
    }

    const handleResetApply = ()=>{
        const types=[
            'stores',
            'status'
        ]
        types?.forEach(each=>  pageDispatch({
            type:reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER,
            payload:{
                type:each,
                active: each === 'stores' ? [] : null
            }
        }))

        const query={
            ...queries,
            ecom_seller_id: '',
            sync_status: ''
        }
        const queryTop = {
            ...queriesTopProduct,
            ecom_seller_id: '',
        }
        fetchApiFilter(query, queryTop)
    }
    const handleDeleteApply = (type)=>{
        let query={...queries}
        let queryTop={...queriesTopProduct}
        switch (type) {
            case "stores":

                pageDispatch({
                    type:reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER,
                    payload:{
                        type: 'stores',
                        active: []
                    }
                })
                query = {
                    ...queries,
                    ecom_seller_id: '',
                }
                queryTop={
                    ...queryTop,
                    ecom_seller_id: '',
                }
                break;
            default:

                pageDispatch({
                    type:reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER,
                    payload:{
                        type: 'status',
                        active: null
                    }
                })
                query = {
                    ...queries,
                    sync_status: ''
                }
                queryTop={
                    ...queryTop,
                }
                break
        }

        fetchApiFilter(query, queryTop)
    }

    const handleSearchStore = data =>{
        let listOrigin =[...listOriginStore]
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const listData = listOrigin.filter(item => {
            const formatNameItem = item?.shop_name
                ? removeAcent(item?.shop_name.toLowerCase())
                : ''
            const formatNameItemShortName = item?.shop_short_name
                ? removeAcent(item?.shop_short_name.toLowerCase())
                : ''

            if (formatNameItem.includes(formatDataValue)) return true
            if (formatNameItemShortName.includes(formatDataValue)) return true
            return false
        })
        pageDispatch({
            type: reportProductAction.REPORT_PRODUCT_STORES_SEARCH,
            payload: {
                keyword: data?.value || '',
                list: listData,
            },
        })
    }

    //===== END STORES ======

    //======STATUS======
    const listStatus = status?.list;

    const handleSelectedStatus = data => pageDispatch({
        type: reportProductAction.REPORT_PRODUCT_STATUS_SELECTED,
        payload: data
    })

    //====== END STATUS ======


    //=========CHART TAB======
    const handleChangeTab= data =>{
        let list = topProductChart?.list?.map(map=>({
            ...map,
            type: data?.tab,
            data: map[data?.tab]
        }))
        list = list?.sort((min, max)=> +max?.data - +min?.data )
        pageDispatch({
            type:reportProductAction.REPORT_PRODUCT_CHART_TAB_ACTIVE,
            payload:{
                tab: data,
                list: list
            }
        })
    }

    return{
        ecommerce:{
            activeValue: activeValue,
            onActive: handleActiveValue
        },
        dateTime:{
            list: listDate,
            activeValue:activeDate,
            dateStart: dateTime?.dateStart,
            dateEnd: dateTime?.dateEnd,
            onActiveDate: handleActiveDate
        },
        stores:{
            list: listStore,
            activeValue: activeStore,
            keyword: keywordStore,
            tab: stores?.tab,
            applyFilter: stores?.applyFilter,
            canSubmitFilter,

            onSelected: handleSelected,
            onInputReset: handleResetInput,
            onTabChange: handleTabChange,
            onApplyFilter: handleApplyFilter,
            onDeleteStore: handleResetApply,
            onSearch: handleSearchStore,
            onDeleteFilter: handleDeleteApply,
        },
        status:{
            list: listStatus,
            activeValue: status?.activeValue,
            tab: status?.tab,
            isStatus: status?.isStatus,
            applyFilter: status?.applyFilter,
            keyword: status?.keyword,

            onSelected: handleSelectedStatus,
            onDeleteFilter: handleDeleteApply,
        },
        chartTag:{
          list: chartTag?.list,
          activeValue:chartTag?.activeValue,
          onChange: handleChangeTab
        },
        topProductTag:{
            list: topProductTag?.list
        },
        topProductChart:{
            list: topProductChart?.list
        },

    }

}