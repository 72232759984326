import styled from 'styled-components'

export const StyledFixedActionBtnGroup = styled.div`
  position: fixed;
  bottom: 104px;
  right: 0;
  z-index: 3;

  transform: rotate(-90deg) translateY(630%);
  transform-origin: left;
`

export const StyledActionBtn = styled.button`
  height: 32px;
  margin: 0 8px 0 0;
  padding: 0 10px;

  background: #fff;
  border: none;
  border-radius: 8px 8px 0 0;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);

  font-size: 14px;
  line-height: 32px;

  transition: color 0.25s;

  cursor: pointer;

  a{
    color: #27272A;
    &:hover {
      color: #1e9a98;
    }
  }

  &:hover {
    color: #1e9a98;

    .action-btn {
      &__icon {
        svg {
          color: #1e9a98;

          path[stroke] {
            stroke: #1e9a98;
          }
        }
      }
    }
  }

  .action-btn {
    &__icon {
      margin: 0 10px 0 0;

      svg {
        display: inline;

        transform: rotate(90deg) translateX(5px);
        transition: color 0.25s;

        path[stroke] {
          transition: stroke 0.25s;
        }
      }
    }
  }
`
