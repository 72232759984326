
import {Text} from 'common/text'
import useNotificationActions from 'Component/NotiComp/hooks/useNotificationActions'
import { NOTIFICATION_MANAGEMENT_ICONS } from 'Component/NotiComp/interfaces/_icon'
import {NotificationContext} from 'Component/NotiComp/notification'
import {useContext, useEffect, useRef} from 'react'
import styled from 'styled-components'
import './style.scss'
import {format} from "date-fns";
import {useTranslation} from "react-i18next";
export const TableNotifications = () => {
  const {t} = useTranslation()
  const {onLoadMore, handleRead} = useNotificationActions()
const {pageState,pageDispatch} = useContext(NotificationContext)
  const {notice, loading, activeTab, canLoadMore, openModal, detailNotice} = pageState
  const {all,my,system} = notice
  const scrollRef = useRef(null)

  const handleDropdownScroll = (id,page,total) => {
    if (!canLoadMore || !onLoadMore || !scrollRef?.current) return
    const clientHeight = scrollRef.current.clientHeight
    const scrollHeight = scrollRef.current.scrollHeight
    const scrollTop = scrollRef.current.scrollTop
    if (clientHeight + scrollTop > scrollHeight - 84) onLoadMore(id,page,total)
  }
  const list = [all,system, my]
  useEffect(()=>{
    scrollRef.current.scrollTop = 0
  },[activeTab])
  return (
      <div
        ref={scrollRef}
        className="tab-notification__body"
        onScroll={()=>handleDropdownScroll(activeTab,list[activeTab]?.perPage,list[activeTab]?.total)}
      >
        {loading ? (
          <div style={{textAlign: 'center'}}>Loading...</div>
        ) : list[activeTab]?.list?.length > 0 ? (
          <div>
            {list[activeTab]?.list?.map(item => (
                <div
                  className={`tab-notification__item`}
                  style={{opacity: item.is_read === "1" ? '0.5' : '1'}}
                  onClick={()=>handleRead(item?.id,item, list[activeTab]?.perPage)}
                >
                  <div className="tab-notification__icon">
                    {item.notice_type === '1' && <>{NOTIFICATION_MANAGEMENT_ICONS.iconBlue}</>}
                    {item.notice_type === '0' && <>{NOTIFICATION_MANAGEMENT_ICONS.iconGreen}</>}
                  </div>
                  <div className="tab-notification__info">
                    <div style={{display:'flex',alignItems:'center'}}>
                      {item.is_read === "0" && <span style={{marginRight:'4px'}}>{NOTIFICATION_MANAGEMENT_ICONS.dot}</span>}
                      <div className={'tooltip-title'}>
                        <Text fontSize={14} fontWeight={600}>
                          {item.title}
                        </Text>
                      </div>
                    </div>
                    <div
                      className={'tooltipV2'}
                    >
                      <Text>{item.description}</Text>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                      {NOTIFICATION_MANAGEMENT_ICONS.clock}
                      <Text color={'#7C88A6'} fontSize={12} style={{marginLeft:'4px'}}>
                        {format(new Date(item.created_at), "dd/MM/yyyy HH:mm")}
                      </Text>
                    </div>
                  </div>
                </div>
            ))}
            {/*{!canLoadMore && (*/}
            {/*  <StyledLoadMore>*/}
            {/*    <Spinner size={48} thickness={4} />*/}
            {/*  </StyledLoadMore>*/}
            {/*)}*/}
          </div>
        ) : (
          <div className='tab-notification__empty'>{t('you_dont_have_noti_yet')}<img src={'/img/notification/empty.png'} alt='empty-notification'/></div>
        )}

      </div>
  )
}

const StyledLoadMore = styled.div`
  padding: 16px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`
