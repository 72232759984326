export const ICONS = {
  medal : (
    <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14106_83768)">
        <path fillRule="evenodd" clipRule="evenodd" d="M36.689 8.60236C40.7452 15.628 38.338 24.6117 31.3124 28.6679C24.2868 32.7242 15.3031 30.317 11.2468 23.2914C7.19059 16.2657 9.59775 7.28207 16.6234 3.22581C23.649 -0.830448 32.6327 1.57672 36.689 8.60236ZM27.6025 13.8484C28.7614 15.8558 28.0737 18.4225 26.0663 19.5815C24.059 20.7404 21.4922 20.0526 20.3333 18.0453C19.1744 16.038 19.8621 13.4712 21.8695 12.3123C23.8768 11.1533 26.4436 11.8411 27.6025 13.8484Z" fill="white" fillOpacity="0.1"/>
        <path d="M27.17 42.4773L22.0944 33.6862C25.7168 34.0717 29.49 33.3548 32.8863 31.3939C36.2826 29.4331 38.79 26.5239 40.2673 23.194L45.3429 31.9851C46.1633 33.4062 45.1087 35.1767 43.4684 35.132L34.9097 34.8987L30.8325 42.4274C30.0511 43.8703 27.9904 43.8984 27.17 42.4773Z" fill="white" fillOpacity="0.1"/>
      </g>
      <defs>
        <clipPath id="clip0_14106_83768">
          <rect width="41.9686" height="41.9686" fill="white" transform="translate(-1.54883 13.7178) rotate(-30)"/>
        </clipPath>
      </defs>
    </svg>
  ),
  crown: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13191_337497)">
        <path d="M10.7865 4.7211C11.0689 4.49192 11.2493 4.14204 11.2493 3.75C11.2493 3.05964 10.6897 2.5 9.99935 2.5C9.30899 2.5 8.74935 3.05964 8.74935 3.75C8.74935 4.14205 8.92983 4.49194 9.21225 4.72112L7.1373 9.56267L4.11275 7.44549C4.14739 7.33086 4.16602 7.20927 4.16602 7.08333C4.16602 6.39298 3.60637 5.83333 2.91602 5.83333C2.22566 5.83333 1.66602 6.39298 1.66602 7.08333C1.66602 7.63983 2.02967 8.11139 2.53231 8.27334L3.33728 15.9206C3.38192 16.3447 3.73957 16.6667 4.16603 16.6667H15.8327C16.2592 16.6667 16.6168 16.3447 16.6615 15.9206L17.4664 8.27333C17.969 8.11137 18.3327 7.63982 18.3327 7.08333C18.3327 6.39298 17.773 5.83333 17.0827 5.83333C16.3923 5.83333 15.8327 6.39298 15.8327 7.08333C15.8327 7.20928 15.8513 7.33087 15.886 7.44551L12.8614 9.56267L10.7865 4.7211Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_13191_337497">
          <rect width="16.6667" height="16.6667" fill="white" transform="translate(1.66602 1.66699)"/>
        </clipPath>
      </defs>
    </svg>
  )
}