import React, {useContext, useEffect} from 'react';
import { ReportOverviewContext} from "../provider/_context";
import {reportOverviewAction} from "../provider/_reducer";
import StringUtils from "../../../../../../orderSingle/utils/string";
import {sendRequestAuth} from "../../../../../../../api/api";
import config from "../../../../../../../config";
import useReportEcommerce from "../../../index";
import {useReportOverviewFilter} from "./useReportOverviewFilter";
import {useTranslation} from "react-i18next";

const useQuickConnect = () => {
  const {t} = useTranslation()
  const {pageState, pageDispatch} = useContext(ReportOverviewContext)
const {methods} = useReportOverviewFilter()

  // ======================= STORE ===================
  const storeLoading = pageState?.modal?.quickConnect?.loading
  const storeList = pageState?.modal?.quickConnect?.storeList
  const storeListDefault = pageState?.modal?.quickConnect?.storeListDefault
  const storeValue = pageState?.modal?.quickConnect?.storeValue
  const storeStatus = pageState?.modal?.quickConnect?.status
  const storeAutoProduct = pageState?.modal?.quickConnect?.autoProduct
  const storeValidate = pageState?.modal?.quickConnect?.storeValidate
  const storeResult = pageState?.modal?.quickConnect?.result
  const storeOpen = pageState?.modal?.quickConnect?.open

    const ecommerceReportInitState = useReportEcommerce()
    useEffect(()=>{
        if(ecommerceReportInitState?.pageState?.stores?.list){
            pageDispatch({
                type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_LIST,
                payload:{
                    list: ecommerceReportInitState?.pageState?.stores?.list,
                    listOrigin: ecommerceReportInitState?.pageState?.stores?.list,
                }
            })
        }
    },[ecommerceReportInitState?.pageState?.stores?.list])

  const handleUpdateStoreList = list => {
   pageDispatch({
      type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_LIST,
      payload:{
          list: list,
      }
    })
  }

  const handleSelectStoreValue = store => {
   pageDispatch({
      type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALUE,
      payload: store
    })
  }

  const handleChangeKeyword = data => {
    const formatDataValue = !!data?.value ? StringUtils.removeAcent(data?.value?.toLowerCase()) : ''
    const storeSearched = storeListDefault.filter(item => {
      const storeName = item?.shop_short_name || item?.shop_name
      const formatNameItem = storeName
        ? StringUtils.removeAcent(storeName.toLowerCase())
        : ''
      return formatNameItem.includes(formatDataValue.trim())
    })
    handleUpdateStoreList(storeSearched)
  }

  const handleToggleAutoProduct = _ =>pageDispatch({ type: reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_AUTO_PRODUCT })
  // ======================= END STORE ===================

  const handleClickTypeConnect = type => {
   pageDispatch({
      type: type.code === 'notConnect'
              ? storeStatus?.failConnect
                  ? reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_NOT_CONNECT
                  : ''
              : storeStatus?.notConnect
                  ? reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_FAIL_CONNECT
                  : '',
      payload: !type?.checked
    })
  }

  const handleModalQuickConnectLoading = type => {
   pageDispatch({
      type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_LOADING,
      payload: type
    })
  }

  const handleSubmit = async _ => {
    if(!!!storeValue?.id) {
     pageDispatch({
        type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE,
        payload: t('e_com_please_select_stall')
      })
    } else {
     pageDispatch({ type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE })
      handleModalQuickConnectLoading(true)

      const statusID =
        storeStatus.notConnect && storeStatus.failConnect
          ? '0,2'
          : storeStatus.notConnect
            ? '0'
            : storeStatus.failConnect
              ? '2'
              : '0'
      const response = await sendRequestAuth(
        'get',
        `${config.API}/ecommerce/product/quick-connect?seller_id=${storeValue?.ecom_seller_id}&status=${statusID}&is_sync=${storeAutoProduct ? 1 : 0}`
      )
      if(response?.data?.success) {
       pageDispatch({
          type: reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS,
          payload: {
            notConnect: true,
            failConnect: true
          }
        })
        handleModalQuickConnectLoading(false)
        handleSelectStoreValue({})
        handleToggleResultQuickConnect()
        handleCloseQuickConnect()
        methods?.fetchApiFilter(methods?.queries)
      } else {
       pageDispatch({
          type: reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS,
          payload: {
            notConnect: true,
            failConnect: true
          }
        })
        handleModalQuickConnectLoading(false)
        handleSelectStoreValue({})
        handleToggleResultQuickConnect()
        handleCloseQuickConnect()
      }
    }
  }

  const handleCloseQuickConnect = _ => {
   pageDispatch({ type: reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE })
   pageDispatch({type: reportOverviewAction.TOGGLE_MODAL_QUICK_CONNECT_OPEN})
   pageDispatch({
      type: reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS,
      payload: {
        notConnect: true,
        failConnect: true
      }
    })
   pageDispatch({ type: reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_AUTO_PRODUCT, payload: false })
  }

  const handleToggleResultQuickConnect = _ =>pageDispatch({type: reportOverviewAction.TOGGLE_MODAL_QUICK_CONNECT_RESULT})
  const handleOpen = _=> pageDispatch({type: reportOverviewAction.TOGGLE_MODAL_QUICK_CONNECT_OPEN})
  return {
    store: {
      loading: storeLoading,
      value: storeValue,
      list: storeList,
      status: storeStatus,
      autoProduct: storeAutoProduct,
      validate: storeValidate,
      result: storeResult,
      open:storeOpen,
    },
    methods: {
      onCloseQuickConnect: handleCloseQuickConnect,
      onCloseResultQuickConnect: handleToggleResultQuickConnect,
      onSelectStore: handleSelectStoreValue,
      onChangeKeyword: handleChangeKeyword,
      onClickTypeConnect: handleClickTypeConnect,
      onToggleAutoProduct: handleToggleAutoProduct,
      onSubmit: handleSubmit,
      onOpen: handleOpen
    }
  }
}

export default useQuickConnect