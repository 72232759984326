import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportProductFilter} from "../../hook/useReportProductFilter";
import {formatMoney} from "../../../../utils/functionUtil";
import {fNumber} from "../../../../utils/formatNumber";
import {Tooltip} from "../../../../../../../../common/tooltip";
import {useTranslation} from "react-i18next";
export const ProductOverView = ()=>{
  const { t } = useTranslation()
    const {dateTime, topProductTag} = useReportProductFilter()
    return(
        <StyleProductOverView>
            <div>
                <Text fontSize={18} fontWeight={600}>{t('overview')}</Text>
                <br/>
                <Text color={'#7C88A6'} >{dateTime?.dateStart} - {dateTime?.dateEnd}</Text>
            </div>
            <div className={'report-product-tag-top-product'}>
                {
                    topProductTag?.list?.map(map=><div
                        key={map?.id}
                        className={'report-product-tag-content'}
                    >
                        {map?.icon}
                        <div className={'report-product-tag-item'}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <Text>{t(map?.title)}</Text>
                                    {
                                        !!map?.iconToolTip && !!map?.tooltip && <Tooltip style={{marginLeft: 4,height: 17}} title={t(map?.tooltip)}>
                                            {map?.iconToolTip}
                                        </Tooltip>
                                    }
                            </div>

                            {
                                map?.isPrice ? <Text fontWeight={600} fontSize={20}>{formatMoney(map?.value)}</Text>:
                                    <Text fontWeight={600} fontSize={20}>{fNumber(map?.value)} {t('purchases_products')}</Text>
                            }
                        </div>
                    </div>)
                }
            </div>

        </StyleProductOverView>
    )
}
const StyleProductOverView = styled.div`

  .report-product-tag{
    &-top-product{
      margin-top: 24px;
      display: flex;
      gap: 24px;
    }
    &-content{
      display: flex;
      gap: 16px;
      width: 33%;
    }
  }
`