import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import ReactApexChart from "react-apexcharts";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../Component/chart";
import {ChartOptionReport} from "../revenue/_chartOption";
import {RevenueTag} from "./_revenueTag";
import {RevenueSelected} from "./_revenueSelected";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {TableLayout} from "../../../../../../../../layouts/tableLayout";
import {TableHeadRevenueList} from "./_tableHead";
import {TableBodyRevenueList} from "./_tableBody";
import {useTranslation} from "react-i18next";
export const Revenue = ()=>{
    const { t } = useTranslation()
    const {dateTime, revenue, status} = useReportOrderFilter()
    const {activeValue} = dateTime

    const data= revenue?.revenueData

    const list = revenue?.list

    const arrDate = data?.map(map => map?.title_date)
    const chartOptions = merge(BaseOptionChart(), {
        labels: arrDate,
        colors: revenue?.activeList?.map(map=>map?.color),
        activeList: revenue?.activeList,
        ...ChartOptionReport,
        language: {
          order: t('orders')
        },
    })
    return(
        <StyleImportantNumber>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding: "16px 16px 0 16px"}}>
                <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}  onClick={status?.isStatus && status?.onStatus}>
                    {status?.isStatus && GLOBAL_ICON.chevron_right}
                    <Text as={'p'} fontWeight={600} fontSize={18} lineHeight={'150%'}>{t('e_com_revenue_order')}</Text>
                </div>
                {
                    !status?.isStatus &&  <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={status?.onStatus}>
                        <Text color={'#1554F6'}>{t('general_view_detail')}</Text>
                        {GLOBAL_ICON.arrowLeft}
                    </div>
                }
            </div>
            {
                status?.isStatus ? <TableLayout
                        table={{
                            tHead: <TableHeadRevenueList/>,
                            tBody:<TableBodyRevenueList/>
                        }}
                    /> :
                    <>
                        <div className={'report-ecommerce-important-number'}>
                            {
                                list?.map(map => <RevenueTag
                                    key={map?.id}
                                    color={map?.color}
                                    title={t(map?.title)}
                                    tooltip={t(map?.tooltiTitle)}
                                    value={map?.value}
                                    isPrice={map?.isPrice}
                                    percent={map?.percent}
                                    compareTitle={activeValue?.value === 'seven days' ? t(map?.lastWeek) : t(map?.lastMonth)}
                                    type={map?.tab}
                                    data={map}
                                />)
                            }
                        </div>
                        <RevenueSelected/>
                        <ReactApexChart
                            type="line"
                            series={revenue?.activeList}
                            options={chartOptions}
                            height={364}
                            palette="1"
                        />
                    </>
            }
        </StyleImportantNumber>
    )
}

const StyleImportantNumber = styled.div`
  .report-ecommerce-important-number{
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    padding: 16px;
  }
  .apexcharts-canvas{
       .apexcharts-graphical{
        transform: translate(20px ,30px) !important;
      }
    }
  .report-arrow_box{
    border-radius: 8px;
    background: rgba(0, 31, 62, 0.95);
    width: 214px;
    padding: 12px;
    height: auto;
    p{
      color: #ffffff;
      font-family: 'SF Pro Display';
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
    span{
      color: #ffffff;
      font-family: 'SF Pro Display';
      font-size: 13px;
      font-weight: 400;
      line-height: 140%;
    }
    .item-tooltip{
        display: flex;
        align-items: flex-start;
    }
  }
`