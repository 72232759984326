import React from "react";
import {GLOBAL_ICON} from "../../../../../../../interfaceGlobal";


export const ECOMMERCE_LIST=[
    {id: 1, name: 'all', value: 'all', type: 'all'},
    {id: 2, name: 'Tiktok Shop', value: 'tiktok', type:'tiktok'},
    {id: 3, name: 'Shopee', value: 'shopee', type:'shopee'},
    {id: 4, name: 'Lazada', value: 'lazada', type:'lazada'},
]

export const transformImageEcommerce = (type, size)=>{
    switch (type) {
        case 'shopee':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoShopee.png'} width={size} height={size} alt={'logoShopee'}/>
        case 'lazada':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoLazada.png'} width={size} height={size} alt={'logoLazada'}/>
        case 'tiktok':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoTiktok.png'} width={size} height={size} alt={'logoTiktok'}/>
        case 'upos':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoupos.png'} width={size} height={size} alt={'logoUpos'}/>
        default:
            break;

    }
}
export const transformProductData = products => {
    return products?.map(item => {
        item.ecom_id = item.id
        item.id = item.ecom_product_id
        return item
    })
}


export const FILTER_DATE=[
    {id: 1, name: 'for_week', value: 'seven days'},
    {id: 2, name: 'for_month', value: 'thirty days'},
]


export const DASHBOARD_PRODUCT_TAG=[
    {
        id: 1,
        title: 'report__quantity_sold',
        iconToolTip: '',
        value: 0,
        isPrice: false,
        tooltip: '',
        icon: GLOBAL_ICON?.bookRedCircle,
        type:'total_quantity',
    },
    {
        id: 2,
        title: 'general_sales_value',
        iconToolTip: GLOBAL_ICON.question,
        value: 0,
        isPrice: true,
        tooltip: 'report__sale_value_tooltip',
        icon: GLOBAL_ICON?.bookBlueCircle,
        type:'total_amount',
    },
    {
        id: 3,
        title: 'report__total_discount_on_product',
        iconToolTip: '',
        value: 0,
        isPrice: true,
        tooltip: '',
        icon: GLOBAL_ICON?.bookGreenCircle,
        type:'total_discount',
    },
]

export const REPORT_PRODUCT_STATUS = [
    {id: 1, title: 'all', status: 3},
    {id: 2, title: 'linked_success', status: 1},
    {id: 3, title: 'link_failed', status: 2},
    {id: 4, title: 'link_not_yet', status: 0},
]

export const REPORT_PRODUCT_TAG_CHART=[
    {
        id: 1,
        title:'e_com_value_highest',
        tab:'total_amount',
    },
    {
        id: 2,
        title:'e_com_count_highest',
        tab:'total_quantity',
    },
    {
        id: 3,
        title:'e_com_percent_cancel_highest',
        tab:'ratio_cancel',
    },
    {
        id: 4,
        title:'e_com_cancel_highest',
        tab:'total_quantity_cancel',
    },
]

export const REPORT_PRODUCT_TABLE_HEAD=[
    {
        id: 1,
        name:'e_com_ranking_revenue',
        iconToolTip: '',
        tooltip: '',
        type:'date'
    },

    {
        id: 2,
        name:'booth',
        iconToolTip: '',
        tooltip: '',
        type:'revenue'
    },
    {
        id: 3,
        name:'total_value',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_value_product_note',
        type:'order'
    },
    {
        id: 4,
        name:'report__quantity_sold',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_amount_product_note',
        type:'agv_revenue'
    },
    {
        id: 5,
        name:'e_com_order_sale',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_order_sale_note',
        type:'agv_revenue'
    },
    {
        id: 6,
        name:'e_com_amount_cancel',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_con_amount_cancel_note',
        type:'agv_revenue'
    },
    {
        id: 7,
        name:'e_com_order_cancel',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_order_cancel_note',
        type:'agv_revenue'
    },
    {
        id: 8,
        name:'e_com_percent_cancel',
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_percent_cancel_note',
        type:'agv_revenue'
    },
]