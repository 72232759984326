import { useWindowSize } from 'Component/ListMenu/listMenu'
import useGlobalContext from 'containerContext/storeContext'
import { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SIDEBAR_COMPONENTS from './_components'
import { SIDEBAR_BOTTOM_ITEM, SIDEBAR_MENU_ITEMS } from './_constants'
import { SIDEBAR_MENU_ICONS } from './_icons'
import { StyledSidebar } from './_styled'
import { Text } from '../../common/text'
import { useTranslation } from 'react-i18next'

const { SidebarMenuItem } = SIDEBAR_COMPONENTS

export const SidebarContext = createContext(null)

export const Sidebar = ({ ...props }) => {
  const location = useLocation()
  const defaultActiveId = SIDEBAR_MENU_ITEMS.find(
    item =>
      (item?.path && location.pathname.includes(item.path)) ||
      item.list
        .map(child => child?.path && location.pathname.includes(child.path))
        .includes(true),
  )?.label

  const [globalState, globalDispatch] = useGlobalContext()
  const { shouldMinimizeSidebar } = globalState

  const [activeId, setActiveId] = useState(defaultActiveId)

  const handleSidebarToggle = () => globalDispatch({ type: 'TOGGLE_SIDEBAR' })

  const [width] = useWindowSize()

  useEffect(() => {
    if (width <= 1440) globalDispatch({ type: 'TOGGLE_SIDEBAR', payload: { toggle: true } })
    else globalDispatch({ type: 'TOGGLE_SIDEBAR', payload: { toggle: false } })
  }, [width])

  useEffect(() => {
    // close submenu when minimize sidebar
    if (shouldMinimizeSidebar) setActiveId(null)
  }, [shouldMinimizeSidebar])

  const {t} = useTranslation()
  return (
    <SidebarContext.Provider value={{ activeId, setActiveId }}>
      <StyledSidebar
        data-minimize={globalState?.shouldMinimizeSidebar}
        {...props}
      >
        <button
          className={`sidebar__toggle ${globalState?.shouldMinimizeSidebar ? 'sidebar__toggle__minimize' : ''} ${width > 1440 ? 'sidebar__toggle__minimizeHover' : ''}`}
          data-rotate={globalState?.shouldMinimizeSidebar}
          onClick={handleSidebarToggle}
        >
          {SIDEBAR_MENU_ICONS.doubleArrow}
        </button>
        <div className="sidebar__menu"
          // data-scrollable={!globalState?.shouldMinimizeSidebar} // when minimize sidebar will have enough height - no need scrollable
             data-scrollable={true} // when minimize sidebar will have enough height - no need scrollable
        >
          <ul style={{height: 'auto'}}
          >
            {SIDEBAR_MENU_ITEMS.map(item => {

              return (
                <div className={'sidebar__menu-content'}>
                  {!!item.title &&
                  <Text as={'p'} fontSize={16} fontWeight={600} className={'sidebar__menu-title'}>{t(item.title)}</Text>}
                  {!!item.title && <Text as={'p'} fontSize={16} fontWeight={600} className={'sidebar__menu-title-line'}>
                    <svg width="24" height="2" viewBox="0 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line y1="1.25" x2="24" y2="1.25" stroke="#C8CBD4" strokeWidth="1.5"/>
                    </svg>
                  </Text>}
                  {item.list.map(menu => (
                    <SidebarMenuItem key={menu.id} data={menu}/>
                  ))}
                </div>
              )
            })}
          </ul>
        </div>
        <ul className="sidebar__bottom">
          <SidebarMenuItem data={SIDEBAR_BOTTOM_ITEM} size="lg"/>
        </ul>
      </StyledSidebar>
    </SidebarContext.Provider>
  )
}
