const items = [
  {
    id: '123456789',
    name: 'Váy tima xanh',
    size: 'A 416 - x ',
    inventory: 5,
    price: 200000,
    defaultAmount: 1,
  },
  {
    id: '123456754',
    name: 'Váy tima đỏ',
    size: 'A 416 - Z ',
    inventory: 10,
    price: 100000,
    defaultAmount: 1,
  },
  {
    id: '123456799',
    name: 'Váy tima vàng',
    size: 'A 416 - Y ',
    inventory: 15,
    price: 300000,
    defaultAmount: 1,
  },
]

export default items
