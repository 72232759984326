import { CategoryInput } from 'Component/CategoryInput'
import useOrderFilterForm from 'Pages/refactorOrder/hooks/useOrderFilterForm'
import { Button } from 'common/button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './_orderAdvancedSearch.scss'

export const OrderAdvancedSearch = ({...props}) => {
  const {advancedSearch} = useOrderFilterForm()
  const {t} = useTranslation()

  const handleIdChange = text => advancedSearch.changeAdvancedSearchLiveStreamVideoIdValue(text)

  const handleSubmit = () => {
    advancedSearch.onChange()
  }

  const handleValueChange = (id) => {
    advancedSearch.changeAdvancedSearchCustomerValue(id)
  }

  return (
    <>
      <CategoryInput
        className={'invoice-filter-form__input-wide'}
        categoryList={[]}
        categoryValue={{name: t('cashbook_customer'), value: ''}}
        categoryWidth={100}
        placeholder={t('search_key')}
        value={advancedSearch.customer.keyword}
        onChange={handleValueChange}
        style={{position: 'relative', width: '392px'}}
      />
       <CategoryInput
        className={'invoice-filter-form__input-wide'}
        categoryList={[]}
        categoryValue={{name: t('liveVideoID'), value: ''}}
        categoryWidth={100}
        placeholder={t('example1')}
        value={advancedSearch.liveVideoId}
        onChange={handleIdChange}
        style={{position: 'relative', width: '392px'}}
      />
      <Button
        badgeType="danger"
        size="md-"
        onClick={handleSubmit}
      >
       {t('search')}
      </Button>
    </>
  )
}
