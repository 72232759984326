export const DISPLAY_NAME_MENU = {
    "MANAGEMENT": "management",
    "OVERVIEW": "overview",
    "CONTROL_PANEL": "ControlPanel",
    "BILL": "Bill",
    "TRANPORT": "transport",
    "CUSTOMER": "customer",
    "BOMB_SHIPMENT": "bombShipment_customer",
    "PRODUCT": "product",
    "CHANEL": "chanel",
    "SALES_CHANEL" : "sales_chanel",
    "SALE_AT_SHOP": "Sale_At_Shop",
    "CREATE_AT_SHOP":"create_at_shop",
    "POS_MANAGEMENT":"pos_management",
    "POS_CONFIG":"pos_config",
    "MARKET": "e-market",
    "E_ORDER": "e_order",
    "E_ORDER_CONNECT": "e_order_connect",
    "E_ORDER_LIST": "e_order_list",
    // "REPORT": "report_config",
    // "REPORT_WAREHOUSE": "report_warehouse",
    // "REPORT_SALE": "report_sale",
    // "REPORT_IMPORT": "report_import",
    "SUPPORT": "support",
    "FACEBOOK": "facebook",
    "TRANPORT_MANAGEMENT": "manage_tranport",
    "TRANSPORT": "Transport",
    "DELIVERY_MANAGEMENT": "delivery_manager",
    "TRANPORT_OVERVIEW": "tranport_overview",
    "SIGN_1_PART": "sign_1_part",
    "TOOL_DEVIDE_ADRESS": "tool_devide_adress",
    "AUTO_BILL": "auto_bill",
    "SHIPMENT_ROUTE": "shipment_route",
    "COD_MANAGEMENT": "cod_management",
    "COD_FOR_CONTROL": "cod-for-control",
    "FOR_CONTROL_COD": "for-control-cod",
    "INDEX_MANAGEMENT":"index_management",
    "PRODUCT_MANAGEMENT":"product_management",
    "WAREHOUSE_MANAGEMENT":"warehouse_management",
    "IMPORT_MANAGEMENT":"import_management",
    "WAREHOUSE_CHECK":"warehouse_check",
    "WAREHOUSE_MOVE":"warehouse_move",
    "ROOT_PRICE_UPDATE":"root_price_update",
    "SUPPLIER_MANAGEMENT": "Supplier_management",
    "SUPPLIER": "supplier",
    "FB_FANPAGE_MANAGE":"FB_fanpage_Manage",
    "FB_LIVESTREAM": "FB_Livestream",
    "FB_SETTING": "FB_Setting",
    "SHOPEE": "Shopee",
    "LAZADA": "Lazada",
    "SENDO": "Sendo",
    "WAREHOUSE_REPORT": "warehouse_report",
    "WAREHOUSE": "warehouse",
    "LIST_WAREHOUSE": "list_warehouse",
    "INVENTORY_INFO": "inventory_information",
    "IMPORT_GOODS": "import_goods",
    "WAREHOUSE_TRANSFER": "warehouse_transfer",
    "SALES_REPORT": "sales_report",
    "IMPORT_REPORT": "import_report",
    "ROLE": "role",
    "USER_MANAGEMENT": "user_management",
    "GROUP_USER": "group_user",
    "CONFIG": "config",
    "SHOP_CONFIG": "shop_config",
    "MANAGEMENT_ORDER_SOURCES": "Manage_order_sources",
    "ORDER_MANAGER": "manage_order",
    "ORDER_POS": "editPos_manage",
    "CREATE_ORDER": "create_order",
    "CONTACT": "contact",
    "SHIPPING_PARTNER": "Shipping_partner",
    "SHIPPING_COMPANY": "shipping_company",
    "PARTNER_MANAGER" : 'partner_manager',
    "PRINT_TEMPLATE_SETTING" :"Print_template_settings",

    "ACCOUNTANT" : 'accountant',
    "RECEIPTS_VOUCHER" : "receipts_voucher",
    "PAYMENT_VOUCHER" : "payment_voucher",
    "CASH_BOOK" : "cash_book",
    "COST_PRICE_UPDATE" : "cost_price_update",
    "SHIPPING_TRACKING" : "package_problem",
    "CREATE_PRODUCT": "create_product",
    "CREATE_CUSTOMER": "create_customer",
    "QUICK_ACTION": "quick_action",

    "REPORT": "report",
    "REPORT_SALE": "report_sale",
    "SALES_ACTIVITY_REPORT": "sales_activity_statistical_report",

    "SUPPORTING_TOOL": "supporting_tool",
    "CONFIG_MANAGEMENT": "configuration_management",
    "POST_PRODUCT": "post_product",
    "PRODUCT_MANAGE_UPOS": "product_manage_upos",

    "GENERAL": {
        "EXPORT_EXCEL": "general_export_excel",
        "CREATE": "general_create",
        "OTHER_FILTERS": 'general_other_filters',
        "DATE_CREATED": 'general_date_created',
        "INVENTORY": 'general_inventory',
        "STATUS": 'general_status',
        "VERSION": 'general_version',
        "OPERATION": "general_operation",
        "ACTIVE": "general_active",
        "ACTIVATED": "general_activated",
        "DEACTIVATION": "general_deactivation",
        "EDIT": "edit",
        "DELETE": "delete",
        "COMING_SOON": "general_coming_soon",
        "ENTER_PRICE": "general_enter_price",
        "RETAIL_PRICE": "general_retail_price",
        "ENTER_RETAIL_PRICE": "general_enter_retail_price",
        "WHOLESALE_PRICE": "general_wholesale_price",
        "ENTER_WHOLESALE_PRICE": "general_enter_wholesale_price",
        "LAST_ENTRY_PRICE": "general_last_entry_price",
        "ENTER_LAST_ENTRY_PRICE": "general_enter_last_entry_price",
        "ORIGIN_PRICE": "general_origin_price",
        "COST_PRICE": "general_cost_price",
        "ENTER_COST_PRICE": "general_enter_cost_price",
        "SELECT_STATUS": "general_select_status",
        "APPLY": "general_apply",
        "OUT_OF": "general_out_of",
        "LINES": "general_lines",
        "NOT_DATA_FOUND": "general_not_data_found",
        "ADD_IMAGE": "general_add_image",
        "INFO_ADD_IMAGE": "general_info_add_image",
        "INFO_MAX_ADD_IMAGE": "general_info_max_add_image",
        "UNIT": "general_unit",
        "ENTER_UNIT_NAME": "general_enter_unit_name",
        "SELECT_UNIT": "general_select_unit",
        "FIND_UNIT": "general_find_unit",
        "ADD_UNIT": "general_add_unit",
        "UNIT_NOT_FOUND": "general_unit_not_found",
        "UNIT_SYMBOL": "general_unit_symbol",
        "ENTER_UNIT_SYMBOL": "general_enter_unit_symbol",
        "WEIGHT": "general_weight",
        "ENTER_WEIGHT": "general_enter_weight",
        "COLLAPSE": "general_collapse",
        "WAREHOUSE": "general_warehouse",
        "SELECT_WAREHOUSE": "general_select_warehouse",
        "FIND_WAREHOUSE": "general_find_warehouse",
        "WAREHOUSE_NOT_FOUND": "general_warehouse_not_found",
        "SIZE": "general_size",
        "COLOR": "general_color",
        "TYPE": "general_type",
        "VALUE": "general_value",
        "YOU_SURE": "general_you_sure",
        "NOTE": "general_note",
        "ENTER_NOTE": "general_enter_note",
        "ACTIVE_OR_INACTIVE": "general_active_or_inactive",
        "CONFIRM": "general_confirm",
        "CANCEL": "general_cancel",
        "REMOVE": "general_remove",
        "CLOSE": "general_close",
        "UPLOAD": "general_upload",
        "NEED_EXPORT_EXCEL": "general_need_export_excel",
        "EXPORT_EXCEL_FROM": "general_export_excel_from",
        "RESET_TO_DEFAULT": "general_reset_to_default",
        "SELECT_FILE": "general_page_select_file",
        "SELECT_FILE_IMPORT": "general_page_select_import_file",
        "DOWNLOAD_SAMPLE_FILE": "general_download_sample_file",
        "HERE": "general_here",
        "COMPLETELY_AND_CORRECTLY": "general_completely_and_correctly",
        "FORMAT_FILE_IMPORT": "general_format_file_import",
        "FILE_IMPORT_MAX_LINE": "general_file_import_max_line",
        "FILE_IMPORT_NOT_CORRECT": "general_file_import_not_correct",
        "FILE_IMPORT_WAITING": "general_file_import_waiting",
        "FILE_IMPORT_HELP": "general_file_import_help",
        "EXPORTED_FILE": "general_exported_file",
        "PROCESSING_DATA": "general_processing_data",
        "NOT_EXPORTED_FILE_YET": "general_not_exported_file_yet",
        "STOP_EXPORTING": "general_stop_exporting",
        "CONTINUE_EXPORT": "general_continue_export",
        "STOP_EXPORTING_EXCEL": "general_stop_exporting_excel",
        "EXCEL_EXPORT_STOP": "general_excel_export_stop",
        "SYSTEM_EXPORTING_EXCEL_YOU_WANT_STOP": "general_system_exporting_excel_you_want_stop",
        "API_ERROR": "general_error_api",
        "BARCODE": "general_barcode",
        "IMAGE": "general_image",
        "SAVE": "general_save",
        "CONFIRM_LEAVING": "general_confirm_leaving",
        "CONFIRM_LEAVING_CONTENT": "general_confirm_leaving_content",
        "CHECK_INFO_BEFORE_SUBMIT": "general_check_info_before_submit",
        "SEARCH_PRODUCT": "general_search_product",
        "PRODUCT_NOT_FOUND": "general_product_not_found",
        "NOT_PRODUCT_YET": "general_not_product_yet",
        "SELL_PRICE": "general_sell_price",
        "STORE_NAME": "general_store_name",
        "PRODUCT_PRICE": "general_product_price",
        "SELECTED": "general_selected",
        "RESET": "general_reset",
        "DESELECT_ALL": "general_deselect_all",
        "VIEW_DETAIL": "general_view_detail",
        "ROW": "general_row",
        "SEE_PHOTO": "general_see_photo",
        "MAX_IMG_3MB": "general_maximum_size_image_3MB",
        "LEAST_1_DATA_EXPORT": "general_excel_least_1_data_export",
        "USER_MANAGEMENT": "general_user_management",
        "ROLE": "general_role",
        "GROUP_CUSTOMER": "group_customer",
        "PAYMENT_METHOD": "general_payment",
        "SOURCE_ORDER": "source_order",
        "DELIVERY_POINT": "delivery_point",
        "TYPE_OF_RECEIPT": "general_type_of_receipt",
        "TYPE_OF_PAYMENT": "general_type_of_payment",
        "PRINTED_FORM": "general_printed_form",
        "DELIVERY_NOTE_FORM": "general_delivery_note_form",
        "PREVIEW": "general_preview",
        "CHOOSE": "general_choose",
        "STORE_INFO": "general_store_info",
        "ORDER_INFO": "order_info",
        "RECEIPT_AND_PAYMENT": "general_receipt_and_payment_info",
        "INVENTORY_CHECK_INFO": "general_inventory_check_info",
        "ADMISSION_INFO": "general_inventory_check_info",
        "USER_PROFILE": "user_information",
        "PACKAGE_INFO": "general_package_info",
        "CHANGE_PASSWORD": "change_password",
        "SIGN_OUT": "sign_out",
        "UPDATE": "update",
        "DOB": "general_dob",
        "GENDER": "general_gender",
        "MALE": "general_male",
        "FEMALE": "general_female",
        "ADDRESS": "address",
        "PHONE": "phone",
        "F_NAME": "full_name",
        "ENTER_FULL_NAME": "general_enter_full_name",
        "FULL_NAME": "Full_Name",
        "USER_NAME": "user_name",
        "USER_PROFILE_SUBTITLE_PASS": "user_profile_page_subtitle_change_pass",
        "USER_PROFILE_TAB_PASS": "user_profile_page_tab_pass",
        "USER_PROFILE_CURRENT_PASS": "user_profile_page_current_pass",
        "USER_PROFILE_NEW_PASS": "user_profile_page_new_pass",
        "USER_PROFILE_CONFIRM_PASS": "user_profile_page_confirm_pass",
        "USER_PROFILE_ENTER_NAME": "user_profile_page_enter_name",
        "USER_PROFILE_ENTER_EMAIL": "user_profile_page_enter_email",
        "USER_PROFILE_ENTER_ADDRESS": "user_profile_page_enter_address",
        "USER_PROFILE_SUCCESS_UPDATE_ACCOUNT": "user_profile_page_success_update_account",
        "USER_PROFILE_SUCCESS_UPDATE_PASSWORD": "user_profile_page_success_update_password",
        "ORDER": "order",
        "ORDER_SENT_DATE": "order_sent_date",
        "PAYMENT_DETAIL": "general_payment_detail",
        "REFUND_HISTORY": "general_refund_history",
        "RECEIVED": "general_received",
        "NOT_RECEIVED": "general_not_received",
        "EXPORT_EXCEL_SUCCESS": "export_excel_success",
        "ODER_ID": "order_id",
        "CUSTOMER_NAME": "customer_name",
        "ADMISSION": "general_admission",
        "TOTAL": "general_total",
        "TOTAL_AMOUNT": "total_amount",
        "SALES_VALUE": "general_sales_value",
        "STILL_PAY": "general_still_pay",
        "PAID": "paid",
        "SUCCESS": "success",
        "PAYMENT_CONFIRM": "general_payment_confirm",
        "PAYMENT_AMOUNT": "general_payment_amount",
        "CONFIRM_STAFF": "general_confirm_staff",
        "PAYMENT_TIME": "general_payment_time",
        "ADDITIONAL_INFO": "general_additional_info",
        "PRODUCT_NAME_SKU": "general_product_name_SKU",
        "ENTER_PRODUCT_NAME_SKU": "general_product_enter_name_SKU",
        "UNIT_PRICE": "general_unit_price",
        "NO_DISCOUNT": "general_no_discount",
        "DECREASED": "general_decreased",
        "SUGGESTED_PRICE": "general_suggested_price",
        "DATE_REFUND": "return_order_page_date_refund",
        "AMOUNT_REFUND": "return_order_page_amount_refund",
        "SEARCH_PAYMENT_METHOD": "cashbook_search_payment_method",
        "PAYMENT_METHOD_NOT_FOUND": "cashbook_payment_method_not_found",
        "INCLUDE": "general_include",
        "DISCOUNT_ON_PRODUCT": "general_discount_on_product",
        "DISCOUNT_ON_TOTAL_ORDER": "general_discount_on_total_order",
        "TITLE": "general_title",
        "EMAIL": "general_email",
        "FIND_NO_DATA": "find_no_data",
        "COPY": "general_copy",
        "ENTER_USERNAME": "general_enter_username",
        "ENTER_REPEAT_PASS": "general_enter_repeat_pass",
        "NO": "general_no",
        "DATE_CREATED_TICKET": "date_created_ticket",
        "CREATOR_TICKET": "creator_ticket",
        "UPDATED_DATE": "updated_date",
        "MARGIN": "margin",
        "USAGE_STATUS": "usage_status",
        "DESCRIPTION": "cashbook_description",
        "ENTER_DESCRIPTION": "receiptManagement_import_description",
        "NOT_SPACE_SPECIAL_CHARACTERS": "please_not_enter_accents_spaces_special_characters",
        "CREATE_SUCCESS": "general_create_success",
        "CREATE_FAILED": "general_create_failed",
        "CITY": "city_name",
        "DISTRICT": "district_name",
        "WARD": "ward_name",
        "SEARCH_CITY": "search_city",
        "SEARCH_DISTRICT": "search_district",
        "SEARCH_WARD": "search_ward",
        "CITY_NO_DATA": "city_no_data",
        "DISTRICT_NO_DATA": "district_no_data",
        "WARD_NO_DATA": "ward_no_data",
        "NO_RESULTS": "no_results",
        "WARNING": "general_warning",
        "STAFF": "staff",
        "RECEIVER": "receiver",
    },
    "VALIDATE": {
        "EMPTY": {
            "PRODUCT_NAME": "validate_empty_product_name",
            "PRODUCT_CODE": "validate_empty_product_code",
            "GROUP_PRODUCT": "validate_empty_group_product",
            "GROUP_PRODUCT_CODE": "validate_empty_group_product_code",
            "GROUP_PRODUCT_NAME": "validate_empty_group_product_name",
            "UNIT": "validate_empty_unit",
            "UNIT_SYMBOL": "validate_empty_unit_symbol",
            "INIT_INVENTORY": "validate_empty_init_inventory",
            "RETAIL_PRICE": "validate_empty_retail_price",
            "LAST_ENTRY": "validate_empty_last_entry_price",
            "COST_PRICE": "validate_empty_cost_price",
            "WHOLESALE_PRICE": "validate_empty_wholesale_price",
            "INVALID_WEIGHT": "validate_empty_weight",
            "INVENTORY_GREATER_THAN_0": "validate_empty_init_inventory_greater_than_0",
            "ATTR_NAME": "validate_empty_attr_name",
            "PRODUCT_SKU": "validate_empty_product_sku",
            "ORIGIN_PRICE": "validate_empty_origin_price",
            "COST_PRICE_IN": "validate_empty_cost_price_in",
            "USER_NAME": "validate_empty_user_name",
            "EMAIL": "validate_empty_email",
            "CURRENT_PASS": "validate_empty_current_pass",
            "NEW_PASS": "validate_empty_new_pass",
            "CONFIRM_PASS": "validate_empty_confirm_pass",
            "RETURN_AMOUNT": "validate_empty_return_amount"
        },
        "MAX": {
            "PRODUCT_NAME": "validate_max_length_product_name",
            "PRODUCT_CODE": "validate_max_length_product_code",
            "PRODUCT_BARCODE": "validate_max_length_product_barcode",
            "ATTR_NAME": "validate_max_length_attr_name",
            "REFUND_REASON": "validate_max_length_refund_reason"
        },
        "EXISTS": {
            "PRODUCT_CODE": "validate_exists_product_code",
            "GROUP_PRODUCT_CODE": "validate_exists_group_product_code",
            "PRODUCT_BARCODE": "validate_exists_product_barcode",
            "UNIT_NAME": "validate_exists_unit_name",
            "PRODUCT_SKU": "validate_exists_product_sku"
        },
        "INVALID": {
            "RETAIL_PRICE": "validate_invalid_retail_price",
            "WHOLESALE_PRICE": "validate_invalid_wholesale_price",
            "LAST_ENTRY_PRICE": "validate_invalid_last_entry_price",
            "COST_PRICE": "validate_invalid_cost_price",
            "INIT_INVENTORY": "validate_invalid_init_inventory",
            "PRODUCT_SKU": "validate_invalid_product_sku_duplicate",
            "PRODUCT_BARCODE_DUPLICATE": "validate_invalid_product_barcode_duplicate",
            "EMAIL": "validate_invalid_email",
            "CURRENT_PASS_MIN_6": "validate_invalid_current_pass_min_6",
            "NEW_PASS_MIN_6": "validate_invalid_new_pass_min_6",
            "CONFIRM_PASS_MIN_6": "validate_invalid_confirm_pass_min_6",
            "CURRENT_PASS_MAX_50": "validate_invalid_current_pass_max_50",
            "NEW_PASS_MAX_50": "validate_invalid_new_pass_max_50",
            "CONFIRM_PASS_MAX_50": "validate_invalid_confirm_pass_max_50",
            "CHARACTER_PASSWORD": "validate_invalid_character_password",
            "NOT_MATCH_PASSWORD": "validate_invalid_not_match_pass",
            "ERROR_PASS": "validate_invalid_error_pass",
            "AMOUNT_PAY_THAN_AMOUNT_PAID": "validate_invalid_payment_amount_than_still_pay",
            "AMOUNT_PAY_THAN_0": "validate_invalid_payment_amount_than_0",
            "AMOUNT_PAY_THAN_AMOUNT": "validate_invalid_payment_amount_than_amount",
            "REFUND_AMOUNT_PAY_THAN_0": "validate_invalid_refund_amount_than_0",
            "REFUND_AMOUNT_PAY_THAN_AMOUNT": "validate_invalid_refund_amount_than_amount",
            "REFUND_AMOUNT_PAY_THAN_TOTAL_VALUE": "validate_invalid_refund_amount_than_total_value",
            "REFUND_AMOUNT_PAY_MAX_99999": "validate_invalid_refund_amount_than_99999",
            "ORDER": "validate_invalid_order",
            "ORDER_INFO": "validate_invalid_order_info",
        }
    },
    "PRODUCT_PAGE": {
        "BREADCRUM_TITLE": "product_page_breadcrum_title",
        "BREADCRUM_FIRST": "product_page_breadcrum_first",
        "BREADCRUM_SECOND": "product_page_breadcrum_second",
        "IMPORT_PRODUCT": "product_page_import_list",
        "PLACEHOLDER_SEARCH": "product_page_placeholder_search",
        "SELECTED_PRODUCT": "product_page_selected_product",
        "PRINT_BARCODE": "product_page_print_barcode",
        "VERSION__LIST": "product_page_version_list",
        "AVAILABLE_VERSION": "product_page_available_version",
        "PRODUCT_SKU": "product_page_product_sku",
        "VERSION_NAME": "product_page_version_name",
        "GROUP_PRODUCT": "product_page_group_product",
        "ADD_GROUP_PRODUCT": "product_page_add_group_product",
        "SELECT_GROUP_PRODUCT": "product_page_select_group_product",
        "EMPTY_GROUP_PRODUCT": "product_page_empty_group_product",
        "FIND_GROUP_PRODUCT": "product_page_find_group_product",
        "ANY_PRODUCT_YET": "product_page_any_product_yet",
        "CREATE_PRODUCT": "product_page_create_product",
        "EDIT_PRODUCT": "product_page_edit_product",
        "BASIC_INFO": "product_page_basic_info",
        "PRODUCT_SPECIFICATIONS": "product_page_product_specifications",
        "WAREHOUSE_INFO": "product_page_warehouse_info",
        "PRICE_INFO": "product_page_price_info",
        "PRICE_AND_INVENTORY_INFO": "product_page_price_info_and_inventory",
        "VERSION_INFO": "product_page_version_info",
        "PRODUCT_NAME": "product_page_product_name",
        "ACTIVE_OR_DEACTIVATION": "product_page_active_or_deactivation",
        "ENTER_PRODUCT_NAME": "product_page_enter_product_name",
        "TOOLTIP_PRODUCT_CODE": "product_page_tooltip_product_code",
        "PRODUCT_CODE": "product_page_product_code",
        "ENTER_PRODUCT_CODE": "product_page_enter_product_code",
        "ENTER_PRODUCT_SKU": "product_page_enter_product_sku",
        "PRODUCT_BARCODE": "product_page_product_barcode",
        "ENTER_PRODUCT_BARCODE": "product_page_enter_product_barcode",
        "TOOLTIP_PRODUCT_BARCODE": "product_page_tooltip_product_barcode",
        "IMAGE_PRODUCT": "product_create_page_image_product",
        "PRODUCT_DESCRIPTION": "product_page_description",
        "ENTER_DESCRIPTION": "product_page_enter_description",
        "SCRIPT_WAREHOUSE": "product_page_script_warehouse",
        "INIT_WAREHOUSE": "product_page_init_warehouse",
        "INITIAL_INVENTORY": "product_page_initial_inventory",
        "ENTER_INITIAL_INVENTORY": "product_page_enter_initial_inventory",
        "SCRIPT_LAST_ENTRY_PRICE": "product_page_script_last_entry_price",
        "SCRIPT_COST_PRICE": "product_page_script_cost_price",
        "CALCULATION_COST_PRICE": "product_page_calculation_cost_price",
        "TITLE_VERSION_INFO": "product_page_title_version_info",
        "SUBTITLE_VERSION_INFO": "product_page_subtitle_version_info",
        "ADD_VERSION": "product_page_add_version",
        "NAME_ATTRIBUTE": "product_page_name_attribute",
        "MAX_ATTRIBUTE": "product_page_max_attr",
        "EMPTY_ATTRIBUTE": "product_page_empty_attr",
        "TYPE_AND_ENTER_ADD_VALUE": "product_page_type_and_enter_add_value",
        "STOP_USING_PRODUCT": "product_page_stop_using_product",
        "STOP_USING_VERSION": "product_page_stop_using_version",
        "MAKE_CHANGE_VERSION": "product_page_make_change_version",
        "SCRIPT_1_VERSION": "product_page_script_1_version",
        "SCRIPT_2_VERSION": "product_page_script_2_version",
        "SCRIPT_3_VERSION": "product_page_script_3_version",
        "SCRIPT_1_PRODUCT": "product_page_script_1_product",
        "SCRIPT_2_PRODUCT": "product_page_script_2_product",
        "SCRIPT_3_PRODUCT": "product_page_script_3_product",
        "REMOVE_PRODUCT": "product_page_remove_product",
        "IMPORT_CUSTOMER_LIST": "product_page_import_customer_list",
        "ACTIVE_SUCCESS": "product_page_active_success",
        "ACTIVE_FAILED": "product_page_active_failed",
        "INACTIVE_SUCCESS": "product_page_inactive_success",
        "INACTIVE_FAILED": "product_page_inactive_failed",
        "DELETE_PRODUCT_SUCCESS": "product_page_delete_product_successfully",
        "DELETE_PRODUCT_FAILED": "product_page_delete_product_failed",
        "ENTER_ATTR_NAME": "product_page_enter_attr_name",
        "PRICE_MANAGEMENT": "product_page_price_management",
        "PRICE_MANAGEMENT_VERSION": "product_page_price_management_version",
        "PRICE_MANAGEMENT_TOOLTIP": "product_page_price_management_tooltip",
        "BARCODE_TOOLTIP": "product_page_barcode_tooltip",

        "TITLE_GROUP_PRODUCT": "product_page_title_group_product",
        "SUBTITLE_GROUP_PRODUCT": "product_page_subtitle_group_product",
        "CODE_GROUP_PRODUCT": "product_page_code_group_product",
        "ENTER_CODE_GROUP_PRODUCT": "product_page_enter_code_group_product",
        "NAME_GROUP_PRODUCT": "product_page_name_group_product",
        "ENTER_NAME_GROUP_PRODUCT": "product_page_enter_name_group_product",
        "PARENT_GROUP_PRODUCT": "product_page_parent_group_product",
        "SELECT_PARENT_GROUP_PRODUCT": "product_page_select_parent_group_product",
        "TITLE_UNIT_MODAL": "product_page_title_unit_modal",
        "SUBTITLE_UNIT_MODAL": "product_page_subtitle_unit_modal",
        "ADDED_SUCCESSFULLY": "product_page_added_successfully",
        "ADDED_FAILED": "product_page_added_failed",
        "UNIT_ADDED_SUCCESSFULLY": "product_page_unit_add_successfully",
        "UNIT_ADDED_FAILED": "product_page_unit_add_failed",
        "INFO": "product_page_info",
        "PAPER_SIZE": "product_page_paper_size",
        "SETUP_TEMPLATE": "product_page_setup_template",
        "EXPORT_BARCODE_PRINTING": "product_page_export_barcode_printing",
        "BACK_PRODUCT_LIST": "product_page_back_product_list",
        "NUM_OF_STAMPS": "product_page_num_of_stamps",
        "NUM_OF_STAMPS_TOOLTIP": "product_page_num_of_stamps_tooltip",
        "LEAST_1_PRODUCT_PRINT_BARCODE": "product_page_least_1_product_print_barcode",
        "PAPER_ROLL": "product_page_paper_roll",
        "PAPER_SIZE_PRINT": "product_page_paper_size_print",
        "IMPORT_NOTIFY": "product_page_import_notify",
        "IMPORT_NOTIFY_1": "product_page_import_notify_1",
        "IMPORT_NOTIFY_2": "product_page_import_notify_2",
        "TEM": "product_page_tem",
        "PRINT_BARCODE_1000": "product_page_print_barcode_1000",
        "PRINT_BARCODE_0": "product_page_print_barcode_0",
        "UPDATE_SUCCESS": "update_product_success"
    },
    "WAREHOUSE_PRODUCT_PAGE": {
        "HEADER": "warehouse_product_page_header",
        "INFO": "warehouse_product_page_information",
        "SEARCH_HEADER": "warehouse_product_page_search_header",
        "LARGER_LEVEL": "warehouse_product_page_larger_level",
        "SMALL_LEVEL": "warehouse_product_page_small_level",
        "LEVEL_IS_EQUAL": "warehouse_product_page_level_is_equal",
        "QUANTITY_WAITING_OPTION_1": "warehouse_product_page_quantity_waiting_option_1",
        "QUANTITY_WAITING_OPTION_2": "warehouse_product_page_quantity_waiting_option_2",
        "ENTER_RATED_VALUE": "warehouse_product_page_enter_rated_value",
        "QUANTITY": "warehouse_product_page_quantity",
        "QUANTITY_TOOLTIP1": "warehouse_product_page_quantity_tooltip1",
        "WAITING_EXPORT": "warehouse_product_page_waiting_export",
        "WAITING_EXPORT_TOOLTIP1": "warehouse_product_page_waiting_export_tooltip1",
        "WAITING_IMPORT": "warehouse_product_page_waiting_import",
        "WAITING_IMPORT_TOOLTIP1": "warehouse_product_page_waiting_import_tooltip1",
        "DETAILS": "warehouse_product_page_detail",
    },
    "CONFIG_AND_SETTING_PAGE": {
        "HEADER": "config_and_setting_page",
        "USER1_SUBTITLE": "config_and_setting_page_user1_subtitle",
        "ROLE_TITLE": "config_and_setting_page_role_title",
        "ROLE_SUBTITLE": "config_and_setting_page_role_subtitle",
        "INFO": "config_and_setting_page_info",
        "PRODUCT_CATEGORY_SUBTITLE": "config_and_setting_page_product_category_subtitle",
        "UNIT_SUBTITLE": "config_and_setting_page_unit_subtitle",
        "GROUP_CUSTOMER_SUBTITLE": "config_and_setting_page_group_customer_subtitle",
        "PAYMENT_METHOD_SUBTITLE": "config_and_setting_page_payment_method_subtitle",
        "SOURCE_ORDER_SUBTITLE": "config_and_setting_page_source_order_subtitle",
        "DELIVERY_POINT_SUBTITLE": "config_and_setting_page_delivery_point_subtitle",
        "TYPE_OF_RECEIPT_SUBTITLE": "config_and_setting_page_type_of_receipt_subtitle",
        "TYPE_OF_PAYMENT_SUBTITLE": "config_and_setting_page_type_of_payment_subtitle",
        "OTHER_CONFIG": "config_and_setting_page_other",
        "PRINT_FORM": "config_and_setting_page_print_form_subtitle",
        "DELIVERY_NOTE_FORM": "config_and_setting_page_delivery_note_form_subtitle",
    },
    "PRINT_PAGE": {
        "VIEW_FORM": "print_page_view_form",
        "SET_FORM": "print_template_setup",
        "CHOOSE_PRINT": "print_page_choose_print",
        "PATTERN_NOT_FOUND": "print_page_print_pattern_not_found",
        "CHOOSE_PRINT_SIZE": "print_page_choose_print_size",
        "NOT_PRINT_SIZE": "print_page_choose_print_size",
        "PRINT_SIZE_NOT_FOUND": "print_page_print_print_size_not_found",
        "TEST_PRINT": "print_page_test",
        "EVO_TEMPLATE": "print_page_evo_template",
        "CHOOSE_KEYWORD": "print_page_choose_keyword",
        "KEYWORD_LIST": "print_page_keyword_list",
        "MODAL_SUBTITLE": "print_page_modal_subtitle",
        "USE_EVO_TEMPLATE": "print_page_use_evo_template",
        "CONFIRM_TEMPLATE": "print_page_confirm_template",
        "STORE_LOGO": "print_page_store_logo",
        "UPDATE_SUCCESS": "print_page_update_success",
        "UPDATE_FAILED": "print_page_update_failed",
    },
    "RETURN_ORDER_PAGE": {
        "HOME": "return_order_page",
        "MANAGEMENT": "return_order_page_management",
        "EXPORT_ERROR_NOTIFY": "return_order_page_export_error_notify",
        "CREATE": "return_order_page_create",
        "NOT_REFUND_YET": "general_no_refund_yet",
        "REFUNDS": "general_refunds",
        "ONE_PART_REFUND": "1_part_back_payment",
        "REFUND": "general_refund",
        "CONFIRM_RECEIPT": "return_order_page_confirm_receipt",
        "ORDER_VALUE": "return_order_page_order_value",
        "VALUE_PAID": "return_order_page_value_paid",
        "REFUNDED": "return_order_page_refunded",
        "STILL_PAY": "return_order_page_still_pay",
        "SEARCH": "return_order_page_search",
        "WAREHOUSE_EXPORT": "warehouse_export",
        "SELECT_WAREHOUSE_EXPORT": "warehouse_export_select",
        "NOT_FOUND_WAREHOUSE_EXPORT": "not_found_warehouse_export",
        "FIND_WAREHOUSE_EXPORT": "find_warehouse_export",
        "REFUND_STATUS": "return_order_page_refund_status",
        "TOTAL_NUMBER_ORDERS": "return_order_page_total_number_orders",
        "TOTAL_VALUE_GOODS": "return_order_page_total_value_goods",
        "AMOUNT_REFUNDED": "return_order_page_amount_refunded",
        "ORDER_RETURN_ID": "return_order_page_order_return_id",
        "GET_REFUNDS": "return_order_page_get_refunds",
        "REFUND_MONEY": "return_order_page_refund_money",
        "REFUND_REASON": "return_order_page_refund_reason",
        "ENTER_REFUND_REASON": "return_order_page_enter_refund_reason",
        "CUSTOMER_REFUND": "return_order_page_customer_refund",
        "REFUND_INFO": "return_order_page_refund_info",
        "REFUND_ORDER": "return_order_page_refund_order",
        "REFUND_MONEY_STATUS": "return_order_page_refund_money_status",
        "REFUND_PRODUCT": "return_order_page_refund_product",
        "WAREHOUSE_RECEIVE_GOODS": "return_order_page_warehouse_receive_goods",
        "NUMBER_GOOD_PAID": "return_order_page_number_goods_paid",
        "MODAL_REFUND_TITLE": "return_order_page_modal_title_refund",
        "MODAL_CONFIRM_TITLE": "return_order_page_modal_title_confirm",
        "MODAL_CONFIRM_SUBTITLE": "return_order_page_modal_subtitle_confirm",
        "CONFIRM_SUCCESS": "return_order_page_confirm_success",
        "CONFIRM_FAILED": "return_order_page_confirm_failed",
        "REFUND_SUCCESS": "return_order_page_refund_success",
        "REFUND_FAILED": "return_order_page_refund_failed",
        "REFUND_TO_CUSTOMERS": "return_order_page_refund_to_customers",
        "SELECT_ORDER_TITLE": "return_order_page_select_order_title",
        "UPDATE_LAST_DATA": "return_order_page_update_last_data",
        "SEARCH_CREATE": "return_order_page_search_create",
        "ANY_ORDER_YET": "return_order_page_any_order_yet",
        "NO_RETURN_FOUND": "return_order_page_no_return_found",
        "WAREHOUSE_GOODS_TOOLTIP": "return_order_page_warehouse_goods_tooltip",
        "REFUND_RECEIVED": "return_order_page_refund_received",
        "RETURN_ALL_PRODUCT": "return_order_page_refund_all_products",
        "CONTENT_RETURNED_GOODS": "return_order_page_content_returned_goods",
        "RETURN_AMOUNT": "return_order_page_return_amount",
        "CREATE_PAGE_UNIT_TOOLTIP": "return_order_page_unit_tooltip",
        "CREATE_PAGE_UNIT_PRICE_PAID": "return_order_page_unit_price_paid",
        "TOTAL_GOOD_PAID": "return_order_page_total_good_paid",
        "CREATE_PAGE_UNIT_PRICE_PAID_TOOLTIP": "return_order_page_unit_price_paid_tooltip",
        "VALUE_PAID_TOOLTIP": "return_order_page_value_paid_tooltip",
        "UNABLE_RETURN_VOUCHER": "return_order_page_unable_return_voucher",
        "UNABLE_RETURN_VOUCHER_CONTENT": "return_order_page_unable_return_voucher_content",
        "UNABLE_RETURN_VOUCHER_BACK": "return_order_page_unable_return_voucher_back",
        "UNABLE_RETURN_VOUCHER_TOOLTIP": "return_order_page_unable_return_voucher_tooltip",
        "CREATE_SUCCESS": "return_order_page_create_success",
        "CREATE_BY_OTHER_STAFF": "return_order_page_create_by_other_staff",
    },
    "FB": {
        "TITLE_1": "fb_page_title_1",
        "TITLE_2": "fb_page_title_2",
        "SUBTITLE_1": "fb_page_subtitle_1",
        "SUBTITLE_2": "fb_page_subtitle_2",
        "LOGIN_WITH_FB": "login-with-facebook",
        "PLEASE_REFER_TO": "fb_page_please_refer",
        "PRIVACY_POLICY": "fb_page_privacy_policy",
        "AND": "fb_page_and",
        "RULES": "fb_page_rules",
        "BY_EVO": "fb_page_by_evo",
    },
    "FEEDBACK": {
        "HOME": "feedback_page_home",
        "THANK": "feedback_page_thank",
        "SEND_FAILED": "feedback_page_send_failed",
        "FILE_MAX_2MB": "feedback_page_file_max_2MB",
        "INVALID_FILE": "feedback_page_invalid_file",
        "TO_EVO": "feedback_page_to_evo",
        "TO_EVO_SUBTITLE": "feedback_page_to_evo_subtitle",
        "OF_YOUR": "feedback_page_of_your",
        "ENTER_TITLE": "feedback_page_enter_title",
        "MAX_TITLE_50": "feedback_page_max_title_50",
        "EMPTY_TITLE": "feedback_page_empty_title",
        "TYPE": "feedback_page_type",
        "SELECT_TYPE": "feedback_page_select_type",
        "SYSTEM_ERROR": "feedback_page_system_error",
        "ADD_FEAT": "feedback_page_add_feat",
        "REQUEST_SOFTWARE": "feedback_page_request_software",
        "REQUEST_SYSTEM": "feedback_page_request_system",
        "CONTENT": "feedback_page_content",
        "ENTER_CONTENT": "feedback_page_enter_content",
        "EMPTY_CONTENT": "feedback_page_empty_content",
        "ATTACHMENTS": "feedback_page_attachments",
        "MAX_2MB": "feedback_page_max_2MB",
        "SEE_ALL": "feedback_page_see_all",
        "SEND": "feedback_page_send",
        "LIST": "feedback_page_list",
        "LIST_OF_YOUR": "feedback_page_list_of_your",
        "NEW_REQUEST": "feedback_page_new_request",
        "PROCESSING": "feedback_page_processing",
        "PROCESSED": "feedback_page_processed",
        "REJECT": "feedback_page_reject",
        "PROCESS_STATUS": "feedback_page_process_status",
        "CODE": "feedback_page_code",
        "CREATE": "feedback_page_create",
        "NOT_YET": "feedback_page_not_yet",
        "NOT_FOUND": "feedback_page_not_found",
        "DETAIL": "feedback_page_detail",
        "SEE_ATTACHMENT": "feedback_page_see_attachment",
        "DATE": "feedback_page_date",
        "FROM_EVO": "feedback_page_from_evo",
        "FEEDBACK_CONTENT": "feedback_page_feedback_content",
        "ANSWER": "feedback_page_answer",
        "DATE_FEEDBACK": "feedback_page_date_feedback",
        "CONTENT_HOTLINE": "feedback_page_content_hotline",
        "CLICK2": "feedback_page_click2",
        "CLICK3": "feedback_page_click3",
        "DATE_SENT": "feedback_page_date_sent"
    },
    "USER_PAGE": {
        "USER_ROLE": "user_page_user_role",
        "CREATE": "user_page_create",
        "CREATE_ROLE": "user_page_create_role",
        "SEARCH": "user_page_search",
        "ROLE": "user_page_role",
        "SELECT_ROLE": "user_page_select_role",
        "EMPTY_ROLE": "user_page_empty_role",
        "SEARCH_ROLE": "user_page_empty_search_role",
        "INACTIVE": "user_page_inactive",
        "STATUS": "user_page_status",
        "SEARCH_FAILED": "user_page_search_failed",
        "ACTIVE_SUCCESS": "user_page_active_success",
        "ACTIVE_FAIL": "user_page_active_fail",
        "SUCCESS_COPY": "user_page_success_copy",
        "EMPLOYEE_ACTIVE": "user_page_employee_active",
        "EMPLOYEE_INACTIVE": "user_page_employee_inactive",
        "INFO": "user_page_info",
        "RESET_PASS": "user_page_reset_pass",
        "RESET_PASS_TOOLTIP": "user_page_reset_pass_tooltip",
        "INACTIVE_TOOLTIP": "user_page_inactive_tooltip",
        "CREATE_SUCCESS": "user_page_create_success",
        "CREATE_FAILED": "user_page_create_failed",
        "CREATE_RES1": "user_page_create_res1",
        "CREATE_RES2": "user_page_create_res2",
        "CREATE_RES3": "user_page_create_res3",
        "CREATE_RES4": "user_page_create_res4",
        "CREATE_ROLE_SUCCESS": "user_page_create_role_success",
        "CREATE_ROLE_FAILED": "user_page_create_role_failed",
        "UPDATE_SUCCESS": "user_page_update_success",
        "UPDATE_FAILED": "user_page_update_failed",
        "AUTHOR": "user_page_author",
        "SUBTITLE": "user_page_author",
        "SELECT_ONE": "user_page_author_select_one",
        "NOTE_EMPTY": "user_page_note_empty",
        "USER_PROFILE_SUBTITLE": "user_page_profile_subtitle",
        "PHONE": "store_phone",
        "ENTER_PHONE": "store_enter_phone",
        "ACTIVE_INACTIVE": "user_page_active_inactive",
        "PASS_TITLE": "user_page_pass_title",
        "PASS_SUBTITLE": "user_page_pass_subtitle",
        "USERNAME": "username",
        "ENTER_CURRENT_PASS": "user_page_enter_current_pass",
        "ENTER_NEW_PASS": "user_page_enter_new_pass",
        "ENTER_CONFIRM_PASS": "user_page_enter_confirm_pass",
        "NEW_PASS_TOOLTIP": "user_page_enter_current_pass_tooltip",
        "ADMIN_TOOLTIP": "user_page_admin_tooltip",
        "DETAIL": "user_page_detail",
        "REFRESH_SUCCESS": "user_page_refresh_success",
        "ROLE_TOOLTIP": "user_page_role_tooltip",
        "CHANGE_PASS_SUCCESS": "user_page_change_pass_success",
        "CHANGE_PASS_FAILED": "user_page_change_pass_failed",
        "NOT_SELECT_ACCOUNT": "user_page_not_select_account",
        "NOTE_MAX": "note_content_is_allowed_to_input_a_maximum_of_255_characters",
        "SELECT_MUST_ONE": "user_page_select_must_one",
        "FULL_NAME_EMPTY": "user_page_full_name_empty",
        "PHONE_NUMBER_REQUIRED": "phone_number_required",
        "PHONE_MAX": "phone_number_min_max_length",
        "EMAIL_FORMAT": "please_enter_valid_email_format",
        "NAME_EMPTY": "error_account_notify",
        "PASS_EMPTY": "error_password_notify",
        "PASSWORD_LENGTH": "password_length_notice",
        "PASSWORD_MAX_LENGTH": "validate_invalid_password_max_length",
        "USER_SELECTED": "user_page_user_selected"
    },
    "ROLE_PAGE": {
        "REMOVE": "role_page_remove",
        "REMOVE_CONTENT": "role_page_remove_content",
        "FULL": "role_page_full",
        "COUNT_USER": "role_page_count_user",
        "DETAIL": "role_page_detail",
        "BACK_LIST": "role_page_back_list",
        "NAME": "role_page_name",
        "NAME_TOOLTIP": "role_page_name_tooltip",
        "DETAIL_ROLE": "role_page_detail_role",
        "HAVE_ROLE": "role_page_have_role",
        "CANT_REMOVE": "role_page_cant_not_remove",
        "CREATE_SUCCESS": "role_page_create_success",
        "CREATE_FAILED": "role_page_create_failed",
        "UPDATE_SUCCESS": "role_page_update_success",
        "UPDATE_FAILED": "role_page_update_failed",
        "DELETE_SUCCESS": "role_page_delete_success",
        "EMPTY_NAME": "role_page_empty_name",
    },
    "PRICE_ADJUST_PAGE": {
        "CREATE": "price_adjust_page_create",
        "EDIT": "price_adjust_page_edit",
        "DRAFT": "price_adjust_page_draft",
        "CHANGED": "price_adjust_page_changed",
        "DETAIL": "price_adjust_page_detail",
        "CANCEL": "price_adjust_page_cancel",
        "SAVE_AND_UPDATE": "price_adjust_page_save_and_update",
        "CODE": "price_adjust_page_code",
        "ENTER_CODE": "price_adjust_page_enter_code",
        "CODE_TOOLTIP": "price_adjust_page_code_tooltip",
        "MODAL_CONFIRM_TITLE": "price_adjust_page_modal_confirm_title",
        "MODAL_CONFIRM_SUBTITLE": "price_adjust_page_modal_confirm_subtitle",
        "SELECTED_PRODUCT": "price_adjust_page_selected_product",
        "ENTER_PRICE": "price_adjust_page_enter_price",
        "NOT_SELECT_PRODUCT": "price_adjust_page_not_select_product",
        "SEARCH": "price_adjust_page_search",
        "SELECT_CREATOR": "price_adjust_page_selected_creator",
        "CREATOR_NOT_FOUND": "price_adjust_page_creator_not_found",
        "CREATOR_NOT_YET": "price_adjust_page_creator_not_yet",
        "FIND_CREATOR": "price_adjust_page_find_creator",
        "CANCEL_MODAL_TITLE": "price_adjust_page_cancel_modal_title",
        "CANCEL_MODAL_SUBTITLE": "price_adjust_page_cancel_modal_subtitle",
        "DATE_UPDATE": "price_adjust_page_date_update",
        "EMPTY": "price_adjust_page_empty",
        "GENERAL_INFO": "price_adjust_page_general_info",
        "TYPE_STATUS": "price_adjust_page_type_status",
        "PRODUCT_INFO": "price_adjust_page_product_info",
        "BEFORE_PRICE": "price_adjust_page_before_price",
        "AFTER_PRICE": "price_adjust_page_after_price",
        "EMPTY_PRICE": "price_adjust_page_empty_price",
        "INVALID_PRICE": "price_adjust_page_invalid_price",
        "CREATE_SUCCESS": "price_adjust_page_create_success",
        "UPDATE_SUCCESS": "price_adjust_page_update_success",
        "TYPE_SUCCESS": "price_adjust_page_cancel_type_success",
        "TYPE_FAILED": "price_adjust_page_cancel_type_failed",
        "ACTION_SUCCESS": "price_adjust_page_action_success",
        "ACTION_FAILED": "price_adjust_page_action_failed",
        "UPDATE": "price_adjust_page_update",
        "BASE_PRICE_CURRENT": "base_price_current",
        "MAX_CODE": "price_adjust_page_max_code",
        "MAX_NOTE": "price_adjust_page_max_note",
    },
    "PAYMENT_METHOD_PAGE": {
        "BREADCRUMD_TITLE": "payment_page_breadcrumd_title",
        "REMOVE": "payment_page_remove",
        "REMOVE_CONTENT": "payment_page_remove_content",
        "MODAL_CREATE_TITLE": "receiptManagement_paymentMethod",
        "MODAL_CREATE_SUBTITLE": "receiptManagement_paymentMethod_text",
        "ENTER_PAYMENT_NAME": "input_payment_method_name",
        "DEFAULT": "default_payment_method",
        "SELECTED": "payment_page_selected",
        "CREATE_SUCCESS": "add_payment_method_success",
        "CREATE_FAILED": "add_payment_method_failed",
        "UPDATE_SUCCESS": "payment_update_method_success",
        "UPDATE_FAILED": "payment_update_method_failed",
        "REQUIRED_NAME": "payment_method_name_required",
        "NOT_SELECT_DEFAULT": "payment_page_not_select_default",
        "ACTIVE_SUCCESS": "payment_page_active_success",
        "INACTIVE_SUCCESS": "payment_page_inactive_success",
        "CHANGE_STATUS_FAILED": "payment_page_change_status_failed",
        "REMOVE_SUCCESS": "payment_page_remove_success",
        "REMOVE_FAILED": "payment_page_remove_failed",
        "SEARCH": "payment_page_search"
    },
    "TYPE_OF_RECEIPT": {
        "MODAL_CREATE_TITLE": "type_of_receipt_modal_create_title",
        "MODAL_CREATE_SUBTITLE": "type_of_receipt_modal_create_subtitle",
        "NAME": "receiptManagement_name",
        "ENTER_NAME": "receiptManagement_enterName",
        "CODE": "receiptManagement_code",
        "ENTER_CODE": "type_of_receipt_enter_code",
        "AUTO_GENERATE_CODE": "type_of_receipt_auto_generate_code",
        "CONFIRM_DUPLICATE": "receiptManagement_confirmDuplicateName",
        "EXISTING_NAME": "receiptManagement_existingName",
        "INACTIVE_TITLE": "type_of_receipt_modal_inactive_title",
        "INACTIVE_SUBTITLE": "type_of_receipt_modal_inactive_subtitle",
        "REMOVE_TITLE": "type_of_receipt_remove_title",
        "REMOVE_SUBTITLE": "type_of_receipt_remove_subtitle",
        "EMPTY": "type_of_receipt_empty",
        "NOT_FOUND": "type_of_receipt_not_found",
        "CREATE": "type_of_receipt_create",
        "SEARCH": "type_of_receipt_search",
        "SELECTED": "type_of_receipt_selected",
        "CREATE_SUCCESS": "receiptManagement_createTypeSuccess",
        "CREATE_FAILED": "receiptManagement_createTypeFailed",
        "UPDATE_SUCCESS": "receiptManagement_updateTypeSuccess",
        "UPDATE_FAILED": "receiptManagement_updateTypeFailed",
        "REMOVE_SUCCESS": "receiptManagement_deleteTypeSuccess",
        "REMOVE_FAILED": "receiptManagement_deleteTypeUsed",
        "CHANGE_TYPE_FAILED": "type_of_receipt_change_type_failed",
        "ACTIVE_SUCCESS": "type_of_receipt_active_success",
        "ACTIVE_FAILED": "type_of_receipt_active_failed",
        "INACTIVE_SUCCESS": "type_of_receipt_inactive_success",
        "INACTIVE_FAILED": "type_of_receipt_inactive_failed",
        "NAME_EMPTY": "receiptManagement_typeNameEmpty",
        "NAME_MAX": "receiptManagement_typeNameMaxLength",
        "CODE_MAX": "receiptManagement_typeCodeMaxLength",
        "NOTE_MAX": "receiptManagement_descriptionMaxLength"
    },
    "TYPE_OF_PAYMENT": {
        "CODE": "paymentManagement_expense_type_code",
        "ENTER_CODE": "paymentManagement_enter_expense_type_code",
        "CODE_TOOLTIP": "paymentManagement_auto_generate_expense_type_code",
        "ENTER_NOTE": "paymentManagement_enter_expense_description",
        "NAME": "paymentManagement_expense_type_name",
        "ENTER_NAME": "paymentManagement_enter_expense_type_name",
        "EMPTY": "type_of_payment_empty",
        "NOTE_FOUND": "type_of_payment_not_found",
        "CREATE": "type_of_payment_create",
        "SEARCH": "type_of_payment_search",
        "INFO": "receiptManagement_expenseCategory",
        "INFO_TEXT": "receiptManagement_expenseCategory_text",
        "NAME_EMPTY": "paymentManagement_payment_type_name_not_empty",
        "NAME_MAX_LENGTH": "type_of_payment_name_max_length",
        "CODE_MAX_LENGTH": "type_of_payment_code_max_length",
        "INACTIVE": "type_of_payment_inactive",
        "INACTIVE_CONTENT": "type_of_payment_inactive_content",
        "REMOVE": "type_of_payment_remove",
        "REMOVE_CONTENT": "type_of_payment_remove_content",
        "DUPLICATE": "type_of_payment_duplicate",
        "DUPLICATE_CONTENT": "type_of_payment_duplicate_content",
        "SELECTED": "type_of_payment_selected"
    },
    "CUSTOMER_PAGE": {
        "INFO": "client-information",
        "ADD_ON": "customer_page_add_on",
        "CODE": "customer_code",
        "ENTER_CODE": "customer_page_enter_code",
        "TOOLTIP_CODE": "customer_page_enter_tooltip",
        "ENTER_NAME": "customer_page_enter_name",
        "ENTER_PHONE": "customer_page_enter_phone",
        "ENTER_EMAIL": "customer_page_enter_email",
        "FULL_ADDRESS": "customer_page_full_address",
        "ENTER_FULL_ADDRESS": "customer_page_enter_full_address",
        "SPLIT_ADDRESS": "customer_page_split_address",
        "ENTER_GROUP_CUSTOMER": "customer_page_enter_group_customer",
        "ADD_CUSTOMER_GROUP": "add_customer_group",
        "PRICE_POLICY": "customer_page_price_policy",
        "SELECT_PRICE_POLICY": "customer_page_select_price_policy",
        "ACTIVE_INACTIVE": "customer_page_active_inactive",
        "UPDATE_SUCCESS": "customer_page_update_success",
        "UPDATE_FAILED": "customer_page_update_failed",
        "EMPTY_GROUP_CUSTOMER_CODE": "require_customer_group_code",
        "EMPTY_GROUP_CUSTOMER_NAME": "require_customer_group_name",
        "EMPTY_NAME": "customer_page_empty_name",
        "EMPTY_ADDRESS": "address_must_not_empty",
        "EMPTY_CITY": "please_enter_select_city",
        "EMPTY_DISTRICT": "please_enter_select_district",
        "EMPTY_WARD": "please_enter_select_ward",
        "INVALID_TRIM": "customer_page_invalid_trim",
        "INVALID_PHONE": "customer_page_invalid_phone",
        "INVALID_SEPARATE": "customer_page_invalid_separate",
        "MAX_ADDRESS": "customer_page_max_address",
        "GROUP_CUSTOMER_NOT_FOUND": "customer_page_group_customer_not_found",
        "SEARCH_GROUP_CUSTOMER": "customer_page_search_group_customer",
        "NOTE": "customer_page_note",
        "ENTER_NOTE": "customer_page_enter_note",
        "MAX_NOTE": "customer_page_max_note",
        "DUPLICATE_CODE": "customer_page_duplicate_code",
        "MAX_CODE": "customer_page_max_code",
        "MAX_NAME": "customer_page_max_name",
        "GROUP_CODE": "customer_group_code",
        "ENTER_GROUP_CODE": "enter_customer_group_code",
        "GROUP_NAME": "customer_group_name",
        "ENTER_GROUP_NAME": "enter_customer_group_name"
    },
    "EDIT_ORDER_PAGE": {
        "HOME": "edit_order",
        "UPDATE_DELIVERY_SUCCESS": "edit_order_page_update_delivery_success",
        "DELIVERY_POINT_TITLE": "delivery_point_information",
        "DELIVERY_POINT_SUBTITLE": "delivery_point_description",
        "DELIVERY_POINT_LABEL_NAME": "delivery_point_name_label",
        "DELIVERY_POINT_ENTER_NAME": "input_delivery_point_name",
        "ENTER_ADDRESS": "address_input_placeholder",
        "EXAM_PRODUCT_NAME": "edit_order_page_exam_product_name",
        "TEMP_DVVC": "edit_order_page_temp_dvvc",
        "TEMP_A4": "print_evo_A4",
        "TEMP_A5": "print_evo_A5",
        "TEMP_K80": "print_evo_k80",
        "SHIPPING_INFO": "shipping_info",
        "DELIVERY_PARTNER": "order_no_delivery_partner",
        "SHIPPING_FEE_ESTIMATE": "shipping_fee_estimate",
        "POS_EDIT": "editPos_edit",
    },
    "SEPARATION_TOOL": {
        "HISTORY": "separation_tool_history",
        "PANEL_TOOLTIP": "separation_tool_panel_tooltip",
        "PANEL_NOTE": "separation_tool_panel_note",
        "EXAMPLE_FILE": "example_file",
        "UPLOAD_FILE": "separation_tool_upload_file",
        "CONFIRM_UPLOAD": "separation_tool_confirm_uploaded",
        "CONFIRM_YOU_WANT": "separation_tool_confirm_you_want",
        "TIME_UPLOAD": "time_upload",
        "UPLOAD_FAILED": "separation_tool_upload_failed",
        "INVALID_FILE": "separation_tool_invalid_failed",
        "ENTER_FILE": "separation_tool_enter_file",
        "ENTER_FILE_NOTE": "separation_tool_enter_file_note",
        "MUST_FILE": "separation_tool_must_file",
        "NOT_CHANGE_FILE": "not_change_order_columns_file",
        "SUPPORT_MAX_1000": "support_maximum_file_1000_order",
        "IN_PROCESS": "separation_tool_in_process",
        "FILE_NAME": "separation_tool_file_name",
        "RESULT": "separation_tool_result",
        "RESULT_TOTAL_SUCCESS": "separation_tool_result_total_success",
        "CREATED_ORDER": "separation_tool_created_order",
        "EMPTY": "separation_tool_empty",
        "UPDATE_STATUS_SUCCESS": "status_update",
        "UPDATE_STATUS_FAILED": "status_update_failed",
        "EXPORTED_SUCCESS": "separation_tool_exported_success",
        "EXPORTED_FAILED": "separation_tool_exported_failed",
        "EXPORT_FILE": "separation_tool_export_file",
        "EXPORT_FILE_NO_INVENTORY": "separation_tool_export_file_no_inventory",
        "CREATE_BULK_ORDER": "separation_tool_create_bulk_order",
        "SUCCESS": "separation_tool_success",
        "FAILED": "separation_tool_failed",
        "EXPORT_ALL": "separation_tool_export_all",
        "EXPORT_SUCCESS": "separation_tool_export_success",
        "EXPORT_FAILED": "separation_tool_export_failed",
        "ERROR": "separation_tool_error",
        "ERROR_TOOLTIP": "separation_tool_error_tooltip",
        "YOU_SURE": "separation_tool_you_sure",
        "HERE": "separation_tool_here",
        "NOT_CORRECT": "separation_tool_not_correct",
        "PARSING_FAILED": "address_parsing_fail",
        "DATA_IN_PROCESS": "separation_tool_data_in_process",
        "SENT_ERROR_SUCCESS": "separation_tool_sent_error_success",
        "SENT_ERROR_FAILED": "separation_tool_sent_error_failed",
        "EMPTY_ALL_FIELD": "separation_tool_empty_all_filed",
        "UPDATE_ADDRESS_SUCCESS": "separation_tool_update_address_success",
        "UPDATE_ADDRESS_FAILED": "separation_tool_update_address_failed",
        "OTHER_ORDER": "separation_tool_other_order",
        "PARSING_FAILED_TOOLTIP": "separation_tool_parsing_failed_tooltip",
        "ADDRESS_FAILED": "separation_tool_address_failed",
        "HAS_ERROR": "separation_tool_has_error",
        "SPLIT": "split",
        "SAVE_AND_SPLIT": "separation_tool_save_and_split",
        "SUGGEST": "separation_tool_suggest",
        "SUGGEST_TOOLTIP": "separation_tool_suggest_tooltip",
        "UPLOAD_AND_SPLIT_SUCCESS": "separation_tool_upload_and_split_success",
        "UPLOAD_AND_SPLIT_FAILED": "separation_tool_upload_and_split_failed",
        "HOME": "separation_tool_page",
        "ADDRESS_HANDLE": "separation_tool_address_handle"
    }
}