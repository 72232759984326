import {useContext} from "react";
import {OrderContext} from "../provider/_context";
import {orderActions} from "../provider/_reducer";
import {removeAcent} from "../../../common/fieldText/_functions";
import {useTranslation} from "react-i18next";

export const useOrderExportSelective = ()=>{
    const {t} = useTranslation()
    const {pageState, pageDispatch} = useContext(OrderContext)
    const {exportExcel} = pageState
    const {activeList, selectedList, list, keyword} = exportExcel

    const handleSelective = data =>{
        const findIndex = list?.findIndex(index => index?.id === data?.id )

        const finIndexActive = activeList?.findIndex(index => index?.id === data?.id )
        const findActive = selectedList?.find(find => find?.id === data?.id)
        let newActive = []
        const status = +data?.status === 1 ? 0 : 1
        const newList = list?.map(item=>{
            if(item?.id === data?.id)  list[findIndex].status = status
            return item
        })
        const activeSelect = activeList.map(item=>{
            if(item?.id === data?.id)  activeList[finIndexActive].status = status
            return item
        })
        const newActiveList = keyword !== '' ? activeSelect : newList

        if(!!!findActive) newActive = [...selectedList,data]
        else newActive = selectedList.filter(filter => filter?.id !== data?.id)

        newActive = newActive.map((item,i)=>{
            return({...item,position:i })
        })
        pageDispatch({type: orderActions.UPDATE_SELECTED_LIST_ACTIVE,payload:{
             list: newList,
             selected:newActive,
             active: newActiveList
        }})

    }

    const handleRowPositionUpdate = (arr)=>{
        const newPosition = arr.map(({content,...item},i)=>{
            return({...item,position:i})
        })
        pageDispatch({type: orderActions.UPDATE_DRAG_SELECTED_LIST,payload:newPosition})
    }

    const handleSearch = (val) =>{
        const {value} = val.target
        const formatDataValue = value
            ? removeAcent(value.toLowerCase())
            : ''

        const ListData = list.filter(item => {
            const formatNameItem = item?.name
                ? removeAcent(t(item.name).toLowerCase())
                : ''
            if (formatNameItem.includes(formatDataValue)) return true
            return false
        })

        pageDispatch({
            type: orderActions.SEARCH_SELECTED_LIST_ACTIVE,
            payload: {
                keyword: value || '',
                activeList: ListData,
            },
        })
    }
    return{
        data:{
            activeList,
            selectedList,
            keyword
        },
        methods:{
            handleSelective,
            handleRowPositionUpdate,
            handleSearch
        }
    }
}