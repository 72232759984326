import {TableLayout} from 'layouts/tableLayout'
import {useContext, useEffect, useRef, useState} from 'react'
import {OrderFilterForm} from './components/orderFilterForm'
import {OrderPanels} from './components/orderPanels'
import {OrderTBody} from './components/orderTable/_orderTBody'
import {OrderTHead} from './components/orderTable/_orderTHead'
import {
  EXPORT_EXCEL_SELECTIVE_ACTIVE,
  EXPORT_ORDER_EXCEL_SELECTIVE,
  ORDER_BREADCRUMB,
  ORDER_PAGE_HEADER_ACTIONS,
} from './interfaces/_constants'
import {OrderProvider} from './provider'
import './components/orderTable/index.scss'
import useOrder from './hooks/useOrder'
import {OrderNotifications} from './components/orderNotifications'
import useOrderFilterForm from './hooks/useOrderFilterForm'
import useAlert from 'hook/useAlert'
import {OrderExport} from './components/orderExport'
import {OrderContext} from './provider/_context'
import {orderActions} from "./provider/_reducer";
import {PageHeader} from "./components/orderPageHeader";
import {checkPermission} from "../../Component/NavBar/until";
import PopupNotPermission from "../permision/popupNotPermission";
import {useNavigate} from "react-router-dom";
import {ExportSelective} from "../../Component/exportExcel/exportCustumize";
import {useOrderExportSelective} from "./hooks/useOrderExportSelective";
import { useTranslation } from 'react-i18next'
const RefactorOrder = () => {
  const {fetch, pagination, provider} = useOrder()

  const {state, dispatch} = provider
  const {table} = state
  useEffect(() => {
    document.title = "Quản lý đơn hàng"
    fetch.origin()
  }, [])
  return (
    <OrderProvider value={{pageState: state, pageDispatch: dispatch}}>
      <Header />
      <OrderNotifications />
      <TableLayout
        header={
          <>
            <OrderFilterForm />
            <OrderPanels />
          </>
        }
        table={{
          tHead: <OrderTHead />,
          tBody: <OrderTBody />,
          scrollable: true,
          width: 'fit-content',
        }}
        pagination={{
          ...table.pagination,
          onAmountChange: pagination.onAmountChange,
          onPageChange: pagination.onPageChange,
        }}
      />
    </OrderProvider>
  )
}

export default RefactorOrder

const Header = () => {
  const {showAlert} = useAlert()
  const validExportPermission = checkPermission('order', 'export')
  const {t} = useTranslation()
  const {pageState,pageDispatch} = useContext(OrderContext)

  const {queries, functions} = useOrderFilterForm()

  const [exportUrl, setExportUrl] = useState('#')
  const [exportModalData, setExportModalData] = useState(null)
  const {data, methods} = useOrderExportSelective()
  const [isColumn,setIsColumn] = useState(null)

  const exportLink = useRef()

  const [openSelective,setOpenSelective] = useState(false)

  const handleExportClick = () => {
    if(validExportPermission) {
      const selectedList = pageState.table.selected.list
      const field = isColumn === 'column' && pageState?.exportExcel?.selectedList.map(item=>item.id).join(',')
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries({
        ...queries,
        keyword:
          selectedList.length > 0
            ? selectedList.map(item => item.id).join(',')
            : (queries?.keyword || ''),
        per_page: '',
        start: '',
      })) {
        queryString += `${i > 0 ? '&' : ''}${key}=${value}`
        i++
      }
      handleLargeExport(
        isColumn === 'column'? {
            ...queries,
            per_page: '',
            start: '',
            field:field,
            keyword:
              selectedList.length > 0
                ? selectedList.map(item => item.id).join(',')
                : (queries?.keyword || ''),
          } :
          {
            ...queries,
            per_page: '',
            start: '',
            keyword:
              selectedList.length > 0
                ? selectedList.map(item => item.id).join(',')
                : (queries?.keyword || ''),
          }
        ,
        {total: selectedList.length > 0 ? selectedList.length : undefined,selected: isColumn},
      )

      const order_column = {
        checked:pageState?.exportExcel?.list,
        column:pageState?.exportExcel?.selectedList,
      }
      localStorage.setItem('orderColumn',JSON.stringify(order_column))
    } else setShowPermission(true)
  }
  const handleLargeExport = (q, opt) =>{
    if (pageState.panels.orderTotal <= 0) {
      showAlert({type: 'info', content: t('report__export_order_notify')})
      return
    }
    setExportModalData({
      data: {
        query: q,
        total: opt?.total || pageState.panels.orderTotal,
      },
      onClose: () => setExportModalData(null),
      selected: opt?.selected
    })
      setOpenSelective(false)
      setIsColumn(null)
  }
  const [showPermission, setShowPermission] = useState(false)
  const handleExportSelective = (boo)=> {
    if(validExportPermission) {
      setOpenSelective(boo)
      const order_column = JSON.parse(localStorage.getItem('orderColumn'))
      if(boo){
        pageDispatch({type: orderActions.UPDATE_SELECTED_LIST_ACTIVE,payload:{
            list: !!order_column ? order_column?.checked : EXPORT_ORDER_EXCEL_SELECTIVE,
            selected: !!order_column ? order_column?.column : EXPORT_EXCEL_SELECTIVE_ACTIVE,
            active: !!order_column ? order_column?.checked : EXPORT_ORDER_EXCEL_SELECTIVE
          }})
        setIsColumn('column')
      }else{
        const newList = EXPORT_ORDER_EXCEL_SELECTIVE?.map((item,i)=>{
          if(+item?.default === 0)  EXPORT_ORDER_EXCEL_SELECTIVE[i].status = 0
          return item
        })
        pageDispatch({type: orderActions.UPDATE_SELECTED_LIST_ACTIVE,payload:{
            list: newList,
            selected:  EXPORT_EXCEL_SELECTIVE_ACTIVE,
            active: newList
          }})
        setIsColumn(null)
      }
    } else {
      setShowPermission(true)
    }
  }
  const actions = [
    functions.refresh,
    () => handleExportSelective(true),
    () => handleBulkOrder(),
    () => handleCreateOrder(),
  ]
  const navigate = useNavigate()
  const validBulkPermission = checkPermission('bulks_order', 'view')
  const validAddPermission = checkPermission('order', 'add')

  const handleBulkOrder = _ => {
    if(validBulkPermission) navigate('/tools/bulks-order')
    else setShowPermission(true)
  }
  const handleCreateOrder = _ => {
    if(validAddPermission) navigate('/orders/create')
    else setShowPermission(true)
  }

  useEffect(() => {
    if (exportUrl && exportUrl !== '#') {
      if (exportLink?.current) exportLink.current.click()
    }
  }, [exportUrl])
    const itemPopper = [
        {name: t('default_excel_column'), action: handleExportClick},
        {name: t('choose_excel_column'), action: ()=>handleExportSelective(true)}
    ]
  return (
    <>
      {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)} />}
      <PageHeader
        actions={ORDER_PAGE_HEADER_ACTIONS.map((item, i) => ({
          ...item,
          onClick: actions[i],
          // props: {
          //   disabled: i === 1 && !canExport,
          // },
          items: i === 1 && itemPopper,
        }))}
        breadcrumbLinks={ORDER_BREADCRUMB}
        breadcrumbTitle={t("order_manager")}
      />
      <a ref={exportLink} href={exportUrl} style={{display: 'none'}}></a>

      {!!exportModalData && <OrderExport data={exportModalData} />}
      {openSelective && <ExportSelective
          data={{
            activeList:data?.activeList,
            selectedList: data?.selectedList,
            keyword: data?.keyword
          }}
          methods={{
            handleSearch: methods?.handleSearch,
            handleRowPositionUpdate: methods?.handleRowPositionUpdate,
            handleSelective: methods?.handleSelective
          }}
          onClose={handleExportSelective}
          exportExcel={handleExportClick} />}
    </>
  )
}
