import React from "react";
import styled from "styled-components";
import {RatioOrder} from "./component/ratioOrder";
import {RatioProduct} from "./component/ratioProduct";
import {useReportUpdateOrder} from "../../hook/useReportUpdateOrder";
import {UpdateOrderModal} from "../modal/updateOrder";
import {UpdateOrderComponent} from "./component/updateOrder";
import useQuickConnect from "../../hook/useQuickConnect";
import ResultQuickConnect from "../modal/quickConnect/_result";
import QuickConnect from '../modal/quickConnect/index'
import {useTranslation} from "react-i18next";
export const RatioReport = ()=>{
    const {updateOrder, updateOrderMethods} = useReportUpdateOrder()
    const {store, methods} = useQuickConnect()
  const { t } = useTranslation()

    const {res} = updateOrder
    const propsEcommerceSync={
        open: updateOrder?.open,
        title: t('update_orders'),
        label: t('order_ecom_into_upos'),
        isFooter: false,
        statusSuccess:{
            success: +updateOrder.statusUpdate === 3,
            title: t('updated_complete_order'),
            message:  +res?.data?.fail === 0 ? t('you_updated_complete_order') : t('you_failed_complete_order'),
            status: +res?.data?.fail === 0,
            totalSuccess: {
                total: res?.data?.success || 0,
                title: t('update_success'),
            },
            totalError: {
                total: res?.data?.fail || 0,
                title: t('update_fail')
            },
            onClose: ()=> updateOrderMethods.onOpenModal(false)
        }
    }
    return(
        <StyleRatioReport>
                <RatioProduct/>
                <RatioOrder/>

            {
                updateOrder?.open && <UpdateOrderModal {...propsEcommerceSync} >
                    <UpdateOrderComponent/>
                </UpdateOrderModal>
            }
            {store?.open && <QuickConnect />}
            {store?.result && <ResultQuickConnect />}
        </StyleRatioReport>
    )

}
const StyleRatioReport = styled.div`
    display: flex;
    grid-gap: 24px;
    width: 100%;
`