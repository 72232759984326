import {ECOMMERCE_LIST, FILTER_DATE, REVENUE_ORDER_TAG, REPORT_ORDER_STATUS} from "../interface/_contant";
import {formatDatetime, getDateFromNow} from '../../../utils/formatDate'
export const reportOrderAction = {
    REPORT_ORDER_ECOMMERCE_ACTIVE:'REPORT_ORDER_ECOMMERCE_ACTIVE',


    //=====DATE TIME=====
    REPORT_ORDER_DATE_TIME_ACTIVE:'REPORT_ORDER_DATE_TIME_ACTIVE',

    //===== END DATE TIME ====

    //======STORES=======
    REPORT_ORDER_STORES_LIST:'REPORT_ORDER_STORES_LIST',
    REPORT_ORDER_STORES_SELECTED:'REPORT_ORDER_STORES_SELECTED',
    REPORT_ORDER_STORES_TAB:'REPORT_ORDER_STORES_TAB',
    REPORT_ORDER_STORES_APPLY_FILTER:'REPORT_ORDER_STORES_APPLY_FILTER',
    REPORT_ORDER_STORES_SEARCH:'REPORT_ORDER_STORES_SEARCH',

    //======STATUS ========
    REPORT_ORDER_STATUS_CHANGE:'REPORT_ORDER_STATUS_CHANGE',
    REPORT_ORDER_STATUS_SELECTED:'REPORT_ORDER_STATUS_SELECTED',
    REPORT_ORDER_STATUS_TAB:'REPORT_ORDER_STATUS_TAB',
    REPORT_ORDER_STATUS_SEARCH:'REPORT_ORDER_STATUS_SEARCH',

    //========IMPORTANT NUMBER=======
    REPORT_ORDER_REVENUE_ACTIVE:'REPORT_ORDER_REVENUE_ACTIVE',
    REPORT_ORDER_REVENUE_UPDATE:'REPORT_ORDER_REVENUE_UPDATE',

    //====== RATIO PRODUCT=======
    REPORT_ORDER_RATIO_ORDER_UPDATE:'REPORT_ORDER_RATIO_ORDER_UPDATE',

    //======= TOP PRODUCT=========
    REPORT_ORDER_REVENUE_LIST_UPDATE:'REPORT_ORDER_REVENUE_LIST_UPDATE',
    REPORT_ORDER_REVENUE_LIST_LOADING:'REPORT_ORDER_REVENUE_LIST_LOADING',


}
export const reportOrderInitState = {
    filter:{
        ecommerce:{
            activeValue: ECOMMERCE_LIST[0],
        },
        dateTime:{
            activeValue: FILTER_DATE[0],
            list: FILTER_DATE,
            dateStart: formatDatetime(getDateFromNow(-6)),
            dateEnd: formatDatetime(getDateFromNow(0))
        },
        stores:{
            list:[],
            listOrigin: [],
            activeValue: [],
            keyword:'',
            tab: 'all',
            applyFilter: [],
        },
        status:{
            isStatus: false,
            list:REPORT_ORDER_STATUS,
            listOrigin: REPORT_ORDER_STATUS,
            activeValue: [],
            keyword:'',
            tab: 'all',
            applyFilter: [],
        },
        revenue:{
            list:REVENUE_ORDER_TAG,
            activeList: REVENUE_ORDER_TAG?.slice(0,2),
            data:[],
            meta:[],
        },
        ratioOrder:{
            data:[]
        },
        revenueList:{
            data:[],
            list:[],
            listOrigin:[],
            sort:'',
            loading: false,
            type:''
        },
    },


}
export const reportOrderReducer = (state, action)=> {
    switch (action.type) {
        case reportOrderAction.REPORT_ORDER_ECOMMERCE_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ecommerce:{
                        ...state?.filter.ecommerce,
                        activeValue: action.payload?.active
                    },
                    stores: {
                        ...state?.filter.stores,
                        activeValue: [],
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                        applyFilter: []
                    }
                }
            }

        case reportOrderAction.REPORT_ORDER_DATE_TIME_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    dateTime:{
                        ...state?.filter.dateTime,
                        activeValue: action?.payload.active,
                        dateStart: action?.payload.dateStart,
                        dateEnd: action?.payload.dateEnd,
                    }
                }
            }

        case reportOrderAction.REPORT_ORDER_STORES_LIST:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_STORES_SELECTED:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       activeValue: action.payload || []
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_STORES_TAB:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       tab: action.payload?.tab,
                        list: action?.payload?.list
                    }
                }
            }

        case reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER:
            switch (action?.payload?.type) {
                case "stores":
                    return {
                        ...state,
                        filter:{
                            ...state?.filter,
                            stores:{
                                ...state?.filter.stores,
                                applyFilter: action.payload?.active || [],
                                activeValue: action.payload?.active || []
                            }
                        }
                    }
                default:
                    return {
                        ...state,
                        filter:{
                            ...state?.filter,
                            status:{
                                ...state?.filter.status,
                                applyFilter: action.payload?.active || [],
                                activeValue: action.payload?.active || []
                            }
                        }
                    }
            }

        case reportOrderAction.REPORT_ORDER_STORES_SEARCH:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        keyword: action.payload?.keyword || '',
                        list: action.payload?.list || []
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_REVENUE_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    revenue:{
                        ...state?.filter.revenue,
                        activeList: action.payload
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_REVENUE_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    revenue:{
                        ...state?.filter.revenue,
                        ...action?.payload
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_RATIO_ORDER_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ratioOrder:{
                        ...state?.filter.ratioOrder,
                        data: action.payload?.data,
                        meta: action.payload?.meta,
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_REVENUE_LIST_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    revenueList:{
                        ...state?.filter.revenueList,
                        ...action.payload
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_REVENUE_LIST_LOADING:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    revenueList:{
                        ...state?.filter.revenueList,
                        loading: action.payload
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_STATUS_CHANGE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    status:{
                        ...state?.filter.status,
                        isStatus: action.payload,

                    },
                    revenueList:{
                        ...state?.filter.revenueList,
                        data: state?.filter.revenueList?.listOrigin,
                        list:state?.filter.revenueList?.listOrigin,
                        sort:''
                    },
                }
            }
        case reportOrderAction.REPORT_ORDER_STATUS_SELECTED:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    status:{
                        ...state?.filter.status,
                        activeValue: action.payload,
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_STATUS_TAB:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    status:{
                        ...state?.filter.status,
                        tab: action.payload?.tab,
                        list: action?.payload?.list
                    }
                }
            }
        case reportOrderAction.REPORT_ORDER_STATUS_SEARCH:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    status:{
                        ...state?.filter.status,
                        keyword: action.payload?.keyword,
                        list: action?.payload?.list
                    }
                }
            }
        default: return state
    }
}