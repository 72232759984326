import {Text} from 'common/text'
import {Tooltip} from 'common/tooltip'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import styled from 'styled-components'
import CountUp from 'react-countup'

export const OrderPanel = ({
  currency,
  title,
  titleTooltip,
  value,
  isHiddenfee,
  isManage,
  ...props
}) => {
  return (
    <StyledOrderPanel {...props}>
      <div className="order-panel__heading">
        <Text color="#7C88A6">{title}</Text>
        {titleTooltip && (
          <Tooltip title={titleTooltip}>{ORDER_ICONS.question}</Tooltip>
        )}
      </div>
      <Text as="h4" fontSize={16} lineHeight={22}>
          {
              +isManage === 0 ?
              +isHiddenfee === 0 ?<> <CountUp start={0} end={value} duration={1} separator="," /> {currency} </>
              :`****** ${currency}` : <> <CountUp start={0} end={value} duration={1} separator="," /> {currency} </>
          }

      </Text>
    </StyledOrderPanel>
  )
}

const StyledOrderPanel = styled.div`
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #ebeef5;
  border-radius: 6px;

  .order-panel {
    &__heading {
      margin-bottom: 4px;

      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;

        transform: translateY(2px);
      }
    }
  }
`
