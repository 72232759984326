import React from "react";
import {useReportUpdateOrder} from "../../../../hook/useReportUpdateOrder";
import {Select} from "../../../../../../../../../../common/form/select";
import {Option} from "../../../../../../../../../../common/form/select/_option";
import {useTranslation} from "react-i18next";

export const EcommerceSyncDateTime = ()=>{
  const { t } = useTranslation()
    const {updateOrderMethods, updateOrder} = useReportUpdateOrder()

    return(
        <Select
            className="order-ecommerce-sync-date-time-form__input-wide"
            value={t(updateOrder.valueDateTime?.name)}
        >
            {updateOrder.listDateTime?.length > 0 &&
            updateOrder.listDateTime?.map(item => (
                <Option
                    key={item.value}
                    className="order-ecommerce-sync-form__option-text"
                    data-active={item.value === updateOrder.valueDateTime?.value}
                    onClick={() => updateOrderMethods.onChangeDateTime(item)}
                >
                    {t(item.name)}
                </Option>
            ))}
        </Select>
    )
}