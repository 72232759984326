import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {RightSightPopup} from "../../../../layouts/rightSightPopup";
import {Input} from "../../../../common/form/input";
import {Text} from "../../../../common/text";
import {THEME_SEMANTICS} from "../../../../common/theme/_semantics";
import {Checkbox} from "../../../../common/form/checkbox";
import styled from "styled-components";
import {formatMoney} from "../../../../util/functionUtil";
import {useModalAddCustomer} from "../../hooks/useModalAddCustomer";

export const AddInfoCustomer = () => {
  const {t} = useTranslation()
  const {
    openModalAddCustomer,
    vatValue,
    nameCompany,
    nameCompanyError,
    addressCompany,
    addressCompanyError,
    taxCode,
    taxCodeError,
    focusTaxCode,
    autoRelease,
    confirmCollect,
    methods,
    detailInvoice
  } = useModalAddCustomer()
  const provisional = (+detailInvoice?.total_amount  - +detailInvoice?.service_fee  - +detailInvoice?.service_vat_fee ) || 0
  const vatPercent = !vatValue || vatValue === "not_taxable" || vatValue === "not_declare"
                      ? 0
                      : vatValue === "10x70"
                        ? 7
                        : vatValue === "5x70"
                          ? 3.5
                          : +vatValue || 0
  const vatMoney = provisional * vatPercent / 100 // total * vat / 100
  useEffect(() => {
    methods?.origin()
  }, [])


  return (
    <RightSightPopup
      openModal={openModalAddCustomer}
      confirmBeforeClose={true}
      clickClose={() => methods.handleCloseModal()}
      acceptance={() => methods.handleSubmit()}
      disableSubmit={!confirmCollect}
      // animationClose={() => methods.animationClose()}
      header={{
        title: t('e_order_add_info_customer'),
        subTitle: t('e_order_add_info_customer_content'),
      }}
      body={[
        {
          item: (
            <StyledAddInfoCustomer>
              <div className={"e-order-model"}>
                <div className={"e-order-model--info"}>
                  <div className={"e-order-model--info-detail"}>
                    <Text>{t('purchases_subtotal')}({t('purchases_products')})</Text>
                    <Text fontWeight={500}>
                      {formatMoney(provisional || 0)}
                    </Text>
                  </div>
                  <div className={"e-order-model--info-detail"}>
                    <Text>{t('product_discount')}</Text>
                    <Text fontWeight={500}>
                      {formatMoney(detailInvoice?.total_discount || 0)}
                    </Text>
                  </div>
                  <div className={"e-order-model--info-detail"}>
                    <Text>{t('e_order_vat_1')}({t(`${vatPercent}%`)})</Text>
                    <Text fontWeight={500}>
                      {formatMoney(vatMoney)}
                    </Text>
                  </div>
                  <hr />
                  <div className={"e-order-model--info-detail"}>
                    <Text fontWeight={500}>{t('amount_due')}</Text>
                    <Text fontWeight={700} fontSize={18}>
                      {formatMoney((provisional + vatMoney + +detailInvoice?.service_fee + +detailInvoice?.service_vat_fee) || 0)}
                    </Text>
                  </div>
                  <div className={"e-order-model--info-detail"}>
                    <Text fontWeight={500}>{t('paid')}</Text>
                    <Text fontWeight={500} fontSize={16} color={"#00AB56"}>
                      {formatMoney(detailInvoice?.total_amount || 0)}
                    </Text>
                  </div>
                  <div className={"e-order-model--info-detail"}>
                    <Text fontWeight={500}>{t('e_order_missing_1')}</Text>
                    <Text fontWeight={500} fontSize={16} color={"#FF424E"}>
                      {formatMoney(vatMoney || 0)}
                    </Text>
                  </div>
                </div>
                <div className={"e-order-model--company"}>
                  <Input
                    label={
                      <>
                        {t('e_order_company_name')}
                        <Text color={THEME_SEMANTICS.failed}> *</Text>
                      </>
                    }
                    value={nameCompany}
                    onChange={e => methods?.handleChangeNameCompany(e.target?.value)}
                    onBlur={() => methods?.handleBlurNameCompany()}
                    validateText={nameCompanyError}
                    validateType={nameCompanyError ? 'danger' : ''}
                    placeholder={t('e_order_company_name_enter')}
                    maxLength={255}
                  />
                </div>
                <div className={"e-order-model--address"}>
                  <Input
                    label={
                      <>
                        {t('Address')}
                        <Text color={THEME_SEMANTICS.failed}> *</Text>
                      </>
                    }
                    value={addressCompany}
                    onChange={e => methods?.handleChangeAddress(e.target?.value)}
                    onBlur={() => methods?.handleBlurAddress()}
                    validateText={addressCompanyError}
                    validateType={addressCompanyError ? 'danger' : ''}
                    placeholder={t('enter_address')}
                    maxLength={255}
                  />
                </div>
                <div className={"e-order-model--tax-code"}>
                  <Input
                    label={
                      <>
                        {t('e_order_tax_code')}
                      </>
                    }
                    value={taxCode}
                    onChange={e => methods?.handleChangeTaxCode(e.target?.value)}
                    onBlur={() => methods?.handleBlurTaxCode()}
                    onFocus={() => methods?.handleFocusTaxCode()}
                    validateText={!focusTaxCode && taxCodeError}
                    validateType={taxCodeError && !focusTaxCode ? 'danger' : ''}
                    placeholder={t('e_order_placeholder_tax_code')}
                    maxLength={14}
                  />
                  {focusTaxCode && <Text fontSize={13}>{t('e_order_enter_max_tax_code')}</Text>}
                </div>
                <div
                  className={"e-order-model--auto-release"}
                  onClick={() => methods?.handleChangeAutoRelease(!autoRelease)}
                >
                  <Checkbox
                    checked={autoRelease}
                  />
                  <Text fontSize={16} >
                    {t('e_order_auto_release_order')}
                  </Text>
                </div>
                <div
                  className={"e-order-model--auto-confirm"}
                  onClick={() => methods?.handleChangeConfirmCollect(!confirmCollect)}
                >
                  <Checkbox
                    checked={confirmCollect}
                  />
                  <Text fontSize={16} >
                    {t('e_order_confirm_collect')} <b>{formatMoney(vatMoney || 0)}</b> {t('e_order_missing')}
                  </Text>
                </div>
              </div>
            </StyledAddInfoCustomer>
          )
        }
      ]}
      footer={
        {
          cancel: {
            width: 74,
            title: t('general_cancel')
          },
          save: {
            width: 163,
            title: t('e_order_create_invoice_1')
          },
        }
      }
    />
  )
}


const StyledAddInfoCustomer = styled.div`
  .e-order-model {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 80%;
    overflow: scroll;
    &--info {
      background: rgba(43, 184, 169, 0.07);
      padding: 12px;
      border-radius: 8px;
      
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      &-detail {
        display: flex;
        justify-content: space-between;
      }
    }
    
    &--vat {
      display: flex;
      gap: 12px;
      &-percent {
        display: flex;
        align-items: end;
        position: relative;
        min-width: 12.75rem;
        &-symbol {
          position: absolute;
          right: 8px;
          bottom: 8px;
        }
      }
      & .alternative-auto-complete__menu-header {
        display: none !important;
      }
    }
    
    &--invoice-symbol {
      & .alternative-auto-complete__menu-header {
        display: none !important;
      }
    }
    
    &--auto-order {
      display: flex;
      gap: 4px;
    }
    
    &--auto-release {
      display: flex;
      gap: 8px;
      cursor: pointer;
    }
    &--auto-confirm {
      display: flex;
      gap: 8px;
      cursor: pointer;
      margin-bottom: 96px;
    }
  }
`
