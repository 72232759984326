import {Text} from 'common/text'
import useNotificationActions from 'Component/NotiComp/hooks/useNotificationActions'
import {NOTIFICATION_TAB} from 'Component/NotiComp/interfaces/_constants'
import {NotificationContext} from 'Component/NotiComp/notification'
import {useContext} from 'react'
import './style.scss'
import useGlobalContext from "../../../../containerContext/storeContext";
import {transFormNoticeNumber} from "../../util/_noticeNumber";
import {noticeCompAction} from "../../provider/_action";
import {useTranslation} from "react-i18next";

export const TabNotification = () => {
  const {t} = useTranslation()
  const {pageState,pageDispatch} = useContext(NotificationContext)
  const {totalUnread, activeTab} = pageState
  const {handleTabChange} = useNotificationActions()
    const [GlobalState] = useGlobalContext()
    const {totalNotice} = GlobalState
  const handleTabActive = (tabId,tab) => {
    handleTabChange(tabId, tab)
    pageDispatch({type:noticeCompAction.SET_CAN_LOAD_MORE_NOTICE,payload:true})
  }

  return (
    <div className="tab-notifacation">
      <div className="tab-notifacation__body">
        {NOTIFICATION_TAB.map(item => {

          const currBadge = [totalNotice.all, totalNotice.system, totalNotice.my][item.id]
          return (
            <div
              key={item.id}
              className="tab-notifacation__tab"
              onClick={() => handleTabActive(item.id,item.tab)}
            >
              <Text
                className={`tab-notifacation__text ${
                  activeTab === item.id ? 'tab-notifacation__text--active' : ''
                }`}
              >
                {t(item.name)}{' '}
                {currBadge !== 0 && <div className="tab-notifacation__total">
                  <Text fontSize={12} color={'#ffff'}>{transFormNoticeNumber(currBadge)}</Text>
                </div>}
              </Text>
            </div>
          )
        })}
      </div>
    </div>
  )
}
