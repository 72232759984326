import {useContext, useEffect} from "react";
import {ReportOverviewContext} from "../provider/_context";
import {reportOverviewAction} from "../provider/_reducer";
import {getDateFromNow, formatDatetime, convertDateTimeToApiFormatV2} from "../../../utils/formatDate";
import useReportEcommerce from "../../../index";
import {IMPORTANT_NUMBER_TAB} from "../interface/_contant";
import {sendRequestAuth} from "../../../../../../../api/api";
import config from "../../../../../../../config";
import {removeAcent} from "../../../../../../../common/fieldText/_functions";
import useAlert from "../../../../../../../hook/useAlert";
import {useTranslation} from "react-i18next";

export const useReportOverviewFilter = ()=>{
  const { t } = useTranslation()
    const {pageState, pageDispatch} = useContext(ReportOverviewContext)

    const{ecommerce, dateTime, stores, importantNumber, ratioProduct, ratioOrder, topProduct} = pageState.filter

    const ecommerceReportInitState = useReportEcommerce()

    const dateStart= dateTime?.dateStart
    const dateEnd= dateTime?.dateEnd

    const {showAlert} = useAlert()
    const queries={
        ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
        ecom_seller_id: ecommerce?.activeValue?.value === 'all' ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        start_date:convertDateTimeToApiFormatV2(dateStart),
        end_date:convertDateTimeToApiFormatV2(dateEnd)
    }

    const transformQueries = (qs)=>{
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        return queryString
    }

    const fetchApiFilter = async (qs)=>{
        const res = await Promise.all([
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/important-indicator${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/ratio-product${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/ratio-order${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/top-product${transformQueries(qs)}`),
        ])
        if(res[0].data?.success){
            const important= res[0].data
            pageDispatch({
                type: reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_UPDATE,
                payload:{
                    meta: important?.meta,
                    data: important?.data,
                    list: IMPORTANT_NUMBER_TAB?.map((map, i) =>({
                        ...map,
                        percent: important?.meta?.find(find => find?.key === map?.tab)?.percent || 0,
                        value: important?.meta?.find(find => find?.key === map?.tab)?.value || 0,
                        data: transformListData(important?.data, map.tab)
                    })),
                    activeList: importantNumber?.activeList?.map((map, i) =>({
                        ...map,
                        percent: important?.meta?.find(find => find?.key === map?.tab)?.percent || 0,
                        value: important?.meta?.find(find => find?.key === map?.tab)?.value || 0,
                        data: transformListData(important?.data, map.tab)
                    }))
                }
            })
        }
        if(res[1].data?.success){
            pageDispatch({
                type: reportOverviewAction.REPORT_OVERVIEW_RATIO_PRODUCT_UPDATE,
                payload:{
                    data: res[1].data?.data
                }
            })
        }
        if(res[2].data?.success){
            pageDispatch({
                type: reportOverviewAction.REPORT_OVERVIEW_RATIO_ORDER_UPDATE,
                payload:{
                    meta: res[2].data?.meta,
                    data: res[2].data?.data
                }
            })
        }
        if(res[3].data?.success){
            pageDispatch({
                type: reportOverviewAction.REPORT_OVERVIEW_TOP_PRODUCT_UPDATE,
                payload:{
                    meta: res[3].data?.meta,
                    data: res[3].data?.data
                }
            })
        }
    }

    useEffect(()=>{
        const list = ecommerceReportInitState?.pageState?.stores?.list
        const activeEcom = ecommerce?.activeValue
        if(list) pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_STORES_LIST,
            payload:{
                list: activeEcom?.value === 'all' ? list : stores?.listOrigin,
                listOrigin: activeEcom?.value === 'all' ? list : stores?.listOrigin
            }
        })
    },[ecommerceReportInitState?.pageState?.stores?.list])

    const transformListData = (data, tab) =>{
        switch (tab) {
            case "total_order":
                return data?.map(map=> map?.value[tab])
            case 'revenue':
                return data?.map(map=> map?.value[tab])
            case 'total_cancel':
                return data?.map(map=> map?.value[tab])
            case 'agv_revenue':
                return data?.map(map=>{
                    if(+map?.value.revenue === 0 || +map?.value.total_order === 0) return 0
                     return +map?.value.revenue / +map?.value.total_order
                })
            default:
                return data?.map(map=>{
                    if(+map?.value.total_product === 0 || +map?.value.total_order === 0) return 0
                    return +map?.value.total_product / +map?.value.total_order
                } )
        }
    }

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.importantNumber
        const data=important?.data
        const meta=important?.meta
        const listUpdate = IMPORTANT_NUMBER_TAB?.map((map, i) =>({
            ...map,
            percent: meta?.find(find => find?.key === map?.tab)?.percent || 0,
            value: meta?.find(find => find?.key === map?.tab)?.value || 0,
            data: transformListData(data,map?.tab)
        }))
        if(ecommerceReportInitState?.pageState?.importantNumber?.data) pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_UPDATE,
            payload:{
                meta: meta,
                data: data,
                list: listUpdate,
                activeList: listUpdate?.slice(0,2)
            }
        })
    },[ecommerceReportInitState?.pageState?.importantNumber?.data])


    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.ratioProduct
        if(ecommerceReportInitState?.pageState?.ratioProduct?.data) pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_RATIO_PRODUCT_UPDATE,
            payload:{
                data: important?.data
            }
        })
    },[ecommerceReportInitState?.pageState?.ratioProduct?.data])

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.ratioOrder
        if(ecommerceReportInitState?.pageState?.ratioOrder?.data) pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_RATIO_ORDER_UPDATE,
            payload:{
                meta: important?.meta,
                data: important?.data
            }
        })
    },[ecommerceReportInitState?.pageState?.ratioOrder?.data])

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.topProduct
        if(ecommerceReportInitState?.pageState?.topProduct?.data) pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_TOP_PRODUCT_UPDATE,
            payload:{
                meta: important?.meta,
                data: important?.data
            }
        })
    },[ecommerceReportInitState?.pageState?.topProduct?.data])
    //======= ECOMMERCE ======
    const activeValue = ecommerce?.activeValue
    const storesList =  ecommerceReportInitState?.pageState?.stores?.list
    const handleActiveValue = data =>{
        let listOrigin = [...storesList]
        if(data?.value !== 'all'){
            listOrigin = listOrigin?.filter(filter => filter?.ecom_type === data?.type)
        }
        pageDispatch({
            type: reportOverviewAction?.REPORT_OVERVIEW_ECOMMERCE_ACTIVE,
            payload: {
                active: data,
                list: listOrigin,
                listOrigin: listOrigin
            }
        })
        fetchApiFilter({
            ...queries,
            ecom_type: data?.value === 'all' ? '' : data?.value,
            ecom_seller_id:''
        })
    }
    //====== END ECOMMERCE ====

    //====== DATE TIME======
    const listDate = dateTime?.list
    const activeDate = dateTime?.activeValue
    const handleActiveDate = data =>{
        let dateStart=''
        let dateEnd=''

        switch (data?.value) {
            case 'seven days':
                dateStart=getDateFromNow(-6,'start')
                dateEnd=getDateFromNow(0,'end')
                break;
            default:
                dateStart=getDateFromNow(-29,'start')
                dateEnd=getDateFromNow(0,'end')
                break
        }

        dateStart = formatDatetime(dateStart)
        dateEnd =  formatDatetime(dateEnd)

        pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_DATE_TIME_ACTIVE,
            payload: {
                active:data,
                dateStart,
                dateEnd
            }
        })

        const query={
            ...queries,
            start_date:convertDateTimeToApiFormatV2(dateStart),
            end_date:convertDateTimeToApiFormatV2(dateEnd)
        }
        fetchApiFilter(query)

    }
    //====== END DATE TIME======

    //====== STORES==========
    const listStore = stores?.list
    const listOriginStore = stores?.listOrigin
    const activeStore = stores?.activeValue
    const keywordStore = stores?.keyword
    const canSubmitFilter = JSON.stringify(activeStore) !== JSON.stringify(stores?.applyFilter) && activeStore?.length > 0
    const handleSelected = data =>{
        let newArr = [...activeStore]
        const findData = newArr?.find(find => +find?.ecom_seller_id === + data?.ecom_seller_id)
        pageDispatch({
            type:reportOverviewAction.REPORT_OVERVIEW_STORES_SELECTED,
            payload: !!findData ?
                newArr?.filter(filter =>+filter?.ecom_seller_id !== + data?.ecom_seller_id ) :
                [...newArr, data]
        })
    }

    const handleResetInput = ()=> pageDispatch({
        type:reportOverviewAction.REPORT_OVERVIEW_STORES_SELECTED,
        payload:[]
    })

    const handleTabChange = data =>{
        let list = [...activeStore]
        if(data === 'all') list = [...listOriginStore]

        pageDispatch({
            type:reportOverviewAction.REPORT_OVERVIEW_STORES_TAB,
            payload:{
                tab: data,
                list: list
            }
        })
    }

    const handleSearchStore = data =>{
       let listOrigin =[...listOriginStore]
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const listData = listOrigin.filter(item => {
            const formatNameItem = item?.shop_name
                ? removeAcent(item?.shop_name.toLowerCase())
                : ''
            const formatNameItemShortName = item?.shop_short_name
                ? removeAcent(item?.shop_short_name.toLowerCase())
                : ''

            if (formatNameItem.includes(formatDataValue)) return true
            if (formatNameItemShortName.includes(formatDataValue)) return true
            return false
        })
        pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_STORES_SEARCH,
            payload: {
                keyword: data?.value || '',
                list: listData,
            },
        })
    }

    const handleApplyFilter = () =>{
        pageDispatch({
            type:reportOverviewAction.REPORT_OVERVIEW_STORES_APPLY_FILTER,
            payload: activeStore
        })
        const query={
            ...queries,
            ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
            ecom_seller_id: stores?.activeValue?.length === 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        }
        fetchApiFilter(query)
    }

    const handleResetApply = ()=>{
        pageDispatch({
            type:reportOverviewAction.REPORT_OVERVIEW_STORES_APPLY_FILTER,
            payload: []
        })
        const query={
            ...queries,
            ecom_seller_id: '',
        }
        fetchApiFilter(query)
    }

    //===== END STORES ======

    //========= IMPORTANT NUMBER =========
    const activeList = importantNumber?.activeList?.map(item => {
      return {
        ...item,
        title: t(item.title),
        tooltiTitle: t(item.tooltiTitle),
        lastWeek: t(item.lastWeek),
        lastMonth: t(item.lastMonth),
        name: t(item.name),
        typeValue: t(item.typeValue),
      }
    })
    const handleActiveList = (data) =>{
        let newArr = [...activeList]
        let newList = [...activeList]
        const findData = newArr?.find(find=> find?.tab === data?.tab)
        pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_ACTIVE,
            payload:  !!findData && newArr?.length > 1 ? newArr.filter(filter=> filter.tab !== data?.tab )
                : !!findData && newArr?.length === 1 ? newArr : [...newArr, data].slice(0,3)
        })

        newList = !!findData ? newArr.filter(filter=> filter.tab !== data?.tab )
            : [...newArr, data]

        if(!!findData && newList.filter(filter=> filter.tab !== data?.tab ).length < 1)
            showAlert({type:'danger', content: t('e_com_min_one_index')})
        if(newList.length > 3)
            showAlert({type:'danger', content: t('e_com_max_three_index')})
    }

    const handleResetImportantNumber=()=>{
        pageDispatch({
            type: reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_ACTIVE,
            payload:  importantNumber?.list.slice(0,2)
        })

    }

    return{
        ecommerce:{
            activeValue: activeValue,
            onActive: handleActiveValue
        },
        dateTime:{
            list: listDate,
            activeValue:activeDate,
            dateStart: dateTime?.dateStart,
            dateEnd: dateTime?.dateEnd,
            onActiveDate: handleActiveDate
        },
        stores:{
            list: listStore,
            activeValue: activeStore,
            keyword: keywordStore,
            tab: stores?.tab,
            applyFilter: stores?.applyFilter,
            canSubmitFilter,

            onSelected: handleSelected,
            onInputReset: handleResetInput,
            onTabChange: handleTabChange,
            onApplyFilter: handleApplyFilter,
            onDeleteStore: handleResetApply,
            onSearch: handleSearchStore
        },
        importantNumber:{
            list: importantNumber?.list,
            activeList: activeList,
            importantData: importantNumber?.data,
            importantMeta: importantNumber?.meta,

            onActiveList: handleActiveList,
            onReset: handleResetImportantNumber
        },
        ratioProduct:{
            data: ratioProduct?.data
        },
        ratioOrder:{
            data: ratioOrder?.data
        },
        topProduct:{
            data: topProduct?.data,
            meta: topProduct?.meta
        },
        methods:{
            fetchApiFilter,
            queries
        }

    }

}