import styled from 'styled-components'
import {THEME_COLORS} from '../../../../../../../../../../common/theme/_colors'
export const StyleUpdateOrderModal = styled.div`
    background: #ffffff;
    .order-ecommerce-sync{
      &-date-time-form__input-wide{
        width: 200px;
        margin-right: 12px;
        .select__menu{
            width: 564px !important;
            padding: 8px 10px !important;
        }
      }
      &-search-form__input-wide{
        width: 420px;
      }
      &-form__option-text{
          cursor: pointer;
          margin-top: 16px;
          font-size: 14px !important;
          font-weight: 400 !important;
          &:nth-child(6){
            margin-bottom: 8px;
          }
          &[data-active = 'true']{
            color: ${THEME_COLORS.primary_300};
          }
          &:hover{
            color: ${THEME_COLORS.primary_300};
          }
      }
      &-search-form__table-layout{
        height: auto;
        //border-bottom: 1px solid #E2EAF8;
        .list-scroll-table{
          &[data-length = 'true' ]{
            height: 300px;
            overflow: auto;
          }
        }
      }
      &-search-form__table-header{
        height: 44px;
        display: flex;
        align-items: center;
        padding: 10px 16px;
        background: #F3F6FC;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: #E2EAF8;
        border-radius: 6px 6px 0px 0px;
      }
      &-search-form__table-body{
        height: 60px;
        display: flex;
        align-items: center;
        padding: 10px 16px;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: #E2EAF8;
      }
      &-search-form__footer{
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    
`