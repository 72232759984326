import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import ReactApexChart from "react-apexcharts";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../Component/chart";
import {chartOptionsProduct} from "./_chartProductOption";
import {useReportProductFilter} from "../../hook/useReportProductFilter";
import {fNumber} from "../../../../utils/formatNumber";
import {EmptyList} from "../../../../components/empty";
export const ChartTopProduct = ()=>{
    const {topProductChart, chartTag} = useReportProductFilter()
    const checkTypeValue = (val, type) => {
        switch (type) {
            case 'ratio_cancel':
                return  Math.round(val * 100)/100 +  '%';
            default:
                if (+val === 0 || +val < 100) return Math.round(val * 10) / 10
                else if (+val < 1000) return    Math.round(val* 10 / 100)/ 10+ 'k'
                else if (+val < 1000000) return    Math.round(val*10 / 1000)/10+ 'k'
                else if (+val < 100000000) return   Math.round(val*10 / 1000000)/10 + 'tr'
                else if (+val < 1000000000) return   Math.round(val*10 / 1000000)/10 + 'tr'
                else if (+val < 100000000000) return   Math.round(val*10 / 1000000000)/10 + 'tỷ'
                else return   Math.round(val*10 / 1000000000)/10 + 'tỷ'
        }
    }
    const chartOptions = merge(BaseOptionChart(), {
        labels:topProductChart?.list?.map(map => map?.product_name),
        listData: topProductChart?.list,
        tabChart: chartTag?.activeValue?.tab,
        xaxis :{
            labels: {
                position: 'top',
                trim: true,
                formatter: function (val) {
                    return checkTypeValue(val, chartTag?.activeValue?.tab)
                },
                style: {
                    colors: ['#0F172A'],
                    fontSize: '14px',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        dataLabels: {
            enabled: true,
            dropShadow: {enabled: false},
            style: {
                colors: ['#ffffff'],
                fontSize: '14px',
                fontWeight: 400,
            },
            formatter: function (val, opt) {
                return checkTypeValue(val, chartTag?.activeValue?.tab)
            },
        },
        ...chartOptionsProduct
    })
    const chartSeries =[
        {
            data: topProductChart?.list?.map(map => map?.data)
        },
    ]
    const heightChart = topProductChart?.list?.length < 3 || topProductChart?.list?.length === 0 ? 200 : topProductChart?.list?.length < 6 ? 350 : 600
    return (
        <StyleChartTopProduct>
            {
                topProductChart?.list?.length > 0 ? <ReactApexChart
                    type="bar"
                    series={chartSeries}
                    options={chartOptions}
                    height={heightChart}
                    width={'100%'}
                />:
                    <EmptyList type={'chart'} border={false} height={500}/>
            }

        </StyleChartTopProduct>
    )
}
const StyleChartTopProduct = styled.div`
  border-radius: 8px;
  border: 1px solid var(--line, #EBEEF5);
  margin-top: 32px;
  .apexcharts-theme-light{
       border: 0 !important;
       background: unset !important;
  }
  .apexcharts-yaxis-label{
    fill: #00081D !important;
  }
  .apexcharts-datalabels{
    transform: translate(-3px, 0px);
  }
  .report-arrow_box{
    padding: 8px;
    border-radius: 8px;
    background: rgba(0, 31, 62, 0.95);
    color: #ffffff;
    .item-tooltip{
      display: flex;
      align-items: flex-start;
      gap: 4px;
    }
  }
`