import React from "react";
import styled from "styled-components";
import {ORDER_STATUS_HANDLE} from "../../interface/_contant";
import {formatMoney} from "../../../../utils/functionUtil";
import {Text} from "../../../../../../../../common/text";
import {fNumber} from "../../../../utils/formatNumber";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {useTranslation} from "react-i18next";
export const ListProceedPanel = ()=>{
  const { t } = useTranslation()
    const {ratioOrder} = useReportOrderFilter()
    const {data} = ratioOrder
    const list = ORDER_STATUS_HANDLE.map((map,i)=>{
        const findData = data?.find(find=>+find?.status === +map?.status)
        if(findData){
            return({
                ...map,
                total: findData?.total,
                total_amount: findData?.total_amount
            })
        }
    })
    return(
        <StyleListProceedPanel>
            {
                list?.map(map=><div
                        key={map?.id}
                        className={'report-top-product_panel'}
                    >
                        {map?.icon}
                        <div>
                             <Text>{t(map?.title)}</Text>
                            <Text as={'p'} fontWeight={600} fontSize={20}>
                                {fNumber(map?.total)} {t('orders')}
                            </Text>
                            <Text color={'#808089'}>
                                {formatMoney(map?.total_amount)}
                            </Text>
                        </div>
                    </div>
                )
            }
        </StyleListProceedPanel>
    )
}
const StyleListProceedPanel = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  align-items: flex-start;
  .report-top-product{
      &_panel{
        height: 109px;
        padding: 16px;
        display: flex;
        gap: 16px;
        align-items: flex-start;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
      }
      &_panel-label{
        display: flex;
        align-items: center;
      }
  }
`