import React from "react";
import {ReportProductProvider} from './provider/_context'
import {GridLayout} from "../../../../../../layouts/gridLayout";
import {useReportProduct} from "./hook/useReportProduct";
import {FilterReport} from "./component/filter/_filterFormReport";
import {ProductOverView} from "./component/productOverview";
import {ProductChart} from "./component/productChart";
export const EcommerceReportProducts = ()=>{
    const {provider} = useReportProduct()
    const{state, dispatch} = provider
    return(
        <ReportProductProvider value={{pageState: state, pageDispatch: dispatch}}>
            <GridLayout
                grid={[
                    {
                        width: 100,
                        sections: [
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 16},
                                    children: <FilterReport />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 16 },
                                    children: <ProductOverView />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 16 },
                                    children: <ProductChart/>,
                                },
                            },
                        ],
                        props: {style: {position: 'relative'}},
                    },
                ]}
            />

        </ReportProductProvider>
    )
}