import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import styled from 'styled-components'
import {Tooltip} from "../../../../common/tooltipv2";

export const OrderTag = ({onDelete, ...props}) => {
  return (
    <StyledOrderTag {...props}>
        {props?.children && <Tooltip baseOn={'height'} placement={'bottom'} className={'tag-order-tooltip'} title={props.children}>
            <Text>{props.children}</Text>
        </Tooltip>}
      <div className="order-tag__delete" onClick={onDelete}>
        {ORDER_ICONS.x}
      </div>
    </StyledOrderTag>
  )
}

const StyledOrderTag = styled.li`
  position: relative;

  margin: 0 12px 12px 0;
  padding: 4px 28px 4px 12px;

  display: inline-block;

  background: rgba(30, 154, 152, 0.16);
  border-radius: 6px;
  .tag-order-tooltip{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25rem;
    width: 100%;
  }
  .order-tag {
    &__delete {
      position: absolute;
      top: 6px;
      right: 8px;

      width: 16px;
      height: 16px;

      border-radius: 6px;

      transition: all 0.25s;

      cursor: pointer;

      &:hover {
        background: ${THEME_SEMANTICS.failed};

        svg {
          color: #fff;

          path[stroke] {
            stroke: #fff;
          }

          path[fill] {
            fill: #fff;
          }
        }
      }
    }
  }
`
