import React from "react";
import styled from "styled-components";
import {REPORT_ORDER_TABLE_HEAD} from "../../interface/_contant";
import {Td} from "../../../../../../../../layouts/tableLayout/_td";
import {Tr} from "../../../../../../../../layouts/tableLayout/_tr";
import {Tooltip} from "../../../../../../../../common/tooltip";
import {Text} from "../../../../../../../../common/text";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {useTranslation} from "react-i18next";
export const TableHeadRevenueList = ()=>{
  const { t } = useTranslation()
    const {revenueList} = useReportOrderFilter()
    return(
        <StyleTableHead>
            <Tr
                type={'tHead'}
                data-type={'tHead'}
                className={'report-order-revenue-tr'}
            >
                {
                    REPORT_ORDER_TABLE_HEAD?.map(map=><Td
                        className={'report-order-revenue-td'}
                        key={map?.id}
                    >
                        <div className={'report-order-revenue-title'}>
                            <Text fontWeight={600}>{t(map?.name)}</Text>
                            <div className={'report-order-revenue-sort'}>
                                <span
                                    onClick={()=>revenueList?.onSort(map?.type,'max')}
                                    style={{ cursor:'pointer'}}
                                >
                                    {revenueList?.sort ==='max' ? map?.iconActive : map.iconDeActive}
                                </span>
                                <span
                                    onClick={()=>revenueList?.onSort(map?.type,'min')}
                                    style={{transform: 'rotate(180deg)',display:'block', cursor:'pointer', lineHeight: '12px'}}
                                >
                                    {revenueList?.sort === 'min' ? map?.iconActive : map.iconDeActive}
                                </span>
                            </div>
                            {
                                !!map?.iconToolTip && !!map?.tooltip && <Tooltip style={{paddingTop: 3}} title={t(map?.tooltip)}>
                                    {map?.iconToolTip}
                                </Tooltip>
                            }
                        </div>
                    </Td>)
                }
            </Tr>

        </StyleTableHead>
    )
}
const StyleTableHead = styled.div`
  margin-top: 24px;
  .report-order-revenue{
    &-tr{
      &[data-type='tHead']{
        .tr__container{
          height: 44px;
        }
      }
    }
     &-td{
        width: 25%;
        text-align: right;
        justify-content: flex-end;
        &:nth-child(1){
          text-align: left;
          justify-content: flex-start;
        }
     }
    &-title{
      display: flex;
      align-items: center;
    }
    
    &-sort{
      margin: 0 4px;
    }
  }

`