import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportOverviewFilter} from "../../hook/useReportOverviewFilter";
import {ImportantNumberTag} from "./_importantNumberTag";
import {ImportantNumberSelected} from "./_importantNumberSelected";
import ReactApexChart from "react-apexcharts";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../Component/chart";
import {ChartOptionReport} from "./_chartOption";
import {useTranslation} from "react-i18next";
export const ImportantNumbers = ()=>{
  const { t } = useTranslation()
    const {dateTime, importantNumber} = useReportOverviewFilter()
    const {activeValue} = dateTime

    const meta= importantNumber?.importantMeta
    const data= importantNumber?.importantData

    const list = importantNumber?.list


    const arrDate = data?.map(map => map?.title_date)
    const chartOptions = merge(BaseOptionChart(), {
        labels: arrDate,
        colors: importantNumber?.activeList?.map(map=>map?.color),
        activeList: importantNumber?.activeList,
        ...ChartOptionReport
    })

    return(
        <StyleImportantNumber>
            <Text as={'p'} fontWeight={600} fontSize={18} lineHeight={'150%'}>{t('e_com_index_important')}</Text>
            <Text color={'#7C88A6'}>{dateTime?.dateStart} - {dateTime?.dateEnd}</Text>
            <div className={'report-ecommerce-important-number'}>
                {
                    list?.map(map => <ImportantNumberTag
                        key={map?.id}
                        color={map?.color}
                        title={t(map?.title)}
                        tooltip={t(map?.tooltiTitle)}
                        value={map?.value}
                        isPrice={map?.isPrice}
                        percent={map?.percent}
                        compareTitle={activeValue?.value === 'seven days' ? t(map?.lastWeek) : t(map?.lastMonth)}
                        type={map?.tab}
                        data={map}
                    />)
                }
            </div>
            <ImportantNumberSelected/>
            <ReactApexChart
                type="line"
                series={importantNumber?.activeList}
                options={chartOptions}
                height={364}
                palette="1"/>
        </StyleImportantNumber>
    )
}

const StyleImportantNumber = styled.div`
  .report-ecommerce-important-number{
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
  }
  .apexcharts-canvas{
       .apexcharts-graphical{
        transform: translate(20px ,30px) !important;
      }
    }
  .report-arrow_box{
    border-radius: 8px;
    background: rgba(0, 31, 62, 0.95);
    width: 214px;
    padding: 12px;
    height: auto;
    p{
      color: #ffffff;
      font-family: 'SF Pro Display';
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
    span{
      color: #ffffff;
      font-family: 'SF Pro Display';
      font-size: 13px;
      font-weight: 400;
      line-height: 140%;
    }
    .item-tooltip{
        display: flex;
        align-items: flex-start;
    }
  }
`