import React from 'react';
import {Box, Modal} from "@mui/material";
import {Button} from "../../common/button";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

export const ConfirmModal = ({
                               header,
                               headerProps,
                               body,
                               bodyProps,
                               footer,
                               footerProps,
                               openModal,
                               closeModal,
                               acceptance,
                               styleBox = '',
                                stylePopup = '',
                                submitProps = {},
                                closeBackdropClick=false
                             }) => {
    const {t} = useTranslation()
  return (
    <>
      <Modal
        open={openModal}
        onClose={closeBackdropClick ? false : closeModal}
      >
        <StyledConfirmPopup>
          <Box className={`confirm-popup ${stylePopup}`} style={{styleBox}}>
            <div>
              {!!header && (
                <div
                  {...headerProps}
                  className={`confirm-popup__header ${headerProps?.className || ''}`}
                >
                  {header}
                </div>
              )}
              {!!body && (
                <div
                  {...bodyProps}
                  className={`confirm-popup__body ${bodyProps?.className || ''}`}
                >
                  {body}
                </div>
              )}
              {!!footer && (
                <div
                  {...footerProps}
                  className={`confirm-popup__group-btn ${footerProps?.className || ''}`}
                >
                  <Button onClick={closeModal}
                          appearance={'ghost'}
                          size={'sm'}
                          className={'confirm-popup__dismiss'}
                  >{t(footer?.cancel?.title || 'cancel')}</Button>
                  <Button onClick={acceptance}
                          className={'confirm-popup__save'}
                          size={'sm'}
                          {...submitProps}
                  >{t(footer?.acceptance?.title || 'confirm')}</Button>
                </div>
              )}
            </div>
          </Box>
        </StyledConfirmPopup>
      </Modal>
    </>
  );
}


export const StyledConfirmPopup = styled.div`
  // Confirm Popup
.confirm-popup {
  background: white;
  margin: 20rem auto 0 auto;
  padding: 32px 24px;
  line-height: 140%;
  width: 480px;
  min-height: 166px;
  border-radius: var(--border-radius);

  &__body {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
  }

  &__group-btn {
    margin-top: 1.5rem;
    text-align: right;

    button {
      font-weight: 400;
      font-size: 15px;

      cursor: pointer;
      border-radius: var(--border-radius-component);
      border: 1px solid #edf0f6;
    }
  }

  &__dismiss {
    background: var(--white-color);
    margin-right: .75rem;

    &:hover {
      border: 1px solid var(--color-package-up2022-7);
    }
  }

  &__save {
    color: var(--white-color);
    background: var(--bg-checked-radio);
    background: var(--color-package-up2022-7);

    &:hover {
      background: var(--color-hover-package-up2022-7);
      color: var(--white-color);
    }
  }
}
`