import React, {useState} from "react";
import { StyleUpdateOrderModal} from "./styled";
import {EcommerceSyncDateTime} from "./_ecommerceSyncDateTime";
import {EcommerceSyncOrderSearch} from "./_ecommerceSearchShop";
import {useReportUpdateOrder} from "../../../../hook/useReportUpdateOrder";
import {transformImageEcommerce} from "../../../../interface/_contant";
import CheckBoxConsignment from "../../../../../../../../../../Component/CheckBoxConsignment/CheckBoxConsignment";
import {Text} from "../../../../../../../../../../common/text";
import {Button} from "../../../../../../../../../../common/button";
import {Spinner} from "../../../../../../../../../../common/spinner";
import {useTranslation} from "react-i18next";

export const UpdateOrderComponent = ()=>{
  const { t } = useTranslation()
    const {updateOrderMethods, updateOrder} = useReportUpdateOrder()
    const {list, selected, listOrigin, statusUpdate} = updateOrder
    return(
        <StyleUpdateOrderModal>
            {
                +statusUpdate === 1 ?
                    <>
                        <div
                            style={{
                                display:'flex',
                                alignItems:'center',
                                marginBottom: 24
                            }}
                        >
                            <EcommerceSyncDateTime/>
                            <EcommerceSyncOrderSearch/>
                        </div>
                        <div className={'order-ecommerce-sync-search-form__table-layout'}>
                            <div className={'order-ecommerce-sync-search-form__table-header'}>
                                <div  style={{width: 30}}  >
                                    <CheckBoxConsignment
                                        handleClick={()=>updateOrderMethods.onChangeSelected('all')}
                                        isChecked={+selected?.length > 0}
                                        minus={+selected?.length < +listOrigin?.map(map=>map.value).length}
                                    />
                                </div>
                                <div style={{flex: 1}} >
                                    <Text fontWeight={600}>{t('booth')}</Text>
                                </div>
                                <div style={{width: 200}}>
                                    <Text fontWeight={600}>{t('ecommerce')}</Text>
                                </div>
                            </div>
                            <div data-length={list?.length > 5} className={list?.length > 5 && 'list-scroll-table common-scrollbar'}>
                                {
                                    list?.length > 0 ? list?.map((map,i)=> <Item key={i} item={map} />)
                                        :
                                        <div style={{
                                            height:200,
                                            border: '1px solid #E2EAF8',
                                            display: 'flex',
                                            alignItems:"center",
                                            justifyContent:'center'
                                        }}>
                                            <div style={{textAlign:'center'}}>
                                                <img
                                                    className="order-empty__banner"
                                                    src="/img/order/order-empty.png"
                                                    alt="empty"
                                                />
                                                <br/>
                                                <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
                                                  {t('not_find_stall')}
                                                </Text>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div
                                style={{
                                    borderBottom: list?.length > 0 && '1px solid #E2EAF8',
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                alignItems:'center',
                                marginTop: 16,
                                cursor:'pointer'
                            }}
                            onClick={()=>updateOrderMethods?.onChangeAuto(!updateOrder.autoConnect)}
                        >
                            <CheckBoxConsignment
                                isChecked={updateOrder?.autoConnect}
                            />
                            <Text style={{marginLeft: 8}}>{t('ecom_order_auto_sku_desc')} </Text>
                        </div>
                        <div className={'order-ecommerce-sync-search-form__footer'}>
                            <div>
                                <Text>
                                    {t('general_selected')}
                                    {' '}
                                    {
                                        selected?.length > 0 ? selected?.length : 0
                                    }
                                    {' '}
                                  {t('stall')}
                                </Text>
                            </div>
                            <div>
                                <Button
                                    size={'sm'}
                                    appearance={'ghost'}
                                    style={{
                                        width: 110,
                                        fontSize: 14,
                                        height: 32
                                    }}
                                    onClick={()=>updateOrderMethods.onOpenModal(false)}
                                >
                                  {t('general_close')}
                                </Button>
                                <Button
                                    size={'sm'}
                                    style={{
                                        width: 110,
                                        fontSize: 14,
                                        height: 32,
                                        marginLeft: 8
                                    }}
                                    disabled={selected?.length <= 0}
                                    onClick={updateOrderMethods.onConfirm}
                                >
                                  {t('general_confirm')}
                                </Button>
                            </div>
                        </div>
                    </>
                    :
                    <div
                        style={{
                            marginBottom: 24,
                            height: 505,
                            textAlign:'center',
                            padding:'11rem'
                        }}
                    >
                        <div style={{
                            marginBottom: 10,
                        }}
                        >
                            <Spinner size={48} thickness={4}/>
                        </div>
                        <Text>{t('e_com_processing_order')}</Text>
                    </div>
            }


        </StyleUpdateOrderModal>
    )
}

const Item = ({item})=>{
    const {updateOrderMethods, updateOrder} = useReportUpdateOrder()
    const renderName = type =>{
        switch (type) {
            case 'tiktok':
                return 'Tiktok Shop';
            case 'lazada':
                return "Lazada";
            case 'shopee':
                return "Shopee";
            default:
                return "UPOS"
        }
    }
    const isCheck = updateOrder?.selected.find(find => +find === +item?.value)
    return(
        <div>
            <div className={'order-ecommerce-sync-search-form__table-body'}>
                <div  style={{width: 30}}  >
                    <CheckBoxConsignment
                        handleClick={()=>updateOrderMethods.onChangeSelected('check',item?.value)}
                        isChecked={isCheck ? true : false}
                    />
                </div>
                <div style={{flex: 1, display:'flex', alignItems:'center'}} >
                    {transformImageEcommerce(item.ecom_type, 28)}
                    <Text style={{marginLeft: 8}}>{item.name}</Text>
                </div>
                <div style={{width: 200}}>
                    <Text>{renderName(item.ecom_type)}</Text>
                </div>
            </div>

        </div>
    )
}