import React from "react";
import styled from "styled-components";
import {useReportOverviewFilter} from "../../../../hook/useReportOverviewFilter";
import {Text} from "../../../../../../../../../../common/text";
import {TOP_PRODUCT_PANEL} from "../../../../interface/_contant";
import {Tooltip} from "../../../../../../../../../../common/tooltip";
import {fNumber} from "../../../../../../utils/formatNumber";
import {formatMoney} from "../../../../../../utils/functionUtil";
import {useTranslation} from "react-i18next";
export const ProductPanel = ()=>{
  const { t } = useTranslation()
    const {topProduct} = useReportOverviewFilter()
    const {meta} = topProduct
    const list = TOP_PRODUCT_PANEL.map((map,i)=>({
        ...map,
        value: meta[map?.isValue] || 0
    }))
    return(
        <StyleProductPanel>
            {
                list?.map(map=><div
                    key={map.id}
                    className={'report-top-product_panel'}
                >
                        {map?.icon}
                        <div>
                            <div className={'report-top-product_panel-label'}>
                                <Text style={{marginRight: 4}}>{t(map.label)}</Text>
                                {
                                    !!map?.iconTooltip && !!map.tooltip && <Tooltip style={{paddingTop: 5}} title={t(map.tooltip)}>
                                        {map.iconTooltip}
                                    </Tooltip>
                                }
                            </div>
                            <Text fontWeight={600} fontSize={20}>
                                {
                                    map?.isValue === 'total_quantity' ? `${fNumber(map?.value)} ${t('purchases_products')}` : formatMoney(map?.value)
                                }
                            </Text>
                        </div>
                </div>
                )
            }
        </StyleProductPanel>
    )
}
const StyleProductPanel = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  align-items: center;
  .report-top-product{
      &_panel{
        height: 84px;
        padding: 16px;
        display: flex;
        gap: 16px;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
      }
      &_panel-label{
        display: flex;
        align-items: center;
      }
  }
`