import {Button} from 'common/button'
import {Input} from 'common/form/input'
import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import UseSurveyLogin from '../hooks/index'
import useSurveyLoginContext from '../hooks/_context'
import {ORDER_SINGLE_ICONS} from 'Pages/orderSingle/interface/_icons'
import { Satellite } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

export const InfoAddress = ({...props}) => {
  const { t } = useTranslation()
  const [state, dispatch]= useSurveyLoginContext();
  const {address,properties, methods} = UseSurveyLogin()

  return (
    <Input
      {...props}
      button={
        <Button
          disabled={!properties.canSplitAddress}
          icon={ORDER_SINGLE_ICONS.target}
          onClick={methods.onAddressSplit}
        >
          {t('split')}
        </Button>
      }
      label={
        <>
          {t('customer_page_full_address')} <Text color={THEME_SEMANTICS.failed}>*</Text>
        </>
      }
      placeholder={t('address_province_hospital')}
      value={address.value}
      validateText={state.dataUpdate.address == '' ? t("please_enter_warehouse_address") :""}
      validateType="danger"
      onBlur={(e) =>{
        methods.onAddressChange(e.target.value)
        dispatch({type: 'SET_ADDRESS_UPDATE', payload: e.target.value})
      }}
      onChange={e => {
        methods.onAddressChange(e.target.value)
        dispatch({type: 'SET_ADDRESS_UPDATE', payload: e.target.value})
      }}
      onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
    />
  )
}
