import React from "react";

export const ECOMMERCE_BREADCUM=[
    {id: 1, name: 'ecommerce', url: '#'},
    {id: 2, name: 'report', url: '#'},
    {id: 3, name: 'overview', url: '#'},
]
export const ECOMMERCE_TAB_LIST=[
    {id: 1, name: 'overview', tab: 'overview'},
    {id: 2, name: 'order', tab: 'order'},
    {id: 3, name: 'product', tab: 'product'},
]
