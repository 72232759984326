import React from "react";
import styled from "styled-components";
import {ECOMMERCE_TAB_LIST} from "../../interfaces/_contant";
import {Text} from "../../../../../../common/text";
import {useEcommerceReportTab} from "../../hook/useEcommerceReportTab";
import {THEME_COLORS} from "../../../../../../common/theme/_colors";
import {EcommerceReportOverView} from "../../pages/overView";
import {EcommerceReportOrder} from "../../pages/order";
import {EcommerceReportProducts} from "../../pages/products";
import {checkPermission} from "../../../../../../Component/NavBar/until";
import NotPermission from "../../../../../permision/notPermission";
import {useTranslation} from "react-i18next";
export const TabListReport = ()=>{
  const { t } = useTranslation()
    const {tab} = useEcommerceReportTab()
    const validOverviewPermission = checkPermission('ecom_report', 'dashboard')
    const validOrderPermission = checkPermission('ecom_report', 'order')
    const validProductPermission = checkPermission('ecom_report', 'product')

    return(
        <StyleTabListReport>
            <div className='ecommerce-report-tabs-active' >
                {
                    ECOMMERCE_TAB_LIST?.map(map=> <div
                        key={map?.id}
                        data-active={map?.tab === tab?.activeTab?.tab}
                        onClick={()=> tab?.onActive(map)}
                        className='ecommerce-report-tabs-item'
                    >
                            <Text
                                fontWeight={500}
                                color={map?.tab === tab?.activeTab?.tab && THEME_COLORS.primary_300}
                            >
                                {t(map?.name)}
                            </Text>
                    </div>)
                }
            </div>
            <div className='ecommerce-report-tabs-active-report'>
                {tab?.activeTab?.tab === 'overview' && validOverviewPermission
                  ? <EcommerceReportOverView/>
                  : tab?.activeTab?.tab === 'order' && validOrderPermission
                    ? <EcommerceReportOrder/>
                    : tab?.activeTab?.tab === 'product' && validProductPermission
                      ? <EcommerceReportProducts/>
                      : <NotPermission style={{height: '75vh'}} />
                }
            </div>
        </StyleTabListReport>
    )
}

const StyleTabListReport = styled.div`
  .ecommerce-report-tabs{
    &-active{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 29px;
      margin-bottom: 16px;
    }
    &-item{
      margin-right: 24px;
      cursor: pointer;
      position: relative;
      &::after{
         content: '';
         width: 0;
         transition: 0.5s;
         position: absolute;
         left: 0;
         bottom: -8px;
       }
      &[data-active='true']{
        &::after{
          width: 100%;
          height: 2px;
          display: block;
          background: ${THEME_COLORS.primary_300};
        }
      }
    }
  }

`