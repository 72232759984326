import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {ListProceedPanel} from "./_listPrceedPanel";
import {useNavigate} from "react-router-dom";
import {PATH} from "../../../../../../../../const/path";
import {useTranslation} from "react-i18next";
export const ListOrderProceed = ()=>{
  const { t } = useTranslation()
    const navigate= useNavigate()
 return(
     <StyleListOrderProceed>
         <div className={'report-list-proceed_title'}>
             <div>
                 <Text as={'p'} fontSize={18} fontWeight={600} >{t('e_com_list_order_process')}</Text>
             </div>
             <div
                 style={{width:'fit-content', cursor:'pointer', display:'flex',alignItems:'center'}}
                 onClick={()=>navigate(PATH.ECOMMERCE_ORDER)}
             >
                 <Text color={'#1162FF'}
                 >
                   {t('e_com_list_oder')}
                 </Text>
                 {GLOBAL_ICON.arrowLeft}
             </div>
         </div>
         <ListProceedPanel/>
     </StyleListOrderProceed>
     )
}

const StyleListOrderProceed = styled.div`
  .report-list-proceed{
      &_title{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
  }

`