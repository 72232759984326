import React from 'react';
import {Box, Modal} from "@material-ui/core";
import styled from "styled-components";
import {Button} from "../../../../../../../../../common/button";
import {Text} from "../../../../../../../../../common/text";
import {GLOBAL_ICON} from "../../../../../../../../../interfaceGlobal";
import useQuickConnect from "../../../hook/useQuickConnect";
import {useTranslation} from "react-i18next";

const ResultQuickConnect = () => {
  const { t } = useTranslation()
  const {methods} = useQuickConnect()
  return (
    <Modal open={true}>
      <Box>
        <StyledResultQuickConnect>
          <Text as={'p'} fontSize={20} fontWeight={600}>{t('e_com_link_done')}</Text>
          {GLOBAL_ICON.resultQuickConnect}
          <Text as={'p'}>{t('e_com_you_have_link_done')}</Text>
          <div className={'result-quick-connect--btn'}>
            <Button
              appearance={'ghost'}
              onClick={() => methods?.onCloseResultQuickConnect()}
            >
              {t('general_close')}
            </Button>
          </div>
        </StyledResultQuickConnect>
      </Box>
    </Modal>
  )
}

export default ResultQuickConnect


const StyledResultQuickConnect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;

  background: #FFFFFF;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  position: absolute;
  width: 612px;
  height: 280px;
  left: calc(50% - 612px/2);
  top: calc(50% - 280px/2);
  
  .result-quick-connect {
    &--btn {
      width: 100%;
      text-align: end;
      
      & button {
        min-width: 110px;
      }
    }
  }
`