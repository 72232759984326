import {ECOMMERCE_TAB_LIST} from "../interfaces/_contant";



export const ecommerceReportInitState = {
  tab:{
    activeTab: ECOMMERCE_TAB_LIST[0]
  },
  stores:{
    list: [],
    listOrigin:[]
  },
  importantNumber:{
    data:[],
    meta:[],
  },
  ratioProduct:{
    data:[]
  },
  ratioOrder:{
    data:[]
  },
  topProduct:{
    data:[],
    meta:[],
  },

  revenueOrder:{
    data:[],
    meta:[]
  },
  ratioOrderStatus:{
    data:[]
  },
  revenueOrderTable:{
    data:[]
  },

  reportProduct:[]

}

export const ecommerceReportActions = {
  TAB_ECOMMERCE_REPORT_ACTIVE:'TAB_ECOMMERCE_REPORT_ACTIVE',
  STORES_ECOMMERCE_LIST:'STORES_ECOMMERCE_LIST',
  IMPORTANT_ECOMMERCE_LIST:'IMPORTANT_ECOMMERCE_LIST',
  RATIO_PRODUCT_ECOMMERCE_LIST:'RATIO_PRODUCT_ECOMMERCE_LIST',
  RATIO_ORDER_ECOMMERCE_LIST:'RATIO_ORDER_ECOMMERCE_LIST',
  TOP_PRODUCT_ECOMMERCE_LIST:'TOP_PRODUCT_ECOMMERCE_LIST',

  REVENUE_ORDER_LIST:'REVENUE_ORDER_LIST',
  RATIO_ORDER__STATUS_LIST:'RATIO_ORDER__STATUS_LIST',
  RATIO_ORDER__TABLE_LIST:'RATIO_ORDER__TABLE_LIST',

  REPORT_PRODUCT_LIST:'REPORT_PRODUCT_LIST'

}

export const ecommerceReportReducer = (state, action) => {
  switch (action.type) {
    case ecommerceReportActions.TAB_ECOMMERCE_REPORT_ACTIVE:
      return {
        ...state,
          tab:{
            ...state?.tab,
            activeTab: action.payload
          }
        }
    case ecommerceReportActions.STORES_ECOMMERCE_LIST:
      return {
        ...state,
        stores:{
            ...state?.stores,
          list: action.payload?.list,
          listOrigin: action.payload?.listOrigin
          }
        }
    case ecommerceReportActions.IMPORTANT_ECOMMERCE_LIST:
      return {
        ...state,
        importantNumber:{
          ...state?.importantNumber,
          meta: action.payload?.meta,
          data: action.payload?.data,
        }
      }
    case ecommerceReportActions.RATIO_PRODUCT_ECOMMERCE_LIST:
      return {
        ...state,
        ratioProduct:{
          ...state?.ratioProduct,
          data: action.payload?.data,
        }
      }
    case ecommerceReportActions.RATIO_ORDER_ECOMMERCE_LIST:
      return {
        ...state,
        ratioOrder:{
          ...state?.ratioOrder,
          data: action.payload?.data,
          meta: action.payload?.meta,
        }
      }
    case ecommerceReportActions.TOP_PRODUCT_ECOMMERCE_LIST:
      return {
        ...state,
        topProduct:{
          ...state?.topProduct,
          data: action.payload?.data,
          meta: action.payload?.meta,
        }
      }
    case ecommerceReportActions.REVENUE_ORDER_LIST:
      return {
        ...state,
        revenueOrder:{
          ...state?.revenueOrder,
          data: action.payload?.data,
          meta: action.payload?.meta,
        }
      }
    case ecommerceReportActions.RATIO_ORDER__STATUS_LIST:
      return {
        ...state,
        ratioOrderStatus:{
          ...state?.ratioOrderStatus,
          data: action.payload?.data,
        }
      }
    case ecommerceReportActions.RATIO_ORDER__TABLE_LIST:
      return {
        ...state,
        revenueOrderTable:{
          ...state?.revenueOrderTable,
          data: action.payload?.data,
        }
      }
    case ecommerceReportActions.REPORT_PRODUCT_LIST:
      return {
        ...state,
        reportProduct:action.payload
      }
    default: return state
  }
}