import React from "react";
import styled from "styled-components";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {Text} from "../../../../../../../../common/text";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {useTranslation} from "react-i18next";
export const RevenueSelected = ()=>{
  const { t } = useTranslation()
    const {revenue} = useReportOrderFilter()
    return(
        <StyleRevenueSelected>
            <div  style={{display:'flex', alignItems:'center'}}>
                {
                    revenue?.activeList?.map(map => <div style={{display:'flex', alignItems:'center',  marginRight : 24}} key={map?.id} >
                        <div
                            style={{
                                background: map?.color,
                                width: 8,
                                height: 8,
                                borderRadius: 50,
                                marginRight : 8
                            }}
                        />
                        <Text>{t(map?.title)}</Text>
                    </div>)
                }
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Text>{t('selected')}: {revenue?.activeList?.length}/3 {t('e_com_index')}</Text>
                <div style={{marginLeft: 12, display:'flex', alignItems:'center'}}>
                    {GLOBAL_ICON.resetNumber}
                    <div style={{cursor:'pointer'}} onClick={revenue?.onReset}>
                        <Text color={'#1162FF'} fontWeight={500} >{t('general_reset_to_default')}</Text>
                    </div>
                </div>
            </div>
        </StyleRevenueSelected>
    )
}
const StyleRevenueSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
`