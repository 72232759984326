import {useCallback, useContext, useEffect} from "react";
import { ReportOverviewContext} from "../provider/_context";
import {debounce} from "@mui/material";
import {reportOverviewAction} from "../provider/_reducer";
import {REPORT_UPDATE_ORDER_DATE} from "../interface/_contant";
import useAlert from "../../../../../../../hook/useAlert";
import {convertDateTime, getDateFromNow} from '../../../utils/formatDate'
import {sendRequestAuth} from "../../../../../../../api/api";
import config from "../../../../../../../config";
import useReportEcommerce from "../../../index";
import {useReportOverviewFilter} from "./useReportOverviewFilter";
import {useTranslation} from "react-i18next";
export const useReportUpdateOrder = ()=>{
  const { t } = useTranslation()
    const {pageState, pageDispatch} = useContext(ReportOverviewContext)
    const {modal} = pageState
    const {updateOrder} = modal
    const {dateTime} = updateOrder
    const valueDateTime = dateTime.value
    const {list, listOrigin, keyword, selected, autoConnect, statusUpdate, res} = updateOrder

    const ecommerceReportInitState = useReportEcommerce()
    const {methods} = useReportOverviewFilter()
    const {showAlert} = useAlert()




    const transformOriginList = data =>{
        return (
            data?.map(item => ({
                name: item?.shop_short_name || item?.shop_name,
                value: item?.ecom_seller_id || '',
                ecom_type: item?.ecom_type,
            }))
        )
    }
    useEffect(()=>{
        if(ecommerceReportInitState?.pageState?.stores?.list) {
            pageDispatch({type:reportOverviewAction.MODAL_ORDER_LIST,payload:{
                    list: transformOriginList(ecommerceReportInitState?.pageState?.stores?.list),
                    listOrigin: transformOriginList(ecommerceReportInitState?.pageState?.stores?.list)
                }})
        }
    },[ecommerceReportInitState?.pageState?.stores?.list])
    const formatDatetime = data => {
        const D = new Date(data)

        let d = D.getDate()
        d = d < 10 ? `0${d}` : d

        let m = D.getMonth()
        m = m + 1
        m = m < 10 ? `0${m}` : m

        let y = D.getFullYear()

        let h = D.getHours()
        h =  h < 10 ? `0${h}` : h


        let M = D.getMinutes()
        M=  M < 10 ? `0${M}` : M

        return `${d}/${m}/${y} ${h}:${M}`
    }

    const handleOpenModal = boo =>{
        pageDispatch({type:reportOverviewAction.MODAL_ORDER_MODAL,payload : boo})
    }
    const queries ={
        seller_id: selected?.join(',') || '',
        start_date: valueDateTime?.length > 0?
            convertDateTime(formatDatetime(valueDateTime[0])) :
            dateTime?.activeValue?.value === 'all of times' ? '' :
                convertDateTime(formatDatetime(getDateFromNow(-1, {type: 'start'})))
        ,
        end_date:valueDateTime?.length > 0?
            convertDateTime(formatDatetime(valueDateTime[1])) :
            dateTime?.activeValue?.value === 'all of times' ? '' :
                convertDateTime(formatDatetime(getDateFromNow(-1, {type: 'start'}))),
        is_auto_connect: autoConnect ? 1 : 0
    }
    const handleChangeDateTime = (data)=>{
        let  dateTimeDefaultValue = []
        switch (data?.value) {
            case REPORT_UPDATE_ORDER_DATE[0].value:
                dateTimeDefaultValue = [
                    ...dateTimeDefaultValue,
                    getDateFromNow(-1, {type: 'start'}),
                ]
                break;
            case REPORT_UPDATE_ORDER_DATE[1].value:
                dateTimeDefaultValue = [
                    ...dateTimeDefaultValue,
                    getDateFromNow(-3, {type: 'start'}),
                    getDateFromNow(0, {type: 'end'}),
                ]
                break;
            case REPORT_UPDATE_ORDER_DATE[2].value:
                dateTimeDefaultValue = [
                    ...dateTimeDefaultValue,
                    getDateFromNow(-5, {type: 'start'}),
                    getDateFromNow(0, {type: 'end'}),
                ]
                break;
            case REPORT_UPDATE_ORDER_DATE[3].value:
                dateTimeDefaultValue = [
                    ...dateTimeDefaultValue,
                    getDateFromNow(-7, {type: 'start'}),
                    getDateFromNow(0, {type: 'end'}),
                ]
                break;
            case REPORT_UPDATE_ORDER_DATE[4].value:
                dateTimeDefaultValue = [
                    ...dateTimeDefaultValue,
                    getDateFromNow(-30, {type: 'start'}),
                    getDateFromNow(0, {type: 'end'}),
                ]
                break;
            case REPORT_UPDATE_ORDER_DATE[5].value:
                dateTimeDefaultValue = [...dateTimeDefaultValue]
                break;
            default:
                break;
        }
        pageDispatch({type:reportOverviewAction.MODAL_ORDER_DATE_TIME_UPDATE,payload:{
                active: data,
                value:dateTimeDefaultValue
            }})
    }
    const debounceSearchChange = useCallback(debounce(async ( keyword) => {
        const response = await sendRequestAuth('get',`${config.API}/ecommerce/list?keyword=${keyword}&ecom_type=&start_date&end_date&status=1&start=0&per_page=200`)
        if(response?.data?.success){
            pageDispatch({type:reportOverviewAction.MODAL_ORDER_LIST,payload:{
                    list: transformOriginList(response?.data?.data)
                }})
        }
    }, 500), [])

    const handleChangeKeyword = keyword => {
        pageDispatch({type:reportOverviewAction.MODAL_ORDER_DATE_TIME_KEYWORD,payload:keyword})
        debounceSearchChange(keyword)
    }

    const handleCheckEcommerceSeller = (type,id)=>{
        let activeList = [...selected]
        const arrValue = listOrigin?.map(map => map.value)
        if(type === 'all'){
            if(+activeList?.length === +arrValue?.length) activeList = []
            else activeList = [...arrValue]
        } else {
            const findCheckbox = activeList.find(find => +find === +id)
            activeList = findCheckbox ?
                activeList.filter(filter => +filter !== +id)
                : activeList = [...activeList, id]
        }


        pageDispatch({type:reportOverviewAction.MODAL_ORDER_LIST_SELECTED, payload: activeList})

    }



    const handleConfirmModal = async () =>{
        pageDispatch({type:reportOverviewAction.MODAL_ORDER_DATE_TIME_STATUS_UPDATE, payload: 2})
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(queries)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        const response = await sendRequestAuth('post',`${config.API}/ecommerce/orders/sync${queryString}`)
        if(response?.data?.success){
            pageDispatch({type:reportOverviewAction.MODAL_ORDER_CONFIRM_UPDATE, payload: {
                    status: 3,
                    res: response?.data
                }})
            methods?.fetchApiFilter(methods?.queries)
        }else showAlert({type:'danger', content: response?.data?.errors?.message || `${t('ecom_update_order_failed')}!`})
    }
    const handleAutoConnect = check => pageDispatch({type:reportOverviewAction.MODAL_ORDER_DATE_TIME_AUTO_CONNECT, payload: check})
    return{
        updateOrder:{
            open: updateOrder?.open,
            valueDateTime:  dateTime.activeValue,
            listDateTime: dateTime.list,
            keyword,
            list,
            selected,
            listOrigin,
            autoConnect,
            statusUpdate,
            res,
        },
        updateOrderMethods:{
            onOpenModal: handleOpenModal,
            onChangeDateTime: handleChangeDateTime,
            onChangeKeyword: handleChangeKeyword,
            onChangeSelected: handleCheckEcommerceSeller,
            onConfirm: handleConfirmModal,
            onChangeAuto: handleAutoConnect,
        }
    }
}