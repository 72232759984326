import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportOverviewFilter} from "../../hook/useReportOverviewFilter";
import {ProductPanel} from "./component/productPanel";
import {TableTopProduct} from "./component/tableTopProduct";
import {useEcommerceReportTab} from "../../../../hook/useEcommerceReportTab";
import {ECOMMERCE_TAB_LIST} from "../../../../interfaces/_contant";
import {useTranslation} from "react-i18next";
export const TopProduct = ()=>{
  const { t } = useTranslation()
    const {dateTime} = useReportOverviewFilter()
    const {dateStart, dateEnd} = dateTime
    const {tab} = useEcommerceReportTab()
 return(
     <StyleTopProduct>
         <div className={'report-top-product_title'}>
             <div>
                 <Text as={'p'} fontSize={18} fontWeight={600} >{t('e_com_ranking_product')}</Text>
                 <Text color={'#7C88A6'} >{dateStart} - {dateEnd}</Text>
             </div>
             <div style={{width:'fit-content', cursor:'pointer'}} onClick={()=>tab?.onActive(ECOMMERCE_TAB_LIST[2])} >
                 <Text color={'#1162FF'}
                 >
                   {t('e_com_view_detail_report_product')}
                 </Text>
             </div>
         </div>
         <ProductPanel/>
         <TableTopProduct/>
     </StyleTopProduct>
     )
}

const StyleTopProduct = styled.div`
  .report-top-product{
      &_title{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
  }

`