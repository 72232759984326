import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import toast from "../../../Component/Toast";
import {orderActions} from "../provider/_reducer";
import {OrderContext} from "../provider/_context";
import {sendRequestAuth} from "../../../api/api";
import config from "../../../config";
import useOrderFilterForm from "./useOrderFilterForm";

export const useModalAddCustomer = () => {
  const {t} = useTranslation()
  const {functions} = useOrderFilterForm()
  const {pageState, pageDispatch} = useContext(OrderContext)
  const modalAddCustomer = pageState?.modalAddCustomer?.data
  const openModalAddCustomer = pageState?.modalAddCustomer?.open
  const [focusTaxCode, setFocusTaxCode] = useState(false)

  const handleFetchOrigin = async _ => {
    // const hasCustomer = pageState?.orders?.customer?.[0]?.data
    // pageDispatch({
    //   type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY,
    //   payload: !hasCustomer?.id ? t('retail_customer') : ""
    // })
    // pageDispatch({
    //   type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS,
    //   payload: !hasCustomer?.id ? t('retail_customer') : ""
    // })
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE,
      payload: ""
    })
    const response = await sendRequestAuth('get', `${config.API}/setting/einvoice/list-partner`)
    if(response?.data?.data) {
      const dataEOrder = response?.data?.data?.[0]

      const responseDetail = await sendRequestAuth('get', `${config.API}/setting/einvoice/detail/${dataEOrder?.id}`)
      if(responseDetail?.data?.success) {
        const settingDetail = JSON.parse(responseDetail?.data?.data?.setting)

        pageDispatch({
          type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_VAT,
          payload: settingDetail?.vat
        })
      }
    }
  }

  const vatValue = modalAddCustomer?.vat
  // ============= Name Company =============
  const nameCompany = modalAddCustomer?.nameCompany?.value
  const nameCompanyError = modalAddCustomer?.nameCompany?.errorMessage
  const handleChangeNameCompany = value => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY,
      payload: value
    })
  }
  const handleBlurNameCompany = _ => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY_MESSAGE_ERROR,
      payload: nameCompany?.length === 0 ? t('e_order_company_name_invalid') : ""
    })
  }
  // ============= End Name Company =============


  // ============= Address Company =============
  const addressCompany = modalAddCustomer?.address?.value
  const addressCompanyError = modalAddCustomer?.address?.errorMessage
  const handleChangeAddress = value => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS,
      payload: value
    })
  }
  const handleBlurAddress = _ => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS,
      payload: addressCompany?.trim()
    })

    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS_MESSAGE_ERROR,
      payload: nameCompany?.length === 0 ? t('address_must_not_empty') : ""
    })
  }
  // ============= End Address Company =============


  // ============= Tax Code Company =============
  const taxCode = modalAddCustomer?.taxCode?.value
  const taxCodeError = modalAddCustomer?.taxCode?.errorMessage
  const handleChangeTaxCode = value => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE,
      payload: value?.trim()
    })
  }
  const handleBlurTaxCode = _ => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE_MESSAGE_ERROR,
      payload: taxCode?.length < 10 && taxCode.length !== 0 ? t('e_order_tax_code_error') : ""
    })
    setFocusTaxCode(false)
  }

  const handleFocusTaxCode = _ => {
    setFocusTaxCode(true)
  }
  // ============= End Tax Code =============

  // ============= AUTO RELEASE  ===================
  const autoRelease = modalAddCustomer?.autoRelease

  const handleChangeAutoRelease = value => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_AUTO_RELEASE,
      payload: value
    })
  }
  // ============= END AUTO RELEASE  ===================

  // =========== CONFIRM COLLECT ========================
  const confirmCollect = modalAddCustomer?.confirmCollect

  const handleChangeConfirmCollect = value => {
    pageDispatch({
      type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_CONFIRM_COLLECT,
      payload: value
    })
  }
  // =============== END CONFIRM COLLECT ========================
  const detailInvoice = modalAddCustomer?.detail

  const handleCloseModal = _ => {
    pageDispatch({
      type: orderActions.TOGGLE_MODAL_ADD_CUSTOMER,
      payload: false
    })

    if (nameCompany?.length === 0 || addressCompany?.length === 0 || (taxCode?.length < 10 && taxCode?.length !== 0)) {
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY_MESSAGE_ERROR, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS_MESSAGE_ERROR, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE_MESSAGE_ERROR, payload: ""})
      pageDispatch({type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_AUTO_RELEASE, payload: true})
    }
  }

  const formValid = _ => {
    let success = true
    if (nameCompany?.length === 0) {
      pageDispatch({
        type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_COMPANY_MESSAGE_ERROR,
        payload: t('e_order_error_company')
      })
      success = false
    }
    if (addressCompany?.length === 0) {
      pageDispatch({
        type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_ADDRESS_MESSAGE_ERROR,
        payload: t('e_order_error_address')
      })
      success = false
    }
    if (taxCode?.length < 10 && taxCode.length !== 0) {
      pageDispatch({
        type: orderActions.UPDATE_MODAL_ADD_CUSTOMER_TAX_CODE_MESSAGE_ERROR,
        payload: t('e_order_tax_code_error')
      })
      success = false
    }
    return success
  }

  const provisional = (+detailInvoice?.total_amount  - +detailInvoice?.service_fee  - +detailInvoice?.service_vat_fee ) || 0

  const [debounceSubmit, setDebounceSubmit] = useState(true)
  const handleSubmit = async _ => {
    const valid = formValid()
    if (valid && debounceSubmit) {
      setDebounceSubmit(false)
      setTimeout(() => setDebounceSubmit(true), 2000)

      const vatPercent = !vatValue || vatValue === "not_taxable" || vatValue === "not_declare"
        ? 0
        : vatValue === "10x70"
          ? 7
          : vatValue === "5x70"
            ? 3.5
            : +vatValue
      const formData = {
        tax_number: taxCode,
        company_name: nameCompany,
        address: addressCompany,
        active: autoRelease ? 1 : 0,
        payment_money: +provisional * +vatPercent / 100
      }
      const response = await sendRequestAuth("post", `${config.API}/setting/einvoice/create/${detailInvoice?.id}`, formData)
      if(response?.data?.success){
        toast.success(response?.data?.message)
        functions?.refresh()
      } else {
        toast.error(response?.data?.message)
      }
      pageDispatch({
        type: orderActions.TOGGLE_MODAL_ADD_CUSTOMER,
        payload: false
      })
    }
  }

  return {
    openModalAddCustomer,
    vatValue,
    nameCompany,
    nameCompanyError,
    addressCompany,
    addressCompanyError,
    taxCode,
    taxCodeError,
    focusTaxCode,
    autoRelease,
    confirmCollect,
    detailInvoice,
    methods: {
      origin: handleFetchOrigin,
      handleChangeNameCompany,
      handleBlurNameCompany,
      handleChangeAddress,
      handleBlurAddress,
      handleChangeTaxCode,
      handleBlurTaxCode,
      handleFocusTaxCode,
      handleChangeAutoRelease,
      handleChangeConfirmCollect,
      handleCloseModal,
      handleSubmit
    }
  }
}
