import React from "react";
import {StyledStoreConfigDetail} from "./styles";
import {Text} from "../../../../../../common/text";
import {Input} from "../../../../../../common/form/input";
import {Button} from "../../../../../../common/button";
import {useUpdateStoreConfig} from "../../../../hook/useUpdateStoreConfig";
import ChoosePrintTemplate from "./_choosePrintTemplate"
import {ConfirmModal} from "../../../../../../layouts/rightSightPopup/confirm";
import './index.scss'
import {SwitchStatus} from "../../../../../SwitchStatus/SwitchStatus";
import { useTranslation } from 'react-i18next'
import {Switch} from "../../../../../../common/switch";

const Index = ({...props})=>{
  const {t} = useTranslation()
    const {
        warningPhone,
        quantityLowRate,
        storeFunction,
        functions,
        hiddenShipFee,
        user
    }=useUpdateStoreConfig()
    return(
        <StyledStoreConfigDetail>
            <div className={'store-config'}>
                <div className={'store-config-order-inventory'}>
                    <Text fontSize={16} fontWeight={600}>{t('ware_house')}</Text>
                    <div className={'store-config-inventory store-config-inventory-edit'}>
                        <Text style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('store_config_warning_quantity')}</Text>
                        <Input
                            className={'store-config-inventory-input'}
                            value={quantityLowRate?.activeValue}
                            onChange={e=>storeFunction.onChange(e,props.isUpdate)}
                        />
                    </div>
                </div>
                <div className={'store-config-order-inventory'}>
                    <Text fontSize={16} fontWeight={600}>{t('auto_bill')}</Text>
                    <div className={'store-config-inventory store-config-inventory-edit'}>
                        <Text style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('setting_print')}</Text>
                        <ChoosePrintTemplate isUpdate={props.isUpdate}/>
                        {/*<Text color={'#151624'} fontSize={15}>In mẫu đơn vị vận chuyển</Text>*/}
                    </div>
                </div>
                <div className={'store-config-btn-update'}>
                    <div className={'store-config-title'} >
                      <Text fontSize={16} fontWeight={600}>{t('store_config_sell')}</Text>
                        <div className={'store-config-switch-alert'}>
                          <SwitchStatus
                            status={warningPhone}
                            handleChange={() => functions?.handleToggleWarning(warningPhone, props.isUpdate)}
                          />
                          <Text style={{marginLeft:'8px'}} fontSize={15}>{t('store_config_warning_phone_create')}</Text>
                        </div>
                    </div>
                </div>
                {
                    +user?.is_manage === 1 && <div className={'store-config-btn-update'}>
                        <div className={'store-config-title'} >
                            <Text fontSize={16} fontWeight={600}>{t('store_config_hidden_fee_delivery')}</Text>
                            <br/>
                            <Text>{t('store_config_hidden_fee_delivery_apply')}</Text>
                            <div className={'store-config-switch-alert'}>
                                <SwitchStatus
                                    status={+hiddenShipFee === 1 ? true : false}
                                    handleChange={() => functions?.handleShipFeeActive(hiddenShipFee)}
                                />
                                <Text style={{marginLeft:'8px'}} fontSize={15}>{t('hidden_fee_delivery')}</Text>
                            </div>
                        </div>
                    </div>
                }

            </div>
          <div className='store-config_action-btn'>
            <Button appearance={'ghost'} onClick={functions.cancelStore}>{t('general_cancel')}</Button>
            <Button className={'store-config_accept'} onClick={()=>functions.updateStore(props.isUpdate)}>{t('update')}</Button>
          </div>
          {confirm && <ConfirmLeavePage setCloseAnchor={props.setCloseAnchor} />}
        </StyledStoreConfigDetail>
    )
};

export default Index;
const ConfirmLeavePage = ({...props})=>{
    const {
        cancelEdit,
        confirm,
        storeFunction
    }=useUpdateStoreConfig()
    return(
            <ConfirmModal
                openModal={confirm}
                body={<Confirm />}
                stylePopup={'store-config-modal_confirm'}
                footer={
                    {
                        cancel: {
                            width: 110,
                            title: 'Huỷ',

                        },
                        acceptance: {
                            width: 110,
                            title: 'Xác nhận'
                        },
                    }
                }
                footerProps={
                    {className:'store-config-modal_dismiss'}
                }
                closeModal={() => storeFunction.handleCancelConfirm()}
                acceptance={() =>{
                    props.setCloseAnchor()
                    storeFunction.handleAcceptConfirm()
                }}
            />
    )
}
const Confirm = ()=>{
    return (
        <>
            <Text
                fontSize={20}
                fontWeight={600}
            >Xác nhận rời khỏi trang</Text>
            <Text as='p' className='store-config-modal_txt'>Một số thông tin đã thay đổi, bạn có chắc chắn muốn rời khỏi trang khi thay đổi chưa được lưu?</Text>
        </>

    )
}
