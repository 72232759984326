import { formatMoney } from '../../../../utils/functionUtil'



export const ChartOptionReport = {
    tooltip: {
        custom: function ({ series, dataPointIndex, w, }) {
          let order =  w?.config?.language?.order
            const svg=(color)=>{
                return `
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="4.5" stroke=${color} stroke-width="3"/>
                </svg>
                `
            }
            const arrName = w?.config?.series?.map(map=> map?.name)
            const arrColor = w?.config?.series?.map(map=> map?.color)
            const arrIsPrice = w?.config?.activeList?.map(map=> map?.isPrice)

            const value = (index, val)=>{
                switch (arrIsPrice[index]) {
                    case true:
                        return formatMoney(val)
                    default:
                        return `${val} ${order}`
                }
            }
            let html = '<div class="report-arrow_box">'
            if (series[0].length > 0)
                html += '<div class="item-tooltip">'+ svg(arrColor[0]) +
                            '<div style="margin-left: 4px">'+
                                '<span>' + arrName[0] + '</span>'+
                                '<p>' + value(0, series[0][dataPointIndex]) + '</p>' +
                            '</div>'+
                        '</div>'
            if (series[1] && series[1].length > 0)
                html += '<div class="item-tooltip">'+ svg(arrColor[1]) +
                            '<div style="margin-left: 4px">'+
                                '<span>' + arrName[1] + '</span>'+
                                '<p>' + value(1, series[1][dataPointIndex]) + '</p>' +
                            '</div>'+
                        '</div>'
            if (series[2] && series[2].length > 0)
                html += '<div class="item-tooltip">'+ svg(arrColor[2])+
                            '<div style="margin-left: 4px">'+
                                '<span>' + arrName[2] + '</span>'+
                                '<p>' + value(2, series[2][dataPointIndex]) + '</p>'+
                            '</div>'+
                        '</div>'
            html += '</div>'
            return html
        }
    },
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: {
        type: "gradient",
            gradient: {
            shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.25,
                opacityTo: 0,
                stops: [0, 90, 100]
        }
    },
    stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: undefined,
        width: 2,
        dashArray: 0,
    },
    xaxis: {
        labels: {
            formatter: function(value) {
                if(value && typeof value != 'number'){
                    const date = value.split('/')
                    return date[0]+"/"+date[1]
                }
                return value
            },
            style: {
                colors: '#27272A',
                    fontSize: '12px',
                    fontFamily: 'SF Pro Display',
                    fontWeight: 500,
                    lineHeight: '140%',
                    cssClass: 'apexcharts-xaxis-custom-label',
            },
        },
        tooltip: {
            enabled: false,
        }
    },
    animation: {
        x: {
            duration: 5000,
                from: 0
        },
        y: {
            duration: 3000,
                from: 500
        }
    },
    legend: {
        show: 0
    },
    yaxis: {
        show: false,
    },

    markers: {
        // strokeColors: ['#168AFF','#168AFF','#168AFF','#168AFF'],
        strokeWidth: [4,4,4,4,4],
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
            size: 7,
                sizeOffset: 5
        }
    }

}