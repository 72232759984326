import {HEADER_ICONS} from './_icons'
import {PATH} from "../../const/path";
import {DISPLAY_NAME_MENU} from "../../const/display_name_menu";

export const HEADER_QUICK_ACCESSES = [
  {
    id: 1,
    name: DISPLAY_NAME_MENU.CREATE_ORDER,
    displayIcon: HEADER_ICONS.createOrder,
    path: PATH.ORDER_CREATE
  },
  // {
  //   id: 2,
  //   name: DISPLAY_NAME_MENU.CREATE_AT_SHOP,
  //   displayIcon: HEADER_ICONS.createPos,
  //   path: PATH.POS
  // },
  {
    id: 3,
    name: DISPLAY_NAME_MENU.CREATE_PRODUCT,
    displayIcon: HEADER_ICONS.createProduct,
    path : PATH.CREATE_PRODUCT
  },
  {
    id: 4,
    name: DISPLAY_NAME_MENU.CREATE_CUSTOMER,
    displayIcon: HEADER_ICONS.createClient,
    path : PATH.CREATE_CUSTOMER
  },
  {
    id: 5,
    name: 'create_purchase',
    displayIcon: HEADER_ICONS.createImport,
    path : PATH.PURCHASE_CREATE
  },
]
