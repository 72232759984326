import React from "react";
import {AlternativeAutoComplete} from "../../../../../../common/form/autoComplete/_alternativeAutoComplete";
import {useUpdateStoreConfig} from "../../../../hook/useUpdateStoreConfig";
import {Option} from "../../../../../../common/form/autoComplete/_option";
import {useTranslation} from "react-i18next";

const Index = ({...props})=>{
  const {t} = useTranslation()
  const {
    bulkOrder,
    storeFunction,
  }=useUpdateStoreConfig()
  const {activeValue, list, keyword} = bulkOrder
  return(
    <AlternativeAutoComplete
      className="store-config__input-wide"
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: '', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 125,
        categoryHidden: true,
        placeholder: t('store_config_select_temp'),
        readOnly: true,
        value: t(activeValue?.name) || '',
      }}
      menuProps={{
        empty: list.length <= 0 ? t('store_config_not_found_temp') : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: t('store_config_search_temp'),
        value: keyword,
        onChange: storeFunction.onKeywordChange,
      }}
    >
      {list.length > 0 &&
      list.map(item => (
        <Option
          key={item.value}
          className="store-config__option-text"
          data-active={item.value === activeValue?.value}
          onClick={() => storeFunction.onChangBulkOrder(item,props.isUpdate)}
        >
          {t(item.name)}
        </Option>
      ))}

    </AlternativeAutoComplete>
  )
}

export default Index;