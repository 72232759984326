import React from "react";
import styled from "styled-components";
import {useReportOverviewFilter} from "../../../../hook/useReportOverviewFilter";
import {Text} from "../../../../../../../../../../common/text";
import {Button} from "../../../../../../../../../../common/button";
import {GLOBAL_ICON} from "../../../../../../../../../../interfaceGlobal";
import ReactApexChart from "react-apexcharts";
import {chartOptionsRatioProduct} from "./_chartOptionRatioProduct";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../../../Component/chart";
import {ChartSeriesRatioProduct} from "./_chartSeries";
import useQuickConnect from "../../../../hook/useQuickConnect";
import {useTranslation} from "react-i18next";
export const RatioProduct = ()=>{
  const { t } = useTranslation()
    const {dateTime, ratioProduct} = useReportOverviewFilter()
    const {methods} = useQuickConnect()

    const {dateStart, dateEnd} = dateTime
    const chartOptions = merge(BaseOptionChart(), {
        listData: ratioProduct?.data,
        ...chartOptionsRatioProduct,
        language: {
          title: [t('success'), t('failed')],
          order: t('orders')
        },
        labels: [t('e_com_product_link'), t('e_com_product_link')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  label: t('e_com_product_link')
                }
              }
            }
          }
        }
    })
    const chartSeries=ratioProduct?.data?.map(map=> +map?.total)
    return(
        <StyleRatioProduct>
            <div className={'ratio-product-title'}>
                <div className={'ratio-product-title_date'}>
                    <Text fontSize={18} fontWeight={600} as={'p'} >{t('e_com_percent_product_link')}</Text>
                    <Text color={'#7C88A6'} >{dateStart} - {dateEnd}</Text>
                </div>
                <Button onClick={methods?.onOpen} appearance={'secondary'} icon={GLOBAL_ICON.connectLink} >{t('linked_quickly')}</Button>
            </div>
            <div className={'ratio-product-chart'}>
                <ReactApexChart
                    type="donut"
                    series={chartSeries}
                    options={chartOptions}
                    height={'100%'}
                    width={200}
                />
            </div>
            <div className={'ratio-product-chart_series'}>
                {
                    ratioProduct?.data?.map((map,i)=><ChartSeriesRatioProduct key={i} data={map} />)
                }
            </div>
        </StyleRatioProduct>
    )

}
const StyleRatioProduct = styled.div`
  width: calc(50% - 12px);
  height: 474px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  .ratio-product{
    &-title{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &-chart{
      width: 100%;
      height: 200px;
      .apexcharts-svg{
        transform: translate(260px, 50px);
        @media screen and (max-width: 1440px){
          transform: translate(189px,50px);
        }
        @media screen and (max-width: 1366px){
          transform: translate(175px,50px);
        }
          @media screen and (max-width: 1280px){
          transform: translate(155px,50px);
        }
        .apexcharts-graphical{
          transform: translate(13px, 15px);
          .apexcharts-datalabel-label{
            transform: translate(0, 28px);
            font-size: 14px;
            font-weight: 400;
            fill: #535E79 !important;
            line-height: 140%;
          }
          .apexcharts-datalabel-value{
            transform: translate(0, -25px);
            fill: #0F172A;
            font-size: 20px;
            font-weight: 600;
            line-height: 140%;
          }
        }
      }
    }
    
    &-chart_series{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 6rem;
    }
    
  }
`