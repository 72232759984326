export const EXPORT_EXCEL_COMPONENT={
   xlsx : (
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.0844 3.75569H26.1659V0L0 4.04363V39.4875L26.1659 44V38.4333H42.0844C42.5665 38.4578 43.0387 38.2901 43.3977 37.9669C43.7566 37.6438 43.9732 37.1915 44 36.7089V5.47856C43.9728 4.99626 43.7561 4.54433 43.3971 4.22152C43.0382 3.8987 42.5663 3.73123 42.0844 3.75569ZM42.3359 36.9952H26.1124L26.0857 34.0231H29.9939V30.5616H26.0559L26.037 28.5162H29.9939V25.0547H26.0071L25.9883 23.0093H29.9939V19.5479H25.9757V17.5024H29.9939V14.041H25.9757V11.9956H29.9939V8.5341H25.9757V5.38731H42.3359V36.9952Z"
            fill="#198A73"
          />
          <path
            d="M32.1992 8.51855H38.9925V11.9757H32.1992V8.51855Z"
            fill="#198A73"
          />
          <path
            d="M32.1992 14.0205H38.9925V17.4777H32.1992V14.0205Z"
            fill="#198A73"
          />
          <path
            d="M32.1992 19.522H38.9925V22.9791H32.1992V19.522Z"
            fill="#198A73"
          />
          <path
            d="M32.1992 25.0239H38.9925V28.4811H32.1992V25.0239Z"
            fill="#198A73"
          />
          <path
            d="M32.1992 30.5259H38.9925V33.983H32.1992V30.5259Z"
            fill="#198A73"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.83602 13.6001L10.2083 13.4069L12.3282 19.2353L14.833 13.1664L18.2053 12.9731L14.1102 21.2483L18.2053 29.5439L14.6397 29.3034L12.2323 22.98L9.8233 29.063L6.54688 28.7739L10.3529 21.4463L6.83602 13.6001Z"
            fill="white"
          />
        </svg>
      ),
      xExcel: (
          <svg
            width="10"
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 4.71373L9.05133 0.663649C9.10288 0.612022 9.16409 0.571053 9.23147 0.543082C9.29884 0.51511 9.37107 0.500683 9.44403 0.500625C9.51698 0.500567 9.58923 0.514879 9.65665 0.542744C9.72407 0.570608 9.78535 0.611479 9.83698 0.663024C9.8886 0.714569 9.92957 0.775778 9.95754 0.843156C9.98551 0.910534 9.99994 0.982762 10 1.05572C10.0001 1.12867 9.98575 1.20092 9.95788 1.26834C9.93002 1.33576 9.88914 1.39704 9.8376 1.44867L5.78627 5.5L9.83635 9.55133C9.88798 9.60288 9.92895 9.66409 9.95692 9.73147C9.98489 9.79884 9.99932 9.87107 9.99937 9.94403C9.99943 10.017 9.98512 10.0892 9.95726 10.1567C9.92939 10.2241 9.88852 10.2853 9.83698 10.337C9.78543 10.3886 9.72422 10.4296 9.65684 10.4575C9.58947 10.4855 9.51724 10.4999 9.44428 10.5C9.37133 10.5001 9.29908 10.4857 9.23166 10.4579C9.16424 10.43 9.10296 10.3891 9.05133 10.3376L5 6.28627L0.948665 10.3364C0.89712 10.388 0.835912 10.4289 0.768534 10.4569C0.701156 10.4849 0.628928 10.4993 0.555975 10.4994C0.483021 10.4994 0.41077 10.4851 0.343348 10.4573C0.275925 10.4294 0.214651 10.3885 0.163024 10.337C0.111397 10.2854 0.0704286 10.2242 0.0424569 10.1568C0.0144851 10.0895 5.82122e-05 10.0172 1.75805e-07 9.94428C-5.78606e-05 9.87133 0.0142538 9.79908 0.0421183 9.73166C0.0699828 9.66424 0.110854 9.60296 0.162399 9.55133L4.21373 5.5L0.163649 1.44867C0.0593839 1.34457 0.000742057 1.20331 0.000624845 1.05597C0.000507634 0.908638 0.0589244 0.767289 0.163024 0.663024C0.267124 0.558759 0.408379 0.500117 0.555715 0.5C0.703052 0.499883 0.8444 0.5583 0.948665 0.662399L5 4.71373Z"
              fill="#7C88A6"
            />
          </svg>
        ),
         export: (
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 9.5V10.3C13 11.4201 13 11.9802 12.782 12.408C12.5903 12.7843 12.2843 13.0903 11.908 13.282C11.4802 13.5 10.9201 13.5 9.8 13.5H4.2C3.07989 13.5 2.51984 13.5 2.09202 13.282C1.71569 13.0903 1.40973 12.7843 1.21799 12.408C1 11.9802 1 11.4201 1 10.3V9.5M10.3333 6.16667L7 9.5M7 9.5L3.66667 6.16667M7 9.5V1.5"
                stroke="#1E9A98"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
          dragIcon:(
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 13.875C10.3284 13.875 11 13.2034 11 12.375C11 11.5466 10.3284 10.875 9.5 10.875C8.67157 10.875 8 11.5466 8 12.375C8 13.2034 8.67157 13.875 9.5 13.875Z" fill="#9CA0AB"/>
            <path d="M9.5 7.875C10.3284 7.875 11 7.20343 11 6.375C11 5.54657 10.3284 4.875 9.5 4.875C8.67157 4.875 8 5.54657 8 6.375C8 7.20343 8.67157 7.875 9.5 7.875Z" fill="#9CA0AB"/>
            <path d="M9.5 19.875C10.3284 19.875 11 19.2034 11 18.375C11 17.5466 10.3284 16.875 9.5 16.875C8.67157 16.875 8 17.5466 8 18.375C8 19.2034 8.67157 19.875 9.5 19.875Z" fill="#9CA0AB"/>
            <path d="M14.8516 13.875C15.68 13.875 16.3516 13.2034 16.3516 12.375C16.3516 11.5466 15.68 10.875 14.8516 10.875C14.0231 10.875 13.3516 11.5466 13.3516 12.375C13.3516 13.2034 14.0231 13.875 14.8516 13.875Z" fill="#9CA0AB"/>
            <path d="M14.8516 7.875C15.68 7.875 16.3516 7.20343 16.3516 6.375C16.3516 5.54657 15.68 4.875 14.8516 4.875C14.0231 4.875 13.3516 5.54657 13.3516 6.375C13.3516 7.20343 14.0231 7.875 14.8516 7.875Z" fill="#9CA0AB"/>
            <path d="M14.8516 19.875C15.68 19.875 16.3516 19.2034 16.3516 18.375C16.3516 17.5466 15.68 16.875 14.8516 16.875C14.0231 16.875 13.3516 17.5466 13.3516 18.375C13.3516 19.2034 14.0231 19.875 14.8516 19.875Z" fill="#9CA0AB"/>
            </svg>
          ),
           searchMd: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.505 18.495C17.7784 18.7683 18.2216 18.7683 18.495 18.495C18.7683 18.2216 18.7683 17.7784 18.495 17.505L17.505 18.495ZM14.6283 13.6384C14.3549 13.365 13.9117 13.365 13.6384 13.6384C13.365 13.9117 13.365 14.3549 13.6384 14.6283L14.6283 13.6384ZM18.495 17.505L14.6283 13.6384L13.6384 14.6283L17.505 18.495L18.495 17.505ZM15.5222 9.11111C15.5222 12.6519 12.6519 15.5222 9.11111 15.5222V16.9222C13.4251 16.9222 16.9222 13.4251 16.9222 9.11111H15.5222ZM9.11111 15.5222C5.57035 15.5222 2.7 12.6519 2.7 9.11111H1.3C1.3 13.4251 4.79715 16.9222 9.11111 16.9222V15.5222ZM2.7 9.11111C2.7 5.57035 5.57035 2.7 9.11111 2.7V1.3C4.79715 1.3 1.3 4.79715 1.3 9.11111H2.7ZM9.11111 2.7C12.6519 2.7 15.5222 5.57035 15.5222 9.11111H16.9222C16.9222 4.79715 13.4251 1.3 9.11111 1.3V2.7Z"
                  fill="#1E9A98"
                />
              </svg>
            ),
}