import React from 'react';
import {Box, Modal} from "@material-ui/core";
import styled from "styled-components";
import SelectStore from "./_selectStore";
import Loading from "./_loading";
import {GLOBAL_ICON} from "../../../../../../../../../interfaceGlobal";
import {Text} from "../../../../../../../../../common/text";
import {NotificationBar} from "../../../../../../../../../common/notificationBar";
import {Checkbox} from "../../../../../../../../../common/form/checkbox";
import {Button} from "../../../../../../../../../common/button";
import useQuickConnect from "../../../hook/useQuickConnect";
import {useTranslation} from "react-i18next";

const Index = () => {
  const { t } = useTranslation()
  const { store, methods } = useQuickConnect()
  const listStatus = [
    {
      id: 1,
      code: 'notConnect',
      icon: GLOBAL_ICON.codepend,
      title: t('e_com_product_not_connect'),
      subtitle: t('e_com_product_upos_with_e_com'),
      checked: store?.status?.notConnect
    },
    {
      id: 2,
      code: 'failConnect',
      icon: GLOBAL_ICON.codepend,
      title: t('e_com_product_connect_failed'),
      subtitle: t('e_com_product_failed_upos_with_e_com'),
      checked: store?.status?.failConnect
    }
  ]

  const onClose = _ => methods.onCloseQuickConnect()

  return (
    <Modal open={true}>
      <Box>
        <StyledQuickConnect>
          <Text as={'p'} fontWeight={600} fontSize={20}>
            {t('e_com_link_product_upos_with_e_com')}
          </Text>
          {store?.loading
            ? <Loading />
            : (
              <div className={'ecom-product-tbody'}>
                <NotificationBar
                  type="warning"
                  style={{ marginBottom: 8 }}
                  className={'ecom-product-notify'}
                >
                  <Text fontSize={14} >
                    {t('e_com_link_product_upos_with_e_com_desc')}
                  </Text>
                </NotificationBar>
                <div className={'ecom-product-select'}>
                  <Text fontWeight={600} fontSize={14}>
                    {t('e_com_stall_connect_upos')}
                  </Text>
                  <SelectStore />
                </div>
                <ItemStore
                  data={listStatus}
                  onSelect={value => methods.onClickTypeConnect(value)}
                />
                <div
                  className={'ecom-product-grp-checkbox'}
                  onClick={() => methods.onToggleAutoProduct()}
                >
                  <Checkbox
                    className={'ecom-product-checkbox-note'}
                    checked={store?.autoProduct}
                  />
                  <Text>{t('e_com_stall_connect_upos_desc')}</Text>
                </div>
                <div className={'ecom-product-group-btn'}>
                  <Button
                    appearance={'ghost'}
                    size={'sm'}
                    onClick={onClose}
                  >
                    {t('general_close')}
                  </Button>
                  <Button
                    size={'sm'}
                    onClick={() => methods?.onSubmit()}
                  >
                    {t('general_confirm')}
                  </Button>
                </div>
              </div>
          )}
        </StyledQuickConnect>
      </Box>
    </Modal>
  )
}

export default Index


const ItemStore = ({data, onSelect, ...props}) => {
  const { t } = useTranslation()
  return (
    <>
      {data?.map(item => (
        <StyledItemStore {...props}
                         key={item.id}
                         onClick={() => onSelect(item)}
                         data-active={data?.checked || false}
        >
          <div data-color={item?.code} className={'ecom-product-quick-connect--title'}>
            {item?.icon}
          </div>
          <div className={'ecom-product-quick-connect--content'}>
            <Text as={'p'} fontWeight={600} fontSize={14}>{t(item?.title) || '---'}</Text>
            <Text fontSize={14}>{t(item?.subtitle) || '---'}</Text>
          </div>
          <Checkbox
            checked={item?.checked || false}
            style={{marginTop: 8}}
          />
        </StyledItemStore>
      ))}
    </>
  )
}

const StyledQuickConnect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  background: #FFFFFF;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  position: absolute;
  width: 612px;
  min-height: 562px;
  left: calc(50% - 612px/2);
  top: calc(50% - 498px/2);
  
  .ecom-product {
    &-tbody {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
    &-notify {
      padding: 6px 12px;
    }
    &-select {
      width: 100%;
      & .alternative-auto-complete__menu {
        height: 230px;
      }
      & .alternative-auto-complete__input {
        margin-top: 8px;
      }
      & .auto-complete__option {
        margin: 18px 0;
        & svg {
          margin-right: 12px;
        }
      }
      &--empty {
        margin-top: 26px;
        text-align: center;
        &-text {
          width: 100% !important;
        }
      }
    } 
    &-grp-checkbox {
      display: flex;
      cursor: pointer;
    }
    &-checkbox-note {
      margin: 10px 12px 0 0;
    }
    &-group-btn {
      width: 100%;
      text-align: end;
      margin-top: 16px;
      button {
        min-width: 110px;
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
`


const StyledItemStore = styled.div`
  display: flex;
  width: 100%;
  background: linear-gradient(0deg, rgba(244, 247, 252, 0.45), rgba(244, 247, 252, 0.45)), #FFFFFF;
  border-radius: 6px;
  padding: 16px;
  gap: 12px;
  cursor: pointer;
  &[data-active=true] {
    border: 1px solid #1e9a98;
  }
  
  .ecom-product-quick-connect {
    &--title[data-color="notConnect"] {
      width: 40px;
      height: 40px;
      text-align: center;
      
      background: #2755F0;
      border-radius: 100px;
      & svg {
        margin-top: 6px;
      }
    }
    &--title[data-color="failConnect"] {
      width: 40px;
      height: 40px;
      text-align: center;
      
      background: #FF424E;
      border-radius: 100px;
      & svg {
        margin-top: 6px;
      }
    }
    &--content {
      flex: 1;
    }
  }
`
