import { DISPLAY_NAME_MENU } from 'const/display_name_menu'
import { PATH } from 'const/path'
import { ROLE } from 'const/role'
import { SIDEBAR_MENU_ICONS } from './_icons'

export const SIDEBAR_BOTTOM_ITEM = {
  id: 1,
  label: 'supporting_tool',
  path: PATH.ADD_ON_TOOLS,
  role: ROLE.ADMINTRATOR,
  displayIcon: SIDEBAR_MENU_ICONS.assistance,
  // extraList: [
  //   {
  //     id: 1,
  //     displayIcon: SIDEBAR_MENU_ICONS.shippingTracking,
  //     label: 'Hành trình vận đơn',
  //     path: PATH.TOOL_SHIPPING_TRACKING,
  //   },
  //   {
  //     id: 2,
  //     displayIcon: SIDEBAR_MENU_ICONS.separateAddress,
  //     label: 'Tool tách địa chỉ',
  //     path: PATH.TOOL_ADDRESS_SEPARATION,
  //   },
  //   {
  //     id: 3,
  //     displayIcon: SIDEBAR_MENU_ICONS.multipleOrder,
  //     label: 'Lên đơn hàng loạt',
  //     path: PATH.TOOL_BULK_ORDER,
  //   },
  // ],
  list: [],
}

export const SIDEBAR_MENU_ITEMS = [
  {
    title: '',
    list: [{
      id: 1,
      label: DISPLAY_NAME_MENU.OVERVIEW,
      path: PATH.ADMIN_DASHBOAR,
      role: ROLE.ADMINTRATOR,
      displayIcon: SIDEBAR_MENU_ICONS.overview,
      list: [],
    }]
  }
  ,
  {
    title: 'order_and_product',
    list: [
      {
        id: 2,
        label: DISPLAY_NAME_MENU.PRODUCT,
        path: PATH.PRODUCT_MANAGEMENT,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.product,
        list: [],
      },
      {
        id: 3,
        label: DISPLAY_NAME_MENU.BILL,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.bill,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.CREATE_ORDER,
            path: '/orders/create',
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.ORDER_MANAGER,
            path: PATH.ORDER,
          },
          {
            id: 3,
            label: 'return_order_page',
            path: PATH.GIVE_BACK_PRODUCT,
          },
        ],
      },
      {
        id: 5,
        label: DISPLAY_NAME_MENU.TRANPORT,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.transport,
        list: [
          // {
          //   id: 1,
          //   label: DISPLAY_NAME_MENU.TRANPORT_OVERVIEW,
          //   path: PATH.DELIVERY_OVERVIEW_DASHBOARD,
          // },
          {
            id: 1,
            label: 'delivery_manager',
            path: PATH.DELIVERY_MANAGEMENT,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.AUTO_BILL,
            path: PATH.TOOL_BULK_ORDER,
          },
          {
            id: 3,
            label: DISPLAY_NAME_MENU.TRANPORT_OVERVIEW,
            path: PATH.DELIVERY_OVERVIEW_DASHBOARD,
          },
          {
            id: 4,
            label: DISPLAY_NAME_MENU.SIGN_1_PART,
            path: PATH.PART_SIGN,
          },

          {
            id: 5,
            label: DISPLAY_NAME_MENU.SHIPPING_TRACKING,
            path: PATH.SHIPPING_TRACKING,
          },
        ],
      },
      {
        id: 7,
        label: DISPLAY_NAME_MENU.WAREHOUSE,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.warehouse,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.LIST_WAREHOUSE,
            path: PATH.WAREHOUSE_MANAGEMENT,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.INVENTORY_INFO,
            path: PATH.WAREHOUSE_PRODUCT,
          },
          {
            id: 3,
            label: DISPLAY_NAME_MENU.IMPORT_GOODS,
            path: PATH.PURCHASE,
          },
          {
            id: 4,
            label: DISPLAY_NAME_MENU.WAREHOUSE_TRANSFER,
            path: PATH.WAREHOUSE_TRANSFER,
          },
          {
            id: 5,
            label: DISPLAY_NAME_MENU.WAREHOUSE_CHECK,
            path: PATH.INVENTORY_MANAGEMENT,
          },
        ],
      },
      {
        id: 9,
        label: DISPLAY_NAME_MENU.PARTNER_MANAGER,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.partnerManager,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.CUSTOMER,
            path: PATH.CUSTOMER,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.BOMB_SHIPMENT,
            path: PATH.BOMB_SHIPMENT,
          },
          {
            id: 3,
            label: DISPLAY_NAME_MENU.SUPPLIER,
            path: PATH.SUPPLIER,
          },
          {
            id: 4,
            label: DISPLAY_NAME_MENU.SHIPPING_COMPANY,
            path: PATH.SHIPPING_PARTNER,
          },
        ],
      }]
  },
  {
    title: 'sales_chanel',
    list: [
      {
        id: 4,
        label: DISPLAY_NAME_MENU.FACEBOOK,
        path: PATH.FACEBOOK_CONNECT,
        displayIcon: SIDEBAR_MENU_ICONS.fb,
        activeList: [
          '/facebook/conversation-stickers',
          '/facebook/response-content-scripts',
          '/facebook/hide-auto-comments',
          '/facebook/auto-responses',
          '/facebook/auto-messages',
          '/facebook/livestream-scripts',
          '/facebook/printer-settings',
        ],
      },
      {
        id: 41,
        label: DISPLAY_NAME_MENU.MARKET,
        isNew: true,
        path: null,
        displayIcon: SIDEBAR_MENU_ICONS.ecommerce,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.CONFIG_MANAGEMENT,
            path: PATH.ECOMMERCES,
          },
          {
            id: 3,
            label: DISPLAY_NAME_MENU.POST_PRODUCT,
            path: PATH.ECOMMERCE_LIST_SALE,
          },
          {
            id: 4,
            label: DISPLAY_NAME_MENU.PRODUCT_MANAGEMENT,
            path: PATH.ECOMMERCE_PRODUCT,
          },
          {
            id: 5,
            label: DISPLAY_NAME_MENU.PRODUCT_MANAGE_UPOS,
            path: PATH.ECOMMERCE_UPOS_PRODUCT,
          },
          {
            id: 6,
            label: DISPLAY_NAME_MENU.ORDER_MANAGER,
            path: PATH.ECOMMERCE_ORDER,
          },
          {
            id: 7,
            label: DISPLAY_NAME_MENU.REPORT,
            path: PATH.ECOMMERCE_REPORT,
          },
        ]
      },
      {
        id: 42,
        label: DISPLAY_NAME_MENU.SALE_AT_SHOP,
        path: null,
        displayIcon: SIDEBAR_MENU_ICONS.pos,
        list:[
          {
            id: 1,
            label: DISPLAY_NAME_MENU.CREATE_AT_SHOP,
            path: PATH.POS,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.POS_MANAGEMENT,
            path: PATH.POS_MANAGEMENT,
          },
          // {
          //   id: 3,
          //   label: DISPLAY_NAME_MENU.POS_CONFIG,
          //   path: PATH.POS_CONFIG,
          // },
        ]
      },
      {
        id: 43,
        label: DISPLAY_NAME_MENU.E_ORDER,
        path: null,
        displayIcon: SIDEBAR_MENU_ICONS.e_order,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.E_ORDER_CONNECT,
            path: PATH.E_ORDER_CONNECT,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.E_ORDER_LIST,
            path: PATH.E_ORDERS,
          },
        ]
      },
    ]
  },
  {
    title: 'report',
    list: [
      {
        id: 8,
        label: DISPLAY_NAME_MENU.ACCOUNTANT,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.accountant,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.RECEIPTS_VOUCHER,
            path: PATH.ACCOUNTANT_RECEIPTS,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.PAYMENT_VOUCHER,
            path: PATH.ACCOUNTANT_PAYMENT,
          },
          {
            id: 3,
            label: DISPLAY_NAME_MENU.CASH_BOOK,
            path: PATH.CASHBOOKS,
          },
          {
            id: 4,
            label: DISPLAY_NAME_MENU.COST_PRICE_UPDATE,
            path: PATH.PRICE_ADJUSTMENT
          },
        ],
      },
      // {
      //   id: 6,
      //   label: DISPLAY_NAME_MENU.COD_FOR_CONTROL,
      //   path: null,
      //   role: ROLE.ADMINTRATOR,
      //   displayIcon: SIDEBAR_MENU_ICONS.codManager,
      //   list: [
      //     {
      //       id: 1,
      //       label: DISPLAY_NAME_MENU.COD_MANAGEMENT,
      //       path: PATH.DELIVERY_COD_MANAGEMENT,
      //     },
      //     // {
      //     //   id: 2,
      //     //   label: DISPLAY_NAME_MENU.FOR_CONTROL_COD,
      //     //   path: PATH.FOR_CONTROL_COD,
      //     // },
      //   ],
      // },
      {
        id: 6,
        label: DISPLAY_NAME_MENU.COD_MANAGEMENT,
        path: PATH.DELIVERY_COD_MANAGEMENT,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.codManager,
        list: [],
      },
      {
        id: 10,
        label: DISPLAY_NAME_MENU.REPORT,
        path: null,
        role: ROLE.ADMINTRATOR,
        displayIcon: SIDEBAR_MENU_ICONS.report,
        list: [
          {
            id: 1,
            label: DISPLAY_NAME_MENU.WAREHOUSE_REPORT,
            path: PATH.REPORT_WAREHOUSE,
          },
          {
            id: 2,
            label: DISPLAY_NAME_MENU.SALES_REPORT,
            path: PATH.REPORT_SALES,
          },
        ],
      },
    ]
  }
]

