import React from 'react';
import styled from "styled-components";
import {THEME_SEMANTICS} from "../../../../../../../../common/theme/_semantics";
import {Text} from "../../../../../../../../common/text";
import {OrderTag} from "../../../../components/tag/_orderTag";
import {useReportProductFilter} from "../../hook/useReportProductFilter";
import {useTranslation} from "react-i18next";

const OverViewTags = ({...props}) => {
  const { t } = useTranslation()
    const {stores, status} = useReportProductFilter()
    return (
        <StyledOverViewTags {...props}>
            {stores?.applyFilter?.length > 0 && (
                <div className={'report-over-view-filter-tag'}>
                    <OrderTag onDelete={()=>stores.onDeleteFilter('stores')} >
                      {t('booth')}: {stores?.applyFilter?.map(item => item.shop_short_name || item?.shop_name).join(', ')}
                    </OrderTag>

                </div>
            )}
            {!!status?.applyFilter && (
                <div className={'report-over-view-filter-tag'}>
                    <OrderTag onDelete={()=>status.onDeleteFilter('status')} >
                      {t('e_com_link_status')}: {t(status?.applyFilter?.title)}
                    </OrderTag>

                </div>
            )}
            {
                !!status?.applyFilter || stores?.applyFilter?.length > 0 ?<div className={'report-over-view-filter-tag'}>
                    <Text
                        as="b"
                        color={THEME_SEMANTICS.delivering}
                        lineHeight={28}
                        style={{marginBottom: 12, cursor: 'pointer'}}
                        onClick={stores.onDeleteStore}
                    >
                      {t('general_reset_to_default')}
                    </Text>
                </div>: ''
            }

        </StyledOverViewTags>
    )
}

export default OverViewTags

export const StyledOverViewTags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .report-over-view-filter-tag {
    margin-top: 16px;
  }
`