import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../common/text";
import {useTranslation} from "react-i18next";
export const EmptyList = ({type='table',height= 300,border=true})=>{
  const { t } = useTranslation()
    const image = type === 'chart' ? '/img/eCommerce/emptychart.png' : '/img/eCommerce/emptystate.png'
    const title = type === 'chart' ? t('e_com_not_result_product') : t('e_com_not_product')
    return(
        <StyleEmpty>
            <div
                style={{
                    alignItems: 'center',
                    display:'flex',
                    justifyContent:'center',
                    height: height,
                    background:'#ffffff',
                    border: border && '1px solid #F0F2F5',
                    borderRadius: !border && 8
                }}
                className={'report-ecommerce-empty'}
            >
                <div style={{textAlign:'center'}}>
                    <img src={image} />
                    <br/>
                    <Text color={'#7C88A6'}>{title}</Text>
                </div>
            </div>
        </StyleEmpty>
    )
}

const StyleEmpty = styled.div`

`