import {Button} from 'common/button'
import {Text} from 'common/text'
import useOrderFilterForm from 'Pages/refactorOrder/hooks/useOrderFilterForm'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import {StyledOrderEmpty} from './_styled'
import { useTranslation } from 'react-i18next'

export const OrderEmpty = ({...props}) => {
  const {badge, search} = useOrderFilterForm()
  const {t} = useTranslation()
  const shouldShowCreateBtn =
    badge.others <= 1 && !badge.advanced && !!!search.value

  return (
    <div style={{position: 'absolute'}}>
      <StyledOrderEmpty {...props}>
        <img
          className="order-empty__banner"
          src="/img/order/order-empty.png"
          alt="empty"
        />
        <Text as="b" color="#7C88A6" style={{marginBottom: 16}}>
          {shouldShowCreateBtn
            ? t('you_havent_order')
            : t('no_matching_data')}
        </Text>
        {shouldShowCreateBtn && (
          <Button href={'/orders/create'} icon={ORDER_ICONS.plus}>{t('create_new_order')}</Button>
        )}
      </StyledOrderEmpty>
    </div>
  )
}
