import { Button } from 'common/button'
import { Text } from 'common/text'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import useOrderRow from 'Pages/refactorOrder/hooks/useOrderRow'
import { ORDER_ICONS } from 'Pages/refactorOrder/interfaces/_icons'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SubmitPaymentModal } from './_submitPaymentModal'
import { formatMoney } from '../../../../util/functionUtil'
import { useTranslation } from 'react-i18next'
import {checkPermission} from "../../../../Component/NavBar/until";
import PopupNotPermission from "../../../permision/popupNotPermission";

export const RowTabPayment = ({ data, rowData, ...props }) => {
  const { row } = rowData
  const {t} = useTranslation()
  const paid = Number(data?.total_payment || 0)
  const total = Number(data?.total_amount || 0)
  const pay = Number(data?.customer_pay || 0)
  const haveToPay = +paid === 0 ? total : paid > total ? 0 : total - paid
  const customerPay = pay == 0 ? 0 : pay - total

  const shouldShowSubmitPayment = data?.show_btn_payment

  const validPaymentPermission = checkPermission('order', 'payment')
  const [showPermission, setShowPermission] = useState(false)

  const handleOpenPayment = _ => validPaymentPermission ? row.onOpenSubmitPaymentModal() :  setShowPermission(true)

  return (
    <StyledRowTabPayment {...props}>
      {
        data && (
          <>
            {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 86 }}>
                <Text AS="B" fontSize={16} lineHeight={22} style={{ marginRight: 8 }}>
                  {t('paid_short')}:
                </Text>
                <Text
                  as="b"
                  color={THEME_SEMANTICS.delivered}
                  fontSize={16}
                  lineHeight={22}
                >
                  {formatMoney(paid)}
                </Text>
              </div>
              {+data?.shipping_status_id === 8 && (
                <div style={{ marginRight: 86 }}>
                  <Text as="b" fontSize={16} lineHeight={22} style={{ marginRight: 8 }}>
                    {t('change_returned_to_customer')}:
                  </Text>
                  <Text
                    as="b"
                    color={THEME_SEMANTICS.failed}
                    fontSize={16}
                    lineHeight={22}
                  >
                    {formatMoney(customerPay)}
                  </Text>
                </div>)
              }
              <div style={{ marginRight: 86 }}>
                <Text as="b" fontSize={16} lineHeight={22} style={{ marginRight: 8 }}>
                  {t('general_still_pay')}:
                </Text>
                <Text
                  as="b"
                  color={THEME_SEMANTICS.failed}
                  fontSize={16}
                  lineHeight={22}
                >
                  {formatMoney(haveToPay)}
                </Text>
              </div>
            </div>
            {Array.isArray(data?.order_payments) && data.order_payments.length > 0 && (
              <div style={{ marginTop: 24 }}>
                {data.order_payments.map(item => {
                    if (item.total_amount > 0)
                      return <Collapser key={item.id} data={item}/>
                  }
                )}
              </div>
            )}
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              {shouldShowSubmitPayment && (
                <>
                  <Button size="sm" onClick={handleOpenPayment}>
                    {t('general_payment_confirm')}
                  </Button>
                  {row.shouldOpenSubmitPaymentModal && (
                    <SubmitPaymentModal
                      data={data}
                      onClose={row.onCloseSubmitPaymentModal}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )
      }
    </StyledRowTabPayment>
  )
}

const Collapser = ({ data }) => {
  const { detail } = useOrderRow(data)
  const [shouldCollapse, setShouldCollapse] = useState(false)
  const {t} = useTranslation()
  return (
    <div className="row-tab-payment__list-item">
      <div
        className="row-tab-payment__list-item-toggle"
        data-active={shouldCollapse}
        onClick={() => setShouldCollapse(!shouldCollapse)}
      >
        <Text as="b">
          {data.status === '1' &&
          `${t('general_payment_confirm')} ${formatMoney(data?.total_amount)} ${t('success')}`}
          {data.status === '2' &&
          `${t('COD_waiting_for_collection')} ${formatMoney(data?.total_amount)}`}
        </Text>
        {data.status === '1' && ORDER_ICONS.chevronLeft}
      </div>
      {data.status === '1' && (
        <div
          className="row-tab-payment__list-item-content"
          data-active={shouldCollapse}
        >
          <div className="row-tab-payment__list-item-figure">
            <Text color="#7C88A6" style={{ display: 'block' }}>
              {t('general_payment_amount')}:
            </Text>
            <Text style={{ display: 'block' }}>
              {formatMoney(data?.total_amount)}
            </Text>
          </div>
          <div className="row-tab-payment__list-item-figure">
            <Text color="#7C88A6" style={{ display: 'block' }}>
              {t('general_payment')}:
            </Text>
            <Text style={{ display: 'block' }}>
              {data?.payment_method_name || '---'}
            </Text>
          </div>
          <div className="row-tab-payment__list-item-figure">
            <Text color="#7C88A6" style={{ display: 'block' }}>
              {t('general_confirm_staff')}:
            </Text>
            <Text style={{ display: 'block' }}>{data?.fullname || '---'}</Text>
          </div>
          <div className="row-tab-payment__list-item-figure">
            <Text color="#7C88A6" style={{ display: 'block' }}>
              {t('general_payment_time')}:
            </Text>
            <Text style={{ display: 'block' }}>
              {data?.dt_created
                ? detail.tabs.payment.formatDateTime(data.dt_created)
                : '---'}
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}

const StyledRowTabPayment = styled.div`
  position: relative;

  .row-tab-payment {
    &__list-item {
      margin-bottom: 12px;
      padding: 12px 24px;

      border-left: 3px solid ${THEME_SEMANTICS.delivered};

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list-item-toggle {
      display: flex;
      align-items: center;

      cursor: pointer;

      &[data-active='true'] {
        svg {
          transform: rotate(0deg);
        }
      }

      svg {
        margin-left: 8px;

        transform: rotate(180deg);
        transition: transform 0.6s;
      }
    }

    &__list-item-content {
      max-height: 0;

      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      transition: max-height 0.5s;

      &[data-active='true'] {
        max-height: 75vh;
      }
    }

    &__list-item-figure {
      width: 250px;
      margin-top: 8px;
    }
  }
`
