import {FIXED_ACTION_BTN_GROUP_ICONS} from './_icons'

export const FIXED_ACTION_BTN_GROUP = [
  {
    id: 1,
    name: 'guide',
    icon: FIXED_ACTION_BTN_GROUP_ICONS.book,
    action: () => console.log('Hướng dẫn'),
    path : 'https://upos-mkt.gitbook.io/huong-dan-su-dung-upos-2.0/'
  },
  {
    id: 2,
    name: 'feedback_page_home',
    icon: FIXED_ACTION_BTN_GROUP_ICONS.chat,
    action: 'feedback',
  },
]
