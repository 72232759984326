import React, {useState} from "react";
import {StyledStoreConfigDetail} from "./styles";
import {Text} from "../../../../../../common/text";
import {Switch} from "../../../../../../common/switch";
import {THEME_COLORS} from "../../../../../../common/theme/_colors";
import {useUpdateStoreConfig} from "../../../../hook/useUpdateStoreConfig";
import {checkPermission} from "../../../../../NavBar/until";
import PopupNotPermission from "../../../../../../Pages/permision/popupNotPermission";
import { useTranslation } from 'react-i18next'

const Index = ({...props})=>{
    const {t} = useTranslation()
    const {
        warningPhone,
        bulkOrder,
        quantityLowRate,
        functions,
        hiddenShipFee,
        user
    }=useUpdateStoreConfig()
    const {activeValue} = bulkOrder
    const validEditPermission = checkPermission('store_setting', 'edit')
    const [showPermission, setShowPermission] = useState(false)
    const handleEdit = _ => {
      if(validEditPermission) functions.changeEdit()
      else setShowPermission(true)
    }
    return(
        <StyledStoreConfigDetail>
            {showPermission && <PopupNotPermission handleClose={() => setShowPermission(false)}/>}
            <div className={'store-config'}>
                <div style={{display:'flex'}}>
                    <div className={'store-config-order-inventory'}>
                        <Text fontSize={16} fontWeight={600}>{t('ware_house')}</Text>
                        <div className={'store-config-inventory'}>
                            <Text  style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('store_config_warning_quantity')}</Text>
                            <Text color={'#151624'} fontSize={15}>{quantityLowRate?.value}</Text>
                        </div>
                    </div>
                    <Text style={{width:'67px',cursor:'pointer'}} fontSize={15} onClick={functions.changeEdit} color={THEME_COLORS.blue_500}>{t('update')}</Text>
                </div>
                <div className={'store-config-order-inventory'}>
                    <Text fontSize={16} fontWeight={600}>{t('auto_bill')}</Text>
                    <div className={'store-config-inventory'}>
                        <Text  style={{marginRight:'16px',width:'144px'}} color={'#7A92A5'}>{t('setting_print')}</Text>
                        <Text color={'#151624'} fontSize={15}>{t(activeValue?.name)}</Text>
                    </div>
                </div>
                <div className={'store-config-btn-update'}>
                    <div className={'store-config-title'} >
                        <Text fontSize={16} fontWeight={600}>{t('store_config_sell')}</Text>
                        <div className={'store-config-switch-alert'}>
                            <Switch checked={warningPhone} disabled={true}/>
                            <Text style={{marginLeft:'8px'}} fontSize={15}>{t('store_config_warning_phone_create')}</Text>
                        </div>
                    </div>
                </div>
                {
                    +user?.is_manage === 1 &&  <div className={'store-config-btn-update'}>
                        <div className={'store-config-title'} >
                            <Text fontSize={16} fontWeight={600}>{t('store_config_hidden_fee_delivery')}</Text>
                            <br/>
                            <Text>{t('store_config_hidden_fee_delivery_apply')}</Text>
                            <div className={'store-config-switch-alert'}>
                                <Switch checked={+hiddenShipFee === 1 ? true : false} disabled={true}/>
                                <Text style={{marginLeft:'8px'}} fontSize={15}>{t('hidden_fee_delivery')}</Text>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </StyledStoreConfigDetail>
    )
}
export default Index;