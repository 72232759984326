import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {Tooltip} from "../../../../../../../../common/tooltip";
import {formatMoney} from "../../../../../../../../util/functionUtil";
import {useReportOverviewFilter} from "../../hook/useReportOverviewFilter";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {Tooltip as TooltipV2} from "../../../../../../../../common/tooltipv2";
import {formatDatetime, getDateFromNow} from "../../../../utils/formatDate";
import {useTranslation} from "react-i18next";
export const ImportantNumberTag = ({...props})=>{
  const { t } = useTranslation()
    const {importantNumber, dateTime} = useReportOverviewFilter()
    const {
        color,
        title,
        tooltip,
        value,
        isPrice,
        percent,
        compareTitle,
        type,
        data
    } = props
    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const transformDate = ()=>{
        const activeDate = dateTime?.activeValue?.value
        let dateStart=''
        let dateEnd =''
        switch (activeDate) {
            case'seven days':
                dateStart = formatDatetime(getDateFromNow(-13))
                dateEnd= formatDatetime(getDateFromNow(-7, 'end'))
                break;
            default:
                dateStart = formatDatetime(getDateFromNow(-58))
                dateEnd= formatDatetime(getDateFromNow(-30, 'end'))
                break;
        }
        return `${dateStart} ~ ${dateEnd}`

    }

    return(
        <StyledImportantNumberTag {...props}>
            <div
                className={'report-important-number'}
                data-active={!!importantNumber?.activeList?.find(find => find?.tab === type)}
                onClick={()=>  importantNumber?.onActiveList(data)}
            >
                <div className={'report-important-number_title'}>
                    <TooltipV2 baseOn={'height'} title={title} className={'tooltip_v2'} >
                        <Text style={{marginRight: 4}} fontSize={15} fontWeight={600}>{t(title)}</Text>
                    </TooltipV2>
                    <Tooltip title={tooltip} style={{paddingTop: 5}}>
                        {GLOBAL_ICON?.question}
                    </Tooltip>
                </div>
                <div className={'report-important-number_value'}>
                    {
                        isPrice ? <Text fontWeight={600} fontSize={20} color={color} >{formatMoney(value)}</Text>
                            : <Text fontWeight={600} fontSize={20} color={color} >{addCommas(Math.round(value * 100) / 100)} <Text fontSize={12} fontWeight={600} color={'#64748B'} >{t(data?.typeValue)}</Text></Text>
                    }
                </div>
                <div className={'report-important-number_percent'}>
                    <Text fontSize={13}>{compareTitle}</Text>
                    <div className={'report-important-number_percent-number'}>
                        <Text>
                            {
                                +percent >= 0 ? GLOBAL_ICON?.percentUp : GLOBAL_ICON?.percentDown
                            }
                        </Text>
                        <Text color={+percent >= 0? '#00AB56' : '#FF424E'}>
                            {percent}%
                        </Text>
                    </div>
                </div>
                <Text fontSize={12}>{transformDate()}</Text>
            </div>
        </StyledImportantNumberTag>
    )

}

const StyledImportantNumberTag = styled.div`
    .report-important-number{
        border-radius: 4px;
        background: #F7F9FD;
        padding: 16px;
        height: 145px;
        cursor: pointer;
        border-top: 4px solid #F7F9FD;
        &[data-active='true']{
          border-top: 4px solid ${props => props.color};
        }
        &_title{
          display: flex;
          align-items: center;
        }
        &_value{
          margin: 8px 0 16px 0;
        }
        &_percent{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
        &_percent-number{
          display: flex;
          align-items: center;
          span{
            &:nth-child(2){
              margin-left: 4px;
            }
          }
        }
    }
    .tooltip_v2{
        display: -webkit-box;
        height: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`