import {Text} from 'common/text'
import {Fragment} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {StyledGridLayout} from './_styled'
import {CustomToolTip} from "../../Component/tooltip/CustomTooltip";

export const GridLayout = ({
  header,
  headerProps,
  grid,
  gridProps,
  ...props
}) => {
  return (
    <StyledGridLayout {...props}>
      {!!header && (
        <div
          {...headerProps}
          className={`grid-layout__header ${headerProps?.className || ''}`}
        >
          {header}
        </div>
      )}
      {Array.isArray(grid) && grid.length > 0 && (
        <div
          {...gridProps}
          className={`grid-layout__container ${gridProps?.className || ''}`}
        >
          {grid.map((column, i) => (
            <div
              {...column?.props}
              key={i}
              className={`grid-layout__column ${column?.className || ''}`}
              style={{
                width: `${column?.width || 100}%`,
                ...column?.props?.style,
              }}
            >
              {Array.isArray(column?.sections) &&
                column.sections.map((section, j) =>
                  section ? (
                    <Section key={j} data={section} {...section?.props} />
                  ) : (
                    <></>
                  ),
                )}
            </div>
          ))}
        </div>
      )}
    </StyledGridLayout>
  )
}

const Section = ({data, ...props}) => {
  const [shouldTransparent, setShouldTransparent] = useState(false)

  const handleWrapperScroll = () => {
    const wrapper = document.querySelector('#content-wrap')
    if (!wrapper) return

    const clientHeight = wrapper.clientHeight
    const scrollHeight = wrapper.scrollHeight
    const scrollTop = wrapper.scrollTop

    setShouldTransparent(clientHeight + scrollTop > scrollHeight - 50)
  }

  useEffect(() => {
    if (data?.type === 'sticky-bottom-transparent') {
      const wrapper = document.querySelector('#content-wrap')
      if (!wrapper) return

      wrapper.addEventListener('scroll', handleWrapperScroll)

      return () => wrapper.removeEventListener('scroll', handleWrapperScroll)
    }
  }, [data?.type])

  return (
    <div
      {...props}
      className={`grid-layout__section ${props?.className || ''}`}
      style={{
        width: `calc(${data?.width || 100}% - 24px)`,
        padding: '24px 24px 0 24px',
        background: shouldTransparent ? 'transparent' : '#fff',
        ...props?.style,
      }}
    >
      {[!!data?.title, !!data?.description].includes(true) && (
        <div className="grid-layout__section-header">
          {!!data?.title && (
            <Text as="h3" fontSize={18} lineHeight={25} style={{width: '100%', display:'flex', alignItems:'center', gap: 4}}>
              {data.title}
              {
                !!data?.tooltipSubTitle && <CustomToolTip title={data?.tooltipSubTitle}>
                  <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M8 14.5C11.866 14.5 15 11.366 15 7.5C15 3.63401 11.866 0.5 8 0.5C4.13401 0.5 1 3.63401 1 7.5C1 11.366 4.13401 14.5 8 14.5Z"
                        stroke="#808089"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8 12.167C8.48325 12.167 8.875 11.7752 8.875 11.292C8.875 10.8087 8.48325 10.417 8 10.417C7.51675 10.417 7.125 10.8087 7.125 11.292C7.125 11.7752 7.51675 12.167 8 12.167Z"
                        fill="#808089"
                    />
                    <path
                        d="M8.00016 8.66667V8.08333C8.40397 8.08333 8.7987 7.96359 9.13445 7.73925C9.4702 7.51491 9.73189 7.19605 9.88642 6.82298C10.0409 6.44991 10.0814 6.0394 10.0026 5.64336C9.92382 5.24731 9.72937 4.88352 9.44384 4.59799C9.15831 4.31246 8.79452 4.11801 8.39847 4.03923C8.00243 3.96045 7.59192 4.00088 7.21885 4.15541C6.84579 4.30994 6.52692 4.57163 6.30258 4.90738C6.07824 5.24313 5.9585 5.63786 5.9585 6.04167"
                        stroke="#808089"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                  </svg>
                </CustomToolTip>
              }
            </Text>
          )}
          {
            data?.isUl ?
                <ul style={{listStyle: 'disc', color: '#7C88A6', paddingLeft: 24}}>
                    {!!data?.description && (
                        <li>
                          <Text
                              {...props?.descriptionProps}
                              as={props?.descriptionProps?.as || 'p'}
                              color={props?.descriptionProps?.color || '#7C88A6'}
                              fontSize={props?.descriptionProps?.fontSize || 15}
                              lineHeight={props?.descriptionProps?.lineHeight || 21}
                              style={{marginTop: 12, ...props?.descriptionProps?.style}}
                          >
                            {data.description}
                          </Text>
                        </li>
                    )}
                    {!!data?.description_2 && (
                        <li>
                          <Text
                              color={props?.descriptionProps?.color || '#7C88A6'}
                              fontSize={props?.descriptionProps?.fontSize || 15}
                              lineHeight={props?.descriptionProps?.lineHeight || 20}
                              style={{marginTop: 4}}
                          >
                            {data.description_2}
                          </Text>
                        </li>
                    )}
                </ul>
                :  !!data?.description && (
                      <Text
                        {...props?.descriptionProps}
                        as={props?.descriptionProps?.as || 'p'}
                        color={props?.descriptionProps?.color || '#7C88A6'}
                        fontSize={props?.descriptionProps?.fontSize || 15}
                        lineHeight={props?.descriptionProps?.lineHeight || 21}
                        style={{marginTop: 12, ...props?.descriptionProps?.style}}
                      >
                      {data.description}
                      </Text>
                  )}

          {Array.isArray(data?.actions) && data.actions.length > 0 && (
            <div
              className="grid-layout__section-header-actions"
              {...data?.actionProps}
            >
              {data.actions.map((item, i) => (
                <Fragment key={i}>{item}</Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      {!!props?.children && (
        <div
          className={`grid-layout__section-container ${props?.className || ''}`}
        >
          {props.children}
        </div>
      )}
    </div>
  )
}
