import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../../../../common/text";
import ReactImageFallback from "react-image-fallback";
import {transformImageEcommerce} from "../../../../../interface/_contant";
import {fNumber} from "../../../../../../../utils/formatNumber";
import {EmptyList} from "../../../../../../../components/empty";
import {useTranslation} from "react-i18next";
export const TopQuantity = ({data, meta})=>{
  const { t } = useTranslation()
    const syncStatus = (status) => {
        switch (status) {
            case 0:
                return{
                    name: t('link_not_yet'),
                    color: '#7C88A6',
                    background:'#EFF3FB'
                }
            case 1:
                return {
                    name: t('linked_success'),
                    color: '#00AB56',
                    background:'#EBFFF5'
                }
            default:
                return {
                    name: t('link_failed'),
                    color: '#FF424E',
                    background:'rgba(255, 66, 78, 0.08)'
                }
        }
    }

    return(
        <StyleTopQuantity>
            <div className={'report-top-product_header'} >
                <Text fontSize={16} fontWeight={500}>{t('e_com_ranking_amount')}</Text>
            </div>
            {
                data?.length > 0 ?  data?.map((map, i) => <div
                    key={i}
                    className={'report-top-product_body'}
                >
                    <Text>#{i + 1}</Text>
                    <div className={'report-top-product_detail'}>
                        <ReactImageFallback
                            className="report-top-product__thumbnail"
                            src={map?.image}
                            fallbackImage='/img/product/default-product-thumbnail.png'
                            alt={data?.product_name || 'thumbnail'}
                        />
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%'}}>
                            <div className={'report-top-product_detail-product'}>
                                <Text>
                                    {map?.product_name}
                                </Text>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    {transformImageEcommerce(map?.ecom_type, 16)}
                                    <Text style={{marginLeft: 4}} color={'#7C88A6'}>{map?.shop_short_name || map?.shop_name}</Text>
                                </div>
                                <div
                                    className={'report-top-product_detail-status'}
                                    style={{background: syncStatus(+map?.sync_status)?.background}}
                                >
                                    <Text color={syncStatus(+map?.sync_status)?.color}>{syncStatus(+map?.sync_status)?.name}</Text>
                                </div>
                            </div>
                            <div className={'report-top-product_detail-value'}>
                                <Text as={'p'} fontSize={16} fontWeight={600} style={{width: '100%'}} >
                                    {fNumber(+meta?.total_quantity)} {t('purchases_products')}
                                </Text>
                                <Text color={'#00AB56'}>
                                    ({
                                    (Math.round((+map?.total_quantity /+meta?.total_quantity)*100) * 100) / 100
                                }%)
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>)
                    :
                    <EmptyList/>

            }
        </StyleTopQuantity>
    )
}
const StyleTopQuantity = styled.div`
  width: calc(50% - 12px);
.report-top-product{
    &_header{
      padding: 16px;
      width: 100%;
      height: 54px;
      border-radius: 4px 4px 0px 0px;
      border-bottom: 1px solid var(--secondary-200, #F0F2F5);
      background: #EBF0FA;
    }
    &_body{
      padding: 20px 16px;
      width: 100%;
      display: flex;
      gap: 24px;
      align-items: center;
      border-right: 1px solid #F0F2F5;
      border-bottom: 1px solid #F0F2F5;
      border-left: 1px solid #F0F2F5;
      background: var(--sematic-white, #FFF);
    }
    
    &__thumbnail{
      width: 54px;
      height: 54px;
    }
    &_detail{
      display: flex;
      gap: 16px;
      align-items: center;
      width: 100%;
    }
     &_detail-product{
      width: 62%;
    }
    &_detail-status{
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;
    }
    &_detail-value{
      text-align: right;
      width: 123px;
    }
  }
`