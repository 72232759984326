import React, {lazy} from 'react'
import { PATH } from '../const/path'
import {
    addOnToolRoute,
    cashBookRoute,
    configAndSettingRoute,
    deliveryRoute,
    facebookRoute, orderRoute,
    partnerRoute, productRoute, reportRoute,
    warehouseRoute, accountantRoute, notificationRoute, posRoute, addOnToolsRoute, ecommerceRoute, eorderRoute
} from './groupRouter'

const AccountVerify = lazy(() => import('Pages/AccountVerify'));
const ControlPanel = lazy(() => import('../Pages/ControlPanel/controlPanel'));
const LoginForm = lazy(() => import('../Pages/LoginForm/login'));
const ForgotPasswordForm = lazy(() => import('../Pages/ForgotPassword'));
const NotFound = lazy(() => import('Pages/404/index.'));
const NoConnection = lazy(() => import('Pages/noConnection'));
const NotLicensed = lazy(() => import('../Pages/permision/notLicensed'));
const NotPermission = lazy(() => import('../Pages/permision/notPermission'));
const renderNewPassword = lazy(() => import('../Pages/LoginForm/updatePassword'));

const publicRoutes = [
  {path: PATH.HOME, component: LoginForm},
  {path: PATH.LOGIN, component: LoginForm},
  {path: PATH.FORGOT_PASSWORD, component: ForgotPasswordForm},
  {path: PATH.NOT_FOUND, component: NotFound},
  {path: PATH.NOT_LICENSED, component: NotLicensed},
  {path: PATH.NOT_PERMISSION, component: NotPermission},
  {path: PATH.NO_CONNECTION, component: NoConnection},
  {path: PATH.ACCOUNT_VERIFY, component: AccountVerify},
  {path: PATH.UPDATE_PASSWORD, component: renderNewPassword},
]
const privateRoutes = [
    {path: PATH.ADMIN_DASHBOAR, component: ControlPanel, prefix: 'publish'},
    ...facebookRoute,
    ...warehouseRoute,
    ...partnerRoute,
    ...addOnToolRoute,
    ...deliveryRoute,
    ...cashBookRoute,
    ...productRoute,
    ...orderRoute,
    ...configAndSettingRoute,
    ...accountantRoute,
    ...reportRoute,
    ...notificationRoute,
    ...posRoute,
    ...addOnToolsRoute,
    ...posRoute,
    ...ecommerceRoute,
    ...eorderRoute
]

export { publicRoutes, privateRoutes }
