import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {Tooltip} from 'common/tooltip'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useAlert from "../../../../hook/useAlert";

export const CellCustomer = ({
  id,
  name,
  phone,
  report,
  onReportClick,
  ...props
}) => {
  const { t } = useTranslation()
  const {showAlert} = useAlert()
  const handleCopy = (e,phone)=>{
    e.stopPropagation()
    navigator.clipboard.writeText(phone)
    showAlert({type:'success', content: t('copied')})
  }
  return (
    <div {...props}>
      {name && (
        <div>
          <Text
            as={!!phone ? "a" :"p"}
            href={phone ? `/partner-management/customer?keyword=${phone}` : '#'}
            target="_blank"
            color={!!phone ? THEME_SEMANTICS.delivering : ''}
            fontWeight={600}
            style={{cursor: 'pointer'}}
          >
            {name}{' '}
          </Text>
          {report ? (
            <Tooltip
              placement="bottom-start"
              title={`${t('tooltip_sos')}: ${report} ${t('timeless')}`}
            >
              <StyledReportIcon
                onClick={e => {
                  e.stopPropagation()
                  if (onReportClick) onReportClick()
                }}
              >
                {ORDER_ICONS.alertTriangle}
              </StyledReportIcon>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      )}
      {phone && (
          <Tooltip placement="top-start" title={t('click_to_copy')}>
            <div onClick={(e)=>handleCopy(e,phone)}>
              <Text color="#7C88A6" fontSize={13} lineHeight={18}>
                {phone}
              </Text>
            </div>
          </Tooltip>
      )}
    </div>
  )
}

const StyledReportIcon = styled.i`
  width: 16px;
  height: 16px;
  margin-left: 4px;

  svg {
    width: 16px;
    height: 16px;

    transform: translateY(2px);

    cursor: pointer;
  }
`
