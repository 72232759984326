import {CategoryInput} from 'Component/CategoryInput'
import useOrderFilterForm from 'Pages/refactorOrder/hooks/useOrderFilterForm'
import {useTranslation} from 'react-i18next'

export const OrderSearch = () => {
  const {search, advancedSearch} = useOrderFilterForm()
  const {t} = useTranslation()

  return (
    <CategoryInput
      className={'invoice-filter-form__input-wide'}
      categoryList={[]}
      categoryValue={{name: t('order_id'), value: ''}}
      categoryWidth={110}
      placeholder={t('example2')}
      value={search.value}
      onChange={search.onChange}
      style={{position: 'relative', width: '392px'}}
    />
  )
}
