import {Breadcrumb} from 'common/breadcrumb'
import {Button} from 'common/button'
import {Tooltip} from 'common/tooltip'
import { useContext } from 'react'
import PopoverPopupOrder from '../orderPopper'
import { Link, useLocation } from 'react-router-dom'
import {StyledOrderPageHeader} from "./_styled";
import {OrderContext} from "../../provider/_context";
import { useTranslation } from 'react-i18next'

export const PageHeader = ({
  actions,
  breadcrumbLinks,
  breadcrumbSubTitle,
  breadcrumbTitle,
  ...props
}) => {
  const {pageState, pageDispatch} = useContext(OrderContext)
  const {loading} = pageState.table.display.loading
  const location = useLocation()
  const {t} = useTranslation()
  return (
    <StyledOrderPageHeader {...props}>
      <div className="order-page-header__breadcrumb">
        <Breadcrumb
          links={breadcrumbLinks}
          subTitle={breadcrumbSubTitle}
          title={breadcrumbTitle}
        />
      </div>
      <div className="order-page-header__actions">
        {Array.isArray(actions) &&
          actions.map(item => (
            <div key={item?.id} className={"order-page-header__action-item " + item?.className}>
              {item?.type == 'dropdown'
              ?
                <PopoverPopupOrder item={item}/>
              :
                (item?.isDeveloping ? (
                  <Tooltip placement="bottom" title="Tính năng đang phát triển">
                    <Button
                      appearance={item?.appearance}
                      icon={item?.icon}
                      onClick={item?.onClick}
                      disabled={!loading}
                    >
                      {t(item?.name)}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    href={item?.href}
                    state={{from: location.pathname}}
                    appearance={item?.appearance}
                    icon={item?.icon}
                    onClick={item?.onClick}
                    disabled={loading}
                    >
                    {t(item?.name)}
                  </Button>
                ))
              }
            </div>

          ))}
      </div>
    </StyledOrderPageHeader>
  )
}
