export const dummyVideos = [
  {
    id: '1234567',
    title: 'Livestream quần áo ưu đãi',
    username: 'Ruby Phan',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'true',
    description: 'Mộc Miên Vintage fashion',
    message: '128',
    call: '48',
    forward: '12',
    like: '24',
    love: '65',
    wow: '18',
    // date: new Date(),
    live: 'true',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  {
    id: '10234567',
    title: 'Test page',
    username: 'Phương Anh',
    inbox: 'Áo đầm giá bao nhiêu vậy em',
    phone: 'false',
    description: 'Mộc Miên Vintage fashion',
    message: '3',
    call: '0',
    forward: '0',
    like: '',
    love: '',
    wow: '',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: '',
  },
  {
    id: '1434567',
    title: 'Xả hàng quần áo công sở',
    username: 'Nguyễn Hoa',
    inbox: 'E ơi live bị đứng chị ko biết em nhắn tin cho chị',
    phone: 'false',
    description: 'Quần áo công sở',
    message: '253',
    call: '84',
    forward: '8',
    like: '86',
    love: '32',
    wow: '',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  {
    id: '1654567',
    title: 'Livestream quần áo ưu đãi',
    username: 'Thanh Hà',
    inbox: 'Cho em hỏi mua đồ mình như thế nào đây',
    phone: 'true',
    description: 'Mộc Miên Vintage fashion',
    message: '128',
    call: '48',
    forward: '12',
    like: '24',
    love: '65',
    wow: '18',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  {
    id: '14934567',
    title: 'Livestream quần áo ưu đãi',
    username: 'Kim Ngân',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'true',
    description: 'Mộc Miên Vintage fashion',
    message: '128',
    call: '48',
    forward: '12',
    like: '24',
    love: '65',
    wow: '18',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  {
    id: '221234567',
    title: 'Live thử',
    username: 'Phương Linh',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'false',
    description: 'Mộc Miên shop',
    message: '3',
    call: '0',
    forward: '0',
    like: '',
    love: '',
    wow: '',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: '',
  },
  {
    id: '123114567',
    title: 'Test',
    username: 'Lan Chi',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'false',
    description: 'Quần áo thời trang nữ',
    message: '3',
    call: '0',
    forward: '0',
    like: '',
    love: '',
    wow: '',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: '',
  },
  {
    id: '123459967',
    title: 'Livestream xả kho',
    username: 'Bảo Hân',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'false',
    description: 'Mộc Miên Vintage fashion',
    message: '128',
    call: '48',
    forward: '12',
    like: '24',
    love: '65',
    wow: '18',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  {
    id: '123884567',
    title: 'Thanh lý tồn kho giá siêu rẻ',
    username: 'Lan Chi',
    inbox: '416 miễn phí ship phải ko em',
    phone: 'false',
    description: 'mặc đẹp',
    message: '128',
    call: '48',
    forward: '12',
    like: '24',
    love: '65',
    wow: '18',
    // date: new Date(),
    live: 'false',
    avatar:
      'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
    img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  },
  // {
  // id: "123458867",
  // title: 'Thanh lý tồn kho giá siêu rẻ',
  //   username: 'Lan Chi',
  //   inbox: '416 miễn phí ship phải ko em',
  //   phone: 'false',
  //   description: 'mặc đẹp',
  //   message: '128',
  //   call: '48',
  //   forward: '12',
  //   like: '24',
  //   love: '65',
  //   wow: '18',
  // date: new Date(),
  //   live: 'false',
  //   avatar:
  //     'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
  //   img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  // },
  // {
  // id: "123456777",
  // title: 'Thanh lý tồn kho giá siêu rẻ',
  //   username: 'Lan Chi',
  //   inbox: '416 miễn phí ship phải ko em',
  //   phone: 'false',
  //   description: 'mặc đẹp',
  //   message: '128',
  //   call: '48',
  //   forward: '12',
  //   like: '24',
  //   love: '65',
  //   wow: '18',
  // date: new Date(),
  //   live: 'false',
  //   avatar:
  //     'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
  //   img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  // },
  // {
  // id: "123456711",
  // title: 'Thanh lý tồn kho giá siêu rẻ',
  //   username: 'Lan Chi',
  //   inbox: '416 miễn phí ship phải ko em',
  //   phone: 'false',
  //   description: 'mặc đẹp',
  //   message: '128',
  //   call: '48',
  //   forward: '12',
  //   like: '24',
  //   love: '65',
  //   wow: '18',
  // date: new Date(),
  //   live: 'false',
  //   avatar:
  //     'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
  //   img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  // },
  // {
  // id: "123456789",
  // title: 'Thanh lý tồn kho giá siêu rẻ',
  //   username: 'Lan Chi',
  //   inbox: '416 miễn phí ship phải ko em',
  //   phone: 'false',
  //   description: 'mặc đẹp',
  //   message: '128',
  //   call: '48',
  //   forward: '12',
  //   like: '24',
  //   love: '65',
  //   wow: '18',
  // date: new Date(),
  //   live: 'false',
  //   avatar:
  //     'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
  //   img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  // },
  // {
  // id: "123456755",
  // title: 'Thanh lý tồn kho giá siêu rẻ',
  //   username: 'Lan Chi',
  //   inbox: '416 miễn phí ship phải ko em',
  //   phone: 'false',
  //   description: 'mặc đẹp',
  //   message: '128',
  //   call: '48',
  //   forward: '12',
  //   like: '24',
  //   love: '65',
  //   wow: '18',
  // date: new Date(),
  //   live: 'false',
  //   avatar:
  //     'https://tse1.mm.bing.net/th?id=OIP.v6pO-sfFocVZMFaKp64EGwHaEo&pid=Api&P=0&w=289&h=181',
  //   img: 'https://i0.wp.com/www.sapo.vn/blog/wp-content/uploads/2019/12/livestream-quan-ao.jpg?resize=680%2C359&quality=100&strip=all&ssl=1',
  // },
]
