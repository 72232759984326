import React, {createContext, memo, useEffect, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {PATH} from '../../const/path'
import {Modal, Box} from '@mui/material'
import Popover from '@mui/material/Popover'

import css from './index.module.scss'
import cls from 'clsx'
import Tab from './Tab'
import {general} from '../Account/account'
import {getUrlLogOut, getUrlUserInfo} from '../../api/url'
import {getData} from '../../api/api'
import useGlobalContext from '../../containerContext/storeContext'
import {calculateDate, UposLogFunc} from '../../util/functionUtil'
import {TAB_USER_PROFILE} from '../Icons'
import {CustomToolTip} from '../tooltip/CustomTooltip'
import {Button} from '../../common/button'
import config from 'config'
import {Text} from 'common/text'
import {ConfirmModal} from 'layouts/rightSightPopup/confirm'
import {useTranslation} from "react-i18next";
import {DISPLAY_NAME_MENU} from "../../const/display_name_menu";

export default memo(function Index({...props}) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [, setShowUser] = useState(false)
  const [avatar] = useState('')
  const [toggle, setToggle] = useState(false)
  const [bolUpdate, setBolUpdate] = useState(false)
  const [aniModalClose, setAniModalClose] = useState(false)
  const [state, dispatch] = useGlobalContext()
  const [tab, setTab] = useState('user')
  const {user, userInfoPopup} = state
  const data = user

  const [fieldPass, setFieldPass] = useState(false)
  const [passDisabled, setPassDisabled] = useState(false)
  const nav = useNavigate()

  const handleClick = value => {
    setAnchorEl(null)
    switch (value) {
      case 'user_information': {
        setShowUser(true)
        setOpen(true)
        setAniModalClose(false)
        setTab('user')
        break
      }
      case 'pack_information': {
        setShowUser(true)
        setOpen(true)
        setAniModalClose(false)
        setTab('user')
        break
      }
      case 'change_password': {
        setPassDisabled(true)
        setFieldPass(true)
        setShowUser(true)
        setOpen(true)
        setAniModalClose(false)
        setTab('password')
        break
      }
      case 'history_login': {
        nav('/login-warning')
        break
      }
      case 'log_out': {
        const originOrderActive = JSON.parse(
          window.localStorage.getItem('origin_order_active'),
        )
        const configTableOrder = JSON.parse(
          window.localStorage.getItem('config_table_order'),
        )
        const configTableDelivery = JSON.parse(
          window.localStorage.getItem('config_table_delivery'),
        )

        const lang = localStorage.getItem('i18n')
        window.localStorage.clear()
        localStorage.setItem('i18n', !!lang ? lang : 'vi')
        if (!!originOrderActive && Array.isArray(originOrderActive))
          window.localStorage.setItem(
            'origin_order_active',
            JSON.stringify(originOrderActive),
          )
        if (!!configTableOrder && Array.isArray(configTableOrder))
          window.localStorage.setItem(
            'config_table_order',
            JSON.stringify(configTableOrder),
          )
        if (!!configTableDelivery && Array.isArray(configTableDelivery))
          window.localStorage.setItem(
            'config_table_delivery',
            JSON.stringify(configTableDelivery),
          )
        const url = getUrlLogOut()
        getData(url)
          .then(() => {
            dispatch({type: 'SET_LOGOUT'})
            dispatch({type: 'SET_USER', payload: {}})
            localStorage.setItem(`fbssls_${config.REACT_APP_FACEBOOK_APP_ID}`)
            sessionStorage.setItem(`fbssls_${config.REACT_APP_FACEBOOK_APP_ID}`)

            return <Navigate to={PATH.LOGIN} />
          })
          .catch(() => {
            console.log('error logout')
          })
        break
      }
      default:
        break
    }
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const handleShowProfile = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const openProfile = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const isUpdate = bol => (bol ? setBolUpdate(true) : setBolUpdate(false))

  useEffect(() => {
    window.addEventListener('click', function (e) {
      const isPopup = document
        .getElementById('btn_user_profile')
        ?.contains(e.target)
      const isPopupBtn = document
        .getElementById('btn_duration')
        ?.contains(e.target)
      if (!isPopup && !isPopupBtn) {
        setToggle(false)
      }
    })
    return function cleanupListener() {
      window.removeEventListener('click', () => {})
    }
  }, [])

  useEffect(() => {
    if (userInfoPopup) {
      handleClick('change_password')
      dispatch({type: 'SET_USER_INFO_POPUP', payload: false})
    }
  }, [userInfoPopup])

  return (
    <>
      <div className={cls(css.wrapper)} style={props?.styled}>
        <img
          aria-describedby={id}
          variant="contained"
          onClick={handleShowProfile}
          className={cls(css.icon_avatar)}
          src={
            (state.user && state.user.avatar) ||
            '/img/iconMenu/defaultAvatar.png'
          }
          alt={'avatar'}
        />
        <Popover
          sx={{top: 14}}
          id={id}
          open={openProfile}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={`${cls(css.account)}`}>
            <div className={cls(css.group)}>
              {avatar ? (
                <img src={avatar} alt={'avatar'} />
              ) : (
                <img src={'/img/iconMenu/defaultAccount.png'} alt={'avatar'} />
              )}
              <div className={cls(css.inner)}>
                <CustomToolTip
                  title={<p className={cls(css.name)}>{data.fullname}</p>}
                  arrow
                >
                  <p className={cls(css.name)}>{data.fullname}</p>
                </CustomToolTip>
                <CustomToolTip
                  title={<p className={cls(css.email)}>{data.email}</p>}
                  arrow
                >
                  <p className={cls(css.email)}>{data.email}</p>
                </CustomToolTip>
                <p className={cls(css.package)}>
                  {data?.package?.license_name}
                </p>
              </div>
            </div>
            <hr />
            <ul>
              {general.map(item => {
                return (
                  <li
                    key={item.class}
                    className={cls(css[item.class])}
                    onClick={() => handleClick(item.value)}
                  >{t(item.label)}</li>
                )
              })}
            </ul>
          </div>
        </Popover>
        <Modal
          open={open}
          onClose={() => {
            if (!bolUpdate) {
              setAniModalClose(true)
              setTimeout(() => {
                setOpen(false)
                setToggle(false)
              }, 300)
            } else {
              setConfirm(true)
            }
          }}
          id={'modal_user_profile'}
        >
          <Box
            className={`${cls(css.box_modal)} ${
              aniModalClose && cls(css.modal_custom)
            }`}
          >
            <div
              className={cls(css.dismiss)}
              onClick={() => {
                if (!bolUpdate) {
                  setAniModalClose(true)
                  setTimeout(() => {
                    setOpen(false)
                  }, 300)
                } else {
                  setConfirm(true)
                }
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13M1 1L13 13"
                  stroke="#F4F7FC"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={cls(css.general)}>
              <img
                className={cls(css.bgp)}
                src={'/img/iconMenu/bgProfile.svg'}
                alt={'background'}
              />
              <div className={cls(css.grp_avatar)}>
                <img
                  className={cls(css.avatar)}
                  src={
                    (state.user && state.user.avatar) ||
                    '/img/iconMenu/deAvatar.png'
                  }
                  alt={'avatar'}
                />
              </div>
              <div className={cls(css.content)}>
                <div className={cls(css.info)}>
                  <CustomToolTip
                    title={<p className={cls(css.name)}>{data.fullname}</p>}
                    arrow
                  >
                    <p className={cls(css.name)}>{data.fullname}</p>
                  </CustomToolTip>
                  <CustomToolTip
                    title={
                      <div className={cls(css.grp_time)}>
                        <p className={cls(css.active)}>
                          {t('general_activated')}:{' '}
                          {data.hasOwnProperty('package')
                            ? `${
                                data.package.dt_created
                                  .split(' ')[0]
                                  .split('-')[2]
                              }/${
                                data.package.dt_created
                                  .split(' ')[0]
                                  .split('-')[1]
                              }/${
                                data.package.dt_created
                                  .split(' ')[0]
                                  .split('-')[0]
                              }`
                            : ''}
                        </p>
                        <p className={cls(css.inactive)}>
                          {t('End')}:{' '}
                          {data.hasOwnProperty('package')
                            ? `${
                                data.package.expiration_date
                                  .split(' ')[0]
                                  .split('-')[2]
                              }/${
                                data.package.expiration_date
                                  .split(' ')[0]
                                  .split('-')[1]
                              }/${
                                data.package.expiration_date
                                  .split(' ')[0]
                                  .split('-')[0]
                              }`
                            : ''}
                        </p>
                      </div>
                    }
                    arrow
                  >
                    <p className={cls(css.package)}>
                      <span>{data?.package?.license_name}</span>
                      {TAB_USER_PROFILE.quest}
                    </p>
                  </CustomToolTip>
                  <div className={cls(css.duration)}>
                    <a
                      id={'btn_user_profile'}
                      onClick={() => setToggle(!toggle)}
                    >
                      {t('remaining')} {calculateDate(data?.package?.expiration_date)}
                    </a>
                    {TAB_USER_PROFILE.arrow}
                    {toggle && (
                      <div className={cls(css.list)} id={'btn_duration'}>
                        <a
                          href={'https://upos.vn/vn/lien-he.html'}
                          target={'_blank'}
                          onClick={() => setToggle(!toggle)}
                        >
                          {t('licensed_renew')}
                        </a>
                        <a
                          href={'https://upos.vn/vn/lien-he.html'}
                          target={'_blank'}
                          onClick={() => setToggle(!toggle)}
                        >
                          {t('update_licence')}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Tab
                userProfile={data}
                tab={tab}
                fieldPass={fieldPass}
                passDisabled={passDisabled}
                isUpdate={isUpdate}
              />
            </div>
          </Box>
        </Modal>

        <ConfirmModal
          openModal={confirm}
          body={
            <>
              <Text fontSize={20} fontWeight={600}>
                {t('general_confirm_leaving')}
              </Text>
              <Text
                style={{marginTop: 24}}
                as="p"
                className="setting-management-modal_txt"
              >
                {t('general_confirm_leaving_content')}
              </Text>
            </>
          }
          stylePopup={'setting-management-modal_confirm'}
          footer={{
            cancel: {
              width: 110,
              title: t(DISPLAY_NAME_MENU.GENERAL.CLOSE)
            },
            acceptance: {
              width: 110,
              title: t(DISPLAY_NAME_MENU.GENERAL.CONFIRM),
            },
          }}
          footerProps={{className: 'setting-management-modal_dismiss'}}
          closeModal={() => {
            setConfirm(false)
          }}
          acceptance={() => {
            setConfirm(false)
            setAniModalClose(true)
            setTimeout(() => {
              setOpen(false)
              setBolUpdate(false)
            }, 300)
          }}
        />
      </div>
    </>
  )
})
