import {useContext, useEffect} from "react";
import {ReportOrderContext} from "../provider/_context";
import {reportOrderAction} from "../provider/_reducer";
import {getDateFromNow, formatDatetime, convertDateTimeToApiFormatV2, formatDatetimeV2} from "../../../utils/formatDate";
import useReportEcommerce from "../../../index";
import {REVENUE_ORDER_TAG} from "../interface/_contant";
import {sendRequestAuth} from "../../../../../../../api/api";
import config from "../../../../../../../config";
import {removeAcent} from "../../../../../../../common/fieldText/_functions";
import useAlert from "../../../../../../../hook/useAlert";
import {useTranslation} from "react-i18next";

export const useReportOrderFilter = ()=>{
  const { t } = useTranslation()
    const {pageState, pageDispatch} = useContext(ReportOrderContext)

    const{ecommerce, dateTime, stores, revenue, ratioOrder, revenueList, status} = pageState.filter

    const ecommerceReportInitState = useReportEcommerce()

    const dateStart= dateTime?.dateStart
    const dateEnd= dateTime?.dateEnd

    const {showAlert} = useAlert()
    const queries={
        ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
        ecom_seller_id: ecommerce?.activeValue?.value === 'all' && stores?.activeValue?.length === 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
        start_date:convertDateTimeToApiFormatV2(dateStart),
        end_date:convertDateTimeToApiFormatV2(dateEnd),
        status: ecommerce?.activeValue?.value === 'all' && status?.isStatus ? '' : status?.activeValue?.map(map=> map?.status).join(',')

    }

    const transformQueries = (qs)=>{
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        return queryString
    }

    const fetchApiFilter = async (qs)=>{
        pageDispatch({
            type:reportOrderAction.REPORT_ORDER_REVENUE_LIST_LOADING,
            payload:true
        })
        const res = await Promise.all([
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/revenue${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/ratio${transformQueries(qs)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/revenue-list${transformQueries(qs)}`),
        ])
        if(res[0].data?.success){
            const important= res[0].data
            const activeList = revenue?.activeList?.map((map, i) =>({
                ...map,
                percent: important?.meta?.find(find => find?.key === map?.tab)?.percent || 0,
                value: important?.meta?.find(find => find?.key === map?.tab)?.value || 0,
                data: transformListData(important?.data, map.tab)
            }))
            pageDispatch({
                type: reportOrderAction.REPORT_ORDER_REVENUE_UPDATE,
                payload:{
                    meta: important?.meta,
                    data: important?.data,
                    list: REVENUE_ORDER_TAG?.map((map, i) =>({
                        ...map,
                        percent: important?.meta?.find(find => find?.key === map?.tab)?.percent || 0,
                        value: important?.meta?.find(find => find?.key === map?.tab)?.value || 0,
                        data: transformListData(important?.data, map.tab)
                    })),
                    activeList: activeList
                }
            })
        }
        if(res[1].data?.success){
            pageDispatch({
                type: reportOrderAction.REPORT_ORDER_RATIO_ORDER_UPDATE,
                payload:{
                    data: res[1].data?.data
                }
            })
        }
        if(res[2].data?.success){
            if(revenueList?.sort){
                const list = res[2].data?.data
                const newDate=(date)=>{
                    const formatDate = date?.split('/')
                    return new Date(`${formatDate[2]}-${formatDate[1]}-${formatDate[0]}`)
                }
                switch (revenueList?.type) {
                    case 'revenue':
                        if(revenueList?.sort === 'max') list?.sort( (a,b) => +a?.revenue - +b?.revenue )
                        else list?.sort( (a,b) => +b?.revenue - +a?.revenue )
                        break;
                    case 'order':
                        if(revenueList?.sort === 'max') list?.sort( (a,b) => +a?.total_order - +b?.total_order )
                        else list?.sort( (a,b) => +b?.total_order - +a?.total_order )
                        break;
                    case 'agv_revenue':
                        if(revenueList?.sort === 'max') list?.sort( (a,b) => +a?.agv_revenue - +b?.agv_revenue )
                        else list?.sort( (a,b) => +b?.agv_revenue - +a?.agv_revenue )
                        break;
                    default:
                        if(revenueList?.sort === 'max') list?.sort( (a,b) =>  newDate(a?.date) - +newDate(b?.date))
                        else list?.sort( (a,b) => +newDate(b?.date) - +newDate(a?.date))
                        break
                }
                pageDispatch({
                    type:reportOrderAction.REPORT_ORDER_REVENUE_LIST_UPDATE,
                    payload:{
                        list: list,
                        sort: revenueList?.sort,
                        type: revenueList?.type,
                        loading: false,
                        data: list
                    }
                })
            }
            else pageDispatch({
                type: reportOrderAction.REPORT_ORDER_REVENUE_LIST_UPDATE,
                payload:{
                    data: res[2].data?.data,
                    list: res[2].data?.data,
                    listOrigin:res[2].data?.data,
                    loading: false
                }
            })

        }
    }

    useEffect(()=>{
        const list = ecommerceReportInitState?.pageState?.stores?.list
        const activeEcom = ecommerce?.activeValue?.value
        if(ecommerceReportInitState?.pageState?.stores?.list) pageDispatch({
            type: reportOrderAction.REPORT_ORDER_STORES_LIST,
            payload:{
                list: activeEcom === 'all' ? list : list.filter(filter => filter?.ecom_type === activeEcom),
                listOrigin: activeEcom === 'all' ? list : list.filter(filter => filter?.ecom_type === activeEcom)
            }
        })
    },[])

    const transformListData = (data, tab) =>{
        switch (tab) {
            case "total_order":
                return data?.map(map=> map?.value[tab])
            case 'revenue':
                return data?.map(map=> map?.value[tab])
            case 'total_cancel':
                return data?.map(map=> map?.value[tab])
            default:
                return data?.map(map=> map?.value[tab])
        }
    }

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.revenueOrder
        const data=important?.data
        const meta=important?.meta
        const listUpdate =  REVENUE_ORDER_TAG?.map((map, i) =>({
            ...map,
            percent: meta?.find(find => find?.key === map?.tab)?.percent || 0,
            value: meta?.find(find => find?.key === map?.tab)?.value || 0,
            data: transformListData(data,map?.tab)
        }))
        const activeList = revenue?.activeList?.map((map, i) =>({
            ...map,
            percent: important?.meta?.find(find => find?.key === map?.tab)?.percent || 0,
            value: important?.meta?.find(find => find?.key === map?.tab)?.value || 0,
            data: transformListData(important?.data, map.tab)
        }))
        if(ecommerceReportInitState?.pageState?.revenueOrder?.data) pageDispatch({
            type: reportOrderAction.REPORT_ORDER_REVENUE_UPDATE,
            payload:{
                meta: meta,
                data: data,
                list: listUpdate,
                activeList: activeList
            }
        })
    },[])


    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.ratioOrder
        if(ecommerceReportInitState?.pageState?.ratioProduct?.data) pageDispatch({
            type: reportOrderAction.REPORT_ORDER_RATIO_ORDER_UPDATE,
            payload:{
                data: important?.data
            }
        })
    },[])

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.ratioOrderStatus
        if(ecommerceReportInitState?.pageState?.ratioOrderStatus?.data) pageDispatch({
            type: reportOrderAction.REPORT_ORDER_RATIO_ORDER_UPDATE,
            payload:{
                data: important?.data
            }
        })
    },[])

    useEffect(()=>{
        const important = ecommerceReportInitState?.pageState?.revenueOrderTable
        if(important?.data && !status?.isStatus) pageDispatch({
            type: reportOrderAction.REPORT_ORDER_REVENUE_LIST_UPDATE,
            payload:{
                data: important?.data,
                listOrigin: important?.data,
                list: important?.data
            }
        })
    },[])
    //======= ECOMMERCE ======
    const activeValue = ecommerce?.activeValue
    const storesList =  ecommerceReportInitState?.pageState?.stores?.list
    const handleActiveValue = data =>{
        let listOrigin = [...storesList]
        if(data?.value !== 'all'){
            listOrigin = listOrigin?.filter(filter => filter?.ecom_type === data?.type)
        }
        pageDispatch({
            type: reportOrderAction?.REPORT_ORDER_ECOMMERCE_ACTIVE,
            payload: {
                active: data,
                list: listOrigin,
                listOrigin: listOrigin
            }
        })
        fetchApiFilter({
            ...queries,
            ecom_type: data?.value === 'all' ? '' : data?.value,
            ecom_seller_id:''
        })
    }
    //====== END ECOMMERCE ====

    //====== DATE TIME======
    const listDate = dateTime?.list
    const activeDate = dateTime?.activeValue
    const handleActiveDate = data =>{
        let dateStart=''
        let dateEnd=''

        switch (data?.value) {
            case 'seven days':
                dateStart=getDateFromNow(-6,'start')
                dateEnd=getDateFromNow(0,'end')
                break;
            default:
                dateStart=getDateFromNow(-29,'start')
                dateEnd=getDateFromNow(0,'end')
                break
        }


        dateStart = formatDatetime(dateStart)
        dateEnd =  formatDatetime(dateEnd)

        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_DATE_TIME_ACTIVE,
            payload: {
                active:data,
                dateStart,
                dateEnd
            }
        })

        const query={
            ...queries,
            ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
            ecom_seller_id: ecommerce?.activeValue?.value === 'all'  && stores?.activeValue?.length === 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
            start_date:convertDateTimeToApiFormatV2(dateStart),
            end_date:convertDateTimeToApiFormatV2(dateEnd)
        }
        fetchApiFilter(query)

    }
    //====== END DATE TIME======

    //====== STORES==========
    const listStore = stores?.list
    const listOriginStore = stores?.listOrigin
    const activeStore = stores?.activeValue
    const keywordStore = stores?.keyword
    const canSubmitFilter =[
        JSON.stringify(activeStore) !== JSON.stringify(stores?.applyFilter) && activeStore?.length > 0,
        status?.isStatus && JSON.stringify(status?.activeValue) !== JSON.stringify(status?.applyFilter)
    ].includes(true)
    const handleSelected = data =>{
        let newArr = [...activeStore]
        const findData = newArr?.find(find => +find?.ecom_seller_id === +data?.ecom_seller_id)
        pageDispatch({
            type:reportOrderAction.REPORT_ORDER_STORES_SELECTED,
            payload: !!findData ?
                newArr?.filter(filter =>+filter?.ecom_seller_id !== +data?.ecom_seller_id ) :
                [...newArr, data]
        })
    }

    const handleResetInput = ()=> pageDispatch({
        type:reportOrderAction.REPORT_ORDER_STORES_SELECTED,
        payload:[]
    })

    const handleTabChange = data =>{
        let list = [...activeStore]
        if(data === 'all') list = [...listOriginStore]

        pageDispatch({
            type:reportOrderAction.REPORT_ORDER_STORES_TAB,
            payload:{
                tab: data,
                list: list
            }
        })
    }

    const handleApplyFilter = () =>{
        const isStatus = status?.isStatus
        const typeFilter = [
            'stores',
            'status'
        ]
        const query={
            ...queries,
            ecom_type: ecommerce?.activeValue?.value === 'all' ? '' : ecommerce?.activeValue?.value,
            ecom_seller_id: stores?.activeValue?.length === 0 && stores?.activeValue?.length < 0 ? '' : stores?.activeValue?.map(map=>map?.ecom_seller_id).join(','),
            status: isStatus && status?.activeValue?.length > 0 ? status?.activeValue?.map(map=>map?.status).join(',') : ''
        }
        typeFilter?.forEach(each =>{
            if(each === 'stores')  pageDispatch({
                type:reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER,
                payload:{
                    type: 'stores',
                    active: stores?.activeValue
                }
            })
            if(each === 'status')  pageDispatch({
                type:reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER,
                payload:{
                    type: 'status',
                    active: isStatus && status?.activeValue?.length > 0 ?  status?.activeValue : []
                }
            })
        })
        fetchApiFilter(query)
    }

    const handleResetApply = ()=>{
        const types=[
            'stores',
            'status'
        ]
        types?.forEach(each=>  pageDispatch({
            type:reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER,
            payload:{
                type:each,
                active: []
            }
        }))

        const query={
            ...queries,
            ecom_seller_id: '',
            status: ''
        }
        fetchApiFilter(query)
    }
    const handleDeleteApply = (type)=>{
        const isStatus = status?.isStatus
        let query={...queries}

        switch (type) {
            case "stores":
                pageDispatch({
                    type:reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER,
                    payload:{
                        type: 'stores',
                        active: []
                    }
                })
                query = {
                    ...queries,
                    ecom_seller_id: '',
                }
                break;
            default:
                pageDispatch({
                    type:reportOrderAction.REPORT_ORDER_STORES_APPLY_FILTER,
                    payload:{
                        type: 'status',
                        active: []
                    }
                })
                query = {
                    ...queries,
                    status: isStatus && type === 'status' && ''
                }
                break
        }

        fetchApiFilter(query)
    }




    const handleSearchStore = data =>{
        let listOrigin =[...listOriginStore]
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const listData = listOrigin.filter(item => {
            const formatNameItem = item?.shop_name
                ? removeAcent(item?.shop_name.toLowerCase())
                : ''
            const formatNameItemShortName = item?.shop_short_name
                ? removeAcent(item?.shop_short_name.toLowerCase())
                : ''

            if (formatNameItem.includes(formatDataValue)) return true
            if (formatNameItemShortName.includes(formatDataValue)) return true
            return false
        })
        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_STORES_SEARCH,
            payload: {
                keyword: data?.value || '',
                list: listData,
            },
        })
    }

    //===== END STORES ======

    //======STATUS======
    const listStatus = status?.list;
    const listOriginStatus = status?.listOrigin
    const activeStatus = status?.activeValue
    const handelDetailRevenue = ()=>{
        const isStatus = status?.isStatus
        if(isStatus) handleDeleteApply('status')
        pageDispatch({type:reportOrderAction.REPORT_ORDER_STATUS_CHANGE, payload: !status?.isStatus})
    }

    const handleSelectedStatus = data =>{
        let newArr = [...activeStatus]
        const findData = newArr?.find(find => +find?.status === +data?.status)
        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_STATUS_SELECTED,
            payload: !!findData ?
                newArr?.filter(filter =>+filter?.status !== +data?.status ) :
                [...newArr, data]
        })

    }
    const handleTabChangeStatus = data =>{
        let list = [...activeStatus]
        if(data === 'all') list = [...listOriginStatus]

        pageDispatch({
            type:reportOrderAction.REPORT_ORDER_STATUS_TAB,
            payload:{
                tab: data,
                list: list
            }
        })
    }

    const handleResetInputStatus = ()=> pageDispatch({
        type:reportOrderAction.REPORT_ORDER_STATUS_SELECTED,
        payload:[]
    })

    const handleSearchStatus = data =>{
        let listOrigin =[...status.listOrigin]
        const formatDataValue = data?.value
            ? removeAcent(data?.value?.toLowerCase())
            : ''

        const listData = listOrigin.filter(item => {
            const formatNameItem = item?.title
                ? removeAcent(item?.title.toLowerCase())
                : ''

            if (formatNameItem.includes(formatDataValue)) return true
            return false
        })
        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_STATUS_SEARCH,
            payload: {
                keyword: data?.value || '',
                list: listData,
            },
        })

    }

    //====== END STATUS ======


    //========= REVENUE =========
    const activeList = revenue?.activeList?.map(item => {
      return {
        ...item,
        title: t(item.title),
        tooltiTitle: t(item.tooltiTitle),
        lastWeek: t(item.lastWeek),
        lastMonth: t(item.lastMonth),
        name: t(item.name),
        typeValue: t(item.typeValue),
      }
    })

    const handleActiveList = (data) =>{
        let newArr = [...activeList]
        let newList = [...activeList]
        const findData = newArr?.find(find=> find?.tab === data?.tab)
        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_REVENUE_ACTIVE,
            payload:  !!findData && newArr?.length > 1 ? newArr.filter(filter=> filter.tab !== data?.tab )
                : !!findData && newArr?.length === 1 ? newArr : [...newArr, data].slice(0,3)
        })
        newList = !!findData ? newArr.filter(filter=> filter.tab !== data?.tab )
            : [...newArr, data]

        if(!!findData && newList.filter(filter=> filter.tab !== data?.tab ).length < 1)
          showAlert({type:'danger', content: t('e_com_min_one_index')})
        if(newList.length > 3)
          showAlert({type:'danger', content: t('e_com_max_three_index')})
    }

    const handleResetImportantNumber=()=>{
        pageDispatch({
            type: reportOrderAction.REPORT_ORDER_REVENUE_ACTIVE,
            payload:  revenue?.list.slice(0,2)
        })

    }

    const listOriginRevenue = revenueList?.listOrigin
    const handleSort = (type, sort)=>{
        let list = [...listOriginRevenue]
        const newDate=(date)=>{
            const formatDate = date?.split('/')
           return new Date(`${formatDate[2]}-${formatDate[1]}-${formatDate[0]}`)
        }
        switch (type) {
            case 'revenue':
                if(sort === 'max') list?.sort( (a,b) => +a?.revenue - +b?.revenue )
                else list?.sort( (a,b) => +b?.revenue - +a?.revenue )
                break;
            case 'order':
                if(sort === 'max') list?.sort( (a,b) => +a?.total_order - +b?.total_order )
                else list?.sort( (a,b) => +b?.total_order - +a?.total_order )
                break;
            case 'agv_revenue':
                if(sort === 'max') list?.sort( (a,b) => +a?.agv_revenue - +b?.agv_revenue )
                else list?.sort( (a,b) => +b?.agv_revenue - +a?.agv_revenue )
                break;
            default:
                if(sort === 'max') list?.sort( (a,b) =>  newDate(a?.date) - +newDate(b?.date))
                else list?.sort( (a,b) => +newDate(b?.date) - +newDate(a?.date))
                break
        }
        pageDispatch({
            type:reportOrderAction.REPORT_ORDER_REVENUE_LIST_UPDATE,
            payload:{
                list: list,
                sort: sort,
                type: type,
            }
        })
    }
    return{
        ecommerce:{
            activeValue: activeValue,
            onActive: handleActiveValue
        },
        dateTime:{
            list: listDate,
            activeValue:activeDate,
            dateStart: dateTime?.dateStart,
            dateEnd: dateTime?.dateEnd,
            onActiveDate: handleActiveDate
        },
        stores:{
            list: listStore,
            activeValue: activeStore,
            keyword: keywordStore,
            tab: stores?.tab,
            applyFilter: stores?.applyFilter,
            canSubmitFilter,

            onSelected: handleSelected,
            onInputReset: handleResetInput,
            onTabChange: handleTabChange,
            onApplyFilter: handleApplyFilter,
            onDeleteStore: handleResetApply,
            onSearch: handleSearchStore,
            onDeleteFilter: handleDeleteApply,
        },
        status:{
          list: listStatus,
          activeValue: status?.activeValue,
          tab: status?.tab,
          isStatus: status?.isStatus,
          applyFilter: status?.applyFilter,
          keyword: status?.keyword,

          onStatus: handelDetailRevenue,
          onSelected: handleSelectedStatus,
          onTabChange: handleTabChangeStatus,
          onInputReset: handleResetInputStatus,
          onDeleteFilter: handleDeleteApply,
          onSearch: handleSearchStatus
        },
        revenue:{
            list: revenue?.list,
            activeList: activeList,
            revenueData: revenue?.data,
            revenueMeta: revenue?.meta,

            onActiveList: handleActiveList,
            onReset: handleResetImportantNumber
        },
        ratioOrder:{
            data: ratioOrder?.data,
        },
        revenueList:{
            data: revenueList?.data,
            list: revenueList?.list,
            sort: revenueList?.sort,
            onSort: handleSort,
            loading: revenueList?.loading
        },
        methods:{
            fetchApiFilter,
            queries
        }

    }

}