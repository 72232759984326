import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {Tooltip} from "../../../../../../../../common/tooltip";
import {formatMoney} from "../../../../../../../../util/functionUtil";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {formatDatetime, getDateFromNow} from "../../../../utils/formatDate";
import {useTranslation} from "react-i18next";
export const RevenueTag = ({...props})=>{
    const { t } = useTranslation()
    const {revenue, dateTime} = useReportOrderFilter()
    const {
        color,
        title,
        tooltip,
        value,
        isPrice,
        percent,
        compareTitle,
        type,
        data
    } = props
    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const transformDate = ()=>{
        const activeDate = dateTime?.activeValue?.value
        let dateStart=''
        let dateEnd =''
        switch (activeDate) {
            case'seven days':
                dateStart = formatDatetime(getDateFromNow(-13))
                dateEnd= formatDatetime(getDateFromNow(-7, 'end'))
                break;
            default:
                dateStart = formatDatetime(getDateFromNow(-58))
                dateEnd= formatDatetime(getDateFromNow(-30, 'end'))
                break;
        }
        return `${dateStart} ~ ${dateEnd}`

    }

    return(
        <StyledRevenueTag {...props}>
            <div
                className={'report-order-revenue'}
                data-active={!!revenue?.activeList?.find(find => find?.tab === type)}
                onClick={()=>  revenue?.onActiveList(data)}
            >
                <div className={'report-order-revenue_title'}>
                    <Text style={{marginRight: 4}} fontSize={15} fontWeight={600}>{title}</Text>
                    <Tooltip title={tooltip}>
                        {GLOBAL_ICON?.question}
                    </Tooltip>
                </div>
                <div className={'report-order-revenue_value'}>
                    {
                        isPrice ? <Text fontWeight={600} fontSize={20} color={color} >{formatMoney(value)}</Text>
                            : <Text fontWeight={600} fontSize={20} color={color} >{addCommas(+value.toFixed(2))} <Text fontSize={12} fontWeight={600} color={'#64748B'} >{t('orders')}</Text></Text>
                    }
                </div>
                <div className={'report-order-revenue_percent'}>
                    <div>
                        <Text fontSize={13}>{compareTitle}</Text>
                        <br/>
                        <Text fontSize={13}>{transformDate()}</Text>
                    </div>
                    <div className={'report-order-revenue_percent-number'}>
                        <Text>
                            {
                                +percent >= 0 ? GLOBAL_ICON?.percentUp : GLOBAL_ICON?.percentDown
                            }
                        </Text>
                        <Text color={+percent >= 0? '#00AB56' : '#FF424E'}>
                            {percent}%
                        </Text>
                    </div>
                </div>
            </div>
        </StyledRevenueTag>
    )

}

const StyledRevenueTag = styled.div`
    .report-order-revenue{
        border-radius: 4px;
        background: #F7F9FD;
        padding: 16px;
        cursor: pointer;
        border-top: 4px solid #F7F9FD;
        &[data-active='true']{
          border-top: 4px solid ${props => props.color};
        }
        &_title{
          display: flex;
          align-items: center;
        }
        &_value{
          margin: 8px 0 16px 0;
        }
        &_percent{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
        &_percent-number{
          display: flex;
          align-items: center;
          span{
            &:nth-child(2){
              margin-left: 4px;
            }
          }
        }
    }
   
`