import React from "react";
import {useReportUpdateOrder} from "../../../../hook/useReportUpdateOrder";
import {GLOBAL_ICON} from "../../../../../../../../../../interfaceGlobal";
import {Input} from "../../../../../../../../../../common/form/input";
import {useTranslation} from "react-i18next";

export const EcommerceSyncOrderSearch = ()=>{
  const { t } = useTranslation()
    const {updateOrderMethods, updateOrder} = useReportUpdateOrder()
    return (
        <Input
            className="order-ecommerce-sync-search-form__input-wide"
            icon={GLOBAL_ICON.searchMd}
            placeholder={t('find_stall')}
            value={updateOrder.keyword}
            onChange={(e)=>updateOrderMethods.onChangeKeyword(e.target.value)}
        />
    )
}