const config = {
  env: "DEV",
  PHONE: "1900 1511",
  REACT_APP_FACEBOOK_APP_ID: "451884059004448",
  REACT_APP_FACEBOOK_APP_SECRET: "6095808955eb54521f358ca21386e606",
  // env: "PROD"
  API: "https://api-dev.upos.vn/api/v3",
  API_V2: 'https://api-dev.upos.vn/api/v2',
  API_FB: 'https://api-node.upos.vn',
  WS: 'https://webhook-dev.upos.vn/',
  HREF: 'http://dev-v2.upos.vn',
  CSKH: '0916967849',
  TRACKING_ID : 'G-MRG9JFQ3JS',
}
export default config;
