import {useReducer} from "react";
import {ecommerceReportActions, ecommerceReportInitState, ecommerceReportReducer} from "../provider/_reducer";
import {sendRequestAuth} from "../../../../../api/api";
import config from "../../../../../config";
import {getDateFromNow} from "../../../utils/date";
import {convertDateTimeToApiFormat, formatDatetimeV2} from "../utils/formatDate";
import {PATH} from "../../../../../const/path";
import {useNavigate} from "react-router-dom";

export const useEcommerceReport = ()=>{
    const navigate = useNavigate()
    const [state,dispatch] = useReducer(ecommerceReportReducer,ecommerceReportInitState)
    const dateStart = getDateFromNow(-6)
    const dateEnd = getDateFromNow(0,'end')
    const queriesDashBoard = {
        ecom_type:'',
        ecom_seller_id:'',
        start_date:convertDateTimeToApiFormat(formatDatetimeV2(dateStart)),
        end_date:convertDateTimeToApiFormat(formatDatetimeV2(dateEnd))
        // start_date:'2023-06-01',
        // end_date:'2023-07-01'
    }
    const queriesOrder = {
        ecom_type:'',
        ecom_seller_id:'',
        start_date:convertDateTimeToApiFormat(formatDatetimeV2(dateStart)),
        end_date:convertDateTimeToApiFormat(formatDatetimeV2(dateEnd)),
        status:''
        // start_date:'2023-06-01',
        // end_date:'2023-07-01'
    }
    const queriesProduct = {
        ecom_type:'',
        ecom_seller_id:'',
        start_date:convertDateTimeToApiFormat(formatDatetimeV2(dateStart)),
        end_date:convertDateTimeToApiFormat(formatDatetimeV2(dateEnd)),
        sync_status:'',
        sort_type:'',
        // start_date:'2023-01-01',
        // end_date:'2023-07-01'
    }
    const transformQueries = (qs)=>{
        let queryString = '?'
        let i = 0
        for (const [key, value] of Object.entries(qs)) {
            queryString += `${i > 0 ? '&' : ''}${key}=${value}`
            i++
        }
        return queryString
    }
    const fetchOrigin = async ()=>{

        const res = await Promise.all([
            sendRequestAuth('get',`${config.API}/ecommerce/list?keyword&ecom_type=&ecom_seller_id&start_date&end_date&status=1&start=0&per_page=200`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/important-indicator${transformQueries(queriesDashBoard)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/ratio-product${transformQueries(queriesDashBoard)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/ratio-order${transformQueries(queriesDashBoard)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/dashboard/top-product${transformQueries(queriesDashBoard)}`),

            //======ORDER=====
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/revenue${transformQueries(queriesOrder)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/ratio${transformQueries(queriesOrder)}`),
            sendRequestAuth('get',`${config.API}/ecommerce/report/order/revenue-list${transformQueries(queriesOrder)}`),

            //======PRODUCT=====
            sendRequestAuth('get',`${config.API}/ecommerce/report/product/dashboard${transformQueries(queriesProduct)}`)
        ])
        if(res[0].data?.success){
          if(res[0].data.data?.length <= 0) navigate(PATH.ECOMMERCES_ONBOARDING)
            dispatch({
                type:ecommerceReportActions.STORES_ECOMMERCE_LIST,
                payload:{
                    list:res[0].data?.data,
                    listOrigin: res[0].data?.data
                }
            })
        }
        if(res[1].data?.success){
            dispatch({
                type:ecommerceReportActions.IMPORTANT_ECOMMERCE_LIST,
                payload:{
                    meta:res[1].data?.meta,
                    data: res[1].data?.data
                }
            })
        }
        if(res[2].data?.success){
            dispatch({
                type:ecommerceReportActions.RATIO_PRODUCT_ECOMMERCE_LIST,
                payload:{
                    data: res[2].data?.data
                }
            })
        }
        if(res[3].data?.success){
            dispatch({
                type:ecommerceReportActions.RATIO_ORDER_ECOMMERCE_LIST,
                payload:{
                    meta:res[3].data?.meta,
                    data: res[3].data?.data
                }
            })
        }
        if(res[4].data?.success){
            dispatch({
                type:ecommerceReportActions.TOP_PRODUCT_ECOMMERCE_LIST,
                payload:{
                    meta:res[4].data?.meta,
                    data: res[4].data?.data
                }
            })
        }
        if(res[5].data?.success){
            dispatch({
                type:ecommerceReportActions.REVENUE_ORDER_LIST,
                payload:{
                    meta:res[5].data?.meta,
                    data: res[5].data?.data
                }
            })
        }
        if(res[6].data?.success){
            dispatch({
                type:ecommerceReportActions.RATIO_ORDER__STATUS_LIST,
                payload:{
                    data: res[6].data?.data
                }
            })
        }
        if(res[7].data?.success){
            dispatch({
                type:ecommerceReportActions.RATIO_ORDER__TABLE_LIST,
                payload:{
                    data: res[7].data?.data
                }
            })
        }
        if(res[8].data?.success){
            dispatch({
                type:ecommerceReportActions.REPORT_PRODUCT_LIST,
                payload:res[8].data?.data
            })
        }
    }

    return{
        provider:{
            state,
            dispatch
        },
        methods:{
            onFetchOrigin: fetchOrigin
        },
    }
}