import React from 'react';
import styled from "styled-components";
import {Spinner} from "../../../../../../../../../common/spinner";
import {Text} from "../../../../../../../../../common/text";
import {useTranslation} from "react-i18next";

const Loading = () => {
  const { t } = useTranslation()
  return (
    <StyledLoading>
      <Spinner size={54} thickness={6} />
      <Text className={'ecom-product-quick-connect--loading'}>{t('e_com_system_processing')}</Text>
      <Text className={'ecom-product-quick-connect--loading'}>{t('e_com_waiting')}</Text>
    </StyledLoading>
  )
}

export default Loading


const StyledLoading = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
  
  display: flex;
  flex-direction: column;
  
  .ecom-product-quick-connect {
    &--loading {
      margin-top: 32px;
    }
  }
`
