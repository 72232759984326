import { Text } from '../../../../common/text'
import { useContext } from 'react'
import { OrderContext } from '../../provider/_context'
import { fNumber } from '../../../../util/formatNumber'
import { useTranslation } from 'react-i18next'
import useGlobalContext from "../../../../containerContext/storeContext";

export const PrintOrderDetail = () => {
  const { pageState } = useContext(OrderContext)
  const { printDetail } = pageState
  const [state] = useGlobalContext()
  const {shopInfo} = state
  let details = [printDetail?.details]
  if(+printDetail?.has_inventory != 1){
    if(printDetail?.details?.includes('\n')){
      details = printDetail?.details.split('\n')
    }
  }
  const {t} = useTranslation()
  return (
    <div style={{ display: 'none' }}>
      <div id={'order-detail-print'}>
        <div style={{ textAlign: 'center', margin: '24px 0' }}>
          <Text fontWeight={600} fontSize={24}>{t('order_detail_upper')}</Text>
        </div>
        <table style={{ width: '100%' }}>
          <tbody style={{ width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}>{t('seller_name')}</Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '30%' }}>{ +printDetail?.shipping_status_id === 8 ? shopInfo?.store_name : printDetail?.creator}</td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('order_id')}</Text>
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}>{printDetail?.id}</td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}>{t('address')}</Text></td>
            <td
              style={{ padding: '0 8px', textAlign: 'left', width: '30%' }}>
              { +printDetail?.shipping_status_id === 8 ?
                  <>{shopInfo?.store_address || '---'} {shopInfo?.ward_name || '---'} {shopInfo?.district_name || '---'} {shopInfo?.city_name || '---'}</>

                  : printDetail?.sender_address || '---'}
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('order_date_created')}</Text>
            </td>
            <td style={{
              padding: '0 8px',
              textAlign: 'left',
              width: '25%'
            }}>{cellCodeOrderFormatDateTime(printDetail?.dt_created)}</td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}>{t('phone')}</Text>
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '30%' }}>
              {+printDetail?.shipping_status_id === 8 ? shopInfo?.store_phone || '---' : printDetail?.sender_phone || '---'}
            </td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('buyer')}</Text></td>
            <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}>{printDetail?.customer_name || '---'}</td>
          </tr>
          {
            +printDetail?.has_inventory === 1
              ?
              <>
                <tr style={{ width: '100%' }}>
                  <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}>Email</Text>
                  </td>
                  <td style={{
                    padding: '0 8px',
                    textAlign: 'left',
                    width: '30%'
                  }}>
                    {+printDetail?.shipping_status_id === 8 ? shopInfo?.store_email || '---' : printDetail?.sender_email || '---'}
                  </td>
                  {
                    +printDetail?.shipping_status_id !== 8 && <>
                      <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('warehouse_export')}</Text></td>
                      <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}>{printDetail?.warehouse_name}</td>
                    </>
                  }

                </tr>
                {
                  +printDetail?.shipping_status_id !== 8 &&  <tr style={{ width: '100%' }}>
                    <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}></Text></td>
                    <td style={{ padding: '0 8px', textAlign: 'left', width: '30%' }}></td>
                    <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('order_status')}</Text></td>
                    <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text
                        color={'rgb(26, 148, 255)'}>{printDetail?.shipping_status_name}</Text></td>
                  </tr>
                }

              </>
              :
              <>
                <tr style={{ width: '100%' }}>
                  <td style={{ padding: '0 8px', textAlign: 'left', width: '20%' }}><Text fontWeight={600}>Email</Text>
                  </td>
                  <td style={{
                    padding: '0 8px',
                    textAlign: 'left',
                    width: '30%'
                  }}>{printDetail?.sender_email || '---'}</td>
                  <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text fontWeight={600}>{t('order_status')}</Text></td>
                  <td style={{ padding: '0 8px', textAlign: 'left', width: '25%' }}><Text
                    color={'rgb(26, 148, 255)'}>{printDetail?.shipping_status_name}</Text></td>
                </tr>
              </>
          }

          </tbody>
        </table>
        {
          +printDetail?.has_inventory == 1
            ?
            <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '16px' }}>
              <thead style={{ width: '100%' }}>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
                fontWeight={600}>{t('product_sku')}</Text></th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
                fontWeight={600}>{t('product_page_product_name')}</Text>
              </th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
                fontWeight={600}>{t('unit')}</Text></th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'center' }}><Text
                fontWeight={600}>{t('quantity')}</Text>
              </th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
                fontWeight={600}>{t('price')} (VNĐ)</Text>
              </th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
                fontWeight={600}>{t('discount')} (VNĐ)</Text></th>
              <th style={{ border: '1px solid rgb(0, 8, 29)', padding: '8px', textAlign: 'right' }}><Text
                fontWeight={600}>{t('money')} (VNĐ)</Text></th>
              </thead>
              <tbody style={{ width: '100%' }}>
              {!!printDetail?.order_details && printDetail.order_details.map((item) => (
                <tr style={{ width: '100%' }}>
                  <td style={{
                    border: '1px solid rgb(0, 8, 29)',
                    padding: '8px',
                    textAlign: 'center'
                  }}>{item?.product_model}</td>
                  <td style={{
                    border: '1px solid rgb(0, 8, 29)',
                    padding: '8px',
                    textAlign: 'center'
                  }}>{item?.product_name}</td>
                  <td style={{
                    border: '1px solid rgb(0, 8, 29)',
                    padding: '8px',
                    textAlign: 'center'
                  }}>{item?.unit_name}</td>
                  <td
                    style={{
                      border: '1px solid rgb(0, 8, 29)',
                      padding: '8px',
                      textAlign: 'center'
                    }}>{item?.quantity}</td>
                  <td style={{
                    border: '1px solid rgb(0, 8, 29)',
                    padding: '8px',
                    textAlign: 'right'
                  }}>{fNumber(item?.price)}</td>
                  <td
                    style={{
                      border: '1px solid rgb(0, 8, 29)',
                      padding: '8px',
                      textAlign: 'right'
                    }}>{fNumber(item?.discount)}</td>
                  <td style={{
                    border: '1px solid rgb(0, 8, 29)',
                    padding: '8px',
                    textAlign: 'right'
                  }}>{fNumber(item?.total_price)}</td>
                </tr>
              ))
              }
              </tbody>
            </table>
            :
            <div style={{
              marginTop: '32px',
              padding: '6px 8px',
              border: '1px solid #EFF2F8',
              borderRight: 'none',
              borderLeft: 'none'
            }}>
              <Text as={'p'} fontWeight={600} style={{ margin: 0 }}>{t('product_description')}</Text>
              {
                details?.map((detail,index) => (<Text as={'p'} key={index} style={{ margin: 0 }}>{detail}</Text>))
              }
            </div>
        }

{
          <div style={{width: '30%', float: 'right', marginTop: '1rem'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Text fontWeight={600} style={{marginRight: '48px'}}>
                {t('service_fee')}:
              </Text>
              <Text style={{marginRight: '8px'}}>
                {fNumber(
                  Number(printDetail?.service_fee) +
                    Number(printDetail?.service_vat_fee),
                )}
              </Text>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Text fontWeight={600} style={{marginRight: '48px'}}>
                {t('{vat_fee_total}')}:
              </Text>
              <Text style={{marginRight: '8px'}}>
                {fNumber(printDetail?.order_vat_value)}
              </Text>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Text fontWeight={600} style={{marginRight: '48px'}}>
                {t('total_amount')} (VNĐ):
              </Text>
              <Text style={{marginRight: '8px'}}>
                {fNumber(printDetail?.total_amount)}
              </Text>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const cellCodeOrderFormatDateTime = dateTimeParam => {
  const dateTimeSplit = dateTimeParam ? dateTimeParam.split(' ') : []
  const ymd = dateTimeSplit[0] ? dateTimeSplit[0].split('-') : []
  const dmy = `${ymd[2] || '--'}/${ymd[1] || '--'}/${ymd[0] || '--'}`
  return `${dmy}`.trim()
}