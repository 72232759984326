export const initProductState = {
  category: {
    isShowModal: false,
    filter_obj: {
      keyword: "",
      category: "",
      status: "",
    },
    data_grid: [],
    page_obj: {
      start: 0,
      per_page: 20,
      totals: 1,
      current_page: 1,
      all_page: 1,
    },
    stateWidget: {
      isShow: false,
      isEdit: false,
      data: {
        category_code: "",
        category_name: "",
        parent_id: "",
        category_note: "",
        id: "",
      },
      objError: {
        category_code: "",
        category_name: "",
        parent_id: "",
        category_note: "",
      },
    },
  },
  unit: {
    stateWidget: {
      isShow: false,
      isEdit: false,
      data: {
        unit_name: "",
        unit_short_name: "",
        id: "",
      },
      objError: {
        unit_name: "",
        unit_short_name: "",
      },
    },
    filter_obj: {
      keyword: "",
    },
    page_obj: {
      start: 0,
      per_page: 20,
      totals: 1,
      current_page: 1,
      all_page: 1,
    },
    data_grid: [],
  },
  product: {
    modalUnit: {
      isShow: false,
      isEdit: false,
      data: {
        unit_name: "",
        unit_short_name: "",
        id: "",
      },
      objError: {
        unit_name: "",
        unit_short_name: "",
      },
      page_obj: {
        start: 0,
        per_page: 20,
        totals: 1,
      },
    },
    modalCategory: {
      isShow: false,
      isEdit: false,
      data: {
        category_code: "",
        category_name: "",
        parent_id: "",
        category_note: "",
        id: "",
      },
      objError: {
        category_code: "",
        category_name: "",
        parent_id: "",
        category_note: "",
      },
    },
    productInfo: {
      initWarehouse: false,
      isMulti: false,
      id: "",
      product_name: "",
      product_model: "",
      barcode: "",
      unit: "",
      category: {},
      category_id: "",
      description: "",
      product_image: "",
      image_thumb: "",
      warehouse: {},
      status: "",
      price: "",
      weight: { label: "kg", value: "kg" },
      wholesale_price: "",
      origin_price: "",
      arr_product_details: [
        // {
        //   id: "",
        //   product_name: "",
        //   sku: "",
        //   barcode: "",
        //   unit: "",
        //   weight: "",
        //   price: "",
        //   supplier_price: "",
        //   wholesale_price: "",
        //   cost_price: "",
        //   warehouse_quantity: "",
        //   image_thumb: "",
        //   status: "",
        // },
      ],
      arr_attr: [
        // {
        //   id: "",
        //   name: "",
        //   value: "",
        //   arr_value: [
        //     {
        //       id: "",
        //       value: "",
        //     },
        //   ],
        // },
      ],
    },
    attr_value: [
      { title: "", value: "" },
      // { title: "Thuộc tinh", value: "Xanh, Đỏ" },
      // { title: "Chất liệu", value: "Dù, Vải" },
    ],
    editVariable: {
      isShow: false,
      data: {},
    },
    filter_obj: {
      keyword: "",
      status: "",
      category_id: "",
    },
    page_obj: {
      start: "",
      per_page: 20,
      totals: 1,
      current_page: 1,
      all_page: 1,
    },
    data_grid: [],
  },
};
