import React from 'react'

export const FEED_BACK_ICONS = {
  cloud: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0125 5C17.9673 4.99997 15.9626 5.56946 14.2228 6.64465C12.4831 7.71984 11.0771 9.25825 10.1625 11.0875C9.39899 12.5992 9.00082 14.2689 9 15.9625C9.00437 16.22 8.91162 16.4697 8.74022 16.662C8.56882 16.8542 8.33133 16.9749 8.075 17C7.93751 17.0103 7.79937 16.9921 7.66925 16.9465C7.53913 16.9009 7.41985 16.8289 7.31889 16.735C7.21794 16.6411 7.1375 16.5273 7.08262 16.4008C7.02774 16.2743 6.99961 16.1379 7 16C7.00051 14.6026 7.22415 13.2143 7.6625 11.8875C7.69175 11.8029 7.69776 11.712 7.67988 11.6243C7.66201 11.5366 7.62091 11.4552 7.56089 11.3888C7.50086 11.3224 7.42411 11.2733 7.33864 11.2467C7.25317 11.2201 7.16212 11.2169 7.075 11.2375C5.34109 11.6674 3.80093 12.6652 2.69996 14.072C1.59899 15.4788 1.00055 17.2136 1 19C1 23.4 4.725 27 9.1125 27H20C22.9174 27.0017 25.7159 25.8443 27.78 23.7826C29.8441 21.7209 31.0046 18.9236 31.0063 16.0063C31.0079 13.0889 29.8506 10.2903 27.7888 8.22624C25.7271 6.16217 22.9299 5.00166 20.0125 5ZM23.95 18.95C23.758 19.1335 23.503 19.2364 23.2375 19.2375C22.9755 19.2371 22.7242 19.1338 22.5375 18.95L20 16.4125V24C20 24.2652 19.8946 24.5196 19.7071 24.7071C19.5196 24.8946 19.2652 25 19 25C18.7348 25 18.4804 24.8946 18.2929 24.7071C18.1054 24.5196 18 24.2652 18 24V16.4125L15.4625 18.95C15.2723 19.1224 15.0231 19.215 14.7664 19.2086C14.5098 19.2023 14.2655 19.0976 14.0839 18.9161C13.9024 18.7345 13.7977 18.4902 13.7914 18.2336C13.785 17.9769 13.8776 17.7277 14.05 17.5375L18.2875 13.2875C18.4771 13.0997 18.7332 12.9944 19 12.9944C19.2668 12.9944 19.5229 13.0997 19.7125 13.2875L23.95 17.5375C24.137 17.725 24.242 17.979 24.242 18.2437C24.242 18.5085 24.137 18.7625 23.95 18.95Z" fill="#1E9A98"/>
    </svg>
  ),
  dismiss: (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L1 13M1 1L13 13" stroke="#F4F7FC" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  star: (
    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.38574 4.55176H3.21973L3.15137 2.96582L4.4502 3.79297L4.87402 3.15039L3.43848 2.3916L4.87402 1.63965L4.4502 0.99707L3.15137 1.82422L3.21973 0.245117H2.38574L2.4541 1.82422L1.15527 0.99707L0.731445 1.63965L2.18066 2.39844L0.731445 3.16406L1.15527 3.80664L2.4541 2.97949L2.38574 4.55176Z" fill="#FF424E"/>
    </svg>
  ),
  excel: (
    <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.931 9.51808C29.931 8.98765 29.7203 8.47894 29.3452 8.10387L21.8272 0.585786C21.4521 0.210713 20.9434 0 20.413 0H4C2.89543 0 2 0.89543 2 2V34C2 35.1046 2.89543 36 4 36H27.931C29.0356 36 29.931 35.1046 29.931 34V9.51808Z" fill="#F2F7FC"/>
      <path d="M21.2412 0V6.68966C21.2412 7.79423 22.1366 8.68966 23.2412 8.68966H29.9309L21.2412 0Z" fill="#B5CBE8"/>
      <path d="M15 13.5H18.7054V15.3857H15V13.5Z" fill="#B5CBE8"/>
      <path d="M15 16.501H18.7054V18.3867H15V16.501Z" fill="#B5CBE8"/>
      <path d="M15 19.502H18.7054V21.3877H15V19.502Z" fill="#B5CBE8"/>
      <path d="M15 22.8535H18.7054V24.7392H15V22.8535Z" fill="#B5CBE8"/>
      <path d="M20 13.5H23.7054V15.3857H20V13.5Z" fill="#B5CBE8"/>
      <path d="M20 16.501H23.7054V18.3867H20V16.501Z" fill="#B5CBE8"/>
      <path d="M20 19.502H23.7054V21.3877H20V19.502Z" fill="#B5CBE8"/>
      <path d="M20 22.8535H23.7054V24.7392H20V22.8535Z" fill="#B5CBE8"/>
      <rect y="11" width="16" height="16" rx="1" fill="#198A73"/>
      <path d="M4 16H12V17H4V16Z" fill="white"/>
      <path d="M4 18H12V19H4V18Z" fill="white"/>
      <path d="M4 20H12V21H4V20Z" fill="white"/>
      <path d="M4 22H10V23H4V22Z" fill="white"/>
    </svg>
  ),
  remove: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.164 6.01828C12.2836 6.01828 12.3922 6.07048 12.478 6.15868C12.5579 6.25288 12.5982 6.36988 12.5865 6.49348C12.5865 6.53428 12.2667 10.5782 12.0841 12.2804C11.9697 13.325 11.2963 13.9592 10.2862 13.9766C9.50954 13.994 8.75037 14 8.00286 14C7.20926 14 6.43316 13.994 5.67982 13.9766C4.70358 13.9532 4.0296 13.3076 3.92106 12.2804C3.73316 10.5722 3.41922 6.53428 3.41339 6.49348C3.40755 6.36988 3.44723 6.25288 3.52776 6.15868C3.60712 6.07048 3.72149 6.01828 3.8417 6.01828H12.164ZM9.23942 2C9.76985 2 10.2437 2.3702 10.3808 2.89819L10.4788 3.33619C10.5582 3.69318 10.8675 3.94578 11.2234 3.94578H12.9729C13.2063 3.94578 13.4006 4.13958 13.4006 4.38618V4.61417C13.4006 4.85477 13.2063 5.05457 12.9729 5.05457H3.0289C2.7949 5.05457 2.60059 4.85477 2.60059 4.61417V4.38618C2.60059 4.13958 2.7949 3.94578 3.0289 3.94578H4.77833C5.1337 3.94578 5.44297 3.69318 5.52291 3.33679L5.61453 2.92759C5.75691 2.3702 6.22548 2 6.76175 2H9.23942Z" fill="#7E8299"/>
    </svg>
  )
}