export const getDateFromNow = (n,otp = {type: 'start'}) => {
  let date = new Date()
  const res = date.setDate(date.getDate() + n )
  return new Date(res)
}
export const getMonthFromNow = (n) => {
  let date = new Date()
  return (+date.getMonth() + 1)
}

export const getYearFromNow = _ => {
  let date = new Date()
  return date.getFullYear()
}

export const daysInMonth =  (month, year) => {
  return new Date(year, month, 0).getDate();
}
export const cellCodeOrderFormatDateTime = dateTimeParam => {
  const dateTimeSplit = dateTimeParam ? dateTimeParam.split(' ') : []
  const ymd = dateTimeSplit[0] ? dateTimeSplit[0].split('-') : []
  const dmy = `${ymd[2] || '--'}/${ymd[1] || '--'}/${ymd[0] || '--'}`
  const hms = dateTimeSplit[1] ? dateTimeSplit[1].split(':') : []
  const hm = `${hms[0]}:${hms[1]}`
  return `${dmy} ${hm}`.trim()
}