import {useEffect, useState} from 'react'
import ButtonSetting from '../BtnSetting/buttonSetting'
import Notification from '../NotiComp/notification'
import Account from '../Account'
import UserInformation from '../UserInformation'
import cls from 'clsx'
import css from './index.module.scss'
import * as React from 'react'
import Popover from '@mui/material/Popover'
import useGlobalContext from '../../containerContext/storeContext'
import {QuickAccesses} from './_components'
import FeedBack from '../FeedBack'
import {Text} from '../../common/text'
import {PackageRenewal} from '../../layouts/packageRenewal'
import {Tag} from '../../common/tag'
import styled from 'styled-components'
import { LanguageSelect } from '../Language'

export default function Header() {
    const [state] = useGlobalContext()
    const [anchorEl, setAnchorEl] = useState()
    const handleThunder = e => setAnchorEl(e.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

  return state.isLogin ? (
    <div className={cls(css.wrapper)}>
      <div className={cls(css.left)}>
        <Text as="a" className={cls(css.linkLogo)} href="/">
          <img className={cls(css.logo)} src="/img/logo-light.png" alt="logo"/>
        </Text>
        <StyleHeader>
          <Tag className={'header-beta-logo'}>
            BETA
          </Tag>
          {/*{<img className={'logo-tet'} src="/img/noel/hoamai.png" alt="logo"/>}*/}
        </StyleHeader>
      </div>
      {state.user.package.is_update_address != '0' &&
      <div className={cls(css.right)}>

        <StyleGuideHeader>
        <div className={'guide-screen-boder-round'}>
          <div className={state.user.is_first_login == 1 ? 'guide-screen-boder-header01' : 'guide-screen-boder-round'}>
            <PackageRenewal/>
            <div className={cls(css.thunder)} data-active={open}>
              <svg
                aria-describedby={id}
                variant="contained"
                onClick={handleThunder}
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 4.5L19.5 4.5M16.5 10L21 10M14 15.5L19 15.5M6 19L12.5964 9.76499C12.8884 9.35624 13.0344 9.15187 13.0281 8.98147C13.0226 8.83312 12.9514 8.69489 12.8339 8.60418C12.6989 8.5 12.4478 8.5 11.9454 8.5L7 8.5L8 0.999999L1.40357 10.235C1.1116 10.6438 0.965619 10.8481 0.971938 11.0185C0.977439 11.1669 1.04858 11.3051 1.1661 11.3958C1.30108 11.5 1.55224 11.5 2.05455 11.5L7 11.5L6 19Z"
                  stroke="white"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{top: 18}}
              >
                <QuickAccesses onClose={handleClose}/>
              </Popover>
            </div>
          </div>
          <div className={state.user.is_first_login == 1 ? 'guide-screen-boder-header02' : 'guide-screen-boder-round'}>
            <ButtonSetting/>
            <div className={'guide-screen-boder-header04'}>
              <Notification/>
            </div>
          </div>

          <div className={state.user.is_first_login == 1 ? 'guide-screen-boder-header03' : 'guide-screen-boder-round'}>
          <LanguageSelect />
          <Account/>
          <UserInformation/>
          </div>
        </div>
        </StyleGuideHeader>

                <FeedBack/>
            </div>
            }
        </div>
    ) : null
}
const StyleHeader = styled.div`
  .header-beta-logo{
      position: relative;
      top: -8px;
      left: 4px;
      height: 14px;
      padding: 0 8px;
      background: linear-gradient(82.85deg, #F40385 -3.66%, #F97000 142.34%);
      font-size: 9px;
      line-height: 14px;
  }
  .logo-tet{
    height: 100%;
    position: absolute;
    left: 182px;
    top: 0px;
  }
`
const StyleGuideHeader = styled.div`
  .guide-screen-boder-round{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .guide-screen-boder-header01{
    border: 1px dashed #FFFFFF;
    padding: 4px;
    text-align: center;
    display: flex;
    margin-right: 12px;
    border-radius: 6px;
    height: 47px;
    div {
      margin: 0px !important;
    }
  }
  .guide-screen-boder-header02{
    border: 1px dashed #FFFFFF;
    width: 82px;
    height: 47px;
    left: 255px;
    border-radius: 6px;
    display: flex;
    padding-top: 6px;
    span {
          padding-top: 4px;
    }
    margin-right: 12px;
  }
  .guide-screen-boder-header03{
    border: 1px dashed #FFFFFF;
    height: 47px;
    border-radius: 6px;
    display: flex;
    div {
      margin: 6px 7px 7px 2px;
    }
    margin-right: 2rem;
  }
  .guide-screen-boder-header04{
    cursor: pointer;
    height: 32px;
    padding-top: 6px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover{
       background: rgba(43, 184, 169, 0.1);
       .notice-component-popover {
           .popper-notice__toggle  {
           svg {
          color: #2bb8a9 !important;

          path {
            stroke: #2bb8a9;
          }
        }
        }
       }
       
    }
  }
  @media screen and (max-width: 1366px) {
    .guide-screen-boder-header01 , .guide-screen-boder-header02, .guide-screen-boder-header03 {
      height: 43px;
    }
  }
`