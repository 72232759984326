import {useReducer} from "react";
import {reportOverviewInitState, reportOverviewReducer} from "../provider/_reducer";

export const useReportOverview = ()=>{
    const [state, dispatch] = useReducer(reportOverviewReducer,reportOverviewInitState)
    return{
        provider:{
            state,
            dispatch
        },
    }
}