import { sendRequestAuth } from "api/api"
import config from "config"
import { useContext } from "react"
import { NotificationContext } from "../notification"
import useGlobalContext from "../../../containerContext/storeContext";
import {noticeCompAction} from "../provider/_action";

const useNotificationActions = () => {
  const {pageState,pageDispatch} = useContext(NotificationContext)
  const {activeTab, notice} = pageState
  const [, GlobalDispatch] = useGlobalContext()
  const handleFetchList = async (tabId, allowLoading = true, perPage = 20) => {
    const res = await sendRequestAuth('get', `${config.API}/notices/list?type=${tabId}&per_page=${perPage}&start=0`)
    if (res.data.success) {
      setListNotification(tabId,res)
      pageDispatch({type:noticeCompAction.GET_TOTAL_NOTICE,payload:{
          all: res.data.meta.total_unread,
          my: res.data.meta.total_unread_my,
          system: res.data.meta.total_unread_system
        }})
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          all:res.data.meta.total_unread,
          my: res.data.meta.total_unread_my,
          system: res.data.meta.total_unread_system,
          noticeManagement:true,
        }})
      pageDispatch({type:noticeCompAction.SET_CAN_LOAD_MORE_NOTICE,payload: +perPage >= res?.data?.meta?.total ? false : true})
    }
  }
  const setListNotification = (activeTab,data)=>{
    switch (activeTab) {
      case 0 :
        pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
            all:{
              list:data.data?.data,
              perPage: data?.data?.meta?.per_page,
              total: data?.data?.meta?.total,
            }
          }})

        break;
      case 1:
        pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
            system:{
              list:data.data?.data,
              perPage: data?.data?.meta?.per_page,
              total: data?.data?.meta?.total,
            }
          }})
        break;
      case 2:
        pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
            my:{
              list:data.data?.data,
              perPage: data?.data?.meta?.per_page,
              total: data?.data?.meta?.total,
            }
          }})
        break;
      default:
        break;
    }
  }
  const handleTabChange=(tabId,tab)=>{
    pageDispatch({type:noticeCompAction.SET_ACTIVE_TAB_TYPE,payload:{
      tab:tabId,
        type:tab
      }})
  }
  const handleReadAllList = async (page=20)=>{
    const res = await Promise.all([
      sendRequestAuth('get', `${config.API}/notices/list?per_page=${page}&start=0&type=0`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=${page}&start=0&type=1`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=${page}&start=0&type=2`),
    ])
    if (res[0].data.success && res[1].data.success && res[2].data.success){
      pageDispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
          all:{
            list:res[0].data.data,
            perPage:res[0].data.meta.per_page,
            total:res[0].data.meta.total,
          },
          my:{
            list:res[2].data.data,
            perPage:res[2].data.meta.per_page,
            total:res[2].data.meta.total,
          },
          system:{
            list:res[1].data.data,
            perPage:res[1].data.meta.per_page,
            total:res[1].data.meta.total,
          }
        }})
      pageDispatch({type:noticeCompAction.GET_TOTAL_NOTICE,payload:{
          all: res[0].data.meta.total_unread,
          my: res[0].data.meta.total_unread_my,
          system: res[0].data.meta.total_unread_system
        }})
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          all:res[0].data.meta.total_unread,
          my: res[0].data.meta.total_unread_my,
          system: res[0].data.meta.total_unread_system,
          noticeManagement:true
        }})
    }
  }
  const handleAllRead = async () => {
    const all = notice?.all?.list
    const findIsRead = all.filter(filter => +filter.is_read === 0)
    if(findIsRead.length > 0){
      GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
          noticeManagement:false
        }})
      const response =  await sendRequestAuth('get', `${config.API}/notices/read-all`)
      if(response?.data?.success) handleReadAllList()
    }
  }

  const onLoadMore = async (id,perPage=20,currentTotal) => {
    pageDispatch({type:noticeCompAction.SET_CAN_LOAD_MORE_NOTICE,payload:false})
    if ( perPage >= currentTotal) return
    await handleFetchList(activeTab, false, perPage + 20)
  }

  const handleRead = async (id,data,page)=>{
    GlobalDispatch({type:'NOTICE_TOTAL_UNREAD',payload:{
        noticeManagement:false
      }})
    const response = await sendRequestAuth('get', `${config.API}/notices/detail/${id}`)
    if(response?.data?.success){
      pageDispatch({type:noticeCompAction.GET_DETAIL_NOTICE,payload:response?.data?.data})
      const isPopup = response?.data?.data?.show_type
      if(+isPopup === 0 && response?.data?.data?.link === null) pageDispatch({type: noticeCompAction.OPEN_MODAL_NOTIFICATION,payload:true})
      else if(response?.data?.data?.link !== null) window.open( response?.data?.data?.link,'_blank')
      if(+data?.is_read === 0) handleReadAllList(page)

    }
  }

  return {
    handleTabChange,
    handleAllRead,
    onLoadMore,
    handleRead,
  }
}

export default useNotificationActions