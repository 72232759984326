import {Text} from 'common/text'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import useOrderFilterForm from 'Pages/refactorOrder/hooks/useOrderFilterForm'
import {ORDER_FILTER_TAG_FIELDS} from 'Pages/refactorOrder/interfaces/_constants'
import {StyledOrderTags} from './_styled'
import {OrderTag} from './_tag'
import { useTranslation } from 'react-i18next'
import {replaceAllCustom} from "../../../../util/functionUtil";

export const OrderTags = ({...props}) => {
  const {
    dateTime,
    duplicate,
    employee,
    product,
    shippingPartner,
    shippingStatus,
    source,
    warehouse,
    functions,
    search,
    advancedSearch
  } = useOrderFilterForm()
  const {t} = useTranslation()
  const shouldShowResetAll = functions.hasFilter()

  const handleDeleteAll = () => functions.filterTagDeleteAll()

  return (
    <StyledOrderTags {...props}>
      {dateTime?.activeValue?.value && dateTime?.activeValue?.type?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete('dateTime.current')}
        >
          {t(dateTime.activeValue.type.name)}: {dateTime.activeValue.value}
        </OrderTag>
      )}
      {search.activeValue && (
        <OrderTag
          onDelete={() => functions.filterTagDelete('keyword')}
        >
          {t('order_id')}: {replaceAllCustom(search.activeValue,',',', ')}
        </OrderTag>
      )}
      {advancedSearch.customer && advancedSearch.customer.activeValue && (
        <OrderTag
          onDelete={() => functions.filterTagDelete('cashbook_customer')}
        >
          {t('cashbook_customer')}: {replaceAllCustom(advancedSearch.customer.activeValue,',',', ')}
        </OrderTag>
      )}
      {advancedSearch.activeLiveVideoId && (
        <OrderTag
          onDelete={() => functions.filterTagDelete('liveVideoID')}
        >
          {t('liveVideoID')}: {replaceAllCustom(advancedSearch.activeLiveVideoId,',',', ')}
        </OrderTag>
      )}
      {Array.isArray(employee.activeValue?.value) &&
        employee.activeValue.type.name !== 'team_of_employees' && (
          <OrderTag
            onDelete={() =>
              functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[8])
            }
          >
            {t('team_of_employees')}:{' '}
            {employee.activeValue.type.name}
          </OrderTag>
        )}
      {Array.isArray(employee.activeValue?.value) &&
        employee.activeValue.value.length > 0 && (
          <OrderTag
            onDelete={() =>
              functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[1])
            }
          >
            {t('employee')}:{' '}
            {employee.activeValue.value.map(item => item?.name).join(', ')}
          </OrderTag>
        )}
      {Array.isArray(shippingStatus.activeValue) &&
        shippingStatus.activeValue.length > 0 && (
          <OrderTag
            onDelete={() =>
              functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[2])
            }
          >
            {t('order_status')}:{' '}
            {shippingStatus.activeValue.map(item => t('delivery_status_'+item.value)).join(', ')}
          </OrderTag>
        )}
      {shippingPartner?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[3])}
        >
          {t('shipping_company')}: {shippingPartner.activeValue.name}
        </OrderTag>
      )}
      {Array.isArray(product.activeValue) && product.activeValue.length > 0 && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[4])}
        >
          {t('product')}:{' '}
          {product.activeValue
            .map(item => item?.data?.sku || 'unknown')
            .join(', ')}
        </OrderTag>
      )}
      {source?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[5])}
        >
          {t('source_order')}: {source.activeValue.name}
        </OrderTag>
      )}
      {warehouse?.activeValue?.name && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[6])}
        >
          {t('warehouse')}: {warehouse.activeValue.name}
        </OrderTag>
      )}
      {duplicate?.activeValue?.value === '1' && (
        <OrderTag
          onDelete={() => functions.filterTagDelete(ORDER_FILTER_TAG_FIELDS[7])}
        >
          {t(duplicate.activeValue.name)}
        </OrderTag>
      )}
      {shouldShowResetAll && (
        <Text
          as="b"
          color={THEME_SEMANTICS.delivering}
          lineHeight={28}
          style={{marginBottom: 12, cursor: 'pointer'}}
          onClick={handleDeleteAll}
        >
          {t('reset_default')}
        </Text>
      )}
    </StyledOrderTags>
  )
}
