import React from "react";
import styled from "styled-components";
import {Td} from "../../../../../../../../layouts/tableLayout/_td";
import {Tr} from "../../../../../../../../layouts/tableLayout/_tr";
import {Text} from "../../../../../../../../common/text";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {fNumber} from "../../../../utils/formatNumber";
import {formatMoney} from "../../../../utils/functionUtil";
import { Skeleton } from '@mui/material';
export const TableBodyRevenueList = ()=>{
    const {revenueList} = useReportOrderFilter()
    return(
        <StyleTableBody>
            {
                revenueList?.loading ? <SkeletonTable/> :
                    revenueList?.list?.map((map, i)=> <Tr
                        className={'report-order-revenue-tr'}
                        key={i}
                    >
                        <Td
                            className={'report-order-revenue-td'}
                        >
                            <Text>{map?.date}</Text>
                        </Td>
                        <Td
                            className={'report-order-revenue-td'}
                        >
                            <Text>{formatMoney(map?.revenue)}</Text>
                        </Td>
                        <Td
                            className={'report-order-revenue-td'}
                        >
                            <Text>{fNumber(map?.total_order)}</Text>
                        </Td>
                        <Td
                            className={'report-order-revenue-td'}
                        >
                            <Text>{formatMoney(map?.agv_revenue)}</Text>
                        </Td>
                    </Tr>)
            }
        </StyleTableBody>
    )
}
const SkeletonTable = ()=>{
    const SkelentonRows = Array.from(Array(10).keys())
    return (
        <>
            {SkelentonRows.map(
                (i) =>
                    <Tr key={i} className={'report-order-revenue-tr'}>
                        <Td className={'report-order-revenue-td'}>
                            <Skeleton variant="rectangular" width={300} height={15}/>
                        </Td>
                        <Td className={'report-order-revenue-td'}>
                            <Skeleton variant="rectangular" width={612} />
                        </Td>
                        <Td className={'report-order-revenue-td'}>
                            <Skeleton variant="text" width={612}/>
                        </Td>
                        <Td className={'report-order-revenue-td'}>
                            <Skeleton variant="text" width={300}/>
                        </Td>
                    </Tr>
            )}
        </>
    )
}
const StyleTableBody = styled.div`
  .report-order-revenue{
     &-td{
        width: 25%;
        text-align: right;
        justify-content: flex-end;
        &:nth-child(1){
          text-align: left;
          justify-content: flex-start;
        }
     }
    &-title{
      display: flex;
      align-items: center;
    }
    
    &-sort{
      margin: 0 4px;
    }
  }

`
