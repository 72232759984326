import React from "react";
import {StyleComponentExportSelective} from "./_styled";
import {EXPORT_EXCEL_COMPONENT} from "../interfaces/_icon"
import CheckBoxConsignment from "../../CheckBoxConsignment/CheckBoxConsignment";
import {Text} from "../../../common/text";
import {DndCol} from "../../../common/dndCol";
import {Input} from "../../../common/form/input";
import {Button} from "../../../common/button";
import { useTranslation } from 'react-i18next'
export const ExportSelective = ({data, methods, ...props})=>{
    const {exportExcel, onClose} = props
    const {t} = useTranslation()
    return(
        <StyleComponentExportSelective>
            <div className='excel-export-selective'>
                <Text fontWeight={600} fontSize={20}>{t('customize_export_excel')}</Text>
                <div className='excel-export-selective_group'>
                    <div className='excel-export-selective_choose-option'>
                        <Text fontWeight={600}>{t('choose_column_want_export')}</Text>
                        <Input
                            className="excel-export-selective_search-input"
                            icon={EXPORT_EXCEL_COMPONENT.searchMd}
                            placeholder={t('search_name_column')}
                            autoComplete={'false'}
                            // defaultValue={searchParams.get('search')}
                            value={data?.keyword}
                            onChange={e => methods?.handleSearch(e)}
                        />
                        <div className='excel-export-selective_list-options common-scrollbar'>
                            {Array.isArray(data?.activeList) && data?.activeList?.map((item,i) => <ItemOptions methods={methods} key={i} item={item} />) }
                        </div>
                    </div>
                    <div className='excel-export-selective_choose-option'>
                        <Text fontWeight={600}>{t('arrange_stt_column')}</Text>
                        <div className='excel-export-selective_list-selective common-scrollbar'>
                            {Array.isArray(data?.selectedList) &&  <DndCol
                                initial={data?.selectedList?.map(item => ({
                                    id: item?.id,
                                    content: <ItemDragDrop item={item}/>,
                                    name: item?.name,
                                    status:item?.status,
                                    default:item?.default,
                                }))}
                                onChange={methods?.handleRowPositionUpdate}
                            /> }

                        </div>
                    </div>
                </div>
                <div className='excel-export-selective_action' style={{textAlign:'right',paddingTop:'6px'}}>
                    <Button appearance={'ghost'} className='excel-export-selective_action-cancel' onClick={()=>onClose(false)} >{t('cancel')}</Button>
                    <Button className='excel-export-selective_action-export' onClick={exportExcel}>{t('apply')}</Button>
                </div>
            </div>
        </StyleComponentExportSelective>
    )
}
const ItemOptions = ({item, methods, ...props}) =>{
    const {t} = useTranslation()
    return(
        <div onClick={_=> +item?.default === 0 && methods?.handleSelective(item)} data-default={+item?.default === 1 && true} className='excel-export-selective_options'>
            <CheckBoxConsignment
                isChecked={+item?.status === 1 ? true : false }
                disable={+item?.default === 1 && true}
                style={{marginRight:'9px'}}

            />

            <Text>{t(item?.name)}</Text>
        </div>
    )
}
const ItemDragDrop = ({item,...props}) => {
    const {t} = useTranslation()
    return(
        <div className='excel-export-selective_options'>
            {EXPORT_EXCEL_COMPONENT.dragIcon}
            <Text style={{marginRight:'8px'}} >{t(item?.name)}</Text>
        </div>
    )
}