import {fNumber} from "../../../../../../../../../../util/formatNumber";

export const chartOptionsRatioOrder={
    chart: {
        width: '380px',
        height: '100%',
        parentHeightOffset: 50,
    },
    colors: [
        '#00AB56', '#FF424E','#FF9F41'
    ],
    stroke: {
        width: 2,
    },
    legend: {
        show:false,
    },
    labels:['order', 'order','order'],
    dataLabels: {enabled: false, dropShadow: {enabled: false}},
    tooltip: {
        enabled: true,
        fillSeriesColor: false,
        custom: function({series, seriesIndex, dataPoint, w}) {
            const orders = w?.config?.listData
            let title =  w?.config?.language?.title
            let order =  w?.config?.language?.order
            const svg = index =>{
                switch (index) {
                    case 0:
                        return `
                             <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="9" cy="9" r="4.5" stroke="#00AB56" stroke-width="3"/>
                                </svg>

                        `
                    case 1:
                        return `
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="4.5" stroke="#FF424E" stroke-width="3"/>
                            </svg>

                        `
                    default:
                        return `
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9" cy="9" r="4.5" stroke="#FF9F41" stroke-width="3"/>
                                </svg>
                          `
                }
            }
            return '<div class="arrow_box" style="width: 143px;height:53px;padding: 6px 12px;display: flex">' +
                '<div class="img-tooltip">' +
                    svg(seriesIndex) +
                '</div>' +
                '<div>' +
                '<p style="font-size:14px;font-weight:400;line-height:140%">'+title[seriesIndex]+'</p>' +
                '<span style="font-size:15px;font-weight:600;line-height:140%">' + orders[seriesIndex]?.total + `${order} </span>` +
                '</div>' +
                '</div>'
        }
    },
    plotOptions: {
        donut: {donut: {labels: {show: false}}},
        pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1.2,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            },
            donut: {
                size: '75%',
                background: 'transparent',
                labels: {
                    show: true,
                    name: {
                        show: true,
                    },
                    value: {
                        show: true,
                        label:'order',
                        fontSize: '14px',
                        fontFamily: 'SF Pro Display',
                        color: '#000000',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        offsetY: 2,
                        width: '100px',
                        wordWrap:'break-word',
                        formatter: function (val) {
                            return val
                        }
                    },
                    total: {
                        show: true,
                        showAlways: false,
                        label:'order',
                        fontSize: '14px',
                        fontFamily: 'SF Pro Display',
                        color: '#000000',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        width: '100px',
                        wordWrap:'break-word',
                        formatter: function (w) {
                            const total = w.config.listData?.map(map=>+map?.total)
                            return fNumber(total?.reduce((a, b) => {
                                return a + b
                            }, 0))

                        }
                    }
                }
            },
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                show: false
            }
        }
    }
    ]
}