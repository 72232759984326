import React from "react";
import styled from "styled-components";
import {useReportOrderFilter} from "../../../../hook/useReportOrderFilter";
import ReactApexChart from "react-apexcharts";
import {chartOptionsRatioProduct} from "./_chartOptionRatioProduct";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../../../Component/chart";
import {useTranslation} from "react-i18next";
export const RatioProduct = ()=>{
  const { t } = useTranslation()
    const {ratioOrder} = useReportOrderFilter()

    const chartOptions = merge(BaseOptionChart(), {
        listData: ratioOrder?.data,
        labels:Array.from(Array(9).keys()).map(_=>'đơn hàng'),
        ...chartOptionsRatioProduct,
        language: {
          order: t('orders')
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  label: t('order_')
                }
              }
            }
          }
        }
    })
    const chartSeries=ratioOrder?.data?.map(map=> map?.total)
    return(
        <StyleRatioProduct>
            <div className={'ratio-product-chart'}>
                <ReactApexChart
                    type="donut"
                    series={chartSeries}
                    options={chartOptions}
                    height={'100%'}
                    width={200}
                />
            </div>
        </StyleRatioProduct>
    )

}
const StyleRatioProduct = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  .ratio-product{
    &-title{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &-chart{
      width: fit-content;
      height: 200px;
      position: relative;
      margin: 0 auto;
      .apexcharts-svg{
        transform: translate(0, 50px);
        .apexcharts-graphical{
          transform: translate(13px, 15px);
           .apexcharts-datalabel-label{
             transform: translate(0, 28px);
             font-size: 14px;
             font-weight: 400;
             fill: #535E79 !important;
             line-height: 140%;
          }
          .apexcharts-datalabel-value{
            transform: translate(0px, -26px);
            fill: #0F172A;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 600;
            line-height: 140%;
          }
        }
      }
    }
    
    &-chart_series{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 6rem;
      padding-left: 5rem;
    }
    
  }
`