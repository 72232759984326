import React from "react";

export const ICONS_FEEDBACK = {
  pin: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1017 7.26647L8.09133 13.2769C6.72449 14.6437 4.50842 14.6437 3.14158 13.2769C1.77475 11.91 1.77475 9.69396 3.14158 8.32713L9.15199 2.31672C10.0632 1.4055 11.5406 1.40549 12.4518 2.31672C13.363 3.22794 13.363 4.70533 12.4518 5.61655L6.67711 11.3913C6.2215 11.8469 5.48281 11.8469 5.0272 11.3913C4.57159 10.9356 4.57159 10.197 5.0272 9.74134L10.0948 4.67374" stroke="#1A94FF" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  clock: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99844 4.6399V7.9999L10.2384 9.1199M13.5984 7.9999C13.5984 11.0927 11.0912 13.5999 7.99844 13.5999C4.90564 13.5999 2.39844 11.0927 2.39844 7.9999C2.39844 4.90711 4.90564 2.3999 7.99844 2.3999C11.0912 2.3999 13.5984 4.90711 13.5984 7.9999Z" stroke="#7C88A6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ),
  up: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3791_33920)">
        <path
          d="M10.4496 5.18258L14.8246 9.55381C14.9384 9.67159 15.0014 9.82933 15 9.99306C14.9986 10.1568 14.9328 10.3134 14.8169 10.4292C14.7011 10.545 14.5443 10.6106 14.3804 10.6121C14.2166 10.6135 14.0587 10.5506 13.9408 10.4368L10.0077 6.50707L6.07455 10.4368C6.0169 10.4964 5.94794 10.544 5.87168 10.5767C5.79543 10.6095 5.71342 10.6267 5.63043 10.6274C5.54744 10.6281 5.46514 10.6123 5.38833 10.5809C5.31152 10.5495 5.24174 10.5032 5.18306 10.4445C5.12438 10.3859 5.07797 10.3162 5.04654 10.2394C5.01512 10.1627 4.9993 10.0805 5.00002 9.99755C5.00074 9.91464 5.01799 9.8327 5.05074 9.75651C5.0835 9.68032 5.13111 9.61142 5.1908 9.55381L9.56581 5.18258C9.68301 5.06551 9.84195 4.99975 10.0077 4.99975C10.1734 4.99975 10.3324 5.06551 10.4496 5.18258Z"
          fill="#7C88A6"
        />
        <path
          d="M10.4496 8.30514L14.8246 12.6764C14.9384 12.7941 15.0014 12.9519 15 13.1156C14.9986 13.2793 14.9328 13.436 14.8169 13.5518C14.7011 13.6675 14.5443 13.7332 14.3804 13.7346C14.2166 13.7361 14.0587 13.6731 13.9408 13.5594L10.0077 9.62962L6.07455 13.5594C6.0169 13.619 5.94794 13.6666 5.87168 13.6993C5.79543 13.732 5.71342 13.7493 5.63043 13.75C5.54744 13.7507 5.46514 13.7349 5.38833 13.7035C5.31152 13.6721 5.24174 13.6257 5.18306 13.5671C5.12438 13.5085 5.07797 13.4387 5.04654 13.362C5.01512 13.2853 4.9993 13.203 5.00002 13.1201C5.00074 13.0372 5.01799 12.9553 5.05074 12.8791C5.0835 12.8029 5.13111 12.734 5.1908 12.6764L9.56581 8.30514C9.68301 8.18807 9.84195 8.12231 10.0077 8.12231C10.1734 8.12231 10.3324 8.18807 10.4496 8.30514Z"
          fill="#7C88A6"
        />
      </g>
      <rect
        x="-0.5"
        y="-0.5"
        width="19"
        height="19"
        rx="9.5"
        transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 19 19)"
        stroke="#7C88A6"
      />
      <defs>
        <clipPath id="clip0_3791_33920">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 15 15)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  excel: (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9252 5.37985C13.9252 4.93782 13.7496 4.5139 13.437 4.20134L10.3903 1.15466C10.0778 0.842099 9.65386 0.666504 9.21183 0.666504H2.66081C1.74033 0.666504 0.994141 1.4127 0.994141 2.33317V15.6665C0.994141 16.587 1.74033 17.3332 2.66081 17.3332H12.2585C13.179 17.3332 13.9252 16.587 13.9252 15.6665V5.37985Z" fill="#F2F7FC"/>
      <path d="M9.90039 0.666504V3.02283C9.90039 3.9433 10.6466 4.68949 11.5671 4.68949H13.9234L9.90039 0.666504Z" fill="#B5CBE8"/>
      <path d="M7.0127 6.9165H8.72817V7.78952H7.0127V6.9165Z" fill="#B5CBE8"/>
      <path d="M7.0127 8.30566H8.72817V9.17868H7.0127V8.30566Z" fill="#B5CBE8"/>
      <path d="M7.0127 9.69482H8.72817V10.5678H7.0127V9.69482Z" fill="#B5CBE8"/>
      <path d="M7.0127 11.2466H8.72817V12.1196H7.0127V11.2466Z" fill="#B5CBE8"/>
      <path d="M9.33594 6.9165H11.0514V7.78952H9.33594V6.9165Z" fill="#B5CBE8"/>
      <path d="M9.33594 8.30566H11.0514V9.17868H9.33594V8.30566Z" fill="#B5CBE8"/>
      <path d="M9.33594 9.69482H11.0514V10.5678H9.33594V9.69482Z" fill="#B5CBE8"/>
      <path d="M9.33594 11.2466H11.0514V12.1196H9.33594V11.2466Z" fill="#B5CBE8"/>
      <rect x="0.0751953" y="5.75928" width="7.40741" height="7.40741" rx="0.833333" fill="#198A73"/>
      <path d="M4.81827 11.5002L3.87938 10.1502L2.95716 11.5002H1.92383L3.36272 9.52244L1.99605 7.61133H3.01827L3.91272 8.87244L4.7905 7.61133H5.76272L4.40716 9.48911L5.85716 11.5002H4.81827Z" fill="white"/>
    </svg>
  ),
  jpg: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.84766" y="2" width="16.3055" height="15.9997" rx="2" fill="#F7F9FD"/>
      <path d="M15.9347 5.68791C15.7886 5.35136 15.5772 5.04889 15.3135 4.79541L13.4281 6.63792L13.4302 6.64005C13.4826 6.68904 13.5262 6.74442 13.561 6.80619C13.561 6.80832 13.5632 6.81045 13.5632 6.81045C13.5763 6.83601 13.5894 6.86157 13.6003 6.88927C13.6046 6.90205 13.6112 6.91483 13.6133 6.92761C13.6199 6.94465 13.6242 6.96382 13.6286 6.98299C13.6308 6.99577 13.6351 7.00855 13.6373 7.0192C13.6373 7.02133 13.6373 7.02133 13.6373 7.02346C13.6482 7.08523 13.6504 7.12996 13.6504 7.14487L13.6765 11.8076C13.6809 12.6383 12.6521 13.0579 12.0527 12.47L11.5666 11.995C11.2266 11.6627 10.6773 11.6627 10.3373 11.995L7.43836 14.828C7.13538 15.1241 7.1877 15.5629 7.44925 15.81C7.57132 15.925 7.73697 15.9996 7.93532 15.9996H11.8086C12.3993 15.9996 12.966 15.7716 13.3845 15.3669L15.3069 13.5138C15.8475 12.9919 16.1526 12.2805 16.1526 11.5392V6.76572C16.157 6.38231 16.0785 6.0202 15.9347 5.68791Z" fill="#191D32"/>
      <path d="M15.9347 5.68791C15.7886 5.35136 15.5772 5.04889 15.3135 4.79541L13.4281 6.63792L13.4302 6.64005C13.4826 6.68904 13.5262 6.74442 13.561 6.80619C13.561 6.80832 13.5632 6.81045 13.5632 6.81045C13.5763 6.83601 13.5894 6.86157 13.6003 6.88927C13.6046 6.90205 13.6112 6.91483 13.6133 6.92761C13.6199 6.94465 13.6242 6.96382 13.6286 6.98299C13.6308 6.99577 13.6351 7.00855 13.6373 7.0192C13.6373 7.02133 13.6373 7.02133 13.6373 7.02346C13.6482 7.08523 13.6504 7.12996 13.6504 7.14487L13.6765 11.8076C13.6809 12.6383 12.6521 13.0579 12.0527 12.47L11.5666 11.995C11.2266 11.6627 10.6773 11.6627 10.3373 11.995L7.43836 14.828C7.13538 15.1241 7.1877 15.5629 7.44925 15.81C7.57132 15.925 7.73697 15.9996 7.93532 15.9996H11.8086C12.3993 15.9996 12.966 15.7716 13.3845 15.3669L15.3069 13.5138C15.8475 12.9919 16.1526 12.2805 16.1526 11.5392V6.76572C16.157 6.38231 16.0785 6.0202 15.9347 5.68791Z" fill="#DAE3F6"/>
      <path d="M13.327 4H8.1939C7.60321 4 7.0365 4.22792 6.61801 4.63263L4.65413 6.52626C4.13755 7.02043 3.84766 7.69567 3.84766 8.40285V12.0517C3.84766 12.3222 3.99587 12.533 4.1964 12.6481C4.17461 12.6204 4.16153 12.597 4.15063 12.5778C4.16589 12.6012 4.17896 12.6268 4.1964 12.6481C4.4536 12.7929 4.80235 12.7844 5.05083 12.5394L7.9694 9.68728C8.30942 9.35499 8.30942 8.81608 7.9694 8.48379L7.47897 8.00453C6.87739 7.41663 7.30678 6.41124 8.15685 6.4155L12.9281 6.44106C13.1221 6.44106 13.2965 6.51774 13.4273 6.63915L15.3127 4.79665C14.8026 4.3046 14.1008 4 13.327 4Z" fill="#191D32"/>
      <path d="M13.327 4H8.1939C7.60321 4 7.0365 4.22792 6.61801 4.63263L4.65413 6.52626C4.13755 7.02043 3.84766 7.69567 3.84766 8.40285V12.0517C3.84766 12.3222 3.99587 12.533 4.1964 12.6481C4.17461 12.6204 4.16153 12.597 4.15063 12.5778C4.16589 12.6012 4.17896 12.6268 4.1964 12.6481C4.4536 12.7929 4.80235 12.7844 5.05083 12.5394L7.9694 9.68728C8.30942 9.35499 8.30942 8.81608 7.9694 8.48379L7.47897 8.00453C6.87739 7.41663 7.30678 6.41124 8.15685 6.4155L12.9281 6.44106C13.1221 6.44106 13.2965 6.51774 13.4273 6.63915L15.3127 4.79665C14.8026 4.3046 14.1008 4 13.327 4Z" fill="#DAE3F6"/>
    </svg>
  ),
  pdf: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9808 6.37985C16.9808 5.93782 16.8052 5.5139 16.4927 5.20134L13.446 2.15466C13.1334 1.8421 12.7095 1.6665 12.2675 1.6665H5.71647C4.796 1.6665 4.0498 2.4127 4.0498 3.33317V16.6665C4.0498 17.587 4.796 18.3332 5.71647 18.3332H15.3142C16.2346 18.3332 16.9808 17.587 16.9808 16.6665V6.37985Z" fill="#F2F7FC"/>
      <path d="M12.9561 1.6665V4.02283C12.9561 4.9433 13.7022 5.68949 14.6227 5.68949H16.979L12.9561 1.6665Z" fill="#B5CBE8"/>
      <path d="M10.0684 7.9165H11.7838V8.78952H10.0684V7.9165Z" fill="#B5CBE8"/>
      <path d="M10.0684 9.30566H11.7838V10.1787H10.0684V9.30566Z" fill="#B5CBE8"/>
      <path d="M10.0684 10.6948H11.7838V11.5678H10.0684V10.6948Z" fill="#B5CBE8"/>
      <path d="M10.0684 12.2466H11.7838V13.1196H10.0684V12.2466Z" fill="#B5CBE8"/>
      <rect x="3.01855" y="7" width="11" height="7" rx="0.833333" fill="#FE2216"/>
      <path d="M5 12H5.66283V11.0541H6.27298C6.94019 11.0541 7.38573 10.6466 7.38573 10.0291V10.0249C7.38573 9.40748 6.94019 9 6.27298 9H5V12ZM6.11056 9.49688C6.49026 9.49688 6.71413 9.68607 6.71413 10.027V10.0312C6.71413 10.3721 6.49026 10.5634 6.11056 10.5634H5.66283V9.49688H6.11056Z" fill="white"/>
      <path d="M7.7786 12H8.98793C9.93827 12 10.4979 11.4407 10.4979 10.4886V10.4844C10.4979 9.5343 9.93608 9 8.98793 9H7.7786V12ZM8.44143 11.4802V9.51767H8.90892C9.49054 9.51767 9.82195 9.86071 9.82195 10.4865V10.4906C9.82195 11.1393 9.50151 11.4802 8.90892 11.4802H8.44143Z" fill="white"/>
      <path d="M10.9259 12H11.5888V10.8191H12.8771V10.3222H11.5888V9.51767H13V9H10.9259V12Z" fill="white"/>
    </svg>
  ),
  word: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9808 6.37985C16.9808 5.93782 16.8052 5.5139 16.4927 5.20134L13.446 2.15466C13.1334 1.8421 12.7095 1.6665 12.2675 1.6665H5.71647C4.796 1.6665 4.0498 2.4127 4.0498 3.33317V16.6665C4.0498 17.587 4.796 18.3332 5.71647 18.3332H15.3142C16.2346 18.3332 16.9808 17.587 16.9808 16.6665V6.37985Z" fill="#F2F7FC"/>
      <path d="M12.9561 1.6665V4.02283C12.9561 4.9433 13.7022 5.68949 14.6227 5.68949H16.979L12.9561 1.6665Z" fill="#B5CBE8"/>
      <path d="M10.0684 7.9165H11.7838V8.78952H10.0684V7.9165Z" fill="#B5CBE8"/>
      <path d="M10.0684 9.30566H11.7838V10.1787H10.0684V9.30566Z" fill="#B5CBE8"/>
      <path d="M10.0684 10.6948H11.7838V11.5678H10.0684V10.6948Z" fill="#B5CBE8"/>
      <path d="M10.0684 12.2466H11.7838V13.1196H10.0684V12.2466Z" fill="#B5CBE8"/>
      <path d="M12.3916 7.9165H14.1071V8.78952H12.3916V7.9165Z" fill="#B5CBE8"/>
      <path d="M12.3916 9.30566H14.1071V10.1787H12.3916V9.30566Z" fill="#B5CBE8"/>
      <path d="M12.3916 10.6948H14.1071V11.5678H12.3916V10.6948Z" fill="#B5CBE8"/>
      <path d="M12.3916 12.2466H14.1071V13.1196H12.3916V12.2466Z" fill="#B5CBE8"/>
      <rect x="3.01855" y="6.75928" width="7.40741" height="7.40741" rx="0.833333" fill="#1C62C2"/>
      <path d="M5.07746 13H5.8952L6.63255 10.3195H6.64919L7.38377 13H8.20151L9.26318 9H8.39555L7.76076 11.8663H7.74413L7.00677 9H6.27219L5.53484 11.8663H5.51821L4.88619 9H4.01855L5.07746 13Z" fill="white"/>
    </svg>
  ),
  user: (
    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="32" height="32.0008" rx="16" fill="#FF424E"/>
      <path opacity="0.36" d="M23.1572 13.6093C23.1572 14.8124 22.7959 18.4218 19.999 18.4218C17.2021 18.4218 16.8408 14.8124 16.8408 13.6093C16.8408 11.2031 18.2548 10 19.999 10C21.7432 10 23.1572 11.2031 23.1572 13.6093Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.7849 18.7542C15.0621 19.5954 12.9735 21.9496 12.568 24.902L12.2734 27.0463C14.3229 28.8834 17.031 30.0008 20 30.0008C22.9692 30.0008 25.6773 28.8833 27.7268 27.046L27.4323 24.902C27.0267 21.9493 24.9378 19.595 22.2146 18.7539C21.6477 19.1874 20.9212 19.4745 19.9996 19.4745C19.0782 19.4745 18.3518 19.1875 17.7849 18.7542ZM19.9372 18.4212C19.9579 18.4216 19.9787 18.4218 19.9996 18.4218C20.0206 18.4218 20.0415 18.4216 20.0623 18.4212C20.0416 18.421 20.0209 18.4209 20.0002 18.4209C19.9792 18.4209 19.9582 18.421 19.9372 18.4212Z" fill="white"/>
    </svg>
  ),
  upos: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.4325 4.50439C31.0526 3.60696 30.5028 2.80042 29.8168 2.12451L24.9132 7.03762L24.9189 7.0433C25.0549 7.17394 25.1683 7.32162 25.259 7.48633C25.259 7.49201 25.2647 7.49769 25.2647 7.49769C25.2987 7.56585 25.3327 7.63401 25.3611 7.70785C25.3724 7.74193 25.3894 7.77601 25.3951 7.81009C25.4121 7.85553 25.4234 7.90665 25.4347 7.95777C25.4404 7.99185 25.4518 8.02593 25.4574 8.05432C25.4574 8.06 25.4574 8.06 25.4574 8.06568C25.4858 8.2304 25.4914 8.34968 25.4914 8.38944L25.5595 20.8227C25.5708 23.0379 22.8951 24.1568 21.3361 22.5892L20.0719 21.3226C19.1876 20.4365 17.759 20.4365 16.8747 21.3226L9.33501 28.8768C8.54703 29.6663 8.68309 30.8364 9.36336 31.4953C9.68082 31.802 10.1117 32.0008 10.6275 32.0008H20.7012C22.2375 32.0008 23.7114 31.393 24.7998 30.3138L29.7998 25.3723C31.2057 23.9808 31.9994 22.0837 31.9994 20.1071V7.37842C32.0107 6.35604 31.8066 5.39045 31.4325 4.50439Z" fill="#3CD6B7"/>
      <path d="M24.6541 0.000976562H11.3038C9.76755 0.000976562 8.29363 0.608725 7.2052 1.68791L2.0975 6.73733C0.753966 8.05507 0 9.8556 0 11.7413V21.471C0 22.1923 0.385487 22.7546 0.907027 23.0614C0.850338 22.9875 0.816324 22.925 0.78798 22.8739C0.827662 22.9364 0.861676 23.0046 0.907027 23.0614C1.57596 23.4476 2.48299 23.4249 3.12924 22.7717L10.7199 15.1663C11.6043 14.2802 11.6043 12.8432 10.7199 11.9572L9.44442 10.6792C7.8798 9.11153 8.99658 6.43062 11.2075 6.44198L23.6167 6.51014C24.1213 6.51014 24.5748 6.71461 24.9149 7.03837L29.8185 2.12526C28.492 0.813202 26.6666 0.000976562 24.6541 0.000976562Z" fill="#3CD6B7"/>
    </svg>
  ),
  plus: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4V16M4 10H16"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
