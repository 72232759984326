import config from "../../../config";

export const ORDER_SINGLE_CONSTANTS = {
  create: {
    shippingPoint: {
      options: [
        {id: 1, name: 'set_as_default_address', value: 'is_default'},
        {id: 2, name: 'hide_name_on_shipping_label', value: 'is_hidden_name'},
        {
          id: 3,
          name: 'hide_address_on_shipping_label',
          value: 'is_hidden_address',
        },
        {
          id: 4,
          name: 'hide_phone_on_shipping_label',
          value: 'is_hidden_phone',
        },
        {
          id: 5,
          name: 'hide_address_province_district_on_shipping_label',
          value: 'is_hidden_province',
        },
      ],
    },
  },
  header: {
    breadcrumb: [
      {
        id: 1,
        name: 'order',
        url: '#',
      },
      {id: 2, name: 'manage_order', url: '#'},
      {id: 3, name: 'create_new_order', url: '#'},
    ],
  },
  form: {
    productInfo: {
      inventoryType: [
        {
          id: 1,
          name: 'enter_product_name',
          tooltip: (
            <ul style={{paddingLeft: 12}}>
              <li style={{marginBottom: 8, listStyleType: 'disc'}}>
                Bạn sẽ tự nhập tên hàng hóa và số lượng của hàng hóa, UPOS đề
                xuất bạn nên dùng cú pháp nội dung hàng hóa là:
              </li>
              <li style={{marginBottom: 8}}>
                Tên sản phẩm | Số lượng sản phẩm
              </li>
              <li style={{listStyleType: 'disc'}}>Ví dụ: Áo dài tay | 2</li>
            </ul>
          ),
          value: 'manual',
        },
        {
          id: 2,
          name: 'use_existing_product_name',
          tooltip: (
            <ul style={{paddingLeft: 12}}>
              <li style={{marginBottom: 8, listStyleType: 'disc'}}>
                Bạn sẽ chọn và sử dụng tên của các sản phẩm mà bạn đã tạo ở phần
                Quản lý sản phẩm trên hệ thống.
              </li>
              <li style={{listStyleType: 'disc'}}>
                Lưu ý: Thao tác này chỉ sử dụng tên sản phẩm, không trừ tồn kho
                khi bán sản phẩm.
              </li>
            </ul>
          ),
          value: 'auto',
        },
      ],
      withInventoryPriceType: [
        {name: 'general_retail_price', value: 1},
        {name: 'general_wholesale_price', value: 2},
      ],
    },
    shippingInfo: {
      1: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      2: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        transport: [
          {value: '', label: 'default', checked: true},
          {value: 'road', label: 'by_road', checked: false},
          {value: 'fly', label: 'by_air', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        fragile: 0,
        exchange_goods: 0,
        pick_date: null,
        pick_shift: [
          {value: 1, label: 'morning', checked: false},
          {value: 2, label: 'afternoon', checked: false},
          {value: 3, label: 'evening', checked: false},
        ],
        cargoInsurrance: {
          value: '',
          active: true,
        },
      },
      3: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request: [
          {value: 1, label: 'show_dont_try', checked: false},
          {value: 2, label: 'not_allowing_view', checked: true},
          {value: 3, label: 'sample_goods', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
        lengh: 1,
        width: 2,
        height: 3,
      },
      4: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      5: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_dont_try', checked: false},
          {value: 2, label: 'not_allowing_view', checked: true},
          {value: 3, label: 'sample_goods', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      6: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      7: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          // {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      8: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      9: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      10: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'sender_pay_end_month', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_dont_try', checked: false},
          {value: 2, label: 'not_allowing_view', checked: true},
          {value: 3, label: 'sample_goods', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      11: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'Người gửi cuối tháng trả phí', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
          {value: 3, label: 'sample_goods', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      13: {
        payer: [
          {value: 1, label: 'geter_pay', checked: false, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: true, payment_method: 'PP_CASH'},
          // {value: 3, label: 'Người gửi cuối tháng trả phí', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true},
          {value: 2, label: 'not_allowing_view', checked: false},
          // {value: 3, label: 'Cho thử hàng', checked: false},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          {value: 6, label: 'send_at_post_office', checked: false},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      14: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'Người gửi cuối tháng trả phí', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'for_mutual_inspection', checked: false},
          {value: 2, label: 'no_inspection', checked: true},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
          // {value: 6, label: 'Gửi tại bưu cục', checked: false},
        ],
        packageQuantity: 1,
        cargoInsurrance: {
          active: false,
          value: '',
        },
      },
      15: {
        payer: [
          {value: 1, label: 'geter_pay', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: false, payment_method: 'PP_CASH'},
          // {value: 3, label: 'Người gửi cuối tháng trả phí', checked: false, payment_method: 'PP_PM'},
        ],
        request: [
          {value: 1, label: 'show_goods_to_customer', checked: true, payment_method: 'CC_CASH'},
          {value: 2, label: 'not_allowing_view', checked: false, payment_method: 'PP_CASH'},
        ],
        request_goods: [
          {value: 1, label: 'pickup_at_home', checked: true},
        ],
        partsign: false,
        packageQuantity: 1,
        cargoInsurrance: {
          active: true,
          value: '',
        },
      },
      16: {
        payer: [
          {value: 1, label: 'geter_pay', checked: false, payment_method: 'CC_CASH'},
          {value: 2, label: 'sender_pay', checked: true, payment_method: 'PP_CASH'},
          // {value: 3, label: 'Người gửi cuối tháng trả phí', checked: false, payment_method: 'PP_PM'},
        ],
        promoCode: {
          value: null,
          status: null,
          message: null
        },
      },
    },
    paymentMethod: {
      listType: [
        {id: 1, name: 'prepaid_payment', value: 'before'},
        {id: 2, name: 'cod_payment_after_successful_delivery', value: 'cod'},
        {id: 3, name: 'payment_after_delivery', value: 'after'},
      ],
    },
  },
  printModal: {
    pageSize: {
      list: [
        {
          name: 'edit_order_page_temp_dvvc',
          value: 'others',
        },
        {
          name: 'print_UPOS_A4_template',
          value: 'a4',
        },
        {
          name: 'print_UPOS_A5_template',
          value: 'a5',
        },
        {
          name: 'print_UPOS_K80_template',
          value: 'k80',
        },
      ],
    },
  },

  errorFeeShipping: {
    data: {
      errors: {
        code: 101,
        message: '101',
        details: {
          code: 2022,
          message: 'incorrect_shipping_fee_information'
        }
      }
    }
  }
}

export const CODE_DISCOUNT_GRAB = config?.env !== 'PRODUCTION' ? "VALIDPROMO" : "SIEUTOC"