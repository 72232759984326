import {Skeleton} from '@mui/material'
import {sendRequestAuth} from 'api/api'
import {Checkbox} from 'common/form/checkbox'
import {Text} from 'common/text'
import config from 'config'
import useAlert from 'hook/useAlert'
import {Td} from 'layouts/tableLayout/_td'
import {Tr} from 'layouts/tableLayout/_tr'
import useOrderFilterForm from 'Pages/refactorOrder/hooks/useOrderFilterForm'
import useOrderRow from 'Pages/refactorOrder/hooks/useOrderRow'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import {OrderContext} from 'Pages/refactorOrder/provider/_context'
import {useContext, useState} from 'react'
import {OrderEmpty} from '../orderEmpty'
import {CellCodeOrder} from './_cellCodeOrder'
import {CellCustomer} from './_cellCustomer'
import {CellPayment} from './_cellPayment'
import {CellStatusOrder} from './_cellStatusOrder'
import {ConfirmDeleteModal} from './_confirmDeleteModal'
import {ReportCustomerModal} from './_reportCustomerModal'
import {RowMenuPopover} from './_rowMenuPopover'
import {RowOrderExtra} from './_rowOrderExtra'
import {formatMoney} from '../../../../util/functionUtil'
import {useNavigate, useSearchParams} from 'react-router-dom'
import toast from '../../../../Component/Toast'
import {useEffect} from 'react'
import {Tooltip} from 'common/tooltipv2'
import {PrintOrderDetail} from '../printDetail'
import {fDateTimeSuffix} from '../../../../util/formatTime'
import useOrderTHead from '../../hooks/useOrderTHead'
import {PATH} from '../../../../const/path'
import {useTranslation} from 'react-i18next'
import {AddInfoCustomer} from "../orderModal/addInfoCustomer";
import {ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_PAYMENT_METHODS} from "../../interfaces/_constants";
import useGlobalContext from "../../../../containerContext/storeContext";
import { getUrlOrderUpdateStatus } from '../../../../api/url'

export const OrderTBody = () => {
  const {pageState} = useContext(OrderContext)
  const useTHead = useOrderTHead()
  const {table} = pageState

    const [GlobalState] = useGlobalContext()
    const {shopInfo, user} = GlobalState

    const displayList = table.display.list
    const displayLoading = table.display.loading
    const paginationAmount = table.pagination.amount
    const paginationTotalItems = table.pagination.totalItems
    const openModalAddCustomer = pageState?.modalAddCustomer?.open

    return (
        <>
            <div
                style={{
                    minHeight: 'calc(100vh - 440px)',
                    width: 'fit-content',
                    minWidth: useTHead?.configItem?.minimizeSidebar ? 1800 : 1636
                }}
            >
                {displayLoading ? (
                    Array.from(Array(paginationAmount), (e, i) => (
                        <OrderPlaceholder key={i} minimize={useTHead?.configItem?.minimizeSidebar}/>
                    ))
                ) : paginationTotalItems > 0 ? (
                    displayList.map(item =>
                        <OrderTr
                            key={item.id}
                            data={item}
                            style={{
                                borderLeft: '1px solid #e2eaf8',
                                borderRight: '1px solid #e2eaf8',
                                width: 'fit-content',
                                minWidth: useTHead?.configItem?.minimizeSidebar ? 1800 : 1636
                            }}
                            isHiddenFee={shopInfo?.is_hidden_shipfee}
                            isManage={user?.is_manage}
                        />)
                ) : (
                    <OrderEmpty/>
                )}
            </div>
            <PrintOrderDetail/>

      {openModalAddCustomer && <AddInfoCustomer />}
    </>
  )
}

const OrderPlaceholder = ({minimize, ...props}) => {
  const {pageState} = useContext(OrderContext)
  const configApplyList = pageState?.table?.configItem?.applyList

  return (
    <Tr
      {...props}
      className="order-table__row"
      style={{
        overflow: 'hidden',
        minWidth: minimize ? 1800 : 1636,
      }}
    >
      {Array.from(Array(configApplyList?.length || 9), (e, i) => (
        <Td key={i} className="order-table__cell" data-type="td">
          <Skeleton
            sx={{
              width: 250,
              height: 33,
              background:
                'linear-gradient(0deg, rgba(244, 247, 252, 0.98), rgba(244, 247, 252, 0.98)), #00081D;',
            }}
          />
        </Td>
      ))}
    </Tr>
  )
}

const OrderTr = ({data, isHiddenFee, isManage, ...props}) => {
    const {t} = useTranslation()
    const {showAlert} = useAlert()
    const {pageState,} = useContext(OrderContext)
    const {functions} = useOrderFilterForm()
    const orderRow = useOrderRow(data)
    const {cell, detail, row} = orderRow
    const {codeOrder, payment} = cell
    const configApplyList = pageState?.table?.configItem?.applyList
    const infoEOrder = pageState?.infoEOrder?.[0]

  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(null)
  const [reportCustomerModalData, setReportCustomerModalData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const getTemplatePrint = content => `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
      </head>
      <body>${content}</body>
    </html>
  `
  const handlePrint = async type => {
    // in
    setIsLoading(true)
    if (type === 'print') {
      let content = document.getElementById('order-detail-print').innerHTML

      let frame = document.createElement('iframe')
      frame.name = 'frame'
      frame.style.position = 'absolute'
      frame.style.top = '-1000000px'
      document.body.appendChild(frame)
      const frameDoc = frame.contentWindow
        ? frame.contentWindow
        : frame.contentDocument.document
        ? frame.contentDocument.document
        : frame.contentDocument
      frameDoc.document.open()
      frameDoc.document.write(content)
      frameDoc.document.close()
      window.frames.frame.focus()
      setTimeout(function () {
        setIsLoading(false)
        window.frames.frame.print()
        document.body.removeChild(frame)
      }, 1500)
      return true
    } else {
      const response = await sendRequestAuth(
        'post',
        `${config.API}/order/print-pos`,
        JSON.stringify({
          order_id: [row?.data?.id.toString()],
          print_size: type,
          print_type: 'order_bill',
        }),
      )
      if (response?.data?.success) {
        const content = getTemplatePrint(response?.data?.data[0] || '')

        const frame = document.createElement('iframe')
        frame.name = 'frame'
        frame.style.position = 'absolute'
        frame.style.top = '-1000000px'

        document.body.appendChild(frame)

        const frameDoc = frame.contentWindow
          ? frame.contentWindow
          : frame.contentDocument.document
          ? frame.contentDocument.document
          : frame.contentDocument
        frameDoc.document.open()
        frameDoc.document.write(content)
        frameDoc.document.close()

        window.frames.frame.focus()
        setTimeout(function () {
          window.frames.frame.print()
          document.body.removeChild(frame)
          setIsLoading(false)
        }, 100)
        return true
      } else {
        toast.error(t('validate_invalid_order_info'))
        setIsLoading(false)
      }
    }
  }

  const handleEditOrder = _ =>
    navigate(
      `/order/edit/${data.id}?redirect_url=${encodeURIComponent(PATH.ORDER)}`,
    )
  const handleCopyOrder = async _ => {
    const response = await sendRequestAuth(
      'post',
      `${config.API}/order/copy/${data.id}`,
    )
    if (response?.data?.success) {
      navigate(`/order/copy/${response?.data?.meta?.insert_id}`)
      toast.success(`${t('copy_from_order')} [${data.id}] ${t('success')}.`)
    } else toast.error(`${t('copy_from_order')} [${data.id}] ${t('failed')}!`)
  }

  const handleCreateInvoice = _ => {
    functions?.handleOpenModelAddCustomer(data)
  }

  const handleShippingStatusUpdate = async status => {
    if ([7, 15].includes(status)) setConfirmDeleteModalData(null)

    setIsLoading(true)

    const response = await sendRequestAuth(
      'post',
      getUrlOrderUpdateStatus(),
      JSON.stringify({
        order_ids: [data.id],
        status,
      }),
    )

    if (response?.data?.success) {
      showAlert({content: t('status_update'), type: 'success'})
      functions.fetchUpdateData()
    } else {
      const errMessage = Array.isArray(response?.data?.errors) ? response?.data?.errors[0]?.message : response?.data?.message

      showAlert({
        content: errMessage,
        type: 'danger',
      })
    }

    setIsLoading(false)
  }

  const handleActionApply = action => {
    switch (action) {
      case 'print':
        handlePrint('print')
        break
      case 'k80':
        handlePrint('k80')
        break
      case 'edit':
        handleEditOrder()
        break
      case 'shipping':
        handleShippingStatusUpdate(1)
        break
      case 'copy':
        handleCopyOrder()
        break
      case 'create_invoice':
        handleCreateInvoice()
        break
      case 'cancel-shipping':
        setConfirmDeleteModalData({
          content: t('confirm_cancel_delivery'),
          title: t('delivery_status_7'),
          onClose: () => setConfirmDeleteModalData(null),
          onSubmit: () => handleShippingStatusUpdate(7),
        })
        break

      case 'cancel-order':
        setConfirmDeleteModalData({
          content: t('confirm_cancel_order'),
          title: t('delivery_status_15'),
          onClose: () => setConfirmDeleteModalData(null),
          onSubmit: () => handleShippingStatusUpdate(15),
        })
        break

      case 'report':
        setReportCustomerModalData({
          customer: {
            name: data?.customer_name,
            phone: data?.customer_mobile,
            total: data?.total_reports,
          },
          onClose: () => setReportCustomerModalData(null),
        })
        break

      default:
        break
    }
  }

  const [searchParams] = useSearchParams()
  useEffect(() => {
    const querySearch = searchParams.get('search') || ''

    if (querySearch && pageState?.table?.display?.list?.length === 1)
      row.onToggleDetail()
  }, [])

    return (
        <>
            {isLoading && (
                <div className="order-table__loading">
                    <img src="/img/loading.gif"/>
                </div>
            )}
            <Tr
                {...props}
                className="order-table__row"
                extra={
                    <RowOrderExtra
                        id={detail?.id}
                        active={row.shouldOpenDetail}
                        data={detail?.active}
                        rowData={orderRow}
                    />
                }
                data-active={row.shouldOpenDetail}
                onClick={row.onToggleDetail}
            >
                <Td className="order-table__cell" data-type="td" style={{margin: '0 12px'}}>
                    <Checkbox
                        checked={row.isSelected}
                        disabled={data?.shipping_status_id === '15'}
                        onClick={e => {
                            e.stopPropagation()
                            if (data?.shipping_status_id === '15') return
                            row.onCheckboxChange()
                        }}
                    />
                </Td>
                <Td className="order-table__cell" data-type="td" style={{width: 234, flex: 1}}>
                    <CellCodeOrder
                        id={data.id}
                        inventory={codeOrder.haveInventory}
                        time={codeOrder.dateTime}
                    />
                </Td>
                <ListTBody
                    list={configApplyList}
                    data={data}
                    reportCustomer={value => setReportCustomerModalData(value)}
                    payment={payment}
                    isHiddenFee={isHiddenFee}
                    isManage={isManage}
                />
                <Td className="order-table__cell" data-type="td" style={{width: 60}}/>
                <Td
                    className="order-table__cell option-col"
                    data-menu="true"
                    data-type="td"
                    onClick={e => e.stopPropagation()}
                >
                    <button
                        className="order-table__detail-toggle"
                        data-active={row.shouldOpenDetail}
                        onClick={row.onToggleDetail}
                    >
                        {ORDER_ICONS.up}
                    </button>
                    <RowMenuPopover
                        id={data.id}
                        inventory={codeOrder.haveInventory}
                        shippingStatus={row.data.shipping_status_id}
                        dataOrder={data}
                        infoEOrder={infoEOrder}
                        onActionClick={handleActionApply}
                    />
                </Td>
            </Tr>
            {!!confirmDeleteModalData && (
                <ConfirmDeleteModal {...confirmDeleteModalData} />
            )}
            {!!reportCustomerModalData && (
                <ReportCustomerModal data={reportCustomerModalData}/>
            )}
        </>
    )
}


const ListTBody = ({
                       list,
                       data,
                       reportCustomer,
                       payment,
                       isHiddenFee,
                       isManage
                   }) => {
    const {t} = useTranslation()
    return list?.map(tbody => {
        const serviceFee = +data?.service_fee + +data?.service_vat_fee
        const revenue = +data?.order_vat_value === 0 ? +data?.total_amount - +data?.vat - +data?.service_vat_fee : +data?.total_amount - +data?.order_vat_value - +data?.service_vat_fee
        switch (tbody.code) {
            case 'customer':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 290}}>
                        <CellCustomer
                            id={data?.customer_id}
                            name={data.customer_name}
                            phone={data.customer_mobile}
                            report={data?.total_reports || 0}
                            onReportClick={() =>
                                reportCustomer({
                                    customer: {
                                        name: data?.customer_name,
                                        phone: data?.customer_mobile,
                                        total: data?.total_reports,
                                    },
                                    mode: 'view',
                                    onClose: () => reportCustomer(null),
                                })
                            }
                        />
                    </Td>
                )
            case 'order_origin':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 158}}>
                        <Tooltip
                            title={data.order_origin_name}
                            className="order-table__tooltipV2"
                        >
                            <Text>{data.order_origin_name}</Text>
                        </Tooltip>
                    </Td>
                )
            case 'pay':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 150, justifyContent: 'center'}}>
                        <CellPayment type={payment.status}/>
                    </Td>
                )
            case 'order_value':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 180, justifyContent: 'end'}}>
                        <Text>{formatMoney(data?.total_amount)}</Text>
                    </Td>
                )
            case 'service_fee':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 190, justifyContent: 'end'}}>
                        <Text>{formatMoney(+serviceFee)}</Text>
                    </Td>
                )
            case 'vat':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 190, justifyContent: 'end'}}>
                        <Text>{
                            +data?.order_vat_value === 0 ? formatMoney(+data?.vat || 0) : formatMoney(+data?.order_vat_value || 0)
                        }</Text>
                    </Td>
                )
            case 'transport_fee':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 190, justifyContent: 'end'}}>
                        {
                            +isManage === 0 ?
                                +isHiddenFee === 0 ? <Text>{formatMoney(data?.ship_fee)}</Text> : '****** ₫'
                                : <Text>{formatMoney(data?.ship_fee)}</Text>
                        }

                    </Td>
                )
            case 'total_balance':
                return (
                    <Td className="order-table__cell" data-type="td" style={{width: 190, justifyContent: 'end'}}>
                        <Text>
                            {
                                +data?.has_inventory === 1 ? formatMoney(+revenue) : formatMoney(data?.total_amount)
                            }
                        </Text>
                    </Td>
                )

      case 'order_status':
        return (
          <Td
            className="order-table__cell"
            data-type="td"
            style={{width: 290, justifyContent: 'center'}}
          >
            <CellStatusOrder id={data.shipping_status_id}>
              {t(`delivery_status_${data.shipping_status_id}`)}
            </CellStatusOrder>
          </Td>
        )
      case 'inventory':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 300}}>
            {data?.warehouse_name || '---'}
          </Td>
        )
      case 'order_note':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 500}}>
            {data?.order_note || '---'}
          </Td>
        )
      case 'order_content':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 500}}>
            {data?.item_details || '---'}
          </Td>
        )
      case 'delivery_id':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.billcode || '---'}
          </Td>
        )
      case 'sender_name':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.sender_name || '---'}
          </Td>
        )
      case 'delivery_date':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            <Text>
              {!!data?.dt_created ? fDateTimeSuffix(data?.dt_created) : '---'}
            </Text>
          </Td>
        )
      case 'partner':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 300}}>
            {data?.partner_ship_name || '---'}
          </Td>
        )
      case 'address':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 350}}>
            <Text>{data?.customer_address || '---'}</Text>
          </Td>
        )
      case 'city':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.city_name || '---'}
          </Td>
        )
      case 'district':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.district_name || '---'}
          </Td>
        )
      case 'ward':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.ward_name || '---'}
          </Td>
        )
      case 'cod':
        return (
          <Td
            className="order-table__cell"
            data-type="td"
            style={{width: 234, display: 'flex', justifyContent: 'end'}}
          >
            <Text>{formatMoney(data?.cod_amount) || '---'}</Text>
          </Td>
        )
      case 'payment':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            <Text>
              {t(
                ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_PAYMENT_METHODS[
                  data?.payment_method
                ],
              ) || '---'}
            </Text>
          </Td>
        )
      case 'delivery_note':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 300}}>
            {data?.note || '---'}
          </Td>
        )
      case 'weight':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.weight || 0}
          </Td>
        )
      case 'length':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {+data?.shipping_status_id === 21
              ? data?.draft_data?.lengh || 0
              : data?.lengh || 0}
          </Td>
        )
      case 'width':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {+data?.shipping_status_id === 21
              ? data?.draft_data?.width || 0
              : data?.width || 0}
          </Td>
        )
      case 'height':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {+data?.shipping_status_id === 21
              ? data?.draft_data?.height || 0
              : data?.height || 0}
          </Td>
        )
      case 'employee_name':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.fullname || '---'}
          </Td>
        )
      case 'phone':
        return (
          <Td className="order-table__cell" data-type="td" style={{width: 234}}>
            {data?.phone || '---'}
          </Td>
        )
      default:
        return ''
    }
  })
}
