
export const chartOptionsProduct={
    chart: {
        width: '380px',
        stacked: true,
    },
    colors: [
        '#3955F6'
    ],
    stroke: {
        width: 1,
    },
    legend: {
        show:true,
    },
    tooltip: {
        custom:({ series, dataPointIndex, w, })=>{
            const listData = w?.config?.listData
            const tab = w?.config?.tabChart
            const typeValue = (value, type)=>{
                switch (type) {
                    case 'ratio_cancel':
                        return Math.round(value* 100)/100 +  '%';
                    default:
                        if (+value === 0 || Math.round(+value*10)/10 < 100) return value
                        else if (+value < 1000) return   Math.round(value*10 / 100)/10+ 'k'
                        else if (+value < 1000000) return   Math.round(value*10 / 1000)/10+ 'k'
                        else if (+value < 100000000) return  Math.round(value*10 / 1000000)/10 + 'tr'
                        else if (+value < 1000000000) return  Math.round(value*10 / 1000000)/10 + 'tr'
                        else if (+value < 100000000000) return  Math.round(value*10 / 1000000000)/10 + 'tỷ'
                        else return  Math.round(value*10 / 1000000000)/10 + 'tỷ'
                }
            }
            let html = '<div class="report-arrow_box">'
            html += '<div class="item-tooltip"> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                    '<circle cx="9" cy="9" r="4.5" stroke=\'#3955F6\' stroke-width="3"/>' +
                '</svg>'+
                '<div style="margin-left: 4px">'+
                '<span>' + listData[dataPointIndex].product_name + '</span>'+
                '<p>' + typeValue(series[0][dataPointIndex], tab) + '</p>' +
                '</div>'+
                '</div>'

            html += '</div>'
            return html
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
            borderRadius: 0,
            barHeight: '35%',
            columnWidth: '100%',
            dataLabels: {
                position: 'center',
            }
        },

    },
    grid: {
        show: true,
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        },
        padding: {
            right: 24,
            left: 24
        },  // you can either change hear to disable all grids
    },
    yaxis: {
        labels: {
            position: 'top',
            align: 'left',
            maxWidth: 200,
            style: {
                colors: ['#00081D'],
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
            },
        },
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                show: false
            }
        }
    }
    ]
}