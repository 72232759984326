import React, {useState} from 'react';
import {Box, Modal} from "@material-ui/core";
import {Text} from "../../../../common/text";
import styled from "styled-components";
import {Button} from "../../../../common/button";
import useOrderTHead from "../../hooks/useOrderTHead";
import {Checkbox} from "../../../../common/form/checkbox";
import {DndCol} from "../../../../common/dndCol";
import {Input} from "../../../../common/form/input";
import {ORDER_ICONS} from "../../interfaces/_icons";
import { useTranslation } from 'react-i18next'

const ConfigTable = () => {
  const { configItem } = useOrderTHead()
  const [hover, setHover] = useState()
  const {t} = useTranslation()

  return (
    <Modal open={true}>
      <Box>
        <StyledConfigTable>
          <div>
            <Text as={'p'} fontSize={20} fontWeight={600}>{t("column_config")}</Text>
            <Text as={'p'} fontSize={16} fontWeight={400} style={{marginTop: 8}}>
              {t('config_column_content')}
            </Text>
          </div>
          <div className={'config-table__body'}>
            <div className={'config-table__list'}>
              <div className={'config-table__list-title'}>
                <Text fontWeight={600}>
                  {t('config_column_content_1')}
                </Text>
                <Input
                  icon={ORDER_ICONS.searchMd}
                  placeholder={t('config_column_content_1')}
                  className={'config-table__search'}
                  onChange={e => configItem.method.onSearch(e?.target?.value)}
                />
              </div>
              <div className={'config-table__list-column scroll-custom'}>
                {configItem?.list?.length > 0
                  ? <>
                    {configItem?.list?.map(item => (
                      <div
                        key={item.id}
                        className={'config-table__item'}
                        onClick={() => {
                          if(item.code !== 'order_id') {
                            configItem?.method?.toggleItemColumn(item)}
                        }
                        }
                      >
                        <Checkbox
                          checked={item.active || item.code === 'order_id'}
                          disabled={item.code === 'order_id'}
                        />
                        <Text style={{cursor: `${item.code !== 'order_id' ? 'pointer' : 'no-drop'}`}}>
                          {t(item.name)}
                        </Text>
                      </div>
                    ))}
                  </>
                  : <Text as={'p'} style={{margin: 'auto'}}>
                    {t('config_column_content_3')}
                  </Text>}
              </div>
            </div>
            <div className={'config-table__active-list scroll-custom'}>
              <div className={'config-table__dnd-item'}>
                <div style={{display: 'flex', cursor: 'no-drop'}}>
                  { ORDER_ICONS?.dndColumn}
                  <Text style={{marginLeft: 8}}>{t('order_id')}</Text>
                </div>
              </div>
              <DndCol
                initial={configItem?.activeList?.filter(item => item.code !== 'order_id')?.map(item => ({
                  id: item?.id,
                  content:  (
                    <div className={'config-table__dnd-item'}
                         onMouseOver={() => setHover(item.id)}
                         onMouseLeave={() => setHover('')}
                    >
                      <div style={{display: 'flex'}}>
                        {ORDER_ICONS?.dndColumnLight}
                        <Text style={{marginLeft: 8}}>{t(item?.name)}</Text>
                      </div>
                      {hover === item.id &&
                      <Text style={{cursor: `${item.code !== 'order_id' ? 'pointer' : 'no-drop'}`}}
                            onClick={() => {
                              if(item.code !== 'order_id') {
                                configItem?.method?.toggleItemColumn(item)}
                            }
                            }
                      >
                        {ORDER_ICONS.xRed}
                      </Text>
                      }
                    </div>
                  ),
                }))}
                style={{width: '100%'}}
                onChange={configItem?.method?.onChangeIndex}
              />
            </div>
          </div>
          <div className={'config-table__group-button'}>
            <Text
              color={'#1162FF'}
              style={{cursor: 'pointer'}}
              onClick={configItem.method.defaultConfigTable}
            >
              {t('set_default')}
            </Text>
            <div>
              <Button
                size={'sm'}
                appearance={'ghost'}
                className={'config-table__button'}
                onClick={configItem.method.cancelConfigTable}
              >
                {t("general_cancel")}
              </Button>
              <Button
                size={'sm'}
                className={'config-table__button config-table__button-apply'}
                onClick={configItem.method.applyConfigTable}
              >
                {t("general_apply")}
              </Button>
            </div>
          </div>
        </StyledConfigTable>
      </Box>
    </Modal>
  )
}

export default ConfigTable


const StyledConfigTable = styled.ul`
  display: flex;
  width: 752px;
  height: 660px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  position: absolute;
  left: calc(50% - 752px/2);
  top: calc(50% - 660px/2);
  
  border-radius: 8px;
  border-bottom: 1px solid #E2EAF8;
  background: var(--sematic-white, #FFF);
  
  .config-table {
    &__body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 12px;
      grid-row-gap: 0;
      width: 100%;
    }
    &__list {
      position: relative;
      display: flex;
      padding: 16px;
      padding-bottom: 0;
      height: 478px;
      
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      
      border-radius: 4px;
      border: 1px solid #D6E1F5;
      background: #FFF;
      
      &-title {
        width: 92%;
        z-index: 1;
        background: white;
        position: absolute;
        top: 0;
        padding-top: 16px;
      }
      
      &-column {
        height: 473px;
        width: 100%;
        overflow: scroll;
        
        display: flex;
        padding: 0 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        
        padding-top: 80px;
        padding-left: 21px;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
    &__search {
      margin-top: 8px;
      width: 100%;
    }
    &__active-list {
      display: flex;
      padding: 16px;
      padding-bottom: 0;
      height: 478px;
      overflow: scroll;
      
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      background: #F6F6FB;
    }
    &__dnd-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      cursor: all-scroll;
      height: 25px;
    }
    &__group-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &__button {
      width: 110px;
      &-apply {
        margin-left: 8px;
      }
    }
    &__item {
      display: flex;
      gap: 8px;
      margin: 18px 0;
      cursor: pointer;
    }
  }
`
