import React from "react";
import {Modal} from "@mui/material";
import { StyleUpdateOrderModal} from "./styled";
import {GLOBAL_ICON} from "../../../../../../../../../interfaceGlobal";
import {Button} from "../../../../../../../../../common/button";
import {Text} from "../../../../../../../../../common/text";
import {useTranslation} from "react-i18next";
export const UpdateOrderModal = ({
        open,
        buttonConfirm,
        title,
        label,
        isFooter = true,
        isMargin = true,
        statusSuccess,
        ...props
    })=>{
    return(
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{display:'flex', alignItems:'center', justifyContent:'center'}}
        >
                <StyleUpdateOrderModal>
                    {!statusSuccess?.success &&
                        <div>
                            <Text fontWeight={600} fontSize={20}>
                                {title}
                            </Text>
                            <br/>
                            {!!label && <Text>{label}</Text>}

                        </div>
                    }
                    {!statusSuccess?.success ?
                        <div data-marginTop={isMargin} data-footer={isFooter} className={'ecommerce-order-modal-body'}>
                            {props.children}
                        </div>
                        :
                        <ComponentSuccess item={statusSuccess}/>
                    }

                    {isFooter &&  <div className='ecommerce-order-modal-footer'>
                        {!!buttonConfirm?.close?.title &&
                        <Button
                            size={'sm'}
                            appearance={'ghost'}
                            style={{
                                width: 110,
                                fontSize: 14,
                                height: 32
                            }}
                            onClick={buttonConfirm?.close?.onClose}
                        >
                            {buttonConfirm?.close?.title}
                        </Button>
                        }
                        {!!buttonConfirm?.confirm?.title &&
                        <Button
                            size={'sm'}
                            style={{
                                width: 110,
                                fontSize: 14,
                                height: 32,
                                marginLeft: 8
                            }}
                            onClick={buttonConfirm?.confirm?.onConfirm}
                        >
                            {buttonConfirm?.confirm?.title}
                        </Button>
                        }
                    </div>
                    }

                </StyleUpdateOrderModal>
        </Modal>
    )
}

const ComponentSuccess = ({item})=>{
  const { t } = useTranslation()
    const totals = [
        {...item.totalSuccess,icon: GLOBAL_ICON.tickSuccess},
        {...item.totalError,icon: GLOBAL_ICON.tickFail}
    ]
    return(
        <div>
            <div style={{textAlign:'center'}}>
                <Text fontWeight={600} fontSize={20}>{item?.title}</Text>
                <div style={{margin:"38px 0 22px 0 "}}>
                    {
                        item?.status ? GLOBAL_ICON.success : GLOBAL_ICON.faild
                    }
                </div>
                <Text fontSize={16}>{item?.message}</Text>
            </div>
            <div style={{margin:"32px 0"}}>
                {
                    totals?.map((map, i)=>{
                        return(
                            <div key={i} className='ecommerce-order-modal-status-group' >
                                <div>{map.icon}</div>
                                <div style={{marginLeft: 8}}>
                                    <Text fontSize={18} fontWeight={700} color={'#223E62'}>{map?.total} {t('order_')}</Text>
                                    <br/>
                                    <Text color={'#7D9AC0'}>{t(map.title)}</Text>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{textAlign:'right'}}>
                <Button
                    size={'sm'}
                    appearance={'ghost'}
                    style={{
                        width: 110,
                        fontSize: 14,
                        height: 32
                    }}
                    onClick={item?.onClose}
                >
                  {t('general_close')}
                </Button>
            </div>
        </div>
    )
}