import {fNumber} from "../../../../../../../../../../util/formatNumber";

export const chartOptionsRatioProduct={
    chart: {
        width: '380px',
        height: '100%',
        parentHeightOffset: 50,
    },
    colors: [
        '#2276FC', '#66C8FF','#F66','#FF9D0B','#A20BFF','#00AB56',
        '#5FD941','#F3EA0F','#DA00C4'
    ],
    stroke: {
        width: 2,
    },
    legend: {
        show:false,
    },
    dataLabels: {enabled: false, dropShadow: {enabled: false}},
    tooltip: {
        enabled: true,
        fillSeriesColor: false,
        custom: function({series, seriesIndex, dataPoint, w}) {
            const orders = w?.config?.listData
            const colors = w?.config?.colors
            let title = w?.config?.listData.map(map=>map?.title)
            let order =  w?.config?.language?.order
            const svg = (color)=>{
                return `
                 <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="4.5" stroke=${color} stroke-width="3"/>
                </svg>
                `
            }
            return '<div class="arrow_box" style="width: fit-content;height:53px;padding: 6px 12px;display: flex">' +
                '<div class="img-tooltip">' +
                    svg(colors[seriesIndex])
                +
                '</div>' +
                '<div>' +
                '<p style="font-size:14px;font-weight:400;line-height:140%">'+title[seriesIndex]+'</p>' +
                '<span style="font-size:15px;font-weight:600;line-height:140%">' + orders[seriesIndex]?.total + ` ${order} </span>` +
                '</div>' +
                '</div>'
        }
    },
    plotOptions: {
        donut: {donut: {labels: {show: false}}},
        pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1.2,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            },
            donut: {
                size: '75%',
                background: 'transparent',
                labels: {
                    show: true,
                    name: {
                        show: true,
                    },
                    value: {
                        show: true,
                        fontSize: '14px',
                        fontFamily: 'SF Pro Display',
                        color: '#000000',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        offsetY: 2,
                        formatter: function (val) {
                            return `${fNumber(val)}`
                        }
                    },
                    total: {
                        show: true,
                        showAlways: false,
                        fontSize: '14px',
                        label:'order_',
                        fontFamily: 'SF Pro Display',
                        color: '#000000',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        formatter: function (w) {
                            return fNumber(w.globals.seriesTotals.reduce((a, b) => {
                                return a + b
                            }, 0))

                        }
                    }
                }
            },
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200
            },
            legend: {
                show: false
            }
        }
    }
    ]
}