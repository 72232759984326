import React from "react";
import {GLOBAL_ICON} from "../../../../../../../interfaceGlobal";


export const ECOMMERCE_LIST=[
    {id: 1, name: 'all', value: 'all', type: 'all'},
    {id: 2, name: 'Tiktok Shop', value: 'tiktok', type:'tiktok'},
    {id: 3, name: 'Shopee', value: 'shopee', type:'shopee'},
    {id: 4, name: 'Lazada', value: 'lazada', type:'lazada'},
]

export const transformImageEcommerce = (type, size)=>{
    switch (type) {
        case 'shopee':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoShopee.png'} width={size} height={size} alt={'logoShopee'}/>
        case 'lazada':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoLazada.png'} width={size} height={size} alt={'logoLazada'}/>
        case 'tiktok':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoTiktok.png'} width={size} height={size} alt={'logoTiktok'}/>
        case 'upos':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoupos.png'} width={size} height={size} alt={'logoUpos'}/>
        default:
            break;

    }
}
export const transformProductData = products => {
    return products?.map(item => {
        item.ecom_id = item.id
        item.id = item.ecom_product_id
        return item
    })
}


export const FILTER_DATE=[
    {id: 1, name: 'for_week', value: 'seven days'},
    {id: 2, name: 'for_month', value: 'thirty days'},
]

export const IMPORTANT_NUMBER_TAB=[
    {
        id: 1,
        title:'total_orders',
        tooltiTitle:'e_com_total_order',
        isPrice: false,
        value: 10000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#369AFE',
        percent: 0,
        tab:"total_order",
        data:[0, 0, 0, 0, 0, 0, 0],
        name:'total_orders',
        fill: "gradient",
        type:"area",
        typeValue:'orders'
    },
    {
        id: 2,
        title:'revenue',
        tooltiTitle:'e_com_total_order_note',
        isPrice: true,
        value: 8500000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#00AB56',
        percent: 0,
        tab:'revenue',
        name:'revenue',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
        typeValue:''
    },
    {
        id: 3,
        title:'e_com_cancel_refund_order',
        tooltiTitle:'e_com_cancel_refund_order_note',
        isPrice: false,
        value: 50000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#3955F6',
        percent: 0,
        tab:"total_cancel",
        name:'e_com_cancel_refund_order',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
        typeValue:'orders'
    },
    {
        id: 4,
        title:'e_com_revenue_percent_order',
        tooltiTitle:'e_com_revenue_average_order_note',
        isPrice: true,
        value: 1000000000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#FF9F41',
        percent: 0,
        tab:"agv_revenue",
        name:'e_com_revenue_percent_order',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
        typeValue:''
    },
    {
        id: 5,
        title:'e_com_average_product',
        tooltiTitle:'e_com_average_product_note',
        isPrice: false,
        value: 5000000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#FF424E',
        percent: 0,
        tab:"total_discount",
        name:'e_com_average_product',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
        typeValue:'product_page_breadcrum_first'
    },
]

export const TOP_PRODUCT_PANEL=[
    {
        id: 1,
        label: 'report__quantity_sold',
        icon:GLOBAL_ICON.topProductSale,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        isValue:'total_quantity'
    },
    {
        id: 2,
        label: 'general_sales_value',
        icon:GLOBAL_ICON.topProductSale,
        iconTooltip: GLOBAL_ICON.question,
        value: 0,
        tooltip: 'report__sale_value_tooltip',
        isValue:'total_amount'
    },
    {
        id: 3,
        label: 'report__total_discount_on_product',
        icon:GLOBAL_ICON.topProductPromo,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        isValue:'total_discount'
    },
]

export const REPORT_UPDATE_ORDER_DATE=[
    {name: 'Yesterday', value:'yesterday'},
    {name: 'three_day_before', value:'three days ago'},
    {name: 'five_day_before', value:'five days ago'},
    {name: '7_days_ago', value:'seven days ago'},
    {name: '30_days_ago', value:'thirty days ago'},
    {name: 'full_time_has_desc', value:'all of times'},
]