import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {TabProductChart} from "./_tabProductChart";
import {ChartTopProduct} from "./_chartTopProduct";
import {TableLayout} from "../../../../../../../../layouts/tableLayout";
import {ProductTableHeader} from "./_productTableHeader";
import {ProductTableBody} from "./_productTableBody";
import {useTranslation} from "react-i18next";
export const ProductChart = ()=>{
  const { t } = useTranslation()
    return (
        <StyleProductChart>
            <div>
                <Text fontSize={18} fontWeight={600}>{t('e_com_top_10_product')}</Text>
            </div>
            <TabProductChart/>
            <ChartTopProduct/>
            <div style={{marginTop: 32}}>
                <TableLayout
                    table={{
                        tHead: <ProductTableHeader/>,
                        tBody: <ProductTableBody/>,
                    }}
                />
            </div>

        </StyleProductChart>
    )
}
const StyleProductChart = styled.div``