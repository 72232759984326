import { Text } from 'common/text'
import { Tooltip } from 'common/tooltip'
import { CustomToolTip } from 'Component/tooltip/CustomTooltip'
import { ORDER_ICONS } from 'Pages/refactorOrder/interfaces/_icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useAlert from "../../../../hook/useAlert";

export const CellCodeOrder = ({ id, inventory, time, ...props }) => {
  const { t } = useTranslation()
  const {showAlert} = useAlert()
  const handleCopy = (e,id)=>{
    e.stopPropagation()
    navigator.clipboard.writeText(id)
    showAlert({type:'success', content: t('copied')})
  }
  return (
    <StyledCellCodeOrder {...props}>
      {id && (
        <div className="cell-code-order__upper">
          <Tooltip placement="top-start" title={t('click_to_copy')}>
            <div onClick={(e)=>handleCopy(e,id)}>
              <Text as="b" className="cell-code-order__id">
                {id}
              </Text>
            </div>
          </Tooltip>
          <Tooltip
            placement="bottom-start"
            title={
              inventory
                ? t('order_deducted_from_inventory')
                : t('order_not_deducted_from_inventory')
            }
          >
            {inventory ? ORDER_ICONS.inventory : ORDER_ICONS.inventoryDanger}
          </Tooltip>
        </div>
      )}
      {time && (
        <div className="cell-code-order__under">
          <CustomToolTip title={t('order_date_created')}>{ORDER_ICONS.clock}</CustomToolTip>
          <Text
            className="cell-code-order__time"
            color="#7C88A6"
            fontSize={13}
            lineHeight={18}
          >
            {time}
          </Text>
        </div>
      )}
    </StyledCellCodeOrder>
  )
}

const StyledCellCodeOrder = styled.div`
  .cell-code-order {
    &__upper,
    &__under {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;

        cursor: pointer;
      }
    }

    &__id {
      margin-right: 4px;
    }

    &__time {
      margin-left: 4px;
    }
  }
`
