import React, {useContext, useEffect, useState} from "react";
import {EcommerceReportContext, EcommerceReportProvider} from "./provider/_context"
import {useEcommerceReport} from "./hook/useEcommerceReport";
import {PageHeader} from "../../../../layouts/pageHeader";
import {ECOMMERCE_BREADCUM} from "./interfaces/_contant";
import {TabListReport} from "./components/tabList";
import {useTranslation} from "react-i18next";
export const EcommerceReport = ()=>{
  const { t } = useTranslation()
    const {provider, methods} = useEcommerceReport()
    const {state, dispatch} = provider
    const [listBreadCum, setListBreadCum] = useState(ECOMMERCE_BREADCUM)
    useEffect(()=>{
      document.title = "Báo cáo sàn TMĐT"
        methods?.onFetchOrigin()
    },[])
    useEffect(()=>{
        let newList =[...listBreadCum]
        const findIndex = newList?.findIndex(find => +find?.id === 3)
        if(findIndex !== -1){
            newList[findIndex].name = state?.tab?.activeTab?.name
        }
        setListBreadCum(newList)
    },[state?.tab?.activeTab])
    return(
        <EcommerceReportProvider value={{pageState:state, pageDispatch: dispatch}} >
            <PageHeader
                breadcrumbLinks={listBreadCum}
                breadcrumbTitle={t('report')}
            />
            <TabListReport/>
        </EcommerceReportProvider>
    )
}
const useReportEcommerce = ()=> useContext(EcommerceReportContext)
export default useReportEcommerce