import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportProductFilter} from "../../hook/useReportProductFilter";
import {THEME_COLORS} from "../../../../../../../../common/theme/_colors";
import {useTranslation} from "react-i18next";
export const TabProductChart = ()=>{
  const { t } = useTranslation()
    const {chartTag} = useReportProductFilter()
    return (
        <StyleTabProductChart>
            {
                chartTag?.list?.map(map=>(
                    <div key={map?.id}
                        className={'report-top-product-tab'}
                         data-active={map?.tab === chartTag?.activeValue?.tab}
                         onClick={()=>chartTag?.onChange(map)}
                    >
                        <Text
                            color={map?.tab !== chartTag?.activeValue?.tab && '#535E79'}
                            fontSize={16}
                            fontWeight={600}
                        >
                            {t(map?.title)}
                        </Text>
                    </div>
                ))
            }
        </StyleTabProductChart>
    )
}
const StyleTabProductChart = styled.div`
  height: 54px;
  border-bottom: 1px solid #EBEEF5;
  display: flex;
  align-items: flex-end;
  gap: 24px;
  .report-top-product{
    &-tab{
      width: fit-content;
      padding: 16px 0;
      transition: 0.5s;
      position: relative;
      cursor: pointer;
      &::after{
        content: '';
        width: 0;
      }
      &[data-active = 'true']{
        &::after{
            content: '';
             position: absolute;
             left: 0;
             bottom: 0;
            display: block;
            width: 100%;
            background: ${THEME_COLORS?.primary_300};
            height: 2px;
        }
      }
    }
  }
`