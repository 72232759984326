import React from "react";
import {GLOBAL_ICON} from "../../../../../../../interfaceGlobal";


export const ECOMMERCE_LIST=[
    {id: 1, name: 'all', value: 'all', type: 'all'},
    {id: 2, name: 'Tiktok Shop', value: 'tiktok', type:'tiktok'},
    {id: 3, name: 'Shopee', value: 'shopee', type:'shopee'},
    {id: 4, name: 'Lazada', value: 'lazada', type:'lazada'},
]

export const transformImageEcommerce = (type, size)=>{
    switch (type) {
        case 'shopee':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoShopee.png'} width={size} height={size} alt={'logoShopee'}/>
        case 'lazada':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoLazada.png'} width={size} height={size} alt={'logoLazada'}/>
        case 'tiktok':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoTiktok.png'} width={size} height={size} alt={'logoTiktok'}/>
        case 'upos':
            return <img style={{marginRight: 4}} src={'/img/eCommerce/logoupos.png'} width={size} height={size} alt={'logoUpos'}/>
        default:
            break;

    }
}
export const transformProductData = products => {
    return products?.map(item => {
        item.ecom_id = item.id
        item.id = item.ecom_product_id
        return item
    })
}


export const FILTER_DATE=[
    {id: 1, name: 'for_week', value: 'seven days'},
    {id: 2, name: 'for_month', value: 'thirty days'},
]

export const REVENUE_ORDER_TAG=[
    {
        id: 1,
        title:'total_orders',
        tooltiTitle:'e_com_total_order',
        isPrice: false,
        value: 10000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#369AFE',
        percent: 0,
        tab:"total_order",
        data:[0, 0, 0, 0, 0, 0, 0],
        name:'total_orders',
        fill: "gradient",
        type:"area",

    },
    {
        id: 2,
        title:'revenue',
        tooltiTitle:'e_com_total_order_note',
        isPrice: true,
        value: 8500000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#00AB56',
        percent: 0,
        tab:'revenue',
        name:'revenue',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
    },
    {
        id: 3,
        title:'report__total_discount',
        tooltiTitle:'e_com_total_discount_note',
        isPrice: true,
        value: 1000000000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#FF9F41',
        percent: 0,
        tab:"total_discount",
        name:'report__total_discount',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
    },
    {
        id: 4,
        title:'e_com_cancel_refund_order',
        tooltiTitle:'e_com_cancel_refund_order_note',
        isPrice: false,
        value: 50000,
        lastWeek: 'e_com_compare_by_week',
        lastMonth: 'e_com_compare_by_month',
        color:'#3955F6',
        percent: 0,
        tab:"total_cancel",
        name:'e_com_cancel_refund_order',
        fill: "gradient",
        type:"area",
        data:[0, 0, 0, 0, 0, 0, 0],
    },

]

export const ORDER_STATUS_HANDLE=[
    {
        id: 1,
        title: 'e_com_wait_confirm',
        icon:GLOBAL_ICON.waitConfirm,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        status: 0
    },
    {
        id: 2,
        title: 'waiting_pickup',
        icon:GLOBAL_ICON.waitConfirm,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        status: 1
    },
    {
        id: 3,
        title: 'cancel_orders',
        icon:GLOBAL_ICON.cancel,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        status: 15
    },
    {
        id: 4,
        title: 'delivery_status_5',
        icon:GLOBAL_ICON.giveBack,
        iconTooltip: '',
        value: 0,
        tooltip: '',
        status: 5
    },
]

export const REPORT_ORDER_STATUS = [
    {id: 1, title: 'e_com_wait_confirm', status: 0},
    {id: 2, title: 'delivery_status_1', status: 1},
    {id: 3, title: 'e_com_waiting_delivery', status: 22},
    {id: 4, title: 'delivery_status_4', status: 4},
    {id: 5, title: 'delivery_status_23', status: 23},
    {id: 6, title: 'delivery_status_17', status: 17},
    {id: 7, title: 'return', status: 5},
    {id: 8, title: 'delivery_status_6', status: 6},
    {id: 9, title: 'delivery_status_15', status: 15},
]


export const REPORT_CHART_SERIES = [
    {id: 1, title: 'e_com_wait_confirm', status: 0, color:'#2276FC'},
    {id: 2, title: 'delivery_status_1', status: 1, color:'#66C8FF'},
    {id: 3, title: 'delivery_status_22', status: 22, color:'#F66'},
    {id: 4, title: 'delivery_status_4', status: 4, color:'#FF9D0B'},
    {id: 5, title: 'delivery_status_23', status: 23, color:'#A20BFF'},
    {id: 6, title: 'delivery_status_17', status: 17, color:'#00AB56'},
    {id: 7, title: 'delivery_status_5', status: 5, color:'#5FD941'},
    {id: 8, title: 'delivery_status_6', status: 6, color:'#F3EA0F'},
    {id: 9, title: 'delivery_status_15', status: 15, color:'#DA00C4'},

]

export const REPORT_ORDER_TABLE_HEAD=[
    {
        id: 1,
        name:'time',
        iconDeActive: GLOBAL_ICON.deActiveSortArrow,
        iconActive: GLOBAL_ICON.activeSortArrow,
        iconToolTip: '',
        tooltip: '',
        type:'date'
    },

    {
        id: 2,
        name:'revenue',
        iconDeActive: GLOBAL_ICON.deActiveSortArrow,
        iconActive: GLOBAL_ICON.activeSortArrow,
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_total_value_order',
        type:'revenue'
    },
    {
        id: 3,
        name:'order',
        iconDeActive: GLOBAL_ICON.deActiveSortArrow,
        iconActive: GLOBAL_ICON.activeSortArrow,
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_total_order',
        type:'order'
    },
    {
        id: 4,
        name:'e_com_revenue_percent_order',
        iconDeActive: GLOBAL_ICON.deActiveSortArrow,
        iconActive: GLOBAL_ICON.activeSortArrow,
        iconToolTip: GLOBAL_ICON.question,
        tooltip: 'e_com_revenue_average_order',
        type:'agv_revenue'
    },
]