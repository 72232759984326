import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import store from './Store/index'
import {Provider} from 'react-redux'
//css
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './Pages/ControlPanel/component/topproduct/index.scss'
import './common/form/datePicker/index.scss';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>
)
