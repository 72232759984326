import React from 'react';
import {transformImageEcommerce} from "../../../interface/_contant";
import {AlternativeAutoComplete} from "../../../../../../../../../common/form/autoComplete/_alternativeAutoComplete";
import {Text} from "../../../../../../../../../common/text";
import {AutoCompleteSingleOption} from "../../../../../../../../orderSingle/components/autocompleteSingleOption";
import useQuickConnect from "../../../hook/useQuickConnect";
import {useTranslation} from "react-i18next";

const SelectStore = ({...props}) => {
  const { t } = useTranslation()
  const {store, methods} = useQuickConnect()

  return (
    <AlternativeAutoComplete
      {...props}
      // main input
      inputProps={{
        categoryList: [], // menu list in category dropdown
        categoryValue: {name: 'booth', value: ''}, // if not exist this value -> default category: categoryList[0]
        categoryWidth: 140,
        categoryHidden: true,
        placeholder: t('select_stall'),
        readOnly: true,
        value: store?.value?.shop_short_name || store?.value?.shop_name || '',
        validateText: store?.validate || '',
        validateType: "danger"
      }}
      // search menu dropdown
      menuProps={{
        empty: ''
          // province.list.length <= 0 ? 'Không tìm thấy tỉnh / thành phố' : '',
      }}
      // search input in dropdown menu
      searchInputProps={{
        placeholder: t('find_stall'),
        // value: province.keyword || '',
        onChange: methods.onChangeKeyword,
      }}
    >
      {store?.list?.length > 0
        ? (
          <>
            {store?.list?.map((item, i) => (
              <AutoCompleteSingleOption
                key={item.id || i}
                // data-active={item.id === stores?.value}
                onClick={() => methods.onSelectStore(item)}
              >
                {transformImageEcommerce(item?.ecom_type,32)}
                {item?.shop_short_name || item.shop_name}
              </AutoCompleteSingleOption>
            ))}
          </>
        )
        : (
          <div className={'ecom-product-select--empty'}>
            <img src={'/img/empty-multiple-choice.png'} alt={'img'} />
            <Text as={'p'}
                  className={'ecom-product-select--empty-text'}
                  fontSize={13}
            >
              {t('ecom_not_find_stall')}
            </Text>
          </div>
        )
      }
    </AlternativeAutoComplete>
  )
}

export default SelectStore