import React from 'react';
import styled from "styled-components";
import {Text} from "../../common/text";
import config from "../../config";
import {useTranslation} from "react-i18next";

const NotPermission = ({...props}) => {
  const {t} = useTranslation()
  return (
    <StyledNotPermission {...props}>
      <img src={'/img/not-license.png'} alt={'img'} className={'not-licensed-img-primary'}/>
      <Text fontWeight={600} fontSize={24} as={'p'} className={'not-licensed-text not-licensed-text-first'}>{t('not_permission_title')}</Text>
      <Text fontSize={18} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-text-second'}>{t('not_permission_content_1')}</Text>
      <Text fontSize={18} as={'p'} color={'#7C88A6'} className={'not-licensed-text'}>{t('not_permission_content_2')}</Text>
      <Text fontSize={16} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-hotline'}>{t('not_licensed_contact')}</Text>
      <Text fontWeight={600} fontSize={16} color={'#1E9A98'} className={'not-licensed-text not-licensed-text-phone'}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_19910_403588)">
            <path d="M12.5867 13.6266C12.3333 13.8599 12 13.9866 11.66 13.9999C10.7333 13.9999 8.78 13.4333 5.66667 10.3466C2.56 7.25993 2.02 5.2866 2 4.39326C2 4.05326 2.13999 3.72659 2.36666 3.47326L3.46667 2.37992C3.59334 2.24658 3.74667 2.14658 3.92 2.07991C4.1 2.01324 4.28001 1.99325 4.46667 2.00659C4.64667 2.01992 4.82 2.07992 4.98 2.17325C5.14 2.26659 5.27333 2.39325 5.37333 2.54658L6.26666 3.81323C6.35333 3.95989 6.41334 4.12658 6.42668 4.29325C6.42668 4.46658 6.4 4.63325 6.33333 4.79325L5.69999 5.93989C5.65999 6.02656 5.63999 6.1266 5.63999 6.2266C5.63999 6.3266 5.65999 6.41993 5.69999 6.51326C6.09999 7.31993 6.64667 8.04656 7.32001 8.64656C7.92667 9.31989 8.64667 9.86659 9.46 10.2599C9.54667 10.2999 9.65334 10.3199 9.75334 10.3199C9.84667 10.3199 9.95333 10.2999 10.04 10.2599L11.1933 9.66658C11.3467 9.59325 11.52 9.5599 11.6867 9.57324C11.86 9.5799 12.0267 9.63326 12.1667 9.7266L13.44 10.6266C13.5933 10.7266 13.7267 10.8599 13.82 11.0199C13.9067 11.1799 13.9733 11.3532 13.9867 11.5332C14.0067 11.7132 13.9867 11.8999 13.9133 12.0732C13.84 12.2466 13.74 12.3999 13.6067 12.5266L12.5867 13.6266Z" stroke="#1E9A98" strokeMiterlimit="10"/>
          </g>
          <defs>
            <clipPath id="clip0_19910_403588">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <a href={`tel:${config.PHONE}`}>{config.PHONE} {t('not_licensed_click_2')}</a>
      </Text>
      <Text fontWeight={600} fontSize={16} color={'#1E9A98'} className={'not-licensed-text not-licensed-text-phone'}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_19910_403588)">
            <path d="M12.5867 13.6266C12.3333 13.8599 12 13.9866 11.66 13.9999C10.7333 13.9999 8.78 13.4333 5.66667 10.3466C2.56 7.25993 2.02 5.2866 2 4.39326C2 4.05326 2.13999 3.72659 2.36666 3.47326L3.46667 2.37992C3.59334 2.24658 3.74667 2.14658 3.92 2.07991C4.1 2.01324 4.28001 1.99325 4.46667 2.00659C4.64667 2.01992 4.82 2.07992 4.98 2.17325C5.14 2.26659 5.27333 2.39325 5.37333 2.54658L6.26666 3.81323C6.35333 3.95989 6.41334 4.12658 6.42668 4.29325C6.42668 4.46658 6.4 4.63325 6.33333 4.79325L5.69999 5.93989C5.65999 6.02656 5.63999 6.1266 5.63999 6.2266C5.63999 6.3266 5.65999 6.41993 5.69999 6.51326C6.09999 7.31993 6.64667 8.04656 7.32001 8.64656C7.92667 9.31989 8.64667 9.86659 9.46 10.2599C9.54667 10.2999 9.65334 10.3199 9.75334 10.3199C9.84667 10.3199 9.95333 10.2999 10.04 10.2599L11.1933 9.66658C11.3467 9.59325 11.52 9.5599 11.6867 9.57324C11.86 9.5799 12.0267 9.63326 12.1667 9.7266L13.44 10.6266C13.5933 10.7266 13.7267 10.8599 13.82 11.0199C13.9067 11.1799 13.9733 11.3532 13.9867 11.5332C14.0067 11.7132 13.9867 11.8999 13.9133 12.0732C13.84 12.2466 13.74 12.3999 13.6067 12.5266L12.5867 13.6266Z" stroke="#1E9A98" strokeMiterlimit="10"/>
          </g>
          <defs>
            <clipPath id="clip0_19910_403588">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <a href={`tel:${config.CSKH}`}>{config.CSKH} ({t('not_licensed_cskh')})</a>
      </Text>
    </StyledNotPermission>
  )
}

export default NotPermission

export const StyledNotPermission = styled.div`
  height: 90vh;
  text-align: center;
  .not-licensed {
    &-img-primary {
      margin-top: 10vh;
    }
    &-text {
      text-align: center;
      width: 100% !important;
      &-first {
        margin-top: 32px;
      }
      &-second {
        margin-top: 24px;
      }
      &-phone {
        margin-right: 16px;
        & svg {
          vertical-align: middle;
        }
        & a {
          color: #1E9A98;
        }
      }
    }
    &-hotline {
      margin: 32px 0 12px 0;
    }
  }
`
