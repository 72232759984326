import React, { createContext, useReducer, useEffect, useState } from 'react'

import Navigation from 'Component/NavBar/navBar'
import ProductReducer from './Pages/ProductManagement/stores/reducer'
import { initProductState } from './Pages/ProductManagement/stores/initState'
import { initialSettingState } from './Pages/Setting/stores/initState'
import SettingReducer from './Pages/Setting/stores/reducer'
import {sendRequest, sendRequestAuth} from './api/api'
import { getUrlStoreSetting } from './api/url'
import useGlobalContext from '../src/containerContext/storeContext'
import { generateTokenDevices, getCookie, setCookie, formatMoney } from './util/functionUtil'
import {noticeCompAction} from "./Component/NotiComp/provider/_action";
import config from "./config";
import ModalNotification from "./Component/modalNotification";
export const SettingContext = createContext(initialSettingState)
const SettingStore = ({ children }) => {
  const [state, dispatch] = useReducer(SettingReducer, initialSettingState)
  return (
    <SettingContext.Provider value={[state, dispatch]}>
      {children}
    </SettingContext.Provider>
  )
}


export const ProductContext = createContext(initProductState)
const ProductStore = ({ children }) => {
  const [state, dispatch] = useReducer(ProductReducer, initProductState)
  return (
    <ProductContext.Provider value={[state, dispatch]}>
      {children}
    </ProductContext.Provider>
  )
}

const LayoutWrapper = (params) => {
  const [state, dispatch] = useGlobalContext()
  const [openModal, setOpenModal] = useState(false)
  const [detailNotice,setDetailNotice] = useState({})
  const getDataStoreSetting = () => {
    const url = getUrlStoreSetting()
    sendRequest('get', url)
      .then(res => {
        if (res && res.data && res.data.success) {
          const data = res.data.data || []
          dispatch({ type: 'GET_STORE_INFO', payload: data })
        } else {
        }
      })
      .catch(error => {
        console.log(`error${error}`)
      })
  }
  const closeModal = ()=>{
    setOpenModal(false)
    fetchAfterCloseModal()
  }
  const checkIsPopup =  async (data)=>{
    const response = await sendRequestAuth('get', `${config.API}/notices/detail/${data?.id}`)
    if(response?.data?.success){
      setOpenModal(true)
      setDetailNotice(response?.data?.data)
    }
  }

  const checkDateTime = (start, end)=>{
    const startExprite = new Date(start)
    const endExprite = new Date(end)
    const date = new Date()
    if(startExprite?.getTime() <= date?.getTime() && endExprite?.getTime() >= date?.getTime()) return true
    return false
  }

  const fetchAfterCloseModal = async ()=>{
    dispatch({type: noticeCompAction.SET_LOADING_NOTICE_TABLE, payload: true})
    const res = await Promise.all([
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=0`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=1`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=2`),
    ])
    if (res[0].data.success && res[1].data.success && res[2].data.success) {
      dispatch({
        type: 'NOTICE_TOTAL_UNREAD', payload: {
          all: res[0].data.meta.total_unread,
          my: res[0].data.meta.total_unread_my,
          system: res[0].data.meta.total_unread_system,
          notice:{
            allList:{
              list: res[0].data.data,
              perPage: res[0].data.meta.per_page,
              total: res[0].data.meta.total,
            },
            myList:{
              list: res[2].data.data,
              perPage: res[2].data.meta.per_page,
              total: res[2].data.meta.total,
            },
            systemList:{
              list: res[1].data.data,
              perPage: res[1].data.meta.per_page,
              total: res[1].data.meta.total,
            },
          }
        }
      })
    }
  }
  const fetchOriginListNotice = async ()=> {
    dispatch({type: noticeCompAction.SET_LOADING_NOTICE_TABLE, payload: true})
    const res = await Promise.all([
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=0`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=1`),
      sendRequestAuth('get', `${config.API}/notices/list?per_page=20&start=0&type=2`),
    ])
    if (res[0].data.success && res[1].data.success && res[2].data.success) {
      const filterData = res[0].data?.data?.filter(filter => +filter?.is_popup === 0 || +filter?.is_read === 1  )
      const findData =  res[0].data?.data?.find(find =>{
        const checkDate = checkDateTime(find?.started_at, find?.ended_at)
        if(+find?.is_popup === 1 && +find?.is_read === 0 && !!checkDate) return find
      })
      if(!!findData) checkIsPopup(findData)
      //set global
      dispatch({
        type: 'NOTICE_TOTAL_UNREAD', payload: {
          all: res[0].data.meta.total_unread,
          my: res[0].data.meta.total_unread_my,
          system: res[0].data.meta.total_unread_system,
          notice:{
            allList:{
              list: res[0].data.data,
              perPage: res[0].data.meta.per_page,
              total: res[0].data.meta.total,
            },
            myList:{
              list: res[2].data.data,
              perPage: res[2].data.meta.per_page,
              total: res[2].data.meta.total,
            },
            systemList:{
              list: res[1].data.data,
              perPage: res[1].data.meta.per_page,
              total: res[1].data.meta.total,
            },
          }
        }
      })
    }
  }


  useEffect(() => {
    const pathNames = [
      '/',
      '/login',
      '/admin'
    ].includes(location.pathname)
    if(!pathNames ) fetchOriginListNotice()
    getDataStoreSetting()
    let clientToken = getCookie('client_token')
    clientToken = !!clientToken ? clientToken : generateTokenDevices()
    location.host == 'localhost:3000'
      ? setCookie({ cname: 'client_token', cvalue: clientToken, exdays: 180, domain: '' })
      : setCookie({ cname: 'client_token', cvalue: clientToken, exdays: 180, domain: ';domain=.upos.vn' })
  }, [])

  return (
    <div className="App">
      <SettingStore>
        <ProductStore>
          <Navigation/>
          {openModal && <ModalNotification openModal={openModal} setOpenModal={closeModal} item={detailNotice}/>}
        </ProductStore>
      </SettingStore>
    </div>
  )
}
export default LayoutWrapper


export const formatShippingFee = ( shipFee = 0) => {
  const [state] = useGlobalContext()
  const manage = state?.user?.is_manage || 0
  const shippingConfig = !!state?.shopInfo?.shipping_config ? state?.shopInfo?.shipping_config[1] : null
  const hidden_fee = shippingConfig?.hidden_shipfee

  if( +manage === 1) return formatMoney(shipFee || 0)
  else return +hidden_fee === 1 ? '*****₫' : formatMoney(shipFee || 0)
}
