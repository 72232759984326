import {useContext} from "react";
import {EcommerceReportContext} from "../provider/_context";
import {ecommerceReportActions} from "../provider/_reducer";

export const useEcommerceReportTab = () =>{
    const {pageState, pageDispatch} = useContext(EcommerceReportContext)

    const handleChangeActiveTab = data => pageDispatch({
        type: ecommerceReportActions.TAB_ECOMMERCE_REPORT_ACTIVE,
        payload: data
    })

    return{
        tab:{
            activeTab: pageState?.tab?.activeTab,
            onActive: handleChangeActiveTab
        }
    }
}