import React from "react";
import styled from "styled-components";
import {useReportOverviewFilter} from "../../hook/useReportOverviewFilter";
import {Text} from "../../../../../../../../common/text";
import {GLOBAL_ICON} from "../../../../../../../../interfaceGlobal";
import {useTranslation} from "react-i18next";
export const ImportantNumberSelected = ()=>{
  const { t } = useTranslation()
    const {importantNumber} = useReportOverviewFilter()
    return(
        <StyleImportantSelected>
            <div  style={{display:'flex', alignItems:'center'}}>
                {
                    importantNumber?.activeList?.map(map => <div style={{display:'flex', alignItems:'center',  marginRight : 24}} key={map?.id} >
                            <div
                                style={{
                                    background: map?.color,
                                    width: 8,
                                    height: 8,
                                    borderRadius: 50,
                                    marginRight : 8
                                }}
                            />
                            <Text>{t(map?.title)}</Text>
                    </div>)
                }
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Text>{t('selected')}: {importantNumber?.activeList?.length}/3 {t('e_com_index')}</Text>
                <div style={{marginLeft: 12, display:'flex', alignItems:'center'}}>
                    {GLOBAL_ICON.resetNumber}
                    <div style={{cursor:'pointer'}} onClick={importantNumber?.onReset}>
                        <Text color={'#1162FF'} fontWeight={500} >{t('general_reset_to_default')}</Text>
                    </div>
                </div>
            </div>
        </StyleImportantSelected>
    )
}
const StyleImportantSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

`