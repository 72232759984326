import React, {useContext} from "react";
import {Text} from "../../../../../../../../common/text";
import {ECOMMERCE_LIST, transformImageEcommerce} from "../../interface/_contant";
import styled from "styled-components";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {THEME_COLORS} from "../../../../../../../../common/theme/_colors";
import {AlternativeAutoComplete} from "../../../../../../../../common/form/autoComplete/_alternativeAutoComplete";
import {Option} from "../../../../../../../../common/form/autoComplete/_option";
import {Button} from "../../../../../../../../common/button";
import OverViewTags from "./_tag";
import {useTranslation} from "react-i18next";
export const FilterReport = ()=>{
  const { t } = useTranslation()
    const {ecommerce, dateTime, stores, status} = useReportOrderFilter()
    return(
        <StyleFilterReport>
            <div className={'report-over-view-filter-ecommerce'}>
                <div className={'report-over-view-filter-ecommerce_list'}>
                    {
                        ECOMMERCE_LIST?.map(map => <div
                                key={map?.id}
                                className={'report-over-view-filter-item'}
                                data-active={ecommerce?.activeValue?.type === map?.type}
                                onClick={()=> ecommerce?.onActive(map)}
                            >
                            {
                                map?.type !== 'all' && transformImageEcommerce(map?.type, 24)
                            }
                            <Text>
                                {t(map?.name)}
                            </Text>
                        </div>)
                    }
                </div>
                <div style={{display: 'flex', alignItems:'center'}}>
                    <Text>{dateTime?.dateStart} - {dateTime?.dateEnd}</Text>
                    <div className={'report-over-view-filter-date'}>
                        {
                            dateTime?.list?.map(map=> <div
                                key={map?.id}
                                className={'report-over-view-filter-date-items'}
                                onClick={()=> dateTime?.onActiveDate(map)}
                            >
                                <Text fontWeight={600} color={dateTime?.activeValue?.value === map?.value && THEME_COLORS.primary_300}>
                                    {t(map?.name)}
                                </Text>
                            </div>)
                        }
                    </div>
                </div>
            </div>
            <div className={'report-over-view-filter-stores'}>
                <AlternativeAutoComplete
                    className="report-over-view-filter__input-wide"
                    // main input
                    inputProps={{
                        categoryList: [{name: '', value: ''}], // menu list in category dropdown
                        categoryValue: {name: t('booth'), value: ''}, // if not exist this value -> default category: categoryList[0]
                        categoryWidth: 100,
                        placeholder: t('select_stall'),
                        // readOnly: true,
                        value:
                            stores.activeValue.length > 0 ?
                                `${t('general_selected')} ${stores.activeValue.length}` :
                                ecommerce?.activeValue?.type === 'all'? t('all') : '',
                        // onIconClick: stores.onInputReset,
                    }}
                    // menu
                    menuProps={{
                        empty:
                           stores?.list?.length <= 0
                                ? stores.tab === 'all'
                                ? t('ecom_not_find_stall')
                                : t('stall_select_not_yet')
                                : '',
                        multipleChoices: true,
                        onReset: stores.onInputReset, // only use this prop for multiple choice
                    }}
                    // tab list <only use this prop for multiple choices>
                    tabProps={{
                        active: stores.tab,
                        checkedNumber: stores.activeValue.length,
                        onChange: stores.onTabChange,
                    }}
                    searchInputProps={{
                        placeholder: t('find_stall'),
                        // value: province.keyword || '',
                        onChange: stores.onSearch,
                    }}
                >
                    {stores.list.length > 0 &&
                    stores.list.map((item, i) => (
                        <Option
                            key={item.ecom_seller_id || i}
                            checked={!!stores.activeValue.find(find => +find.ecom_seller_id === +item.ecom_seller_id)}
                            multipleChoices={true}
                            onClick={() => stores.onSelected(item)}
                            style={{margin: '8px 0'}}
                        >
                            <div className="report-over-view-filter__option-text">
                                {transformImageEcommerce(item?.ecom_type, 28)}
                                <Text style={{marginLeft: 8}}>
                                    {item?.shop_short_name || item?.shop_name || '---'}
                                </Text>
                            </div>
                        </Option>
                    ))}
                </AlternativeAutoComplete>
                {
                    status?.isStatus && <AlternativeAutoComplete
                        className="report-over-view-filter__input-wide"
                        // main input
                        inputProps={{
                            categoryList: [{name: '', value: ''}], // menu list in category dropdown
                            categoryValue: {name: t('e_com_type_oder'), value: ''}, // if not exist this value -> default category: categoryList[0]
                            categoryWidth: 140,
                            placeholder: t('select_shipping_status'),
                            // readOnly: true,
                            value:
                                status.activeValue.length > 0 ?
                                    `${t('general_selected')} ${status.activeValue.length}` :
                                    ecommerce?.activeValue?.type === 'all'? t('all') : '',
                            // onIconClick: status.onInputReset,
                        }}
                        // menu
                        menuProps={{
                            empty:
                                status?.list?.length <= 0
                                    ? status.tab === 'all'
                                    ? t('report__search_no_status_order')
                                    : t('report__havent_status_order_yet')
                                    : '',
                            multipleChoices: true,
                            onReset: status.onInputReset, // only use this prop for multiple choice
                        }}
                        // tab list <only use this prop for multiple choices>
                        tabProps={{
                            active: status.tab,
                            checkedNumber: status.activeValue.length,
                            onChange: status.onTabChange,
                        }}
                        searchInputProps={{
                            placeholder: t('e_com_find_type_oder'),
                            // value: province.keyword || '',
                            onChange: status.onSearch,
                        }}
                    >
                        {status.list.length > 0 &&
                        status.list.map((item, i) => (
                            <Option
                                key={item.id || i}
                                checked={!!status.activeValue.find(find => +find.status === +item.status)}
                                multipleChoices={true}
                                onClick={() => status.onSelected(item)}
                                style={{margin: '8px 0'}}
                            >
                                <div className="report-over-view-filter__option-text">
                                    <Text style={{marginLeft: 8}}>
                                        {t(item?.title)}
                                    </Text>
                                </div>
                            </Option>
                        ))}
                    </AlternativeAutoComplete>
                }

                <Button
                    appearance={'secondary'}
                    disabled={!stores?.canSubmitFilter}
                    onClick={()=> stores?.canSubmitFilter && stores.onApplyFilter()}
                    size={'md-'}
                    style={{minWidth: 101}}
                >
                  {t('general_apply')}
                </Button>
            </div>
            <OverViewTags/>
        </StyleFilterReport>
    )
}
const StyleFilterReport = styled.div`
     .report-over-view-filter{
        &-ecommerce{
          display: flex;
          align-items: center;
          justify-content: space-between;
        } 
        &-ecommerce_list{
          display: flex;
          align-items: center;
        }
        &-item{
            display: flex;
            align-items: center;
            height: 34px;
            padding: 7px 12px;
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            margin-right: 12px;
            cursor: pointer;
            &[data-active='true']{
              border: 1px solid ${THEME_COLORS.primary_300};
            }
        }
         &-date{
          display: flex;
          align-items: center;
          border-radius: 6px;
          border: 1px solid #EBEEF5;
          margin-left: 8px;
        }
        &-date-items{
           padding: 10px 16px;
           cursor: pointer;
           &:nth-child(1){
              border-right: 1px solid #EBEEF5;
           }
        }
        
        &-stores{
          display: flex;
          align-items: center;
          margin-top: 16px ;
        }
        &__input-wide{
          width: 392px;
          margin-right: 12px;
        }
        &__option-text{
          display: flex;
          align-items: center;
          cursor: pointer;
            span{
              &:hover{
                color: ${THEME_COLORS.primary_300} !important;
              }
            }
        }
        
     }

`