export const FIXED_ACTION_BTN_GROUP_ICONS = {
  book: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 13.75L3.125 16.25C3.125 16.5952 3.40482 16.875 3.75 16.875L16.25 16.875C16.5952 16.875 16.875 16.5952 16.875 16.25L16.875 13.75C16.875 13.4048 16.5952 13.125 16.25 13.125L3.75 13.125C3.40482 13.125 3.125 13.4048 3.125 13.75Z"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 16.875L6.25 13.125"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.125 10L3.125 12.5C3.125 12.8452 3.40482 13.125 3.75 13.125L16.25 13.125C16.5952 13.125 16.875 12.8452 16.875 12.5L16.875 10C16.875 9.65482 16.5952 9.375 16.25 9.375L3.75 9.375C3.40482 9.375 3.125 9.65482 3.125 10Z"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 13.125L13.75 9.375"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.91992 6.28638L3.56992 8.71138C3.65967 9.0462 4.00385 9.24487 4.33867 9.15513L16.4637 5.90513C16.7985 5.81538 16.9972 5.4712 16.9074 5.13638L16.2574 2.71138C16.1677 2.37655 15.8235 2.17788 15.4887 2.26763L3.36367 5.51763C3.02885 5.60737 2.83018 5.95155 2.91992 6.28638Z"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 6.62476L13.0234 2.99976"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 8.5625L5.78125 4.9375"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chat: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4998 11.6666L15.0946 14.2293C15.4565 14.5868 15.6375 14.7655 15.6502 14.9191C15.6613 15.0524 15.6077 15.1829 15.5062 15.27C15.3892 15.3703 15.1348 15.3703 14.6261 15.3703L13.3262 15.3703C12.8698 15.3703 12.5396 15.744 12.4734 16.1956V16.1956C12.3134 17.2885 11.4551 18.1468 10.3622 18.3069C10.182 18.3333 9.96693 18.3333 9.53687 18.3333L5.6665 18.3333C4.26637 18.3333 3.56631 18.3333 3.03153 18.0608C2.56112 17.8211 2.17867 17.4386 1.93899 16.9682C1.6665 16.4334 1.6665 15.7334 1.6665 14.3333L1.6665 8.16658C1.6665 6.76645 1.6665 6.06639 1.93899 5.53161C2.17867 5.0612 2.56112 4.67875 3.03153 4.43907C3.56631 4.16658 4.26637 4.16658 5.6665 4.16658L9.1665 4.16658M18.3332 4.16658L17.0722 5.98026C16.895 6.23521 16.8064 6.36268 16.7435 6.50142C16.6878 6.62455 16.6472 6.75401 16.6227 6.88694C16.5951 7.03672 16.5951 7.19197 16.5951 7.50248L16.5951 8.99992C16.5951 9.93334 16.5951 10.4 16.4134 10.7566C16.2536 11.0702 15.9987 11.3251 15.6851 11.4849C15.3285 11.6666 14.8618 11.6666 13.9284 11.6666L11.8332 11.6666C10.8998 11.6666 10.433 11.6666 10.0765 11.4849C9.76292 11.3251 9.50795 11.0702 9.34816 10.7566C9.1665 10.4 9.1665 9.93334 9.1665 8.99992L9.1665 4.33325C9.1665 3.39983 9.1665 2.93312 9.34816 2.5766C9.50795 2.263 9.76292 2.00803 10.0765 1.84824C10.433 1.66658 10.8997 1.66658 11.8332 1.66658L14.0951 1.66658C14.8716 1.66658 15.2599 1.66658 15.5662 1.79345C15.9746 1.96261 16.2991 2.28706 16.4682 2.69544C16.5951 3.00173 16.5951 3.39001 16.5951 4.16658L18.3332 4.16658Z"
        stroke="#7C88A6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
}
