export const IconBell = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconDoubleCheck = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7332 4.13356C10.4665 3.93356 9.99988 3.93356 9.79988 4.2669L5.13322 10.2669L2.86655 7.4669C2.66655 7.20023 2.19988 7.13356 1.93322 7.33356C1.66655 7.53356 1.59988 8.00023 1.79988 8.2669L4.59988 11.7336C4.73322 11.9336 4.93322 12.0002 5.13322 12.0002C5.33322 12.0002 5.53322 11.9336 5.66655 11.7336L10.8665 5.0669C11.1332 4.80023 11.0665 4.40023 10.7332 4.13356Z"
      fill="#1A94FF"
    />
    <path
      d="M14.0666 4.13356C13.8 3.93356 13.3333 3.93356 13.1333 4.2669L8.46662 10.2669L8.06662 9.73356L7.19995 10.8002L7.93328 11.7336C8.06662 11.8669 8.26662 12.0002 8.46662 12.0002C8.66662 12.0002 8.86662 11.9336 8.99995 11.7336L14.2 5.0669C14.4666 4.80023 14.4 4.33356 14.0666 4.13356Z"
      fill="#1A94FF"
    />
    <path
      d="M5.46647 8.73369L6.33313 7.66702L6.1998 7.53369C5.9998 7.20036 5.53313 7.13369 5.26647 7.33369C4.9998 7.53369 4.93313 8.00036 5.13313 8.26702L5.46647 8.73369Z"
      fill="#1A94FF"
    />
  </svg>
)

export const NOTIFICATION_MANAGEMENT_ICONS = {
  iconBlue: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#1292FF" />
      <path
        d="M22 24.5C19.3583 24.5 17.009 25.7755 15.5134 27.755C15.1915 28.181 15.0305 28.394 15.0358 28.6819C15.0398 28.9043 15.1795 29.1849 15.3545 29.3222C15.581 29.5 15.8949 29.5 16.5227 29.5H27.4773C28.1051 29.5 28.4191 29.5 28.6456 29.3222C28.8206 29.1849 28.9602 28.9043 28.9643 28.6819C28.9696 28.394 28.8086 28.181 28.4867 27.755C26.991 25.7755 24.6418 24.5 22 24.5Z"
        fill="white"
      />
      <path
        d="M22 22C24.0711 22 25.75 20.3211 25.75 18.25C25.75 16.1789 24.0711 14.5 22 14.5C19.929 14.5 18.25 16.1789 18.25 18.25C18.25 20.3211 19.929 22 22 22Z"
        fill="white"
      />
      <path
        d="M22 24.5C19.3583 24.5 17.009 25.7755 15.5134 27.755C15.1915 28.181 15.0305 28.394 15.0358 28.6819C15.0398 28.9043 15.1795 29.1849 15.3545 29.3222C15.581 29.5 15.8949 29.5 16.5227 29.5H27.4773C28.1051 29.5 28.4191 29.5 28.6456 29.3222C28.8206 29.1849 28.9602 28.9043 28.9643 28.6819C28.9696 28.394 28.8086 28.181 28.4867 27.755C26.991 25.7755 24.6418 24.5 22 24.5Z"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 22C24.0711 22 25.75 20.3211 25.75 18.25C25.75 16.1789 24.0711 14.5 22 14.5C19.929 14.5 18.25 16.1789 18.25 18.25C18.25 20.3211 19.929 22 22 22Z"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  iconGreen: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#15C881" />
      <path
        d="M21.9996 22.0002H22.0083M25.0932 25.0938C20.9927 29.1943 16.2835 31.1334 14.575 29.4248C12.8664 27.7163 14.8055 23.0071 18.906 18.9066C23.0065 14.8061 27.7157 12.867 29.4242 14.5756C31.1327 16.2841 29.1937 20.9933 25.0932 25.0938ZM25.0931 18.9064C29.1936 23.0069 31.1327 27.7161 29.4242 29.4246C27.7156 31.1332 23.0065 29.1941 18.9059 25.0936C14.8054 20.9931 12.8664 16.284 14.5749 14.5754C16.2835 12.8669 20.9926 14.8059 25.0931 18.9064ZM22.4371 22.0002C22.4371 22.2418 22.2412 22.4377 21.9996 22.4377C21.7579 22.4377 21.5621 22.2418 21.5621 22.0002C21.5621 21.7586 21.7579 21.5627 21.9996 21.5627C22.2412 21.5627 22.4371 21.7586 22.4371 22.0002Z"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  dot:(
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" fill="#FF424F"/>
        <rect x="0.5" y="0.5" width="11" height="11" rx="5.5" stroke="white"/>
      </svg>
  ),
  close:(
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.6">
          <rect width="32" height="32" rx="16" fill="#717483"/>
          <path d="M22 10L10 22M10 10L22 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </svg>

  ),
  clock:(
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00039 4.6399V7.9999L10.2404 9.1199M13.6004 7.9999C13.6004 11.0927 11.0932 13.5999 8.00039 13.5999C4.9076 13.5999 2.40039 11.0927 2.40039 7.9999C2.40039 4.90711 4.9076 2.3999 8.00039 2.3999C11.0932 2.3999 13.6004 4.90711 13.6004 7.9999Z" stroke="#7C88A6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

