import React from 'react'
import "./CheckBoxConsignment.scss"
export default function CheckBoxConsignment({ ...props }) {
  const { isChecked, name, id, handleClick, disable ,minus, checkedLess} = props;
  return (
    <div {...props} className='consignement__checkbox-main' data-minus={minus} data-checked={isChecked}>
      <input type="checkbox" id={id}
        className={`consignement__checkbox ${!checkedLess ? 'checked-less' : 'checked-less'}`}
        name={name}
        onChange={handleClick}
        checked={isChecked} 
        disabled={disable}
        />
    </div>

  )
}
