const ProductReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_PRODUCT_FILTER_OBJ":
      return {
        ...state,
        product: {
          ...state.product,
          filter_obj: {
            ...state.product.filter_obj,
            ...action.payload,
          },
        },
      };
    case "CHANGE_PRODUCT_PAGE_OBJ":
      return {
        ...state,
        product: {
          ...state.product,
          page_obj: {
            ...state.product.page_obj,
            ...action.payload,
          },
        },
      };
    case "CHANGE_UNIT_PAGE_OBJ":
      return {
        ...state,
        unit: {
          ...state.unit,
          page_obj: action.payload,
        },
      };
    case "CHANGE_CATEGORY_PAGE_OBJ":
      return {
        ...state,
        category: {
          ...state.category,
          page_obj: {
            ...state.category.page_obj,
            ...action.payload,
          },
        },
      };
    case "CHANGE_CATEGORY_FILTER_OBJ":
      return {
        ...state,
        category: {
          ...state.category,
          filter_obj: {
            ...state.category.filter_obj,
            ...action.payload,
          },
        },
      };
    case "CHANGE_UNIT_FILTER_OBJ":
      return {
        ...state,
        unit: {
          ...state.unit,
          filter_obj: {
            ...state.unit.filter_obj,
            ...action.payload,
          },
        },
      };
    case "CHANGE_STATE_WIDGET":
      return {
        ...state,
        category: {
          ...state.category,
          stateWidget: {
            ...state.category.stateWidget,
            ...action.payload,
          },
        },
      };
    case "CHANGE_STATE_WIDGET_UNIT":
      return {
        ...state,
        unit: {
          ...state.unit,
          stateWidget: {
            ...state.unit.stateWidget,
            ...action.payload,
          },
        },
      };
    case "CHANGE_DATA_WIDGET":
      return {
        ...state,
        category: {
          ...state.category,
          stateWidget: {
            ...state.category.stateWidget,
            data: {
              ...state.category.stateWidget.data,
              ...action.payload,
            },
          },
        },
      };
    case "CHANGE_DATA_WIDGET_UNIT":
      return {
        ...state,
        unit: {
          ...state.unit,
          stateWidget: {
            ...state.unit.stateWidget,
            data: {
              ...state.unit.stateWidget.data,
              ...action.payload,
            },
          },
        },
      };
    case "UPDATE_OBJ_ERROR":
      return {
        ...state,
        category: {
          ...state.category,
          stateWidget: {
            ...state.category.stateWidget,
            objError: action.payload,
          },
        },
      };
    case "UPDATE_OBJ_ERROR_UNIT":
      return {
        ...state,
        unit: {
          ...state.unit,
          stateWidget: {
            ...state.unit.stateWidget,
            objError: action.payload,
          },
        },
      };
    case "UPDATE_STATUS_MODAL":
      return {
        ...state,
        category: {
          ...state.category,
          isShowModal: action.payload,
        },
      };
    case "UPDATE_STATUS_MODAL_CREATE_PRODUCT_UNIT":
      return {
        ...state,
        product: {
          ...state.product,
          modalUnit: {
            ...state.product.modalUnit,
            isShow: action.payload,
          },
        },
      };
    case "UPDATE_STATUS_MODAL_CREATE_PRODUCT_CATEGORY":
      return {
        ...state,
        product: {
          ...state.product,
          modalCategory: {
            ...state.product.modalCategory,
            isShow: action.payload,
          },
        },
      };
    case "UPDATE_STATUS_EDIT_VARIABLE":
      return {
        ...state,
        product: {
          ...state.product,
          editVariable: {
            ...state.product.editVariable,
            isShow: action.payload,
          },
        },
      };
    case "UPDATE_DATA_EDIT_VARIABLE":
      return {
        ...state,
        product: {
          ...state.product,
          editVariable: {
            ...state.product.editVariable,
            data: action.payload,
          },
        },
      };
    case "UPDATE_PRODUCT_ATT":
      return {
        ...state,
        product: {
          ...state.product,
          attr_value: action.payload,
        },
      };
    case "UPDATE_PRODUCT_INFO":
      return {
        ...state,
        product: {
          ...state.product,
          productInfo: {
            ...state.product.productInfo,
            ...action.payload,
          },
        },
      };
    case "UPDATE_PRODUCT_ATTRIBUTE":
      return {
        ...state,
        product: {
          ...state.product,
          productInfo: {
            ...state.product.productInfo,
            arr_attr: [
              ...state.product.productInfo.arr_attr,
              ...action.payload,
            ],
          },
        },
      };
    case "DELETE_PRODUCT_ATTRIBUTE":
      return {
        ...state,
        product: {
          ...state.product,
          productInfo: {
            ...state.product.productInfo,
            arr_attr: [
              ...state.product.productInfo.arr_attr.slice(0, action.payload),
              ...state.product.productInfo.arr_attr.slice(action.payload + 1),
            ],
          },
        },
      };
    default:
      return state;
  }
};

export default ProductReducer;
