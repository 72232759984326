import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../common/text";
import {useReportOrderFilter} from "../../hook/useReportOrderFilter";
import {RatioProduct} from "./component/ratioChart";
import {REPORT_CHART_SERIES} from "../../interface/_contant";
import {ChartSeriesRatio} from "./component/ratioChart/_chartSeries";
import {useTranslation} from "react-i18next";
export const RatioReport = ()=>{
  const { t } = useTranslation()
    const {dateTime, ratioOrder, status} = useReportOrderFilter()
    const list = REPORT_CHART_SERIES?.map(map =>{
        const findData=ratioOrder?.data?.find(find=>find?.title === map?.title)
        if(ratioOrder?.data?.find){
            return({
                ...map,
                ...findData
            })
        }
    })
    return(
        <StyleRatioReport>
            <div className={'report-order-chart_date'}>
                <Text fontSize={18} fontWeight={600} as={'p'} >{t('e_com_percent_oder_product')}</Text>
                <Text color={'#7C88A6'} >{dateTime?.dateStart} - {dateTime?.dateEnd}</Text>
            </div>
            <div className={'report-order-chart_grid'} >
                <div style={{width: "40%"}}>
                    <RatioProduct/>
                </div>
                <div className={'report-order-chart_series'} >
                    {
                        list?.map(map=> <ChartSeriesRatio key={map?.id} data={map} />)
                    }
                </div>
            </div>


        </StyleRatioReport>
    )

}
const StyleRatioReport = styled.div`
    .report-order-chart{
      &_date{
        margin-top: 24px;
      }
      &_grid{
        display: flex;
      }
      &_series{
        display: grid;
        grid-template-columns: auto auto auto;
        width: 60%;
        height: 336px;
        margin: 1rem 0;
      }
    }
`