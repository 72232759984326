import {useReducer} from "react";
import {
    reportOrderInitState,
    reportOrderReducer,
} from "../provider/_reducer";

export const useReportOrder = ()=>{
    const [state, dispatch] = useReducer(reportOrderReducer,reportOrderInitState)
    return{
        provider:{
            state,
            dispatch
        },
    }
}