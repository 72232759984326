import {noticeCompState} from "./_initialState";
import {noticeCompAction} from "./_action";

export const noticeCompReducer = (state = noticeCompState,action)=>{
    switch (action.type) {
        case noticeCompAction.GET_LIST_NOTICE:
            return{
                ...state,
                notice:{
                    ...state.notice,
                    ...action.payload,
                }
            }
        case noticeCompAction.GET_TOTAL_NOTICE:
            return {
                ...state,
                totalUnread: {
                    ...state.totalUnread,
                    ...action.payload
                }
            }
        case noticeCompAction.SET_LOADING_NOTICE_TABLE:
            return {
                ...state,
                loading:action.payload
            }
        case noticeCompAction.SET_ACTIVE_TAB_TYPE:
            return{
                ...state,
                activeTab: action.payload.tab,
                idTab: action.payload.type,
            }
        case noticeCompAction.SET_CAN_LOAD_MORE_NOTICE:
            return {
                ...state,
                canLoadMore: action.payload
            }
        case noticeCompAction.OPEN_MODAL_NOTIFICATION:
            return {
                ...state,
                openModal: action.payload
            }
        case noticeCompAction.GET_DETAIL_NOTICE:
            return {
                ...state,
                detailNotice: action.payload
            }
        default:
            throw new Error()
    }
}