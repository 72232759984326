import {
    ECOMMERCE_LIST,
    FILTER_DATE,
    REVENUE_ORDER_TAG,
    REPORT_PRODUCT_STATUS,
    REPORT_PRODUCT_TAG_CHART
} from "../interface/_contant";
import {formatDatetime, getDateFromNow} from '../../../utils/formatDate'
export const reportProductAction = {
    REPORT_PRODUCT_ECOMMERCE_ACTIVE:'REPORT_PRODUCT_ECOMMERCE_ACTIVE',

    REPORT_PRODUCT_GET_ALL_LIST:'REPORT_PRODUCT_GET_ALL_LIST',

    //=====DATE TIME=====
    REPORT_PRODUCT_DATE_TIME_ACTIVE:'REPORT_PRODUCT_DATE_TIME_ACTIVE',

    //===== END DATE TIME ====

    //======STORES=======
    REPORT_PRODUCT_STORES_LIST:'REPORT_PRODUCT_STORES_LIST',
    REPORT_PRODUCT_STORES_SELECTED:'REPORT_PRODUCT_STORES_SELECTED',
    REPORT_PRODUCT_STORES_TAB:'REPORT_PRODUCT_STORES_TAB',
    REPORT_PRODUCT_STORES_APPLY_FILTER:'REPORT_PRODUCT_STORES_APPLY_FILTER',
    REPORT_PRODUCT_STORES_SEARCH:'REPORT_PRODUCT_STORES_SEARCH',

    //======STATUS ========
    REPORT_PRODUCT_STATUS_SELECTED:'REPORT_PRODUCT_STATUS_SELECTED',

    REPORT_PRODUCT_UPDATE:'REPORT_PRODUCT_UPDATE',

    //======= TOP PRODUCT=========
    REPORT_PRODUCT_TOP_PRODUCT_UPDATE:'REPORT_PRODUCT_TOP_PRODUCT_UPDATE',

    REPORT_PRODUCT_CHART_TAB_ACTIVE:'REPORT_PRODUCT_CHART_TAB_ACTIVE'


}
export const reportProductInitState = {
    filter:{
        ecommerce:{
            activeValue: ECOMMERCE_LIST[0],
        },
        dateTime:{
            activeValue: FILTER_DATE[0],
            list: FILTER_DATE,
            dateStart: formatDatetime(getDateFromNow(-6)),
            dateEnd: formatDatetime(getDateFromNow(0))
        },
        stores:{
            list:[],
            listOrigin: [],
            activeValue: [],
            keyword:'',
            tab: 'all',
            applyFilter: [],
        },
        status:{
            list: REPORT_PRODUCT_STATUS,
            listOrigin: REPORT_PRODUCT_STATUS,
            activeValue: null,
            keyword:'',
            applyFilter: null,
        },
        chartTag:{
            list: REPORT_PRODUCT_TAG_CHART,
            activeValue: REPORT_PRODUCT_TAG_CHART[0]
        },
        topProductTag:{
          list: []
        },
        topProductChart:{
            list:[],
        },
        topProductTable:{
            list:[],

        }
    },


}
export const reportProductReducer = (state, action)=> {
    switch (action.type) {
        case reportProductAction.REPORT_PRODUCT_ECOMMERCE_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ecommerce:{
                        ...state?.filter.ecommerce,
                        activeValue: action.payload?.active
                    },
                    stores: {
                        ...state?.filter.stores,
                        activeValue: [],
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                        applyFilter: []
                    },
                    status:{
                        ...state.filter?.status,
                        activeValue: null,
                        applyFilter: null
                    }
                }
            }
        case reportProductAction.REPORT_PRODUCT_GET_ALL_LIST:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                    },
                    topProductTag:{
                        list:action?.payload?.topProductTag
                    },
                    topProductChart:{
                        list:action?.payload?.topProductChart,

                    },
                    topProductTable:{
                        list:action?.payload?.topProductTable,

                    }
                }
            }
        case reportProductAction.REPORT_PRODUCT_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    topProductTag:{
                        list:action?.payload?.topProductTag
                    },
                    topProductChart:{
                        list:action?.payload?.topProductChart,

                    },
                    topProductTable:{
                        list:action?.payload?.topProductTable,

                    }
                }
            }
        case reportProductAction.REPORT_PRODUCT_DATE_TIME_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    dateTime:{
                        ...state?.filter.dateTime,
                        activeValue: action?.payload.active,
                        dateStart: action?.payload.dateStart,
                        dateEnd: action?.payload.dateEnd,
                    }
                }
            }

        case reportProductAction.REPORT_PRODUCT_STORES_SELECTED:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       activeValue: action.payload || []
                    }
                }
            }
        case reportProductAction.REPORT_PRODUCT_STORES_TAB:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       tab: action.payload?.tab,
                        list: action?.payload?.list
                    }
                }
            }

        case reportProductAction.REPORT_PRODUCT_STORES_APPLY_FILTER:
            switch (action?.payload?.type) {
                case "stores":
                    return {
                        ...state,
                        filter:{
                            ...state?.filter,
                            stores:{
                                ...state?.filter.stores,
                                applyFilter: action.payload?.active || [],
                                activeValue: action.payload?.active || []
                            }
                        }
                    }
                default:
                    return {
                        ...state,
                        filter:{
                            ...state?.filter,
                            status:{
                                ...state?.filter.status,
                                applyFilter: action.payload?.active || null,
                                activeValue: action.payload?.active || null
                            }
                        }
                    }
            }

        case reportProductAction.REPORT_PRODUCT_STORES_SEARCH:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        keyword: action.payload?.keyword || '',
                        list: action.payload?.list || []
                    }
                }
            }

        case reportProductAction.REPORT_PRODUCT_STATUS_SELECTED:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    status:{
                        ...state?.filter.status,
                        activeValue: action.payload,
                    }
                }
            }
        case reportProductAction.REPORT_PRODUCT_CHART_TAB_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    chartTag:{
                        ...state?.filter.chartTag,
                        activeValue: action.payload?.tab,
                    },
                    topProductChart: {
                        ...state?.filter.topProductChart,
                        list: action.payload?.list
                    }
                }
            }
        default: return state
    }
}