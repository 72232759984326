import React from "react";
import styled from "styled-components";
import {useReportOverviewFilter} from "../../../../hook/useReportOverviewFilter";
import {Text} from "../../../../../../../../../../common/text";
import {Button} from "../../../../../../../../../../common/button";
import ReactApexChart from "react-apexcharts";
import {chartOptionsRatioOrder} from "./_chartOptionRatioOrder";
import merge from "lodash/merge";
import {BaseOptionChart} from "../../../../../../../../../../Component/chart";
import {ChartSeriesRatioOrder} from "./_chartSeries";
import {useReportUpdateOrder} from "../../../../hook/useReportUpdateOrder";
import {useTranslation} from "react-i18next";
export const RatioOrder = ()=>{
  const { t } = useTranslation()
    const {dateTime, ratioOrder} = useReportOverviewFilter()
    const {updateOrderMethods} = useReportUpdateOrder()

    const {dateStart, dateEnd} = dateTime
    const chartOptions = merge(BaseOptionChart(), {
        listData: ratioOrder?.data,
        ...chartOptionsRatioOrder,
        labels:[t('order'), t('order'), t('order')],
        language: {
          title: [t('success'), t('failed'), t('progress')],
          order: t('orders')
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                total: {
                  label: t('orders')
                }
              }
            }
          }
        }
    })
    const chartSeries=ratioOrder?.data?.map(map=> +map?.total)
    return(
        <StyleRatioOrder>
            <div className={'ratio-order-title'}>
                <div className={'ratio-order-title_date'}>
                    <Text fontSize={18} fontWeight={600} as={'p'} >{t('e_com_async_percent_order')}</Text>
                    <Text color={'#7C88A6'} >{dateStart} - {dateEnd}</Text>
                </div>
                <Button
                    appearance={'secondary'}
                    onClick={()=>updateOrderMethods?.onOpenModal(true)}
                >
                  {t('update_orders')}
                </Button>
            </div>
            <div className={'ratio-order-chart'}>
                <ReactApexChart
                    type="donut"
                    series={chartSeries}
                    options={chartOptions}
                    height={'100%'}
                    width={200}
                />
            </div>
            <div className={'ratio-order-chart_series'}>
                {
                    ratioOrder?.data?.map((map,i)=><ChartSeriesRatioOrder key={i} data={map} />)
                }
            </div>
        </StyleRatioOrder>
    )

}
const StyleRatioOrder = styled.div`
  height: 474px;
   width: calc(50% - 12px);
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  .ratio-order{
    &-title{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &-chart{
      width: 100%;
      height: 200px;
      .apexcharts-svg{
        transform: translate(270px, 50px);
         @media screen and (max-width: 1440px){
          transform: translate(214px,50px);
        }
         @media screen and (max-width: 1366px){
          transform: translate(200px,50px);
        }
         @media screen and (max-width: 1280px){
          transform: translate(180px,50px);
        }
        .apexcharts-graphical{
          transform: translate(13px, 15px);
          .apexcharts-datalabels-group{
             .apexcharts-datalabel-label{
                transform: translate(0px, 28px);
                font-size: 14px;
                font-weight: 400;
                fill: #535E79 !important;
                line-height: 140%;
              }
              .apexcharts-datalabel-value{
                transform: translate(0px,-25px););
                fill: #0F172A;
                font-family: Roboto;
                font-size: 20px;
                font-weight: 600;
                line-height: 140%;
              }
          }
          
        }
      }
    }
    
    &-chart_series{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 6rem;
    }
    
  }
`