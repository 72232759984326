import React from "react";
import {StyledProductTable} from "./_style";
import {useReportProductFilter} from "../../hook/useReportProductFilter";
import {Tr} from "../../../../../../../../layouts/tableLayout/_tr";
import {Td} from "../../../../../../../../layouts/tableLayout/_td";
import {Text} from "../../../../../../../../common/text";
import ReactImageFallback from "react-image-fallback";
import {transformImageEcommerce} from "../../interface/_contant";
import {formatMoney} from "../../../../utils/functionUtil";
import {fNumber} from "../../../../utils/formatNumber";
import {Tooltip} from "../../../../../../../../common/tooltipv2";
import {EmptyList} from "../../../../components/empty";
import {useTranslation} from "react-i18next";

export const ProductTableBody = ()=>{
  const { t } = useTranslation()
    const {topProductChart, chartTag} = useReportProductFilter()
    const {list}=topProductChart
    const syncStatus = (status) => {
        switch (+status) {
            case 0:
                return{
                    name: t('link_not_yet'),
                    color: '#7C88A6',
                    background:'#EFF3FB'
                }
            case 1:
                return {
                    name: t('linked_success'),
                    color: '#00AB56',
                    background:'#EBFFF5'
                }
            default:
                return {
                    name: t('link_failed'),
                    color: '#FF424E',
                    background:'rgba(255, 66, 78, 0.08)'
                }
        }
    }
    return(
        <StyledProductTable>
            {
                list?.length > 0 ? list?.map((map, i)=> <Tr
                    key={map?.ecom_product_id_details}
                    className={'report-product-table-tr'}
                >
                    <Td
                        className={'report-product-table-td'}
                    >
                       <Text>#{i +1}</Text>
                        <ReactImageFallback
                            src={map?.image}
                            fallbackImage='/img/product/default-product-thumbnail.png'
                            alt={map?.product_name || 'thumbnail'}
                            style={{margin: '0 16px', width: 54, height: 54, borderRadius: 6}}
                        />
                        <div>
                            <Tooltip baseOn={'height'} className={'report-product-table-tooltip'} title={map?.product_name}>
                                <Text as={'a'} href={`/ecommerce/product/${map?.ecom_product_id_details}`} color={'#1A94FF'} >{map?.product_name}</Text>
                            </Tooltip>
                            <div style={{background: syncStatus(map?.sync_status)?.background, width:'fit-content', padding: '4px 8px', borderRadius: 4}}>
                                <Text color={syncStatus(map?.sync_status)?.color}>
                                    {syncStatus(map?.sync_status)?.name}
                                </Text>
                            </div>
                        </div>
                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {transformImageEcommerce(map?.ecom_type,20)}
                        <Text>{map?.shop_short_name || map?.shop_name}</Text>

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {formatMoney(map?.total_amount)}

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {fNumber(map?.total_quantity)}

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {fNumber(map?.total_order)}

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {fNumber(map?.total_quantity_cancel)}

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {fNumber(map?.total_order_cancel)}

                    </Td>
                    <Td
                        className={'report-product-table-td'}
                    >
                        {Math.round(map?.ratio_cancel*100)/100}%

                    </Td>
                </Tr>)
                    :
                    <EmptyList/>
            }
        </StyledProductTable>
    )
}