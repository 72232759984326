import {Button} from 'common/button'
import {Checkbox} from 'common/form/checkbox'
import {Text} from 'common/text'
import {Th} from 'layouts/tableLayout/_th'
import {Tr} from 'layouts/tableLayout/_tr'
import useOrderTHead from 'Pages/refactorOrder/hooks/useOrderTHead'
import {ORDER_TABLE_THEAD_SELECTED_ACTIONS} from 'Pages/refactorOrder/interfaces/_constants'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import {OrderContext} from 'Pages/refactorOrder/provider/_context'
import {useState} from 'react'
import {useContext} from 'react'
import {ConfirmDeleteModal} from './_confirmDeleteModal'
import {PaymentFilterPopover} from './_paymentFilterPopover'
import {checkPermission} from '../../../../Component/NavBar/until'
import PopupNotPermission from '../../../permision/popupNotPermission'
import ConfigTable from '../orderModal/configTable'
import {Tooltip} from '../../../../common/tooltip'
import {useTranslation} from 'react-i18next'

export const OrderTHead = ({...props}) => {
  const {pageState} = useContext(OrderContext)
  const displayList = pageState.table.display.list
  const {t} = useTranslation()

  const {globalLoading, checkbox, selected, notifications, configItem} =
    useOrderTHead()

  const [confirmDeleteModalData, setConfirmDeleteModalData] = useState(false)
  const [canDelete, setCanDelete] = useState(true)
  const [submit, setSubmit] = useState(() => {})
  const listTHead = configItem?.applyList

  const actions = ORDER_TABLE_THEAD_SELECTED_ACTIONS.map((item, i) => ({
    ...item,
    onClick: () => {
      const onClickFunc = selected.actionMenu.actions[i]
      switch (item?.value) {
        case '7':
          setConfirmDeleteModalData({
            title: 'cancel_delivery',
            description: t('confirm_cancel_delivery'),
          })
          setSubmit(() => selected.actionMenu.actions[i])
          break

        case '15':
          setConfirmDeleteModalData({
            title: 'cancel_order',
            description: t('confirm_cancel_order'),
          })
          setSubmit(() => selected.actionMenu.actions[i])
          break

        default:
          onClickFunc()
          break
      }
    },
  }))

  const handeSubmitDelete = () => {
    notifications.delete()
    setCanDelete(false)
    setConfirmDeleteModalData(null)
    if (!canDelete) return
    const response = submit()
    response.then(() => setCanDelete(true))
  }

  const checkFullPageChecked = () => {
    let checkFullPage = true
    displayList.forEach(item => {
      const findItem = selected.list.find(find => find.id === item.id)
      if (!!!findItem) checkFullPage = false
    })
    return checkFullPage
  }

  const validSendDeliveryPermission = checkPermission('order', 'send_delivery')
  const validCancelDeliveryPermission = checkPermission(
    'order',
    'cancel_delivery',
  )
  const validCancelPermission = checkPermission('order', 'cancel')
  const [showPermission, setShowPermission] = useState(false)
  const handleItemClick = data => {
    if (
      (+data.id === 1 && validSendDeliveryPermission) ||
      (+data.id === 2 && validCancelDeliveryPermission) ||
      (+data.id === 3 && validCancelPermission)
    ) {
      if (data?.onClick) data.onClick()
    } else setShowPermission(true)
    selected.actionMenu.onToggle(false)
  }

  return (
    <>
      {showPermission && (
        <PopupNotPermission handleClose={() => setShowPermission(false)} />
      )}
      <Tr
        {...props}
        type="tHead"
        style={{
          borderLeft: '1px solid #e2eaf8',
          borderRight: '1px solid #e2eaf8',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          width: 'fit-content',
          height: 44,
          minWidth: configItem?.minimizeSidebar ? 1800 : 1636,
        }}
      >
        <Th className="order-table__cell" style={{margin: '0 12px'}}>
          <Checkbox
            checked={checkbox.checked}
            indeterminate={!checkFullPageChecked()}
            onClick={e => {
              e.stopPropagation()
              checkbox.onClick()
            }}
          />
        </Th>
        {checkbox.checked ? (
          <Th className="order-table__cell" data-selected="true" data-type="th">
            <Text as="b">
              {selected.list.length > 9
                ? selected.list.length
                : `0${selected.list.length}`}{' '}
              {t('selected_order')}
            </Text>
            <div className="order-table__selected-action-dropdown">
              <Button
                className="order-table__selected-action-toggle"
                size="xs"
                onClick={() => selected.actionMenu.onToggle(true)}
              >
                {t('action')} {ORDER_ICONS.caretRight}
              </Button>
              {selected.actionMenu.open && (
                <>
                  <div
                    className="order-table__selected-action-backdrop"
                    onClick={() => selected.actionMenu.onToggle(false)}
                  ></div>
                  <ul className="order-table__selected-action-menu common-popover">
                    {actions.map(item => (
                      <li
                        key={item.id}
                        className="order-table__selected-action-menu-item"
                        onClick={() => handleItemClick(item)}
                      >
                        {t(item.name)}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </Th>
        ) : (
          <>
            <Th className="order-table__cell" style={{width: 234, flex: 1}}>
              {t('order_id')}
            </Th>
            <ListTHead data={listTHead} />
            <Th className="order-table__cell" style={{width: 60}} />
            <Th
              className="order-table__cell"
              icon={
                <div
                  style={{position: 'relative', width: '100%', height: '100%'}}
                  onClick={configItem.method.toggleConfigTable}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 4,
                      width: 20,
                      height: 20,
                    }}
                  >
                    <Tooltip
                      title={t('column_config')}
                      style={{marginLeft: 10}}
                    >
                      {ORDER_ICONS.gearSix}
                    </Tooltip>
                  </div>
                </div>
              }
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                background: 'rgb(239,243,251)',
                height: 44,
                borderTop: '1px solid rgb(226, 234, 248)',
              }}
            />
          </>
        )}
      </Tr>
      {!!confirmDeleteModalData && (
        <ConfirmDeleteModal
          content={confirmDeleteModalData?.description}
          isLoading={!canDelete}
          title={confirmDeleteModalData?.title}
          onClose={() => setConfirmDeleteModalData(null)}
          onSubmit={handeSubmitDelete}
        />
      )}
      {globalLoading.value && (
        <div className="order-table__loading">
          <img src="/img/loading.gif" />
        </div>
      )}
      {configItem?.show && <ConfigTable />}
    </>
  )
}

const ListTHead = ({data}) => {
  const {t} = useTranslation()

  return data?.map(thead => {
    switch (thead.code) {
      case 'customer':
        return (
          <Th className="order-table__cell" style={{width: 290}}>
            {t('customer')}
          </Th>
        )
      case 'order_origin':
        return (
          <Th className="order-table__cell" style={{width: 158}}>
            {t('source_order')}
          </Th>
        )
      case 'pay':
        return (
          <Th
            className="order-table__cell"
            icon={<PaymentFilterPopover />}
            style={{width: 150, textAlign: 'center'}}
          >
            {t('payment')}
          </Th>
        )
      case 'order_value':
        return (
          <Th
            className="order-table__cell"
            style={{width: 180, textAlign: 'end'}}
          >
            {t('return_order_page_order_value')}
          </Th>
        )
      case 'service_fee':
        return (
          <Th
            className="order-table__cell"
            style={{width: 190, textAlign: 'end'}}
          >
            {t('service_fee')}
          </Th>
        )
      case 'vat':
        return (
          <Th
            className="order-table__cell"
            style={{width: 190, textAlign: 'end'}}
          >
            {t('vat')}
          </Th>
        )
      case 'transport_fee':
        return (
          <Th
            className="order-table__cell"
            style={{width: 190, textAlign: 'end'}}
          >
            {t('shipping_fee')}
          </Th>
        )
      case 'total_balance':
        return (
          <Th
            className="order-table__cell"
            style={{width: 190, textAlign: 'end'}}
          >
            {t('total-balance')}
          </Th>
        )
      case 'order_status':
        return (
          <Th
            className="order-table__cell"
            style={{width: 290, textAlign: 'center'}}
          >
            {t('management__order_status')}
          </Th>
        )
      case 'inventory':
        return (
          <Th className="order-table__cell" style={{width: 300}}>
            {t('warehouse_export')}
          </Th>
        )
      case 'order_note':
        return (
          <Th className="order-table__cell" style={{width: 500}}>
            {t('order_note')}
          </Th>
        )
      case 'order_content':
        return (
          <Th className="order-table__cell" style={{width: 500}}>
            {t('item_content')}
          </Th>
        )
      case 'delivery_id':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('billcode')}
          </Th>
        )
      case 'sender_name':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('shipment_location')}
          </Th>
        )
      case 'delivery_date':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('date_column_shipping')}
          </Th>
        )
      case 'partner':
        return (
          <Th className="order-table__cell" style={{width: 300}}>
            {t('Shipping_partner')}
          </Th>
        )
      case 'address':
        return (
          <Th className="order-table__cell" style={{width: 350}}>
            {t('store_address')}
          </Th>
        )
      case 'city':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('general_city')}
          </Th>
        )
      case 'district':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('general_district')}
          </Th>
        )
      case 'ward':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('general_ward')}
          </Th>
        )
      case 'cod':
        return (
          <Th
            className="order-table__cell"
            style={{width: 234, textAlign: 'end'}}
          >
            {t('cod_fee')}
          </Th>
        )
      case 'payment':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('general_payment')}
          </Th>
        )
      case 'delivery_note':
        return (
          <Th className="order-table__cell" style={{width: 300}}>
            {t('delivery_note_form')}
          </Th>
        )
      case 'weight':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('facebookManagement_weight')}
          </Th>
        )
      case 'length':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('long_column')}
          </Th>
        )
      case 'width':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('width_column')}
          </Th>
        )
      case 'height':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('height_column')}
          </Th>
        )
      case 'employee_name':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('employee_column')}
          </Th>
        )
      case 'phone':
        return (
          <Th className="order-table__cell" style={{width: 234}}>
            {t('phone')}
          </Th>
        )
      default:
        return ''
    }
  })
}
