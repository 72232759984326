import React from "react";
import {useReportOrder} from "./hook/useReportOrder";
import {ReportOrderProvider} from './provider/_context'
import {GridLayout} from "../../../../../../layouts/gridLayout";
import {FilterReport} from "./component/filter/_filterFormReport";
import {Revenue} from "./component/revenue";
import {RatioReport} from "./component/ratio";
import {ListOrderProceed} from "./component/listOrderProceed";
export const EcommerceReportOrder = ()=>{
    const {provider} = useReportOrder()
    const{state, dispatch} = provider
    const {status} = state.filter
    const sectionsRatio = !status?.isStatus ?  {
        title: '',
        props: {
            style: {position: 'relative', padding: 16},
            children:  <RatioReport />,
        },
    } : ''
    const sectionListProceed = !status?.isStatus ?  {
        title: '',
        props: {
            style: {position: 'relative', padding: 16},
            children: <ListOrderProceed />,
        },
    } : ''
    return(
        <ReportOrderProvider value={{pageState: state, pageDispatch: dispatch}}>
            <GridLayout
                grid={[
                    {
                        width: 100,
                        sections: [
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 16},
                                    children: <FilterReport />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 0 },
                                    children: <Revenue />,
                                },
                            },
                            sectionsRatio,
                            sectionListProceed,

                        ],
                        props: {style: {position: 'relative'}},
                    },
                ]}
            />
        </ReportOrderProvider>

    )
}