import styled from "styled-components";
export const StyledProductTable = styled.div`

  .report-product-table{
    &-tr{
        border-right: 1px solid #F0F2F5;
        border-bottom: 1px solid #F0F2F5;
        border-left: 1px solid #F0F2F5;
      &[data-table='tHead']{
        .tr__container{
          height: 56px !important;
        }
      }
    }
    &-td{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
       
      &:nth-child(1){
        width: 25%;
        align-items: center;
        justify-content: flex-start;
      }
      &:nth-child(2){
        width: 16%;
        align-items: center;
        justify-content: flex-start;
      }
       &:nth-child(3){
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: 1366px){
          width: 9%;
        }
      }
    }
    &-tooltip{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

`