import {sendRequestAuth} from 'api/api'
import config from 'config'
import useAlert from 'hook/useAlert'
import {useContext, useState} from 'react'
import {OrderContext} from '../provider/_context'
import {orderActions} from '../provider/_reducer'
import useOrderFilterForm from './useOrderFilterForm'
import {CONFIG_ITEM_TABLE} from "../interfaces/_constants";
import {removeAcent} from "../../../common/fieldText/_functions";
import useGlobalContext from "../../../containerContext/storeContext";
import {useTranslation} from "react-i18next";
import { getUrlOrderUpdateStatus } from '../../../api/url'

const useOrderTHead = () => {
  const {t} = useTranslation()
  const {showAlert} = useAlert()
  const {functions} = useOrderFilterForm()
  const [GlobalState, ] = useGlobalContext()
  const {pageState, pageDispatch} = useContext(OrderContext)
  const {table} = pageState

  const [statusUpdateErrList, setStatusErrList] = useState([])
  const [
    shouldOpenUpdateStatusFailedModal,
    setShouldOpenUpdateStatusFailedModal,
  ] = useState(false)
  const [globalLoading, setGlobalLoading] = useState(false)

  const displayList = table.display.list.filter(
    item => item.shipping_status_id !== '15',
  )
  const selectedList = table.selected.list.filter(
    item => item.shipping_status_id !== '15',
  )

  // CHECKBOX
  const shouldActiveCheckbox = selectedList.length > 0

  const isActive =
    displayList.length <= 0
      ? false
      : selectedList.length < displayList.length
      ? false
      : !!!displayList.find(
          item => !!!selectedList.find(find => find?.id === item?.id),
        )

  const handleCheckboxChange = () => {
    let newSelectedList = []
    if (isActive) {
      newSelectedList = selectedList.filter(
        item => !!!displayList.find(find => find?.id === item?.id),
      )
    } else {
      let addingList = []
      displayList.forEach(item => {
        if (item?.shipping_status_id === '15') return
        if (!!!selectedList.find(find => find?.id === item?.id))
          addingList = [...addingList, item]
      })
      newSelectedList = [...selectedList, ...addingList]
    }

    pageDispatch({
      type: orderActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {selected: {list: newSelectedList}},
    })
  }

  // SELECTED ACTION DROPDOWN
  const [shouldOpenSelectedActionMenu, setShouldOpenSelectedActionMenu] =
    useState(false)

  const fetchUpdateOrderStatus = async (idList, status) => {
    const response = await sendRequestAuth(
      'post',
      getUrlOrderUpdateStatus(),
      JSON.stringify({
        order_ids: idList,
        status: status,
      }),
    )
    if (response?.data?.success) {
      showAlert({content: t('status_update'), type: 'success'})
      functions.fetchUpdateData()
    } else {
      const hasError = Array.isArray(response?.data?.errors)

      pageDispatch({
        type: orderActions.NOTIFICATIONS_LIST_UPDATE,
        payload: {
          notifications: {
            list: hasError
              ? response.data.errors.filter(item => !!!item?.success)
              : [],
          },
        },
      })

      if (hasError && response?.data?.errors?.find(x => x.success)) {
        functions.fetchUpdateData()
      }
    }

    pageDispatch({
      type: orderActions.TABLE_SELECTED_LIST_UPDATE,
      payload: {
        selected: {
          list: [],
        },
      },
    })

    return response
  }

  const handleBulkOrderApply = statusValue => {
    setGlobalLoading(true)

    const response = fetchUpdateOrderStatus(
      selectedList.map(item => item.id),
      statusValue,
    )

    response.then(() => {
      setGlobalLoading(false)
    })

    return response
  }

  const handleNotifcationDelete = () => {
    pageDispatch({
      type: orderActions.NOTIFICATIONS_LIST_UPDATE,
      payload: {
        notifications: {
          list: [],
        },
      },
    })
  }


  // ==================================== CONFIG TABLE ===============================
  // ===================== ======================== ===============================
  const showConfigTable = table?.configItem?.show || false
  const configList = table?.configItem?.list || []
  const configListOrigin = table?.configItem?.listOrigin || []
  const configActiveList = table?.configItem?.activeList?.filter(item => item.active || item.code === 'order_id') || []
  const configApplyList = table?.configItem?.applyList

  const toggleConfigTable = _ => pageDispatch({type: orderActions.SHOW_CONFIG_ITEM_TABLE})

  const handleConfigTableKeywordChange = keyword => {
    const formatDataValue = keyword.trim()
      ? removeAcent(keyword.trim()?.toLowerCase())
      : ''

    const listData = configListOrigin.filter(item => {
      const formatNameItem = item?.name
        ? removeAcent(t(item.name)?.toLowerCase())
        : ''
      return formatNameItem.includes(formatDataValue)
    })?.map(item => {
      const activeItem = configActiveList?.find(it => it.code === item.code)
      item.active = !!activeItem || item.code === 'order_id'
      return item
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_LIST,
      payload: listData
    })
  }

  const toggleItemColumn = item => {
    const hasColumn = configActiveList?.find(act => act.code === item.code)
    const listConfig = [...configList]?.map(conf => {
      if(conf.id === item.id) conf.active = !item.active
      return conf
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_LIST,
      payload: listConfig
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_ACTIVE_LIST,
      payload: !!hasColumn ? configActiveList?.filter(act => act.code !== item.code) : [...configActiveList, {...item, active: true}]
    })
  }
  const onChangeIndex = arr => {
    const result = arr?.map((item, index) => {
      const oldElement = configActiveList?.find(it => it?.id === item?.id)
      item.code = oldElement?.code
      item.name = oldElement?.name
      item.active = oldElement?.active
      item.position = index + 1
      return item
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_ACTIVE_LIST,
      payload: result
    })
  }

  const cancelConfigTable = _ => {
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_LIST,
      payload: configListOrigin?.map(item => {
        const hasColumn = configApplyList?.find(col => +col.id === +item.id)
        item.active = !!hasColumn
        return {...item}
      })
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_ACTIVE_LIST,
      payload: configApplyList || []
    })
    toggleConfigTable()
  }

  const applyConfigTable = _ => {
    try {
      pageDispatch({
        type: orderActions.UPDATE_CONFIG_ITEM_LIST,
        payload: configListOrigin?.map(item => {
          const hasActive = configActiveList?.find(conf => conf.id === item.id)
          item.active = !!hasActive
          return {...item}
        }) || []
      })
      pageDispatch({
        type: orderActions.UPDATE_CONFIG_ITEM_APPLY_LIST,
        payload: configActiveList
      })

      const configTableOrder = [{
        user_id: GlobalState.user.user_id,
        data: configActiveList
      }]
      let configTableArray = JSON.parse(window.localStorage.getItem('config_table_order')) || ''
      if(!!configTableArray) {
        const checkLocalStorage = configTableArray.find(item => item?.user_id === GlobalState?.user?.user_id)
        if(!!checkLocalStorage) {
          configTableArray.map(item => {
            if(item?.user_id === GlobalState?.user?.user_id) item.data = configActiveList
          })
        } else {
          configTableArray = [...configTableArray, {
            user_id: GlobalState.user.user_id,
            data: configActiveList
          }]
        }
      } else {
        configTableArray = configTableOrder
      }
      setTimeout(() => {
        localStorage.setItem(`config_table_order`, JSON.stringify(configTableArray))
      }, 500)
      toggleConfigTable()
    } catch (e) {
      toggleConfigTable()
    }
  }

  const defaultConfigTable = _ => {
    const defaultList = [...CONFIG_ITEM_TABLE]?.map(item => {
      return {...item}
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_LIST,
      payload: defaultList
    })
    pageDispatch({
      type: orderActions.UPDATE_CONFIG_ITEM_ACTIVE_LIST,
      payload: defaultList
    })
  }
  // =========================== END CONFIG TABLE ===============================
  // ===================== ======================== ===============================

  return {
    configItem: {
      minimizeSidebar: GlobalState.shouldMinimizeSidebar,
      show: showConfigTable,
      list: configList,
      activeList: configActiveList,
      applyList: configApplyList,
      method: {
        toggleConfigTable,
        toggleItemColumn,
        onChangeIndex,
        cancelConfigTable,
        applyConfigTable,
        defaultConfigTable,
        onSearch: handleConfigTableKeywordChange
      }
    },
    globalLoading: {value: globalLoading, set: setGlobalLoading},
    checkbox: {
      checked: shouldActiveCheckbox,
      onClick: handleCheckboxChange,
    },
    modal: {
      updateStatus: {
        data: statusUpdateErrList,
        open: !!(
          shouldOpenUpdateStatusFailedModal && statusUpdateErrList.length > 0
        ),
        onToggle: setShouldOpenUpdateStatusFailedModal,
      },
    },
    notifications: {
      delete: handleNotifcationDelete,
    },
    selected: {
      actionMenu: {
        open: shouldOpenSelectedActionMenu,
        onToggle: setShouldOpenSelectedActionMenu,
        actions: [
          () => handleBulkOrderApply(1),
          () => handleBulkOrderApply(7),
          () => handleBulkOrderApply(15),
        ],
      },
      list: selectedList,
    },
  }
}

export default useOrderTHead
