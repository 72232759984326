import styled from 'styled-components'
export const StyleUpdateOrderModal = styled.div`
    width: 680px;
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
    .ecommerce-order-modal{
      &-body{
        margin: 32px 0;
        &[data-footer = 'false']{
          margin: 32px 0 0 0;
        }
        &[data-marginTop = 'false']{
          margin: 0;
        }
      }
      &-footer{
        text-align: right;
      }
      &-status-group{
        display: flex;
        align-items: center;
        padding: 15px 0px 15px 12px;
        border: 1px solid #EFF2F8;
        border-radius: 8px;
        &:nth-child(1){
          margin-bottom: 8px;
        }
      }
    }
`