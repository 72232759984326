import React from "react";
import {StyledProductTable} from "./_style";
import {Tr} from "../../../../../../../../layouts/tableLayout/_tr";
import {REPORT_PRODUCT_TABLE_HEAD} from "../../interface/_contant";
import {Td} from "../../../../../../../../layouts/tableLayout/_td";
import {Text} from "../../../../../../../../common/text";
import {Tooltip} from "../../../../../../../../common/tooltip";
import {useTranslation} from "react-i18next";

export const ProductTableHeader = ()=>{
  const { t } = useTranslation()
    return(
        <StyledProductTable>
                <Tr
                    type={'tHead'}
                    className={'report-product-table-tr'}
                    data-table={'tHead'}
                >
                    {
                        REPORT_PRODUCT_TABLE_HEAD?.map(map => <Td
                            key={map.id}
                            className={'report-product-table-td'}
                        >
                            <Text fontWeight={500}>{t(map?.name)}</Text>
                            {
                                !!map?.iconToolTip && !!map?.tooltip && <Tooltip style={{paddingTop: 5, marginLeft: 4}} title={t(map?.tooltip)}>
                                    {map?.iconToolTip}
                                </Tooltip>
                            }
                        </Td>)
                    }
                </Tr>
        </StyledProductTable>
    )
}