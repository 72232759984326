import {Button} from 'common/button'
import {Text} from 'common/text'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'

import styled from 'styled-components'
import {formatMoney} from '../../../../util/functionUtil'
import {formatDatetime} from 'common/form/datePicker/_functions'
import useOrderRow from 'Pages/refactorOrder/hooks/useOrderRow'
import {ORDER_ICONS} from 'Pages/refactorOrder/interfaces/_icons'
import {useNavigate} from 'react-router-dom'
import {CellPayment} from './_cellRefundCustomer'
import { useTranslation } from 'react-i18next'

export const RowTabRefurn = ({data, rowData, ...props}) => {
  const {t} = useTranslation()
  const {row, detail} = useOrderRow(data)
  const navigate = useNavigate()
  const handleEditOrder = _ => navigate(`/giveback-product/create/${data.id}`)
  const isFullRefund = !!!data?.order_details?.find(item => {
    return +item.quantity - +item.quantity_returned !== 0
  })

  return (
    <StyledRowTabDetail {...props}>
      {Array.isArray(data?.refund_list) && data.refund_list.length > 0 && (
        <>
          {' '}
          <div className="row-tab-detail__header">
            <Text fontSize={16} fontWeight={600}>
              {t('purchases_return_history')}
            </Text>
            {!isFullRefund &&
              ['8', '4', '20', '19'].includes(
                detail.active.shipping_status_id,
              ) && (
                <Button
                  onClick={handleEditOrder}
                  target={'_blank'}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  {ORDER_ICONS.plus} {t("create_return_order")}
                </Button>
              )}
          </div>
          <div className="row-tab-detail__table">
            <div className="row-tab-detail__thead">
              <div className="row-tab-detail__tr">
                <div className="row-tab-detail__th">{t('return_order_page_order_return_id')}</div>
                <div className="row-tab-detail__th">{t('return_order_creation_date')}</div>
                <div className="row-tab-detail__th">
                  {t('return_order_receipt_date')}
                </div>
                <div className="row-tab-detail__th">{t('return_order_page_value_paid')}</div>
                <div className="row-tab-detail__th">{t('return_order_page_refund_money')}</div>
                <div className="row-tab-detail__th">{t('return_order_page_get_refunds')}</div>
                <div className="row-tab-detail__th">{t('return_order_page_warehouse_receive_goods')}</div>
              </div>
            </div>
            <div className="row-tab-detail__tbody">
              {data.refund_list.map(item => (
                <div key={item.order_id} className="row-tab-detail__tr">
                  <div
                    className="row-tab-detail__td"
                    title={item?.order_code}
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Text
                      href={`/giveback-products?search=${item?.order_code}`}
                      style={{cursor: 'pointer'}}
                      target="_blank"
                      color={THEME_SEMANTICS.delivering}
                      as={'a'}
                    >
                      {item?.order_code || '---'}
                    </Text>
                  </div>
                  <div className="row-tab-detail__td">
                    {formatDatetime(item?.create_date || '---')}
                  </div>
                  <div className="row-tab-detail__td">
                    {+item?.status === 1
                      ? item?.receive_date
                        ? formatDatetime(item?.receive_date)
                        : '---'
                      : '---'}
                  </div>
                  <div className="row-tab-detail__td">
                    {/* {item?.total_price
                      ? Number(item.total_price) < 10
                        ? `0${item.total_price}`
                        : item.total_price
                      : '---'} */}
                      {formatMoney(item?.total_price || '---')}
                  </div>
                  <div className="row-tab-detail__td">
                  <CellPayment
                      money={item?.payment_money }
                      status={item?.status_payment}
                    />
                  </div>
                  <div className="row-tab-detail__td">
                    {+item?.status === 1 ? (
                      <Text className={'row-tab-detail__td--success'}>
                        {t('general_received')}
                      </Text>
                    ) : (
                      <Text className={'row-tab-detail__td--danger'}>
                        {t('general_not_received')}
                      </Text>
                    )}
                  </div>
                  <div className="row-tab-detail__td">
                    {item?.warehouse_name || '---'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {!data?.refund_list.length && (
        <div
          className="row-tab-detail__journey"
          style={{opacity: detail.isLoading ? 0.5 : 1}}
        >
          <div className="row-tab-detail__journey-no-data">
            <img src={'/img/order/order-empty.png'} alt="Empty state" />
            <p>{t('no_return_orders')}</p>
            {!isFullRefund &&
              ['8', '4', '20', '19'].includes(
                detail.active.shipping_status_id,
              ) && (
                <Button
                  onClick={handleEditOrder}
                  target={'_blank'}
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  {ORDER_ICONS.plus} {t("create_return_order")}
                </Button>
              )}
          </div>
        </div>
      )}
    </StyledRowTabDetail>
  )
}

const StyledRowTabDetail = styled.div`
  .row-tab-detail {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__content {
      position: relative;

      margin-bottom: 12px;

      display: flex;
      flex-wrap: wrap;
    }

    &__content-group {
      width: calc(100% / 3 - 12px);
      margin-bottom: 12px;
      margin-right: 12px;
    }

    &__info-table {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inventory {
      min-height: 40px;
      margin-bottom: 12px;
      padding: 10px 0;

      display: flex;
      align-items: center;

      border-bottom: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &__table {
      margin-bottom: 12px;
      margin-top: 21px;
      overflow: hidden;

      border: 1px solid #e2eaf8;
      border-radius: 8px;
    }

    &__thead {
      .row-tab-detail__tr {
        background: #f7f9fd;
      }
    }

    &__tr {
      display: flex;
    }

    &__th {
      min-height: 44px;
      padding: 12px;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;

      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
      }
      &:nth-child(5) {
        width: 10%;

        text-align: right;
      }
      &:nth-child(6) {
        width: 15%;
        text-align: center;
      }
      &:nth-child(7) {
        flex: 1;
      }
    }

    &__td {
      min-height: 56px;
      padding: 18px 12px;

      border-top: 1px solid #e2eaf8;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &:nth-child(1) {
        width: 10%;
        color: ${THEME_SEMANTICS.delivering};
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
      }
      &:nth-child(5) {
        width: 10%;
        text-align: right;
      }
      &:nth-child(6) {
        width: 15%;
        text-align: center;
      }
      &:nth-child(7) {
        flex: 1;
      }

      &--success {
        color: #00ab56 !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #e5fff2;
        border-radius: 4px;
      }
      &--danger {
        color: #ff424e !important;
        font-size: 12px !important;
        padding: 4px 12px;
        background: #ffebec;
        border-radius: 4px;
      }
    }

    &__result-item {
      margin-bottom: 8px;

      display: flex;

      color: ${THEME_COLORS.secondary_100};
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__result-label {
      padding: 0 12px;

      flex: 1;

      text-align: right;
    }

    &__result-value {
      width: 13%;
      padding: 0 12px;

      text-align: right;
    }

    &__journey-no-data {
      display: grid;
      justify-content: center;

      img {
        margin: auto;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #7c88a6;
        text-align: center;
        margin: 16px 0;
      }
    }
  }
`
