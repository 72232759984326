import React from "react";
import styled from "styled-components";
import {TopQuantity} from "./topQuantity";
import {TopRevenue} from "./topRevenue";
import {useReportOverviewFilter} from "../../../../hook/useReportOverviewFilter";
export const TableTopProduct = ()=>{
    const {topProduct} = useReportOverviewFilter()
    const {data, meta} = topProduct
    return(
        <StyleTableTopProduct>
            <TopRevenue data={data?.revenue} meta={meta} />
            <TopQuantity data={data?.quantity}  meta={meta}/>
        </StyleTableTopProduct>
    )
}
const StyleTableTopProduct = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 24px;
`