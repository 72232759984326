export const noticeCompAction={
    GET_LIST_NOTICE:'GET_LIST_NOTICE',
    GET_DETAIL_NOTICE:'GET_DETAIL_NOTICE',
    //TOTAL
    GET_TOTAL_NOTICE:'GET_TOTAL_NOTICE',

    //PER_PAGE
    GET_PER_PAGE_NOTICE:'GET_PER_PAGE_NOTICE',

    SET_LOADING_NOTICE_TABLE:'SET_LOADING_NOTICE_TABLE',
    SET_ACTIVE_TAB_TYPE:'SET_ACTIVE_TAB_TYPE',
    SET_CAN_LOAD_MORE_NOTICE:'SET_CAN_LOAD_MORE_NOTICE',
    OPEN_MODAL_NOTIFICATION:'OPEN_MODAL_NOTIFICATION',
}