import {useReducer} from "react";
import {reportProductInitState, reportProductReducer} from "../provider/_reducer";

export const useReportProduct = ()=>{
    const [state, dispatch] = useReducer(reportProductReducer,reportProductInitState)
    return{
        provider:{
            state,
            dispatch
        },
    }
}