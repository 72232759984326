export function ftoDate(date = 0) {
    switch(date)
    {
        case 1: {date="CN"; break;}
        case 2: {date="T2"; break;}
        case 3: {date="T3"; break;}
        case 4: {date="T4"; break;}
        case 5: {date="T5"; break;}
        case 6: {date="T6"; break;}
        case 7: {date="T7"; break;}
        default: date = 0; break;
    }
    return date
}

export const getDateFromNow = (n,otp = {type: 'start'}) => {
    let date = new Date()
    if(otp && otp?.type == 'start')
        date.setHours(0,0,0,0);
    if(otp && otp?.type == 'end')
        date.setHours(23,59,0,0);
    const res = date.setTime(date.getTime() + n * 24 * 60 * 60 * 1000)
    return new Date(res)
}

export const convertDateTime = (date) => {
    const splitDateTime = date?.split(' ')
    const splitDate = splitDateTime[0]?.split('/')
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]} ${splitDateTime[1]}`
}


export const formatDatetime = data => {
    const D = new Date(data)

    const day= ftoDate(D.getDay()+1)
    let d = D.getDate()
    d = d < 10 ? `0${d}` : d

    let m = D.getMonth()
    m = m + 1
    m = m < 10 ? `0${m}` : m

    let y = D.getFullYear()


    return `${day}, ${d}/${m}/${y}`
}

export const formatDatetimeV2 = data => {
    const D = new Date(data)

    let d = D.getDate()
    d = d < 10 ? `0${d}` : d

    let m = D.getMonth()
    m = m + 1
    m = m < 10 ? `0${m}` : m

    let y = D.getFullYear()


    return `${d}/${m}/${y}`
}

export const convertDateTimeToApiFormat = data => {
    const dateTimeSplit = data.split('/')
    const splitDate = dateTimeSplit[2]?.split(' ')
    const ymd = `${splitDate[0]}-${dateTimeSplit[1]}-${dateTimeSplit[0]}`
    return `${ymd || ''}`.trim()
}
export const convertDateTimeToApiFormatV2 = data => {
    const dateSplit = data?.split(',')
    const spaceSplit= dateSplit[1].split(' ')
    const dateTimeSplit = spaceSplit[1].split('/')
    const splitDate = dateTimeSplit[2]?.split(' ')
    const ymd = `${splitDate[0]}-${dateTimeSplit[1]}-${dateTimeSplit[0]}`
    return `${ymd || ''}`.trim()
}