import {Popper} from './component/popoverNotice'
import React, {createContext, useEffect, useReducer, useState} from 'react'
import {HeaderNotification} from './component/header'
import {TabNotification} from './component/tab'
import {TableNotifications} from './component/table'
import './style.scss'
import {IconBell} from './interfaces/_icon'
import {THEME_COLORS} from 'common/theme/_colors'
import {Text} from 'common/text'
import { Link } from 'react-router-dom'
import useGlobalContext from "../../containerContext/storeContext";
import {PATH} from "../../const/path";
import {noticeCompReducer} from "./provider/_reducer";
import {noticeCompState} from "./provider/_initialState";
import ModalNotification from "../modalNotification";
import {noticeCompAction} from "./provider/_action";
import {useTranslation} from "react-i18next";

export const NotificationContext = createContext()

export default function Notification({
  iconBlack
}) {
    const {t} = useTranslation()
    const [state,dispatch] = useReducer(noticeCompReducer,noticeCompState)
    const [GlobalState, GlobalDispatch] = useGlobalContext()
    const {notice} = GlobalState.totalNotice
    const closeModal = (boo)=>{
        dispatch({type: noticeCompAction.OPEN_MODAL_NOTIFICATION,payload:boo})

    }
    const seeAllNotice = state?.idTab === 'all'? t('see_all_notification') : state?.idTab === 'system' ? t('see_all_noti_system') : t('see_all_noti_my')
  useEffect(()=>{
      dispatch({type:noticeCompAction.GET_LIST_NOTICE,payload:{
          all:{
              list:notice?.allList?.list,
              perPage:20,
              total:notice?.allList.total,
          },
              my:{
              list:notice?.myList.list,
              perPage:20,
              total:notice?.myList.total,
          },
              system:{
              list:notice?.systemList.list,
              perPage:20,
              total:notice?.systemList.total,
          },
          }})
      dispatch({
          type: noticeCompAction.GET_TOTAL_NOTICE, payload: {
              all:GlobalState.totalNotice.all,
              my: GlobalState.totalNotice.my,
              system: GlobalState.totalNotice.system,
          }
      })
  },[notice?.allList])
    return (
      <>
          <Popper
              renderPopper={({onClose}) => (
                  <NotificationContext.Provider value={{pageState: state, pageDispatch: dispatch}}>
                      <div className="notification">
                          <HeaderNotification />
                          <TabNotification />
                          <TableNotifications onClose={onClose} />
                          {state.notice?.all?.total > 0 && (
                              <div style={{textAlign: 'center',paddingTop:'8px'}} onClick={onClose}>
                              <Link to={`${PATH.NOTIFICATION_MANAGEMENT}?tab=${state?.idTab}` || '#'}>

                                      <Text color={THEME_COLORS.blue_500} fontWeight={500}>
                                          {seeAllNotice}
                                      </Text>

                              </Link>
                              </div>
                          )}
                      </div>
                  </NotificationContext.Provider>
              )}
              renderToggle={({open}) => (iconBlack
                                          ? ICON_BACK_BELL
                                          : open ? <IconBell /> : <IconBell />)}
              placement="bottom-end"
              popperProps={{
                  className: 'notification-bell',
              }}
              noticeValue = {+GlobalState.totalNotice.all > 0 && GlobalState.totalNotice.all}
              noticeClass={'notification-bell-total'}
              popoverMainClass={'notice-component-popover'}
          />

          {state?.openModal && <ModalNotification openModal={state?.openModal} setOpenModal={closeModal} item={state.detailNotice}/>}
      </>


  )
}

const ICON_BACK_BELL = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.7207 15.5833H19.4013V17.4167H2.5957V15.5833H4.27626V9.16668C4.27626 5.11659 7.2859 1.83334 10.9985 1.83334C14.7111 1.83334 17.7207 5.11659 17.7207 9.16668V15.5833ZM8.47765 19.25H13.5193V21.0833H8.47765V19.25Z" fill="#00081D"/>
</svg>
