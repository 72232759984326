import React from 'react';
import config from "../../config";
import {Text} from "../../common/text";
import styled from "styled-components";
import {Box, Modal} from "@mui/material";
import {useTranslation} from "react-i18next";

const PopupNotPermission = ({cID, cAnchorEl, handleClose, ...props}) => {
  const {t} = useTranslation()
  const open = true;
  return (
    <Modal open={open}>
      <Box>
        <StyledPopupNotPermission {...props}>
          <Text className={'not-licensed-cancel'} onClick={handleClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.22473 18.9684L5.03125 17.775L10.8062 12L5.03125 6.22497L6.22473 5.03149L11.9997 10.8065L17.7747 5.03149L18.9682 6.22497L13.1932 12L18.9682 17.775L17.7747 18.9684L11.9997 13.1934L6.22473 18.9684Z" fill="black"/>
            </svg>
          </Text>
          <img src={'/img/not-license.png'} alt={'img'} className={'not-licensed-img-primary'}/>
          <Text fontWeight={600} fontSize={18} as={'p'} className={'not-licensed-text not-licensed-text-first'}>{t('popup_not_permission_title')}</Text>
          <Text fontSize={14} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-text-second'}>{t('popup_not_permission_content')}</Text>
          <div className={'not-licensed-group-phone'}>
            <Text fontSize={14} as={'p'} color={'#7C88A6'} className={'not-licensed-text not-licensed-hotline'}>{t('not_licensed_contact')}</Text>
            <Text fontWeight={600} fontSize={14} color={'#1A94FF'} className={'not-licensed-text not-licensed-text-phone'}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0738 14.5623C12.7748 14.8377 12.3812 14.9873 11.9798 15.003C10.8859 15.003 8.57985 14.334 4.90439 10.69C1.23679 7.04606 0.599295 4.71644 0.575684 3.66181C0.575684 3.26042 0.740952 2.87477 1.00854 2.57569L2.30717 1.28494C2.45671 1.12754 2.63772 1.00947 2.84235 0.930767C3.05485 0.852063 3.26736 0.828463 3.48773 0.844204C3.70023 0.859945 3.90485 0.930778 4.09374 1.04096C4.28263 1.15115 4.44003 1.30068 4.55809 1.4817L5.61272 2.97704C5.71503 3.15019 5.78588 3.34698 5.80162 3.54374C5.80162 3.74836 5.77013 3.94512 5.69142 4.13401L4.94373 5.48769C4.89651 5.59001 4.8729 5.70811 4.8729 5.82616C4.8729 5.94422 4.89651 6.0544 4.94373 6.16458C5.41595 7.1169 6.06134 7.97473 6.85625 8.68306C7.57245 9.47797 8.42244 10.1234 9.38263 10.5877C9.48494 10.6349 9.61087 10.6585 9.72893 10.6585C9.83911 10.6585 9.96503 10.6349 10.0673 10.5877L11.4289 9.88725C11.6099 9.80068 11.8146 9.76131 12.0113 9.77705C12.216 9.78492 12.4127 9.84792 12.578 9.95811L14.0812 11.0206C14.2623 11.1386 14.4197 11.2961 14.5299 11.485C14.6322 11.6738 14.7109 11.8784 14.7266 12.0909C14.7502 12.3034 14.7266 12.5238 14.64 12.7284C14.5535 12.9331 14.4354 13.1141 14.278 13.2636L13.0738 14.5623Z" stroke="#1A94FF" strokeWidth="0.974212" strokeMiterlimit="10"/>
              </svg>
              <a href={`tel:${config.PHONE}`}>{config.PHONE} {t('not_licensed_click_2')}</a>
            </Text>
            <Text fontWeight={600} fontSize={14} color={'#1A94FF'} className={'not-licensed-text not-licensed-text-phone'}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0738 14.5623C12.7748 14.8377 12.3812 14.9873 11.9798 15.003C10.8859 15.003 8.57985 14.334 4.90439 10.69C1.23679 7.04606 0.599295 4.71644 0.575684 3.66181C0.575684 3.26042 0.740952 2.87477 1.00854 2.57569L2.30717 1.28494C2.45671 1.12754 2.63772 1.00947 2.84235 0.930767C3.05485 0.852063 3.26736 0.828463 3.48773 0.844204C3.70023 0.859945 3.90485 0.930778 4.09374 1.04096C4.28263 1.15115 4.44003 1.30068 4.55809 1.4817L5.61272 2.97704C5.71503 3.15019 5.78588 3.34698 5.80162 3.54374C5.80162 3.74836 5.77013 3.94512 5.69142 4.13401L4.94373 5.48769C4.89651 5.59001 4.8729 5.70811 4.8729 5.82616C4.8729 5.94422 4.89651 6.0544 4.94373 6.16458C5.41595 7.1169 6.06134 7.97473 6.85625 8.68306C7.57245 9.47797 8.42244 10.1234 9.38263 10.5877C9.48494 10.6349 9.61087 10.6585 9.72893 10.6585C9.83911 10.6585 9.96503 10.6349 10.0673 10.5877L11.4289 9.88725C11.6099 9.80068 11.8146 9.76131 12.0113 9.77705C12.216 9.78492 12.4127 9.84792 12.578 9.95811L14.0812 11.0206C14.2623 11.1386 14.4197 11.2961 14.5299 11.485C14.6322 11.6738 14.7109 11.8784 14.7266 12.0909C14.7502 12.3034 14.7266 12.5238 14.64 12.7284C14.5535 12.9331 14.4354 13.1141 14.278 13.2636L13.0738 14.5623Z" stroke="#1A94FF" strokeWidth="0.974212" strokeMiterlimit="10"/>
              </svg>
              <a href={`tel:${config.CSKH}`}>{config.CSKH} ({t('not_licensed_cskh')})</a>
            </Text>
          </div>
        </StyledPopupNotPermission>
      </Box>
    </Modal>
  )
}

export default PopupNotPermission

export const StyledPopupNotPermission = styled.div`
  padding: 48px 24px 24px 24px;
  text-align: center;
  width: 600px;
  height: 352px;
  background: #fff;
  margin: 30vh auto auto auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  
  .not-licensed {
    &-cancel {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    &-img-primary {
      width: 150px;
      height: 108px;
    }
    &-text {
      text-align: center;
      width: 100% !important;
      &-first {
        margin-top: 32px;
      }
      &-second {
        margin-top: 8px;
      }
      &-phone {
        margin-right: 16px;
        & svg {
          vertical-align: middle;
          margin-right: 4px;
        }
        & a {
          color: #1A94FF;
        }
      }
    }
    &-hotline {
      margin: 16px 0 8px 0;
    }
    &-group-phone {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #F8FAFD;
      height: 82px;
    }
  }
`
