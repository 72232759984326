import { Link } from 'react-router-dom'
import {HEADER_QUICK_ACCESSES} from './_constants'
import {StyledQuickAccesses} from './_styled'
import { useTranslation } from 'react-i18next'

export const QuickAccesses = ({...props}) => {
  const {t} = useTranslation()
  return (
    <StyledQuickAccesses {...props}>
      <h5 className="quick-accesses__heading">{t('quick_action')}</h5>
      <ul className="quick-accesses__list">
        {HEADER_QUICK_ACCESSES.map(item => (
          <li
            key={item.id}
            className="quick-accesses__item"
            onClick={props?.onClose}
          >
            <Link
              to={item?.path ?? '#'}
            >
              {item.displayIcon}
              <span>{t(item.name)}</span>
            </Link>
          </li>
        ))}
      </ul>
    </StyledQuickAccesses>
  )
}
