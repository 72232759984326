import React from "react";
import styled from "styled-components";
import {Text} from "../../../../../../../../../../common/text";
import {useTranslation} from "react-i18next";
export const ChartSeriesRatio = ({...props}) =>{
  const { t } = useTranslation()
    const {data, key} = props

    return(
        <StyleChartSeries>
            <div>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="4.5" stroke={data?.color} stroke-width="3"/>
                </svg>
            </div>
            <div style={{marginLeft: 8}}>
                <Text as={'p'} >{t(data?.title)}</Text>
                <Text fontSize={18} fontWeight={600}>{data?.total} {t('orders')} </Text>
              <Text color={'#00AB56'} fontSize={16} fontWeight={500}>{' '}({!data?.percent ? 0 : Math.round(data?.percent * 100) / 100}%)</Text>
            </div>
        </StyleChartSeries>
    )
}
const StyleChartSeries = styled.div`
  width: 100%;
  height: 112px;
  padding: 28px 24px;
  display: flex;
  align-items: flex-start;
  border-right: 1px dotted var(--line, #EBEEF5);
  border-bottom: 1px dotted var(--line, #EBEEF5);
  &:nth-child(3){
    border-right: unset;
  }
  &:nth-child(6){
    border-right: unset;
  }
  &:nth-child(7){
    border-right: 1px dotted var(--line, #EBEEF5);
  }
  &:nth-child(8){
    border-right: 1px dotted var(--line, #EBEEF5);
  }
  &:nth-child(9){
    border-right: unset;
  }
  
`