import { THEME_COLORS } from 'common/theme/_colors'
import { THEME_SEMANTICS } from 'common/theme/_semantics'
import { ORDER_ICONS } from './_icons'
import { Text } from '../../../common/text'
import { Tooltip } from '../../../common/tooltip'
import { REPORT_SALE_ICONS } from '../../Report/Sales/interfaces/_icons'
import { useTranslation } from 'react-i18next'

export const ORDER_BREADCRUMB = [
  { id: 1, name: 'order', url: '#' },
  { id: 2, name: 'manage_order', url: '#' },
]

export const ORDER_PAGE_HEADER_ACTIONS = [
  {
    id: 1,
    name: null,
    appearance: 'secondary',
    icon: ORDER_ICONS.repeat,
  },
  {
    id: 2,
    name: 'export_excel',
    appearance: 'secondary',
    icon: ORDER_ICONS.download,
    type: 'dropdown',
  },
  {
    id: 3,
    name: 'batch_order',
    appearance: 'secondary',
    // href: '/tools/bulks-order',
    icon: ORDER_ICONS.filePlus,
  },
  {
    id: 4,
    name: 'create_order',
    appearance: 'primary',
    // href: '/orders/create',
    icon: ORDER_ICONS.plus,
  },
]

export const ORDER_FILTER_FORM_DATE_TIME_SORT_TYPES = [
  { id: 1, name: 'order_date_created', value: 'created' },
  { id: 2, name: 'order_sent_date', value: 'sended' },
]

export const ORDER_FILTER_FORM_DUPLICATE_VALUES = [
  { id: 1, name: 'no_duplicate_filter', value: '0' },
  { id: 2, name: 'duplicate_order_filter', value: '1' },
]

export const ORDER_FILTER_TAG_FIELDS = [
  'dateTime',
  'employee',
  'shippingStatus',
  'shippingPartner',
  'product',
  'source',
  'warehouse',
  'duplicate',
  'employee_type',
  'cashbook_customer',
  'keyword',
  'liveVideoID'
]

export const ORDER_LIMIT_EXPORT = 5000

export const ORDER_TABLE_CELL_PAYMENT_TYPES = {
  danger: 'not_payment',
  success: 'paid',
  warning: 'partial_paid',
}

export const ORDER_TABLE_CELL_SHIPPING_STATUSES = {
  _1: { background: '#EBF5FF', color: '#1A94FF' },
  _2: { background: '#EFFBF4', color: '#33CC70' },
  _3: { background: '#ECF4FE', color: '#0B74E5' },
  _4: { background: '#EBFFF5', color: '#00AB56' },
  _5: { background: '#FFEBF2', color: '#FC5994' },
  _6: { background: '#EBFFF9', color: '#007B56' },
  _7: { background: '#FFEBEB', color: '#FF7471' },
  _8: { background: '#EBFAFF', color: '#038DB2' },
  _15: { background: '#EFF3FB', color: '#7C88A6' },
  _17: { background: '#FFF0EB', color: '#FC4C0A' },
  _19: { background: '#EBFFF4', color: '#007D3A' },
  _20: { background: '#FFF5EB', color: '#FC820A' },
  _21: { background: '#EFF3FB', color: '#7C88A6' },
  _22: { background: '#EBF8FE', color: '#1BACF9' },
  _23: { background: '#FFEBEC', color: '#FF424E' },
}

const fullStatusIdList = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '15',
  '17',
  '19',
  '20',
  '21',
  '22',
  '23',
]
export const ORDER_TABLE_ROW_MENU_POPOVER = [
  {
    id: 1,
    name: 'edit',
    icon: ORDER_ICONS.edit05,
    inventoryStatus: ['1', '21'],
    // isDeveloping: true,
    noInventoryStatus: ['1', '21'],
    action: 'edit',
  },
  {
    id: 2,
    name: 'print_order_details_a4',
    icon: ORDER_ICONS.printer,
    inventoryStatus: fullStatusIdList,
    noInventoryStatus: fullStatusIdList,
    action: 'print',
  },
  {
    id: 3,
    name: 'print_order_k80',
    icon: ORDER_ICONS.printer,
    inventoryStatus: ['8'],
    noInventoryStatus: ['8'],
    action: 'k80',
  },
  {
    id: 4,
    name: 'general_copy',
    icon: ORDER_ICONS.copy03,
    inventoryStatus: fullStatusIdList,
    // isDeveloping: true,
    noInventoryStatus: fullStatusIdList.filter(item => item !== '8'),
    action: 'copy',
  },
  {
    id: 5,
    name: 'e_order_create_invoice_1',
    icon: ORDER_ICONS.create_invoice,
    inventoryStatus: ['8'],
    // isDeveloping: true,
    noInventoryStatus: ['8'],
    action: 'create_invoice',
  },
  {
    id: 6,
    name: 'delivery_status_1',
    icon: ORDER_ICONS.truck,
    inventoryStatus: ['8', '21'],
    noInventoryStatus: ['21'],
    action: 'shipping',
  },
  {
    id: 7,
    name: 'delivery_status_7',
    icon: ORDER_ICONS.truckX,
    inventoryStatus: ['1'],
    noInventoryStatus: ['1'],
    action: 'cancel-shipping',
  },
  {
    id: 8,
    name: 'delivery_status_15',
    icon: ORDER_ICONS.packageX,
    inventoryStatus: ['1', '7', '8', '21'],
    noInventoryStatus: ['1', '7', '21'],
    action: 'cancel-order',
  },
  {
    id: 9,
    name: 'report_customer',
    icon: ORDER_ICONS.flag03,
    isDanger: true,
    inventoryStatus: fullStatusIdList,
    noInventoryStatus: fullStatusIdList.filter(item => item !== '8'),
    action: 'report',
  },
]

export const ORDER_TABLE_ROW_EXTRA_TABS = [
  { id: 1, name: 'order_detail', value: 'detail' },
  { id: 2, name: 'delivery', value: 'shipping' },
  { id: 3, name: 'payment', value: 'payment' },
  { id: 4, name: 'order_history_infomation', value: 'history' },
  { id: 5, name: 'purchases_return_history', value: 'refurn' },
]

export const ORDER_TABLE_ROW_EXTRA_TAB_DETAIL_HEADING_LIST = [
  'seller_information',
  'recipient_information',
  'other_information',
]
const lang = window.localStorage.getItem('i18n') || 'vi'
export const ORDER_TABLE_ROW_EXTRA_TAB_DETAIL_FIGURE_LIST = [
  {
    id: 1,
    label: 'delivery_point',
    color: THEME_SEMANTICS.delivering,
  },
  {
    id: 2,
    label: 'cod_name_customer',
    color: THEME_SEMANTICS.delivering,
  },
  {
    id: 3,
    label: 'unique_order_id',
    color: THEME_COLORS.secondary_100,
  },
  {
    id: 4,
    label: 'creator_order',
    color: THEME_COLORS.secondary_100,
  },
  {
    id: 5,
    label: 'address',
    color: THEME_COLORS.secondary_100,
  },
  // {
  //   id: 6,
  //   label: 'order_note',
  //   color: THEME_COLORS.secondary_100,
  // },
  {
    id: 6,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text color="#7C88A6">{
          lang === ' vi' ? 'Phí giao hàng hộ' : 'Shipping fee'
        }: </Text>
        <Tooltip
          placement={'bottom'}
          title={
            lang === 'vi' ? 'Ghi nhận phí vận chuyển thu từ các shop gửi đơn nhờ giao hộ (dành riêng cho các Điểm thu hàng hộ)'
              : 'Recording delivery fees collected from shops sending orders for delivery (for Pickup Points)'
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '4px'
          }}
        >
          {REPORT_SALE_ICONS.question}
        </Tooltip>
      </div>
    ),
    color: THEME_COLORS.secondary_100,
  },
]
export const ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_FIGURE_LIST = [
  { id: 1, name: 'shipping_company' },
  { id: 2, name: 'cod_fee', tooltip: 'order_cod_amount', down_cod: 'code_reduced' },
  { id: 3, name: 'require_get_order' },
  { id: 4, name: 'order_date_sended' },
  { id: 5, name: 'cargo_insurance' },
  { id: 6, name: 'delivery_note_form' },
  { id: 7, name: 'fee_payer' },
  {id: 8, name: 'estimated_delivery_time'},
]

export const ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_REQUIREMENTS = {
  partSign: { _1: 'receipt_a_part' },
  payer: {
    _1: 'receiver_pays_fee',
    _2: 'sender_pays_fee',
    _3: 'sender_pay_end_month',
  },
  requestGoods: { _1: 'pickup_at_home', _6: 'send_at_post_office' },
  recipientView: { _1: 'show_goods_to_customer', _2: 'not_show_goods_to_customer' },
}

export const ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_PAYMENT_METHODS = {
  PP_PM: 'sender_pay_end_month',
  PP_CASH: 'sender_pays_fee',
  CC_CASH: 'receiver_pays_fee',
}

export const ORDER_TABLE_ROW_EXTRA_TAB_SHIPPING_PRINT_DATA = [
  { id: 1, name: 'template_print_spartner', type: 'others', size: '' },
  { id: 2, name: 'template_print_a5', type: 'UPOS', size: 'a5' },
  { id: 3, name: 'template_print_a4', type: 'UPOS', size: 'a4' },
  { id: 4, name: 'template_print_k80', type: 'UPOS', size: 'k80' },
]

export const ORDER_TABLE_ROW_EXTRA_TAB_PAYMENT_SUBMIT_MODAL_FIGURES = [
  { id: 1, name: 'return_order_page_order_value', color: THEME_COLORS.secondary_100 },
  { id: 2, name: 'cod-number', color: THEME_COLORS.secondary_100 },
  { id: 3, name: 'paid_short', color: THEME_SEMANTICS.delivered },
  { id: 4, name: 'general_still_pay', color: THEME_SEMANTICS.failed },
]

export const ORDER_TABLE_THEAD_SELECTED_ACTIONS = [
  { id: 1, name: 'sended-order', value: '1' },
  { id: 2, name: 'cancel_delivery', value: '7' },
  { id: 3, name: 'cancel_order', value: '15' },
]

export const ORDER_TABLE_THEAD_PAYMENT_FILTER_LIST = [
  { id: 1, name: 'paid_short', value: 'paid' },
  { id: 2, name: 'not_payment', value: 'unpaid' },
  { id: 3, name: '1_part_payment', value: 'partial' },
]
export const TABLE_CELL_PAYMENT_TYPES = {
  danger: 'not_payment',
  success: 'paid_short',
  warning: 'partial_paid',
}

export const EXPORT_ORDER_EXCEL_SELECTIVE = [
  { id: 'stt', name: 'stt_column', position: 0, default: 1, status: 1 },
  { id: 'order_id', name: 'order_id', position: 1, default: 1, status: 1 },
  { id: 'sku', name: 'sku_column', position: 2, default: 1, status: 1 },
  { id: 'customer_name', name: 'Full_Name', position: 3, default: 1, status: 1 },
  { id: 'dt_created', name: 'purchases_purchase_date', position: '', default: 0, status: 0 },
  { id: 'total_amount', name: 'return_order_page_order_value', position: '', default: 0, status: 0 },
  { id: 'service_fee', code: 'service_fee', name: 'service_fee', position: '', default: 0, status: 0 },
  { id: 'order_vat_value', code: 'vat', name: 'vat', position: '', default: 0, status:  0},
  { id: 'order_revenue', code: 'total_balance', name: 'total-balance', position: '', default: 0, status: 0 },
  { id: 'ship_fee', name: 'shipping_fee', position: 4, default: 0, status: 0 },
  { id: 'shipping_status_name', name: 'Shipping_status', position: '', default: 0, status: 0 },
  { id: 'warehouse_name', name: 'warehouse_export', position: '', default: 0, status: 0 },
  { id: 'origin_name', name: 'source_column', position: '', default: 0, status: 0 },
  { id: 'order_note', name: 'order_note', position: '', default: 0, status: 0 },
  { id: 'product_name', name: 'warehouse_check_product_name', position: '', default: 0, status: 0 },
  { id: 'quantity', name: 'quantity', position: '', default: 0, status: 0 },
  { id: 'price', name: 'sell_price', position: '', default: 0, status: 0 },
  { id: 'discount', name: 'report__discount_on_product', position: '', default: 0, status: 0 },
  { id: 'order_discount', name: 'sell_coulmn', position: '', default: 0, status: 0 },
  { id: 'total', name: 'total_price', position: '', default: 0, status: 0 },
  { id: 'customer_phone', name: 'phone', position: '', default: 0, status: 0 },
  { id: 'billcode', name: 'waybill_code', position: '', default: 0, status: 0 },
  { id: 'sender_name', name: 'shipment_location', position: '', default: 0, status: 0 },
  { id: 'dt_send_shipping', name: 'date_column_shipping', position: '', default: 0, status: 0 },
  { id: 'shipping_name', name: 'Shipping_partner', position: '', default: 0, status: 0 },
  { id: 'address', name: 'address', position: '', default: 0, status: 0 },
  { id: 'city_name', name: 'city_name', position: '', default: 0, status: 0 },
  { id: 'district_name', name: 'district_name', position: '', default: 0, status: 0 },
  { id: 'ward_name', name: 'ward_name', position: '', default: 0, status: 0 },
  { id: 'cod', name: 'cod_fee', position: '', default: 0, status: 0 },
  { id: 'payment_method', name: 'cashbook_payment_method', position: '', default: 0, status: 0 },
  { id: 'note', name: 'delivery_note_form', position: '', default: 0, status: 0 },
  { id: 'weight', name: 'facebookManagement_weight', position: '', default: 0, status: 0 },
  { id: 'length', name: 'long_column', position: '', default: 0, status: 0 },
  { id: 'width', name: 'width_column', position: '', default: 0, status: 0 },
  { id: 'height', name: 'height_column', position: '', default: 0, status: 0 },
  { id: 'employee_name', name: 'employee_column', position: '', default: 0, status: 0 },
  { id: 'employee_phone', name: 'phone', position: '', default: 0, status: 0 },
]

export const EXPORT_EXCEL_SELECTIVE_ACTIVE = [
  { id: 'stt', name: 'stt_column', position: 0, default: 1, status: 1 },
  { id: 'order_id', name: 'order_id', position: 1, default: 1, status: 1 },
  { id: 'sku', name: 'sku_column', position: 2, default: 1, status: 1 },
  { id: 'customer_name', name: 'Full_Name', position: 3, default: 1, status: 1 },
]

export const CONFIG_ITEM_TABLE = [
  { id: 1, code: 'order_id', name: 'order_id', active: true },
  { id: 2, code: 'customer', name: 'customer', active: true },
  { id: 3, code: 'order_origin', name: 'source_order', active: true },
  { id: 4, code: 'pay', name: 'payment', active: true },
  { id: 5, code: 'order_value', name: 'order_value', active: true },
  { id: 27, code: 'service_fee', name: 'service_fee', active: true },
  { id: 28, code: 'vat', name: 'vat' },
  { id: 29, code: 'total_balance', name: 'total-balance' },
  { id: 6, code: 'transport_fee', name: 'shipping_fee', active: true },
  { id: 7, code: 'order_status', name: 'management__order_status', active: true },
  { id: 8, code: 'inventory', name: 'warehouse_export' },
  { id: 9, code: 'order_note', name: 'order_note' },
  { id: 10, code: 'order_content', name: 'product_description' },
  { id: 11, code: 'delivery_id', name: 'billcode' },
  { id: 12, code: 'delivery_date', name: 'date_column_shipping' },
  { id: 13, code: 'partner', name: 'Shipping_partner' },
  { id: 14, code: 'address', name: 'address' },
  { id: 15, code: 'city', name: 'general_city' },
  { id: 16, code: 'district', name: 'general_district' },
  { id: 17, code: 'ward', name: 'general_ward' },
  { id: 18, code: 'cod', name: 'cod_fee' },
  { id: 19, code: 'payment', name: 'general_payment' },
  { id: 20, code: 'delivery_note', name: 'delivery_note_form' },
  { id: 21, code: 'weight', name: 'facebookManagement_weight' },
  { id: 22, code: 'length', name: 'long_column' },
  { id: 23, code: 'width', name: 'width_column' },
  { id: 24, code: 'height', name: 'height_column' },
  { id: 25, code: 'employee_name', name: 'employee_column' },
  { id: 26, code: 'phone', name: 'phone' },
  { id: 30, code: 'sender_name', name: 'shipment_location' },
]