import {ECOMMERCE_LIST, FILTER_DATE, IMPORTANT_NUMBER_TAB} from "../interface/_contant";
import {formatDatetime, getDateFromNow} from '../../../utils/formatDate'
import {REPORT_UPDATE_ORDER_DATE} from '../interface/_contant'
export const reportOverviewAction = {
    REPORT_OVERVIEW_ECOMMERCE_ACTIVE:'REPORT_OVERVIEW_ECOMMERCE_ACTIVE',


    //=====DATE TIME=====
    REPORT_OVERVIEW_DATE_TIME_ACTIVE:'REPORT_OVERVIEW_DATE_TIME_ACTIVE',

    //===== END DATE TIME ====

    //======STORES=======
    REPORT_OVERVIEW_STORES_LIST:'REPORT_OVERVIEW_STORES_LIST',
    REPORT_OVERVIEW_STORES_SELECTED:'REPORT_OVERVIEW_STORES_SELECTED',
    REPORT_OVERVIEW_STORES_TAB:'REPORT_OVERVIEW_STORES_TAB',
    REPORT_OVERVIEW_STORES_APPLY_FILTER:'REPORT_OVERVIEW_STORES_APPLY_FILTER',
    REPORT_OVERVIEW_STORES_SEARCH:'REPORT_OVERVIEW_STORES_SEARCH',

    //========IMPORTANT NUMBER=======
    REPORT_OVERVIEW_IMPORTANT_NUMBER_ACTIVE:'REPORT_OVERVIEW_IMPORTANT_NUMBER_ACTIVE',
    REPORT_OVERVIEW_IMPORTANT_NUMBER_UPDATE:'REPORT_OVERVIEW_IMPORTANT_NUMBER_UPDATE',

    //====== RATIO PRODUCT=======
    REPORT_OVERVIEW_RATIO_PRODUCT_UPDATE:'REPORT_OVERVIEW_RATIO_PRODUCT_UPDATE',

    //======= RATIO ORDER=========
    REPORT_OVERVIEW_RATIO_ORDER_UPDATE:'REPORT_OVERVIEW_RATIO_ORDER_UPDATE',

    //======= TOP PRODUCT=========
    REPORT_OVERVIEW_TOP_PRODUCT_UPDATE:'REPORT_OVERVIEW_TOP_PRODUCT_UPDATE',

    //===== MODAL======
    MODAL_ORDER_MODAL:'MODAL_ORDER_MODAL',
    MODAL_ORDER_LIST:'MODAL_ORDER_LIST',
    MODAL_ORDER_LIST_SELECTED:'MODAL_ORDER_LIST_SELECTED',
    MODAL_ORDER_DATE_TIME_UPDATE:"MODAL_ORDER_DATE_TIME_UPDATE",
    MODAL_ORDER_DATE_TIME_KEYWORD:"MODAL_ORDER_DATE_TIME_KEYWORD",
    MODAL_ORDER_DATE_TIME_AUTO_CONNECT:"MODAL_ORDER_DATE_TIME_AUTO_CONNECT",
    MODAL_ORDER_DATE_TIME_STATUS_UPDATE:"MODAL_ORDER_DATE_TIME_STATUS_UPDATE",
    MODAL_ORDER_CONFIRM_UPDATE:"MODAL_ORDER_CONFIRM_UPDATE",
    
    //======MODAL QUICK CONNECT======
    TOGGLE_MODAL_QUICK_CONNECT_OPEN: 'TOGGLE_MODAL_QUICK_CONNECT_OPEN',
    TOGGLE_MODAL_QUICK_CONNECT_RESULT: 'TOGGLE_MODAL_QUICK_CONNECT_RESULT',
    UPDATE_MODAL_QUICK_CONNECT_STORE_LIST: 'UPDATE_MODAL_QUICK_CONNECT_STORE_LIST',
    UPDATE_MODAL_QUICK_CONNECT_STORE_VALUE: 'UPDATE_MODAL_QUICK_CONNECT_STORE_VALUE',
    UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE: 'UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE',
    CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_NOT_CONNECT: 'CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_NOT_CONNECT',
    CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_FAIL_CONNECT: 'CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_FAIL_CONNECT',
    CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS: 'CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS',
    CHANGE_MODAL_QUICK_CONNECT_STORE_AUTO_PRODUCT: 'CHANGE_MODAL_QUICK_CONNECT_STORE_AUTO_PRODUCT',
    UPDATE_MODAL_QUICK_CONNECT_LOADING: 'UPDATE_MODAL_QUICK_CONNECT_LOADING',


}
export const reportOverviewInitState = {
    filter:{
        ecommerce:{
            activeValue: ECOMMERCE_LIST[0],
        },
        dateTime:{
            activeValue: FILTER_DATE[0],
            list: FILTER_DATE,
            dateStart: formatDatetime(getDateFromNow(-6)),
            dateEnd: formatDatetime(getDateFromNow(0))
        },
        stores:{
            list:[],
            listOrigin: [],
            activeValue: [],
            keyword:'',
            tab: 'all',
            applyFilter: [],
        },
        importantNumber:{
            list:IMPORTANT_NUMBER_TAB,
            activeList: IMPORTANT_NUMBER_TAB?.slice(0,2),
            data:[],
            meta:[],
        },
        ratioProduct:{
            data:[]
        },
        ratioOrder:{
            data:[]
        },
        topProduct:{
            data:[],
            meta:[],
        },
    },
    modal: {
        quickConnect: {
            open: false,
            loading: false,
            storeList: [],
            storeListDefault: [],
            storeValue: {},
            storeValidate: '',
            status: {
                notConnect: true,
                failConnect: true,
            },
            autoProduct: false,
            result: false,
        },
        updateOrder:{
            open: false,
            list:[],
            listOrigin:[],
            selected:[],
            dateTime:{
                list:REPORT_UPDATE_ORDER_DATE,
                activeValue: REPORT_UPDATE_ORDER_DATE[0],
                value:[],
            },
            keyword:'',
            autoConnect: false,
            statusUpdate: 1,
            res:null
        }
    },

}
export const reportOverviewReducer = (state, action)=> {
    switch (action.type) {
        case reportOverviewAction.REPORT_OVERVIEW_ECOMMERCE_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ecommerce:{
                        ...state?.filter.ecommerce,
                        activeValue: action.payload?.active
                    },
                    stores: {
                        ...state?.filter.stores,
                        activeValue: [],
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                        applyFilter: []
                    }
                }
            }

        case reportOverviewAction.REPORT_OVERVIEW_DATE_TIME_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    dateTime:{
                        ...state?.filter.dateTime,
                        activeValue: action?.payload.active,
                        dateStart: action?.payload.dateStart,
                        dateEnd: action?.payload.dateEnd,
                    }
                }
            }

        case reportOverviewAction.REPORT_OVERVIEW_STORES_LIST:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        list: action?.payload?.list,
                        listOrigin: action?.payload?.listOrigin,
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_STORES_SELECTED:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       activeValue: action.payload || []
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_STORES_TAB:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                       tab: action.payload?.tab,
                        list: action?.payload?.list
                    }
                }
            }

        case reportOverviewAction.REPORT_OVERVIEW_STORES_APPLY_FILTER:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        applyFilter: action.payload || [],
                        activeValue: action.payload || []
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_STORES_SEARCH:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    stores:{
                        ...state?.filter.stores,
                        keyword: action.payload?.keyword || '',
                        list: action.payload?.list || []
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_ACTIVE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    importantNumber:{
                        ...state?.filter.importantNumber,
                        activeList: action.payload
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_IMPORTANT_NUMBER_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    importantNumber:{
                        ...state?.filter.importantNumber,
                        ...action.payload,
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_RATIO_PRODUCT_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ratioProduct:{
                        ...state?.filter.ratioProduct,
                        data: action.payload?.data,
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_RATIO_ORDER_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    ratioOrder:{
                        ...state?.filter.ratioOrder,
                        data: action.payload?.data,
                        meta: action.payload?.meta,
                    }
                }
            }
        case reportOverviewAction.REPORT_OVERVIEW_TOP_PRODUCT_UPDATE:
            return {
                ...state,
                filter:{
                    ...state?.filter,
                    topProduct:{
                        ...state?.filter.topProduct,
                        data: action.payload?.data,
                        meta: action.payload?.meta,
                    }
                }
            }
        case reportOverviewAction.MODAL_ORDER_MODAL:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        open:action.payload,
                        selected:[],
                        list: state.modal.updateOrder?.listOrigin,
                        dateTime:{
                            list:REPORT_UPDATE_ORDER_DATE,
                            activeValue: REPORT_UPDATE_ORDER_DATE[0],
                            value:[],
                        },
                        keyword:'',
                        autoConnect: false,
                        statusUpdate: 1,
                        res: null
                    }
                }
            }
        case reportOverviewAction.MODAL_ORDER_LIST:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        list:action.payload.list,
                        listOrigin: action.payload.listOrigin || state.modal.updateOrder?.listOrigin
                    }
                }
            }
        case reportOverviewAction.MODAL_ORDER_DATE_TIME_UPDATE:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        dateTime:{
                            ...state.modal.updateOrder?.dateTime,
                            activeValue:action.payload.active,
                            value: action.payload.value
                        }
                    }
                }
            }
        case reportOverviewAction.MODAL_ORDER_DATE_TIME_KEYWORD:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        keyword:action.payload
                    }
                }
            }
        case reportOverviewAction.MODAL_ORDER_LIST_SELECTED:
            return {
                ...state,
                modal:{
                    updateOrder:{
                        ...state.modal.updateOrder,
                        selected:action.payload
                    }

                }
            }
        case reportOverviewAction.MODAL_ORDER_DATE_TIME_AUTO_CONNECT:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        autoConnect:action.payload
                    }

                }
            }
        case reportOverviewAction.MODAL_ORDER_DATE_TIME_STATUS_UPDATE:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        statusUpdate:action.payload
                    }

                }
            }
        case reportOverviewAction.MODAL_ORDER_CONFIRM_UPDATE:
            return {
                ...state,
                modal:{
                    ...state.modal,
                    updateOrder:{
                        ...state.modal.updateOrder,
                        statusUpdate: action.payload?.status,
                        res: action.payload?.res
                    }
                }
            }

        case reportOverviewAction.TOGGLE_MODAL_QUICK_CONNECT_OPEN:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        open: !state?.modal?.quickConnect?.open || false
                    }
                },
            }
        case reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_LIST:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        storeList: action.payload.list || [],
                        storeListDefault: action.payload?.listOrigin || state.modal.quickConnect.storeListDefault,
                    }
                },
            }
        case reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALUE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        storeValue: action.payload || {}
                    }
                },
            }
        case reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_STORE_VALIDATE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        storeValidate: action.payload || ''
                    }
                },
            }
        case reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_NOT_CONNECT:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        status: {
                            ...state.modal.quickConnect.status,
                            notConnect: action.payload
                        }
                    }
                },
            }
        case reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS_FAIL_CONNECT:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        status: {
                            ...state.modal.quickConnect.status,
                            failConnect: action.payload
                        }
                    }
                },
            }
        case reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_STATUS:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        status: {
                            ...state.modal.quickConnect.status,
                            notConnect: action.payload.notConnect,
                            failConnect: action.payload.failConnect
                        }
                    }
                },
            }
        case reportOverviewAction.CHANGE_MODAL_QUICK_CONNECT_STORE_AUTO_PRODUCT:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        autoProduct: action.payload || !state.modal.quickConnect.autoProduct,
                    }
                },
            }
        case reportOverviewAction.UPDATE_MODAL_QUICK_CONNECT_LOADING:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        loading: action.payload
                    }
                },
            }
        case reportOverviewAction.TOGGLE_MODAL_QUICK_CONNECT_RESULT:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    quickConnect: {
                        ...state.modal.quickConnect,
                        result: !state?.modal?.quickConnect?.result || false
                    }
                },
            }
        default: return state
    }
}