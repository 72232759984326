import useGlobalContext from "../../containerContext/storeContext";
import {publicRoutes} from "../../routes";
import {PATH} from "../../const/path";

export const LinkPage = (route, state) => {
  const notLicensed = publicRoutes.find(item => item.path === PATH.NOT_LICENSED).component
  const notPermission = publicRoutes.find(item => item.path === PATH.NOT_PERMISSION).component
  const { user } = state
  const { permission } = user
  const licenseID = user?.package?.license_id
  const packageFeature = permission?.package?.feature
  const accountPermission = permission?.account
  const validPackage = checkPackage(route, packageFeature, licenseID)
  if(validPackage) {
    if(+user?.is_manage === 1) return route.component
    const subBranch = accountPermission?.filter(item => item?.feature_code === route?.prefix)
    if(subBranch?.length > 0) {
      return !!subBranch?.find(it => +it.actions[route?.action] === 1) ? route.component : notPermission
    }
    return route?.prefix === 'publish' ? route.component : notPermission
  } else {
    return notLicensed
  }
}

const checkPackage = (route, packageFeature, licenseID) => {
  if(!!!route?.prefix || !!!packageFeature) return true
  const prefixFeature = packageFeature[route?.prefix]
  if(!!!prefixFeature) return true
  return !!prefixFeature?.find(item => +item === +licenseID)
}

export const checkPermission = (prefix, action) => {
  const [state, ] = useGlobalContext()
  const {  user } = state
  const { permission } = user

  if(+user?.is_manage === 1) return true

  const accountPermission = permission?.account
  const subBranch = accountPermission?.filter(item => item?.feature_code === prefix)
  if(subBranch?.length > 0) {
    return !!subBranch?.find(it => +it.actions[action] === 1)
  }
  return false
}

export const checkMaxPackage = (state, module, amount) => {
  const licenseID = state?.user?.package?.license_id
  let info = configLicence.find(item => +item.licence_id === +licenseID)
  if(!!!info) info = configLicence[0]
  info.maxEmployee = state?.user?.package?.nb_staff || 1
  info.maxWarehouse = state?.user?.package?.nb_store || 0
  info.maxFanpage = state?.user?.package?.nb_fanpage || 0
  info.maxEcommerce = state?.user?.package?.nb_ecommerce || 0
  return +info[module] > +amount
}

const configLicence = [
  {
    licence_id: 17,
    licence_name: "Free",
    maxEmployee: 1,
    maxWarehouse: 0,
    maxFanpage: 0,
    maxEcommerce: 0
  },
  {
    licence_id: 18,
    licence_name: "Pos",
    maxEmployee: 3,
    maxWarehouse: 1,
    maxFanpage: 0,
    maxEcommerce: 0
  },
  {
    licence_id: 19,
    licence_name: "Live",
    maxEmployee: 3,
    maxWarehouse: 1,
    maxFanpage: 3,
    maxEcommerce: 1
  },
  {
    licence_id: 20,
    licence_name: "Omnichannel",
    maxEmployee: 10,
    maxWarehouse: 3,
    maxFanpage: 5,
    maxEcommerce: 10
  },
  {
    licence_id: 4,
    licence_name: "Professional",
    maxEmployee: 999999999,
    maxWarehouse: 15,
    maxFanpage: 15,
    maxEcommerce: 15
  }
]