import React from "react";
import {GridLayout} from "../../../../../../layouts/gridLayout";
import {FilterReport} from "./component/filter/_filterFormReport";
import {ReportOverviewProvider} from './provider/_context'
import {useReportOverview} from "./hook/useReportOverview";
import {ImportantNumbers} from "./component/importantNumber";
import {RatioReport} from "./component/ratio";
import {TopProduct} from "./component/topProduct";
export const EcommerceReportOverView = ()=>{
    const {provider} = useReportOverview()
    const{state, dispatch} = provider
    return(
        <ReportOverviewProvider value={{pageState: state, pageDispatch: dispatch}}>
            <GridLayout
                grid={[
                    {
                        width: 100,
                        sections: [
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', padding: 16},
                                    children: <FilterReport />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative'},
                                    children: <ImportantNumbers />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative', background: '#f3f6fc', borderRadius: 0, padding: 0},
                                    children: <RatioReport />,
                                },
                            },
                            {
                                title: '',
                                props: {
                                    style: {position: 'relative'},
                                    children: <TopProduct />,
                                },
                            },

                        ],
                        props: {style: {position: 'relative'}},
                    },
                ]}
            />
        </ReportOverviewProvider>

    )
}