export const transFormNoticeNumber = (num)=>{
    let tail = 0
    if(num < 1000) {
        return num
    }
    if( num < 1000000){
        tail= num%1000
        num = Math.floor(num/1000)
        tail = tail > 100 ? Math.floor(tail/100) : 0
        num = tail > 0 ? `${num}.${tail}K` : `${num}K`
        return num
    }
    if( num < 1000000000){
        tail= num%1000000
        num = Math.floor(num/1000000)
        tail = tail > 100000 ? Math.floor(tail/100000) : 0
        num = tail > 0 ? `${num}.${tail}M` : `${num}M`
        return num
    }
}

