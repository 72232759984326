export const ORDER_SINGLE_ICONS = {
  alertError: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C6.1339 17 3 13.8661 3 10C3 6.1339 6.1339 3 10 3C13.8661 3 17 6.1339 17 10C17 13.8661 13.8661 17 10 17Z"
        fill="#EE4064"
      />
      <path
        d="M10 7V11M10 13.6667H10.0083"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  alertTriangle: (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.57337 18.6818L10.7858 6.22403C11.1844 5.53552 11.3837 5.19126 11.6437 5.07564C11.8705 4.97479 12.1295 4.97479 12.3563 5.07564C12.6163 5.19126 12.8156 5.53552 13.2142 6.22403L20.4266 18.6818C20.8267 19.3728 21.0267 19.7183 20.9971 20.0019C20.9714 20.2492 20.8418 20.4739 20.6406 20.6202C20.4101 20.7878 20.0108 20.7878 19.2124 20.7878H4.7876C3.98916 20.7878 3.58994 20.7878 3.35935 20.6202C3.15823 20.4739 3.02865 20.2492 3.00286 20.0019C2.9733 19.7183 3.17332 19.3728 3.57337 18.6818Z"
        fill="#FF424E"
        stroke="#FF424E"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="11.6836"
        y1="10.3877"
        x2="11.6836"
        y2="13.6491"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="11.9989" cy="17.2802" r="1.31535" fill="white" />
    </svg>
  ),
  alertTriangleSmall: (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.38225 12.6212L7.19052 4.31602C7.45626 3.85701 7.58913 3.62751 7.76248 3.55043C7.91369 3.48319 8.08631 3.48319 8.23752 3.55043C8.41087 3.62751 8.54374 3.85701 8.80948 4.31602L13.6178 12.6212C13.8845 13.0819 14.0178 13.3122 13.9981 13.5012C13.9809 13.6661 13.8945 13.816 13.7604 13.9134C13.6067 14.0252 13.3406 14.0252 12.8083 14.0252H3.19173C2.65944 14.0252 2.39329 14.0252 2.23957 13.9134C2.10549 13.816 2.0191 13.6661 2.00191 13.5012C1.9822 13.3122 2.11555 13.0819 2.38225 12.6212Z"
        fill="#FF424E"
        stroke="#FF424E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="8.12207"
        y1="7.4248"
        x2="8.12207"
        y2="8.93241"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle cx="7.99897" cy="11.6865" r="0.8769" fill="white" />
    </svg>
  ),
  caretUp: (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 4.75L5 1L8.75 4.75"
        stroke="#1E9A98"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  chevron: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82831 5.50502C6.55494 5.23166 6.11173 5.23166 5.83836 5.50502C5.56499 5.77839 5.56499 6.22161 5.83836 6.49497L6.82831 5.50502ZM9.66667 9.33333L9.17169 9.82831C9.44506 10.1017 9.88827 10.1017 10.1616 9.82831L9.66667 9.33333ZM13.495 6.49497C13.7683 6.22161 13.7683 5.77839 13.495 5.50503C13.2216 5.23166 12.7784 5.23166 12.505 5.50503L13.495 6.49497ZM6.82831 10.1717C6.55494 9.89832 6.11173 9.89832 5.83836 10.1717C5.56499 10.4451 5.56499 10.8883 5.83836 11.1616L6.82831 10.1717ZM9.66667 14L9.17169 14.495C9.44506 14.7683 9.88827 14.7683 10.1616 14.495L9.66667 14ZM13.495 11.1616C13.7683 10.8883 13.7683 10.4451 13.495 10.1717C13.2216 9.89832 12.7784 9.89832 12.505 10.1717L13.495 11.1616ZM5.83836 6.49497L9.17169 9.82831L10.1616 8.83836L6.82831 5.50502L5.83836 6.49497ZM10.1616 9.82831L13.495 6.49497L12.505 5.50503L9.17169 8.83836L10.1616 9.82831ZM5.83836 11.1616L9.17169 14.495L10.1616 13.505L6.82831 10.1717L5.83836 11.1616ZM10.1616 14.495L13.495 11.1616L12.505 10.1717L9.17169 13.505L10.1616 14.495Z"
        fill="#1A94FF"
      />
    </svg>
  ),
  clipboardText: (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3325 5.33441H15.3077C14.934 4.91473 14.4758 4.57887 13.9631 4.34888C13.4503 4.1189 12.8948 4 12.3329 4C11.7709 4 11.2154 4.1189 10.7027 4.34888C10.19 4.57887 9.73172 4.91473 9.35805 5.33441H6.33325C5.97965 5.33441 5.64053 5.47488 5.3905 5.72491C5.14047 5.97494 5 6.31406 5 6.66766V20.6668C5 21.0204 5.14047 21.3595 5.3905 21.6095C5.64053 21.8595 5.97965 22 6.33325 22H18.3325C18.6861 22 19.0252 21.8595 19.2752 21.6095C19.5252 21.3595 19.6657 21.0204 19.6657 20.6668V6.66766C19.6657 6.31406 19.5252 5.97494 19.2752 5.72491C19.0252 5.47488 18.6861 5.33441 18.3325 5.33441ZM12.3329 5.33441C13.0394 5.33661 13.7163 5.61825 14.2159 6.11784C14.7155 6.61743 14.9972 7.29438 14.9994 8.00091H9.66636C9.66856 7.29438 9.9502 6.61743 10.4498 6.11784C10.9494 5.61825 11.6263 5.33661 12.3329 5.33441ZM14.9994 16.0004H9.66636C9.48956 16.0004 9.32001 15.9302 9.19499 15.8051C9.06997 15.6801 8.99974 15.5106 8.99974 15.3338C8.99974 15.157 9.06997 14.9874 9.19499 14.8624C9.32001 14.7374 9.48956 14.6671 9.66636 14.6671H14.9994C15.1762 14.6671 15.3457 14.7374 15.4707 14.8624C15.5957 14.9874 15.666 15.157 15.666 15.3338C15.666 15.5106 15.5957 15.6801 15.4707 15.8051C15.3457 15.9302 15.1762 16.0004 14.9994 16.0004ZM14.9994 13.3339H9.66636C9.48956 13.3339 9.32001 13.2637 9.19499 13.1386C9.06997 13.0136 8.99974 12.8441 8.99974 12.6673C8.99974 12.4905 9.06997 12.3209 9.19499 12.1959C9.32001 12.0709 9.48956 12.0006 9.66636 12.0006H14.9994C15.1762 12.0006 15.3457 12.0709 15.4707 12.1959C15.5957 12.3209 15.666 12.4905 15.666 12.6673C15.666 12.8441 15.5957 13.0136 15.4707 13.1386C15.3457 13.2637 15.1762 13.3339 14.9994 13.3339Z"
        fill="#1E9A98"
      />
    </svg>
  ),
  contactBook: (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.20216 9C3.20216 9.38244 2.88635 9.69231 2.49659 9.69231H1.08545C0.695687 9.69231 0.379883 9.38244 0.379883 9C0.379883 8.61756 0.695687 8.30769 1.08545 8.30769H2.49659C2.8864 8.30769 3.20216 8.61756 3.20216 9ZM3.20216 4.56923C3.20216 4.18679 2.88635 3.87692 2.49659 3.87692H1.08545C0.695687 3.87692 0.379883 4.18679 0.379883 4.56923C0.379883 4.95167 0.695687 5.26154 1.08545 5.26154H2.49659C2.8864 5.26154 3.20216 4.95167 3.20216 4.56923ZM3.20216 13.4308C3.20216 13.0483 2.88635 12.7385 2.49659 12.7385H1.08545C0.695687 12.7385 0.379883 13.0483 0.379883 13.4308C0.379883 13.8132 0.695687 14.1231 1.08545 14.1231H2.49659C2.8864 14.1231 3.20216 13.8132 3.20216 13.4308ZM15.6202 2.21538V15.7846C15.6202 17.0081 14.6093 18 13.3624 18H3.48447C2.2376 18 1.22665 17.0081 1.22665 15.7846V14.6769H2.49667C3.19689 14.6769 3.7667 14.1178 3.7667 13.4308C3.7667 12.7437 3.19689 12.1845 2.49667 12.1845H1.22657V10.2461H2.49659C3.1968 10.2461 3.76661 9.68697 3.76661 8.99992C3.76661 8.31286 3.1968 7.75376 2.49659 7.75376H1.22657V5.8153H2.49659C3.1968 5.8153 3.76661 5.2562 3.76661 4.56915C3.76661 3.8821 3.1968 3.323 2.49659 3.323H1.22657V2.21538C1.22657 0.991955 2.23752 0 3.48439 0H13.3624C14.6092 0 15.6202 0.991914 15.6202 2.21538ZM6.66962 6.56695C6.66962 7.74664 7.64442 8.70312 8.84671 8.70312C10.049 8.70312 11.0238 7.74664 11.0238 6.56695C11.0238 5.38725 10.049 4.43073 8.84671 4.43073C7.64446 4.43077 6.66962 5.38725 6.66962 6.56695ZM12.2334 11.7889C12.2334 10.4782 11.1503 9.41534 9.81446 9.41534H7.87925C6.54319 9.41534 5.45998 10.4782 5.45998 11.7889V12.7385H12.2334V11.7889Z"
        fill="#1E9A98"
      />
    </svg>
  ),
  dropdown: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8L10.5 12L6.5 8"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  emptyShipping: (
    <svg
      width="161"
      height="160"
      viewBox="0 0 161 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2666_132995)">
        <rect
          width="160"
          height="160"
          transform="translate(0.5)"
          fill="white"
        />
        <g filter="url(#filter0_f_2666_132995)">
          <ellipse
            cx="80.7223"
            cy="120.747"
            rx="42.7945"
            ry="25.774"
            fill="#B8C3D1"
            fillOpacity="0.25"
          />
        </g>
        <path
          d="M80.0008 145C110.21 145 134.699 120.602 134.699 90.5057C134.699 60.4095 110.21 36.0117 80.0008 36.0117C49.7919 36.0117 25.3027 60.4095 25.3027 90.5057C25.3027 120.602 49.7919 145 80.0008 145Z"
          fill="#F5F6FA"
        />
        <path
          d="M145.426 56.7881C147.089 56.7881 148.438 55.4449 148.438 53.7881C148.438 52.1312 147.089 50.7881 145.426 50.7881C143.763 50.7881 142.415 52.1312 142.415 53.7881C142.415 55.4449 143.763 56.7881 145.426 56.7881Z"
          fill="#E0E2EE"
        />
        <path
          d="M21.4437 78.2917C23.6218 78.2917 25.3875 76.5326 25.3875 74.3626C25.3875 72.1927 23.6218 70.4336 21.4437 70.4336C19.2657 70.4336 17.5 72.1927 17.5 74.3626C17.5 76.5326 19.2657 78.2917 21.4437 78.2917Z"
          fill="#E0E2EE"
        />
        <path
          d="M140.098 75.0459C142.702 75.0459 144.814 72.9427 144.814 70.3482C144.814 67.7537 142.702 65.6504 140.098 65.6504C137.494 65.6504 135.383 67.7537 135.383 70.3482C135.383 72.9427 137.494 75.0459 140.098 75.0459Z"
          fill="#E0E2EE"
        />
        <g filter="url(#filter1_d_2666_132995)">
          <path
            d="M21.7012 124.415L38.8479 63.7716L61.996 56.9385L49.1359 115.02L21.7012 124.415Z"
            fill="#E8EBF2"
          />
          <path
            d="M138.298 124.415L121.151 63.7716L98.0029 56.9385L110.863 115.02L138.298 124.415Z"
            fill="white"
          />
          <path
            d="M98.0021 56.9385L110.862 115.02L79.998 126.124V62.9174L98.0021 56.9385Z"
            fill="#F1F2F7"
          />
          <path
            d="M61.9948 56.9385L49.1348 115.02L79.9989 126.124V62.9174L61.9948 56.9385Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.5355 96.1431L47.8496 94.6056L50.3359 93.8369L51.0218 95.3744L48.5355 96.1431Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9925 97.4243L43.3066 95.8869L45.7929 95.1182L46.4788 96.6556L43.9925 97.4243Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.4476 98.7051L38.7617 97.1677L41.3337 96.4844L42.0196 98.0218L39.4476 98.7051Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.0804 92.2139L61.3945 90.6765L63.9665 89.9932L64.6524 91.5306L62.0804 92.2139Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.5376 93.4952L56.9375 91.9577L59.4238 91.2744L60.1096 92.8119L57.5376 93.4952Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.0804 94.7764L52.3945 93.239L54.8808 92.5557L55.5667 94.0931L53.0804 94.7764Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.7132 88.2852L75.0273 86.7478L77.5993 86.0645L78.1995 87.6019L75.7132 88.2852Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.1683 89.6509L70.4824 88.1135L73.0544 87.3447L73.7403 88.8822L71.1683 89.6509Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.6245 90.9322L66.0244 89.3947L68.5107 88.626L69.1966 90.1634L66.6245 90.9322Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.7432 82.904L87.543 81.6228C87.8002 81.3665 88.1431 81.1103 88.4003 80.7687C88.6575 80.5124 88.9147 80.1708 89.0862 79.8291L90.4579 80.8541C90.2007 81.1957 89.9435 81.6228 89.6863 81.9644C89.3434 82.3061 89.0004 82.6478 88.7432 82.904Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.7148 85.3808L83.8574 83.9287C84.629 83.5871 85.3149 83.2454 86.0008 82.8184L87.0296 84.185C86.258 84.612 85.4864 85.0391 84.7148 85.3808Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.2562 87.0034L79.5703 85.466C80.4276 85.2097 81.1992 84.9535 81.9709 84.6973L82.7425 86.2347C81.9709 86.491 81.1135 86.7472 80.2562 87.0034Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.8864 69.1517L86.9434 70.3475C86.6004 70.0913 86.2575 69.835 85.9146 69.6642C85.8288 69.5788 85.7431 69.5788 85.6573 69.4934C85.5716 69.4079 85.4859 69.4079 85.4001 69.3225C85.3144 69.3225 85.2287 69.2371 85.1429 69.2371C85.0572 69.2371 84.9715 69.1517 84.8857 69.1517L85.1429 67.6143C86.1717 67.8705 87.1148 68.4684 87.8864 69.1517Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.0599 73.764L89.5167 74.0202C89.2595 73.2515 88.8308 72.4828 88.3164 71.714L89.6024 70.9453C90.2883 71.7995 90.717 72.739 91.0599 73.764Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.3157 78.8889L89.7725 78.2056C89.7725 78.1202 89.8582 77.9493 89.8582 77.8639L89.9439 77.5223C90.0297 77.0098 90.0297 76.4119 90.0297 75.8994L91.6586 76.0702C91.7443 76.7536 91.6586 77.4369 91.5729 78.1202L91.4871 78.5472C91.4014 78.6327 91.3157 78.8035 91.3157 78.8889Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.7422 73.7646H78.3711C78.4569 74.5334 78.7998 75.3021 79.1427 76.0708L77.771 76.6687C77.2566 75.8146 76.9137 74.7896 76.7422 73.7646Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.1139 68.9814L79.3142 70.1772C78.7998 70.6897 78.4569 71.2876 78.2854 71.9709L76.7422 71.373C76.9994 70.5189 77.5138 69.6648 78.1139 68.9814Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.7433 67.1875L83.0005 68.8104C82.2289 68.8104 81.4573 68.9812 80.7714 69.3228L79.9141 67.8708C80.7714 67.4437 81.7145 67.1875 82.7433 67.1875Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.1152 86.064L87.9726 84.8682L90.3731 86.7473L89.5158 87.9431L87.1152 86.064Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.9141 82.7325L83.8571 81.6221C84.2001 81.9637 84.6287 82.22 84.9717 82.5616L85.5718 82.9887L86.1719 83.4158L85.3146 84.6116L84.7145 84.1845L84.1143 83.672C83.6857 83.3304 83.3427 83.0741 82.9141 82.7325Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.1426 78.8034L80.3429 77.9492C80.6001 78.2909 80.943 78.6325 81.2002 78.9742C81.5431 79.3158 81.8861 79.6575 82.1433 79.9992L81.1144 81.0241C80.7715 80.6825 80.4286 80.3408 80.0856 79.9137C79.7427 79.5721 79.3998 79.2304 79.1426 78.8034Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.8037 95.9722L100.661 94.7764L102.976 96.6555L102.119 97.8513L99.8037 95.9722Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.6035 92.6407L96.4609 91.5303L98.7757 93.324L97.9183 94.5198L95.6035 92.6407Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.4023 89.395L92.2597 88.1992L94.5745 90.0783L93.7172 91.2741L91.4023 89.395Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.406 105.88L113.264 104.685L115.664 106.564L114.807 107.759L112.406 105.88Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.205 102.634L109.062 101.438L111.463 103.232L110.606 104.428L108.205 102.634Z"
          fill="#A5B1BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.004 99.3032L104.861 98.1074L107.262 99.9865L106.404 101.182L104.004 99.3032Z"
          fill="#A5B1BE"
        />
        <path
          d="M86.0768 20C73.0452 20 62.5 30.1642 62.5 42.6347C62.5 59.5466 82.3902 82.5019 83.2475 83.6977C84.2763 85.1497 86.0768 85.8971 87.8772 84.5518C88.2201 84.2956 88.563 84.0393 88.8202 83.6977C89.6776 82.5019 109.568 59.5466 109.568 42.6347C109.654 30.1642 99.1083 20 86.0768 20ZM86.0768 52.3718C80.5041 52.3718 75.9602 48.0157 75.9602 42.6347C75.9602 37.2536 80.5041 32.8975 86.0768 32.8975C91.6494 32.8975 96.1933 37.2536 96.1933 42.6347C96.1933 48.0157 91.6494 52.3718 86.0768 52.3718Z"
          fill="#E0E2EE"
        />
        <path
          d="M86.0009 52.3968C91.6829 52.3968 96.289 47.8078 96.289 42.1471C96.289 36.4864 91.6829 31.8975 86.0009 31.8975C80.319 31.8975 75.7129 36.4864 75.7129 42.1471C75.7129 47.8078 80.319 52.3968 86.0009 52.3968Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2666_132995"
          x="13.9277"
          y="70.9727"
          width="133.589"
          height="99.5479"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12"
            result="effect1_foregroundBlur_2666_132995"
          />
        </filter>
        <filter
          id="filter1_d_2666_132995"
          x="15.7012"
          y="52.9385"
          width="128.597"
          height="81.1851"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.647059 0 0 0 0 0.694118 0 0 0 0 0.745098 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2666_132995"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2666_132995"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2666_132995">
          <rect
            width="160"
            height="160"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  markerPin01: (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z"
        stroke="#1E9A98"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.5C12 13.5 15 10.8137 15 7.5C15 4.18629 12.3137 1.5 9 1.5C5.68629 1.5 3 4.18629 3 7.5C3 10.8137 6 13.5 9 16.5Z"
        stroke="#1E9A98"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  note: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H12.2422C12.4063 17.5001 12.5688 17.4678 12.7203 17.4047C12.8719 17.3417 13.0094 17.2493 13.125 17.1328L17.1328 13.125C17.2493 13.0094 17.3417 12.8719 17.4047 12.7203C17.4678 12.5688 17.5001 12.4063 17.5 12.2422V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM7.5 6.875H12.5C12.6658 6.875 12.8247 6.94085 12.9419 7.05806C13.0592 7.17527 13.125 7.33424 13.125 7.5C13.125 7.66576 13.0592 7.82473 12.9419 7.94194C12.8247 8.05915 12.6658 8.125 12.5 8.125H7.5C7.33424 8.125 7.17527 8.05915 7.05806 7.94194C6.94085 7.82473 6.875 7.66576 6.875 7.5C6.875 7.33424 6.94085 7.17527 7.05806 7.05806C7.17527 6.94085 7.33424 6.875 7.5 6.875ZM10 13.125H7.5C7.33424 13.125 7.17527 13.0592 7.05806 12.9419C6.94085 12.8247 6.875 12.6658 6.875 12.5C6.875 12.3342 6.94085 12.1753 7.05806 12.0581C7.17527 11.9408 7.33424 11.875 7.5 11.875H10C10.1658 11.875 10.3247 11.9408 10.4419 12.0581C10.5592 12.1753 10.625 12.3342 10.625 12.5C10.625 12.6658 10.5592 12.8247 10.4419 12.9419C10.3247 13.0592 10.1658 13.125 10 13.125ZM7.5 10.625C7.33424 10.625 7.17527 10.5592 7.05806 10.4419C6.94085 10.3247 6.875 10.1658 6.875 10C6.875 9.83424 6.94085 9.67527 7.05806 9.55806C7.17527 9.44085 7.33424 9.375 7.5 9.375H12.5C12.6658 9.375 12.8247 9.44085 12.9419 9.55806C13.0592 9.67527 13.125 9.83424 13.125 10C13.125 10.1658 13.0592 10.3247 12.9419 10.4419C12.8247 10.5592 12.6658 10.625 12.5 10.625H7.5ZM12.5 15.9922V12.5H15.9922L12.5 15.9922Z"
        fill="#1E9A98"
      />
    </svg>
  ),
  plus: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4V16M4 10H16"
        stroke="#1A94FF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  question: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.5C11.866 14.5 15 11.366 15 7.5C15 3.63401 11.866 0.5 8 0.5C4.13401 0.5 1 3.63401 1 7.5C1 11.366 4.13401 14.5 8 14.5Z"
        stroke="#808089"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12.167C8.48325 12.167 8.875 11.7752 8.875 11.292C8.875 10.8087 8.48325 10.417 8 10.417C7.51675 10.417 7.125 10.8087 7.125 11.292C7.125 11.7752 7.51675 12.167 8 12.167Z"
        fill="#808089"
      />
      <path
        d="M8.00016 8.66667V8.08333C8.40397 8.08333 8.7987 7.96359 9.13445 7.73925C9.4702 7.51491 9.73189 7.19605 9.88642 6.82298C10.0409 6.44991 10.0814 6.0394 10.0026 5.64336C9.92382 5.24731 9.72937 4.88352 9.44384 4.59799C9.15831 4.31246 8.79452 4.11801 8.39847 4.03923C8.00243 3.96045 7.59192 4.00088 7.21885 4.15541C6.84579 4.30994 6.52692 4.57163 6.30258 4.90738C6.07824 5.24313 5.9585 5.63786 5.9585 6.04167"
        stroke="#808089"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  reset: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.58333 17.4165C13.4954 17.4165 16.6667 14.2452 16.6667 10.3332C16.6667 7.97856 15.5178 5.89228 13.75 4.60434M10.4167 18.9998L8.75 17.3332L10.4167 15.6665M9.58333 3.24984C5.67132 3.24984 2.5 6.42115 2.5 10.3332C2.5 12.6878 3.64889 14.7741 5.41667 16.062M8.75 4.99984L10.4167 3.33317L8.75 1.6665"
        stroke="#1A94FF"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  searchMd: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.505 18.495C17.7784 18.7683 18.2216 18.7683 18.495 18.495C18.7683 18.2216 18.7683 17.7784 18.495 17.505L17.505 18.495ZM14.6283 13.6384C14.3549 13.365 13.9117 13.365 13.6384 13.6384C13.365 13.9117 13.365 14.3549 13.6384 14.6283L14.6283 13.6384ZM18.495 17.505L14.6283 13.6384L13.6384 14.6283L17.505 18.495L18.495 17.505ZM15.5222 9.11111C15.5222 12.6519 12.6519 15.5222 9.11111 15.5222V16.9222C13.4251 16.9222 16.9222 13.4251 16.9222 9.11111H15.5222ZM9.11111 15.5222C5.57035 15.5222 2.7 12.6519 2.7 9.11111H1.3C1.3 13.4251 4.79715 16.9222 9.11111 16.9222V15.5222ZM2.7 9.11111C2.7 5.57035 5.57035 2.7 9.11111 2.7V1.3C4.79715 1.3 1.3 4.79715 1.3 9.11111H2.7ZM9.11111 2.7C12.6519 2.7 15.5222 5.57035 15.5222 9.11111H16.9222C16.9222 4.79715 13.4251 1.3 9.11111 1.3V2.7Z"
        fill="#1E9A98"
      />
    </svg>
  ),
  serviceDown: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6607 13.0938L15.669 8.08542L14.4915 6.90625L10.6607 10.7396L6.83068 6.90625L5.65234 8.08458L10.6607 13.0938Z"
        fill="#1A94FF"
      />
    </svg>
  ),
  success: (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#F1FFFC" />
      <path
        d="M12.4611 18.1393C11.8753 17.5536 10.9255 17.5536 10.3397 18.1393C9.75394 18.7251 9.75394 19.6749 10.3397 20.2607L12.4611 18.1393ZM16.2004 24L15.1397 25.0607C15.4533 25.3742 15.8895 25.533 16.3312 25.4943C16.773 25.4556 17.175 25.2235 17.4292 24.8602L16.2004 24ZM25.8292 12.8602C26.3043 12.1815 26.1393 11.2462 25.4606 10.7712C24.7819 10.2961 23.8466 10.4611 23.3715 11.1398L25.8292 12.8602ZM10.3397 20.2607L15.1397 25.0607L17.2611 22.9393L12.4611 18.1393L10.3397 20.2607ZM17.4292 24.8602L25.8292 12.8602L23.3715 11.1398L14.9715 23.1398L17.4292 24.8602Z"
        fill="#00E0C2"
      />
    </svg>
  ),
  target: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0013 5.66797C6.71497 5.66797 5.66797 6.71464 5.66797 8.0013C5.66797 9.28797 6.71497 10.3346 8.0013 10.3346C9.28764 10.3346 10.3346 9.28797 10.3346 8.0013C10.3346 6.71464 9.28764 5.66797 8.0013 5.66797ZM8.0013 9.0013C7.44997 9.0013 7.0013 8.55264 7.0013 8.0013C7.0013 7.44997 7.44997 7.0013 8.0013 7.0013C8.55264 7.0013 9.0013 7.44997 9.0013 8.0013C9.0013 8.55264 8.55264 9.0013 8.0013 9.0013Z"
        fill="white"
      />
      <path
        d="M15.3333 7.33333H13.9607C13.652 4.557 11.443 2.348 8.66667 2.03933V0.666667C8.66667 0.298667 8.368 0 8 0C7.632 0 7.33333 0.298667 7.33333 0.666667V2.03933C4.557 2.348 2.348 4.557 2.03933 7.33333H0.666667C0.298667 7.33333 0 7.632 0 8C0 8.368 0.298667 8.66667 0.666667 8.66667H2.03933C2.348 11.443 4.557 13.652 7.33333 13.9607V15.3333C7.33333 15.7013 7.632 16 8 16C8.368 16 8.66667 15.7013 8.66667 15.3333V13.9607C11.443 13.652 13.652 11.443 13.9607 8.66667H15.3333C15.7013 8.66667 16 8.368 16 8C16 7.632 15.7013 7.33333 15.3333 7.33333ZM8 12.6667C5.42667 12.6667 3.33333 10.5733 3.33333 8C3.33333 5.42667 5.42667 3.33333 8 3.33333C10.5733 3.33333 12.6667 5.42667 12.6667 8C12.6667 10.5733 10.5733 12.6667 8 12.6667Z"
        fill="white"
      />
    </svg>
  ),
  trash: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 4.99984V4.33317C13.3333 3.39975 13.3333 2.93304 13.1517 2.57652C12.9919 2.26292 12.7369 2.00795 12.4233 1.84816C12.0668 1.6665 11.6001 1.6665 10.6667 1.6665H9.33333C8.39991 1.6665 7.9332 1.6665 7.57668 1.84816C7.26308 2.00795 7.00811 2.26292 6.84832 2.57652C6.66667 2.93304 6.66667 3.39975 6.66667 4.33317V4.99984M8.33333 9.58317V13.7498M11.6667 9.58317V13.7498M2.5 4.99984H17.5M15.8333 4.99984V14.3332C15.8333 15.7333 15.8333 16.4334 15.5608 16.9681C15.3212 17.4386 14.9387 17.821 14.4683 18.0607C13.9335 18.3332 13.2335 18.3332 11.8333 18.3332H8.16667C6.76654 18.3332 6.06647 18.3332 5.53169 18.0607C5.06129 17.821 4.67883 17.4386 4.43915 16.9681C4.16667 16.4334 4.16667 15.7333 4.16667 14.3332V4.99984"
        stroke="#FF424E"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  warning: (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7520_110746)">
        <path
          d="M1.92419 12.5657L7.40744 3.09464C7.71049 2.57119 7.86201 2.30947 8.0597 2.22157C8.23214 2.14489 8.42899 2.14489 8.60143 2.22157C8.79912 2.30947 8.95064 2.57119 9.25369 3.09463L14.7369 12.5657C15.0411 13.091 15.1931 13.3537 15.1707 13.5693C15.1511 13.7573 15.0526 13.9282 14.8997 14.0394C14.7243 14.1668 14.4208 14.1668 13.8138 14.1668H2.84731C2.24029 14.1668 1.93679 14.1668 1.76148 14.0394C1.60858 13.9282 1.51006 13.7573 1.49046 13.5693C1.46798 13.3537 1.62005 13.091 1.92419 12.5657Z"
          fill="#FF9F41"
          stroke="#FF9F41"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="8.33008"
          y1="6.5"
          x2="8.33008"
          y2="8.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="8.33008" cy="11.5" r="1" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_7520_110746">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.330078 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  x: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6.74197L14.4821 0.261839C14.5646 0.179236 14.6625 0.113685 14.7703 0.0689304C14.8781 0.0241757 14.9937 0.00109261 15.1104 0.000999753C15.2272 0.000906893 15.3428 0.0238065 15.4506 0.0683897C15.5585 0.112973 15.6566 0.178367 15.7392 0.260838C15.8218 0.34331 15.8873 0.441245 15.9321 0.54905C15.9768 0.656855 15.9999 0.772419 16 0.889145C16.0001 1.00587 15.9772 1.12147 15.9326 1.22935C15.888 1.33722 15.8226 1.43526 15.7402 1.51786L9.25802 8L15.7382 14.4821C15.8208 14.5646 15.8863 14.6625 15.9311 14.7703C15.9758 14.8781 15.9989 14.9937 15.999 15.1104C15.9991 15.2272 15.9762 15.3428 15.9316 15.4506C15.887 15.5585 15.8216 15.6566 15.7392 15.7392C15.6567 15.8218 15.5588 15.8873 15.4509 15.9321C15.3431 15.9768 15.2276 15.9999 15.1109 16C14.9941 16.0001 14.8785 15.9772 14.7707 15.9326C14.6628 15.888 14.5647 15.8226 14.4821 15.7402L8 9.25802L1.51786 15.7382C1.43539 15.8208 1.33746 15.8863 1.22965 15.9311C1.12185 15.9758 1.00629 15.9989 0.889559 15.999C0.772834 15.9991 0.657233 15.9762 0.549357 15.9316C0.441481 15.887 0.343442 15.8216 0.260838 15.7392C0.178235 15.6567 0.112686 15.5588 0.067931 15.4509C0.0231762 15.3431 9.31395e-05 15.2276 2.81288e-07 15.1109C-9.25769e-05 14.9941 0.0228061 14.8785 0.0673893 14.7707C0.111972 14.6628 0.177367 14.5647 0.259839 14.4821L6.74197 8L0.261839 1.51786C0.0950143 1.35131 0.00118729 1.1253 0.000999753 0.889559C0.000812215 0.653821 0.0942791 0.427663 0.260838 0.260838C0.427398 0.0940138 0.653406 0.000187824 0.889145 2.81686e-07C1.12488 -0.000187261 1.35104 0.0932796 1.51786 0.259839L8 6.74197Z"
        fill="#191D32"
      />
      <path
        d="M8 6.74197L14.4821 0.261839C14.5646 0.179236 14.6625 0.113685 14.7703 0.0689304C14.8781 0.0241757 14.9937 0.00109261 15.1104 0.000999753C15.2272 0.000906893 15.3428 0.0238065 15.4506 0.0683897C15.5585 0.112973 15.6566 0.178367 15.7392 0.260838C15.8218 0.34331 15.8873 0.441245 15.9321 0.54905C15.9768 0.656855 15.9999 0.772419 16 0.889145C16.0001 1.00587 15.9772 1.12147 15.9326 1.22935C15.888 1.33722 15.8226 1.43526 15.7402 1.51786L9.25802 8L15.7382 14.4821C15.8208 14.5646 15.8863 14.6625 15.9311 14.7703C15.9758 14.8781 15.9989 14.9937 15.999 15.1104C15.9991 15.2272 15.9762 15.3428 15.9316 15.4506C15.887 15.5585 15.8216 15.6566 15.7392 15.7392C15.6567 15.8218 15.5588 15.8873 15.4509 15.9321C15.3431 15.9768 15.2276 15.9999 15.1109 16C14.9941 16.0001 14.8785 15.9772 14.7707 15.9326C14.6628 15.888 14.5647 15.8226 14.4821 15.7402L8 9.25802L1.51786 15.7382C1.43539 15.8208 1.33746 15.8863 1.22965 15.9311C1.12185 15.9758 1.00629 15.9989 0.889559 15.999C0.772834 15.9991 0.657233 15.9762 0.549357 15.9316C0.441481 15.887 0.343442 15.8216 0.260838 15.7392C0.178235 15.6567 0.112686 15.5588 0.067931 15.4509C0.0231762 15.3431 9.31395e-05 15.2276 2.81288e-07 15.1109C-9.25769e-05 14.9941 0.0228061 14.8785 0.0673893 14.7707C0.111972 14.6628 0.177367 14.5647 0.259839 14.4821L6.74197 8L0.261839 1.51786C0.0950143 1.35131 0.00118729 1.1253 0.000999753 0.889559C0.000812215 0.653821 0.0942791 0.427663 0.260838 0.260838C0.427398 0.0940138 0.653406 0.000187824 0.889145 2.81686e-07C1.12488 -0.000187261 1.35104 0.0932796 1.51786 0.259839L8 6.74197Z"
        fill="#F4F7FC"
        fillOpacity="0.6"
      />
    </svg>
  ),
  x01: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#7C8EA0" />
      <path
        d="M12 10.742L18.4821 4.26184C18.5646 4.17924 18.6625 4.11369 18.7703 4.06893C18.8781 4.02418 18.9937 4.00109 19.1104 4.001C19.2272 4.00091 19.3428 4.02381 19.4506 4.06839C19.5585 4.11297 19.6566 4.17837 19.7392 4.26084C19.8218 4.34331 19.8873 4.44124 19.9321 4.54905C19.9768 4.65685 19.9999 4.77242 20 4.88914C20.0001 5.00587 19.9772 5.12147 19.9326 5.22935C19.888 5.33722 19.8226 5.43526 19.7402 5.51786L13.258 12L19.7382 18.4821C19.8208 18.5646 19.8863 18.6625 19.9311 18.7703C19.9758 18.8781 19.9989 18.9937 19.999 19.1104C19.9991 19.2272 19.9762 19.3428 19.9316 19.4506C19.887 19.5585 19.8216 19.6566 19.7392 19.7392C19.6567 19.8218 19.5588 19.8873 19.4509 19.9321C19.3431 19.9768 19.2276 19.9999 19.1109 20C18.9941 20.0001 18.8785 19.9772 18.7707 19.9326C18.6628 19.888 18.5647 19.8226 18.4821 19.7402L12 13.258L5.51786 19.7382C5.43539 19.8208 5.33746 19.8863 5.22965 19.9311C5.12185 19.9758 5.00629 19.9989 4.88956 19.999C4.77283 19.9991 4.65723 19.9762 4.54936 19.9316C4.44148 19.887 4.34344 19.8216 4.26084 19.7392C4.17824 19.6567 4.11269 19.5588 4.06793 19.4509C4.02318 19.3431 4.00009 19.2276 4 19.1109C3.99991 18.9941 4.02281 18.8785 4.06739 18.7707C4.11197 18.6628 4.17737 18.5647 4.25984 18.4821L10.742 12L4.26184 5.51786C4.09501 5.35131 4.00119 5.1253 4.001 4.88956C4.00081 4.65382 4.09428 4.42766 4.26084 4.26084C4.4274 4.09401 4.65341 4.00019 4.88914 4C5.12488 3.99981 5.35104 4.09328 5.51786 4.25984L12 10.742Z"
        fill="white"
      />
    </svg>
  ),
  errorCircle: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C6.1339 17 3 13.8661 3 10C3 6.1339 6.1339 3 10 3C13.8661 3 17 6.1339 17 10C17 13.8661 13.8661 17 10 17Z"
        fill="#EE4064"
      />
      <path
        d="M10 7V11M10 13.6667H10.0083"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  caretUpDisabled: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99719 7.85654L9.00219 4.85154L8.29569 4.14404L5.99719 6.44404L3.69919 4.14404L2.99219 4.85104L5.99719 7.85654Z"
        fill="#7C88A6"
        fillOpacity="0.5"
      />
    </svg>
  ),
  viDiscountGrab: (
    <svg
      width="135"
      height="20"
      viewBox="0 0 135 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000244141 4.87383C1.63552 4.42957 2.84412 2.86362 2.84412 1C2.84412 0.6547 2.80262 0.31962 2.72461 0H131.937C131.859 0.31962 131.818 0.6547 131.818 1C131.818 2.86279 133.025 4.42818 134.66 4.87323V6.12677C133.025 6.57182 131.818 8.13721 131.818 10C131.818 11.8628 133.025 13.4282 134.66 13.8732V15.1268C133.025 15.5718 131.818 17.1372 131.818 19C131.818 19.3453 131.859 19.6804 131.937 20H2.72461C2.80262 19.6804 2.84412 19.3453 2.84412 19C2.84412 17.1364 1.63552 15.5704 -0.000244141 15.1262V13.8738C1.63552 13.4296 2.84412 11.8636 2.84412 10C2.84412 8.13638 1.63552 6.57043 -0.000244141 6.12617V4.87383Z"
        fill="#FF5E00"
        fillOpacity="0.1"
      />
    </svg>
  ),
  enDiscountGrab: (
    <svg
      width="173"
      height="20"
      viewBox="0 0 173 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.87381C2.10145 4.42954 3.65412 2.8636 3.65412 1C3.65412 0.6547 3.60081 0.31962 3.50059 0H169.502C169.402 0.31962 169.348 0.6547 169.348 1C169.348 2.86283 170.9 4.42825 173 4.87326V6.12674C170.9 6.57175 169.348 8.13717 169.348 10C169.348 11.8628 170.9 13.4283 173 13.8733V15.1267C170.9 15.5717 169.348 17.1372 169.348 19C169.348 19.3453 169.402 19.6804 169.502 20H3.50059C3.60081 19.6804 3.65412 19.3453 3.65412 19C3.65412 17.1364 2.10145 15.5705 0 15.1262V13.8738C2.10145 13.4295 3.65412 11.8636 3.65412 10C3.65412 8.1364 2.10145 6.57046 0 6.12619V4.87381Z"
        fill="#FF5E00"
        fillOpacity="0.1"
      />
    </svg>
  ),
  edit: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2093 4.0814H6.07829C4.82577 4.0814 4.19951 4.0814 3.72111 4.32516C3.3003 4.53957 2.95817 4.8817 2.74376 5.30251C2.5 5.78091 2.5 6.40717 2.5 7.65969V13.9217C2.5 15.1742 2.5 15.8005 2.74376 16.2789C2.95817 16.6997 3.3003 17.0418 3.72111 17.2562C4.19951 17.5 4.82577 17.5 6.07829 17.5H12.3403C13.5928 17.5 14.2191 17.5 14.6975 17.2562C15.1183 17.0418 15.4604 16.6997 15.6748 16.2789C15.9186 15.8005 15.9186 15.1742 15.9186 13.9217V10.7907M6.97285 13.0271H8.22118C8.58586 13.0271 8.76819 13.0271 8.93978 12.9859C9.09192 12.9494 9.23735 12.8892 9.37075 12.8074C9.52121 12.7152 9.65014 12.5863 9.90801 12.3284L17.0368 5.19962C17.6544 4.58204 17.6544 3.58076 17.0368 2.96318C16.4192 2.34561 15.418 2.34561 14.8004 2.96318L7.67156 10.092C7.41369 10.3499 7.28476 10.4788 7.19256 10.6293C7.11081 10.7627 7.05057 10.9081 7.01404 11.0602C6.97285 11.2318 6.97285 11.4141 6.97285 11.7788V13.0271Z"
        stroke="#1E9A98"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
}
