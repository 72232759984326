import {useRef, useState} from 'react'
import {StyledPopperNotice} from './_styled'
import {Text} from "../../../../common/text";
import {transFormNoticeNumber} from "../../util/_noticeNumber";

const placementList = [
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start',
]

export const Popper = ({
  disabled,
  placement = 'bottom-start', // top-start | top | top-end | right-start | right | right-end | bottom-end | bottom | bottom-start | left-end | left | left-start
  renderPopper,
  renderToggle,
  children,
  popperProps,
  toggleProps,
  noticeValue = 0,
  noticeClass,
  popoverMainClass,
  ...props
}) => {
  const checkPlacement = placementList.includes(placement)
    ? placement
    : 'bottom-start'

  const [shouldOpenPopper, setShouldOpenPopper] = useState(false)
  const [currentPlacement, setCurrentPlacement] = useState(checkPlacement)

  const toggleRef = useRef(null)

  const handleToggle = boo => {
    if (boo) {
      const viewportOffset = toggleRef.current.getBoundingClientRect()
      // these are relative to the viewport, i.e. the window
      const top = viewportOffset.top
      if (top + 500 > screen.height || top - 500 < 0) {
        let tmpPlacement = currentPlacement
        if (tmpPlacement.includes('top')) tmpPlacement.replace(/top/g, 'bottom')
        if (tmpPlacement.includes('bottom'))
          tmpPlacement.replace(/bottom/g, 'top')
        setCurrentPlacement(tmpPlacement)
      }
    }
    setShouldOpenPopper(boo)
  }

  if (!renderToggle && !children) return

  return (
    <StyledPopperNotice {...props} className={`${popoverMainClass}`}>
      <span
        {...toggleProps}
        ref={toggleRef}
        className={`popper-notice__toggle ${toggleProps?.className || ''}`}
        onClick={() => !disabled && handleToggle(true)}
      >
        {renderToggle ? renderToggle({open: shouldOpenPopper}) : children}
      </span>
      {+noticeValue > 0 &&   <div  onClick={() => !disabled && handleToggle(true)} className={noticeClass}>
        <Text fontSize={10} fontWeight={700} color={'#ffffff'}>
          {transFormNoticeNumber(noticeValue)}
        </Text>

      </div>}
      {!disabled && shouldOpenPopper && (
        <>
          <div
            className="popper-notice__backdrop"
            onClick={() => handleToggle(false)}
          ></div>
          <div
            {...popperProps}
            className={`popper-notice__container ${popperProps?.className || ''}`}
            data-placement={currentPlacement}
            onClick={e => {
              e.stopPropagation()
              if (popperProps?.onClick) popperProps.onClick()
            }}
          >
            {renderPopper({onClose: () => handleToggle(false)})}
          </div>
        </>
      )}
    </StyledPopperNotice>
  )
}
