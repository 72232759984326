import {THEME_COLORS} from 'common/theme/_colors'
import styled from 'styled-components'
export const StyleComponentExportSelective=styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);
  .excel-export-selective{
    width: 880px;
    height: 586px;
    margin: 5rem auto;
    background: #ffffff;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px 24px 24px 24px;
    &_group{
      width: 832px;
      height: 422px;
      margin: 24px 0;
      display: flex;
      gap: 16px;
    }
    
    &_search-input{
      margin-top: 8px;
    }
    
    &_choose-option{
      width: 404px;
      height: 100%;
      &:nth-child(2){
        background: #F7F9FD;
        padding: 16px;
      }
    }
    
    &_list-options{
      width: 404px;
      height: 344px;
      overflow: auto;
      margin-top: 16px;
    }
    
    &_options{
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      cursor: pointer;
      &[data-default = true]{
        cursor: not-allowed;
        .consignement__checkbox-main{
          opacity: 0.5;
        }
      }
    }
    
    &_list-selective{
       width: 100%;
      height: 344px;
      overflow: auto;
      margin-top: 16px;
    }
    
    &_action-cancel{
      width: 110px !important;
      height: 32px;
      font-size: 14px;
      padding: 0 !important;
      line-height: 20px !important;
    }
    
    &_action-export{
      width: 97px !important;
      height: 32px;
      font-size: 14px;
      padding: 0 !important;
      margin-left: 8px;
      line-height: 20px !important;
    }
  }
`